import settings from "../../../config/settings";
import { store } from "../../../redux/store";
import actions from "../../../redux/settings/users/actions";
import { showNotification } from "components/utility/helper";

/* const getValuesFromLocationHash = (hash) => {
	const arr = hash.split("&");
	const values = {};
	arr.forEach((element) => {
		let temp = element.split("=");
		values[temp[0]] = temp[1];
	});
	return values;
}; */

let apiCount = 0;
let notify = false;
function GmailIntegration() {
	const location = window.location;
	const searchParams = new URLSearchParams(location.search);
	const code = searchParams.get("code");
	const state = searchParams.get("state");
	const error = searchParams.get("error");
	const authState = localStorage.getItem("gmail-oauth-state");
	const scopesApproved = searchParams.get("scope");
	localStorage.removeItem("gmail-oauth-state");
	if (error === null && code !== null && apiCount < 1 && state === authState) {
		if (scopesApproved.includes("https://www.googleapis.com/auth/gmail.send")) {
			apiCount = 1;
			const isUpdate = localStorage.getItem("gmail-update");
			const integrationId = localStorage.getItem("gmail-integration-id");
			localStorage.removeItem("gmail-update");
			localStorage.removeItem("gmail-integration-id");
			if (!isUpdate) {
				store.dispatch({
					type: actions.CREATE_INTEGRATION_TOKEN,
					payload: {
						integration_type: "gmail",
						code: code,
					},
				});
			} else {
				store.dispatch({
					type: actions.UPDATE_INTEGRATION_TOKEN,
					payload: {
						integration_type: "gmail",
						code: code,
						id: integrationId,
					},
				});
			}
		} else {
			showNotification({
				type: "error",
				message: "Failed",
				description: "Insufficient permissions provided!",
			});
			notify = true;
			setTimeout(() => {
				window.close();
			}, 3000);
		}
	}

	if (
		location.href !== `${location.origin}/gmail-integration` &&
		error !== null &&
		notify === false
	) {
		showNotification({
			type: "error",
			message: error,
			description: "Failed to authorize Gmail!",
		});
		notify = true;
		setTimeout(() => {
			window.close();
		}, 3000);
	}

	if (location.href === `${location.origin}/gmail-integration`) {
		const state = Math.random().toString(16).slice(2);
		localStorage.setItem("gmail-oauth-state", state);
		const uri = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
			settings.GOOGLE_OAUTH_CLIENT_ID
		}&redirect_uri=${
			settings.APP_BASE_URL
		}gmail-integration&response_type=code&scope=https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.email&access_type=offline&state=${localStorage.getItem(
			"gmail-oauth-state",
		)}&prompt=select_account&include_granted_scopes=true`;
		window.location = uri;
	}
	return null;
}

export default GmailIntegration;