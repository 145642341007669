const actions = {
	GET_ADMIN_MEETINGS_LIST: "GET_ADMIN_MEETINGS_LIST",
	GET_ADMIN_MEETINGS_LIST_SUCCESS: "GET_ADMIN_MEETINGS_LIST_SUCCESS",
	GET_ADMIN_MEETINGS_LIST_FAILURE: "GET_ADMIN_MEETINGS_LIST_FAILURE",
	SET_ADMIN_MEETINGS_DRAWER_VISIBLE: "SET_ADMIN_MEETINGS_DRAWER_VISIBLE",
	ADMIN_MEETINGS_SELECTED_LIST: "ADMIN_MEETINGS_SELECTED_LIST",
	DELETE_ADMIN_MEETINGS_CONFIRMATION_MODAL_VISIBLE:
		"DELETE_ADMIN_MEETINGS_CONFIRMATION_MODAL_VISIBLE",
	DELETE_ADMIN_MEETINGS_LIST: "DELETE_ADMIN_MEETINGS_LIST",
	DELETE_ADMIN_MEETINGS_LIST_SUCCESS: "DELETE_ADMIN_MEETINGS_LIST_SUCCESS",
	DELETE_ADMIN_MEETINGS_LIST_FAILURE: "DELETE_ADMIN_MEETINGS_LIST_FAILURE",
	SET_ADMIN_MEETINGS_TABLE_SIZE: "SET_ADMIN_MEETINGS_TABLE_SIZE",
	SET_ADMIN_MEETINGS_TABLE_CURRENT_PAGE:
		"SET_ADMIN_MEETINGS_TABLE_CURRENT_PAGE",
	SET_ADMIN_MEETINGS_TABLE_SORT_ORDER: "SET_ADMIN_MEETINGS_TABLE_SORT_ORDER",
	CREATE_NEW_ADMIN_MEETINGS: "CREATE_NEW_ADMIN_MEETINGS",
	CREATE_NEW_ADMIN_MEETINGS_SUCCESS: "CREATE_NEW_ADMIN_MEETINGS_SUCCESS",
	CREATE_NEW_ADMIN_MEETINGS_FAILURE: "CREATE_NEW_ADMIN_MEETINGS_FAILURE",
	UPDATE_ADMIN_MEETINGS_CONTENT: "UPDATE_ADMIN_MEETINGS_CONTENT",
	UPDATE_ADMIN_MEETINGS_CONTENT_SUCCESS:
		"UPDATE_ADMIN_MEETINGS_CONTENT_SUCCESS",
	UPDATE_ADMIN_MEETINGS_CONTENT_FAILURE:
		"UPDATE_ADMIN_MEETINGS_CONTENT_FAILURE",
	RESET_ADMIN_MEETINGS_DATA: "RESET_ADMIN_MEETINGS_DATA",
	GET_ADMIN_MEETINGS_TOKEN: "GET_ADMIN_MEETINGS_TOKEN",
	GET_ADMIN_MEETINGS_TOKEN_SUCCESS: "GET_ADMIN_MEETINGS_TOKEN_SUCCESS",
	GET_ADMIN_MEETINGS_TOKEN_FAILURE: "GET_ADMIN_MEETINGS_TOKEN_FAILURE",
	SET_ADMIN_MEETING_DATA: "SET_ADMIN_MEETING_DATA",
	RESET_ADMIN_MEETING_DATA: "RESET_ADMIN_MEETING_DATA",
	SET_ADMIN_MEETINGS_APPROVE_UNAPPROVE_KEY:
		"SET_ADMIN_MEETINGS_APPROVE_UNAPPROVE_KEY",
	APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS: "APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS",
	APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_SUCCESS:
		"APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_SUCCESS",
	APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_FAILURE:
		"APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_FAILURE",
};

export default actions;
