const actions = {
	CHANGE_MOMENT_MODAL_STATUS: "CHANGE_MOMENT_MODAL_STATUS",
	GET_TEMPLATES_LIST: "GET_TEMPLATES_LIST",
	ALL_TEMPLATES: "ALL_TEMPLATES",
	ADD_RECIPIENT_COUNT: "ADD_RECIPIENT_COUNT",
	GET_EMPLOYEES_LIST: "GET_EMPLOYEES_LIST",
	CREATE_MOMENT: "CREATE_MOMENT",
	GET_MOMENTS: "GET_MOMENTS",
	MOMENTS: "MOMENTS",
	SET_MOMENT_SIDE_PANEL: "SET_MOMENT_SIDE_PANEL",
	GET_MOMENT: "GET_MOMENT",
	MOMENT: "MOMENT",
	SET_TEMPLATE_URL: "SET_TEMPLATE_URL",
	SET_LIST_PAGE_SIZE: "SET_LIST_PAGE_SIZE",
	SET_MOMENT_PAGINATION: "SET_MOMENT_PAGINATION",
	GET_EMP_PAGE_RENDERING_DATA: "GET_EMP_PAGE_RENDERING_DATA",
	EMP_RENDERING_DATA: "EMP_RENDERING_DATA",
	GET_ROLES: "GET_ROLES",
	COMPANY_ROLES: "COMPANY_ROLES",
	SET_ROLE_EMPMOMENT: "SET_ROLE_EMPMOMENT",
	VALIDATE_EMPLOYEE_ROLE: "VALIDATE_EMPLOYEE_ROLE",
	VALIDATED_EMPLOYEE_MOMENT: "VALIDATED_EMPLOYEE_MOMENT",
	CONTRIBUTION_DRAWER_STATUS: "CONTRIBUTION_DRAWER_STATUS",
	CREATE_CONTRIBUTION: "CREATE_CONTRIBUTION",
	CONTRIBUTION_DATA: "CONTRIBUTION_DATA",
	GET_CONTRIBUTIONS: "GET_CONTRIBUTIONS",
	VIDEO_RECORDER_STATUS: "VIDEO_RECORDER_STATUS",
	EMP_PAGE_TOKEN: "EMP_PAGE_TOKEN",
	GET_ASSET_TOKENS: "GET_ASSET_TOKENS",
	ASSET_TOKENS: "ASSET_TOKENS",
	SET_DESCRIPTION: "SET_DESCRIPTION",
	MOMENT_SELECTED_LIST: "MOMENT_SELECTED_LIST",
	DELETE_MOMENTS_LIST: "DELETE_MOMENTS_LIST",
};
export default actions;
