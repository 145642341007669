import actions from "./actions";
import { defaultPage, defaultPageSize } from "components/constants";
import employeeActions from "redux/employee/actions";

const initState = {
	userTasks: [],
	listPerPage: defaultPageSize,
	currentPage: defaultPage,
	totalTasks: 0,
	taskSortKey: "",
	taskSortValue: "",
	isFetchingTaskDetail: false,
	taskDetails: {},
	isUpdatingTask: false,
	isShowConfetti: false,
	overDueTasks: [],
	totalOverDueTasks: 0,
	userOpenTasks: [],
	overDueTaskPage: 1,
	totalOpenTasks: 0,
};

export default function TaskReducer(state = initState, action) {
	switch (action.type) {
		case actions.FETCH_USER_TASKS_SUCCESS:
			const openTasks = action.payload.tasks.filter(
				(task) => task.user_status !== "completed",
			);
			return {
				...state,
				userTasks: action.payload.tasks,
				userOpenTasks: action.isInfinite
					? state.userOpenTasks.concat(openTasks)
					: openTasks,
				totalTasks: action.payload.total_records,
				totalOpenTasks: action.payload.open_tasks,
				taskLoader: false,
				taskInitialLoader: false,
			};
		case actions.FETCH_USER_TASKS:
			return {
				...state,
				taskLoader: true,
				taskInitialLoader: action.initialFetch,
			};
		case actions.FETCH_USER_TASKS_FAILURE:
			return {
				...state,
				taskLoader: false,
				taskInitialLoader: false,
			};
		case actions.SET_TASK_PAGE_SIZE:
			return {
				...state,
				listPerPage: action.payload,
			};
		case actions.SET_TASK_PAGINATION:
			return {
				...state,
				currentPage: action.payload,
			};
		case actions.SET_TASK_SORT_VALUES:
			return {
				...state,
				taskSortKey: action.sortKey,
				taskSortValue: action.sortOrder,
			};
		case actions.FETCH_USER_TASK_CONTENT:
			return {
				...state,
				isFetchingTaskDetail: true,
			};
		case actions.FETCH_USER_TASK_CONTENT_SUCCESS:
			return {
				...state,
				isFetchingTaskDetail: false,
				taskDetails: action.payload.task,
			};
		case actions.FETCH_USER_TASK_CONTENT_FAILURE:
			return {
				...state,
				isFetchingTaskDetail: false,
			};
		case actions.UPDATE_USER_TASK:
			return {
				...state,
				isUpdatingTask: true,
			};
		case actions.UPDATE_USER_TASK_SUCCESS:
			return {
				...state,
				isUpdatingTask: false,
				taskDetails:
					parseInt(action.params.id) ===
					parseInt(action.payload.employee_event_id)
						? action.payload
						: state.taskDetails,
				isShowConfetti: action.params.payload.user_status ? true : false,
			};
		case actions.TASK_SET_CONFETTI_VISIBLE:
			return {
				...state,
				isShowConfetti: action.payload,
			};
		case actions.UPDATE_USER_TASK_FAILURE:
			return {
				...state,
				isUpdatingTask: false,
			};
		case actions.REMOVE_USER_UPLOADED_FILES_SUCCESS:
			return {
				...state,
				isUpdatingTask: false,
				taskDetails: {
					...state.taskDetails,
					files:
						action.ids.length === 1
							? state.taskDetails.files.filter(
									(file) => file.attachment_id !== action.ids[0],
							  )
							: [],
				},
			};
		case actions.REMOVE_USER_UPLOADED_FILES_FAILURE:
			return {
				...state,
				isUpdatingTask: false,
			};
		case employeeActions.REMOVE_UPLOADED_FILES:
			return {
				...state,
				isUpdatingTask: true,
			};
		case employeeActions.SET_FORM_UPDATE_LOADER:
			return {
				...state,
				isUpdatingTask: action.payload,
			};
		case actions.FETCH_OVERDUE_TASKS:
			return {
				...state,
				isFetchingOverdueTasks: true,
			};
		case actions.FETCH_OVERDUE_TASKS_SUCCESS:
			return {
				...state,
				overDueTasks: action.payload.tasks,
				totalOverDueTasks: action.payload.tasks?.length,
				isFetchingOverdueTasks: false,
			};
		case actions.FETCH_OVERDUE_TASKS_FAILURE:
			return {
				...state,
				isFetchingOverdueTasks: false,
			};
		case actions.SET_OVER_DUE_TASK_PAGINATION:
			return {
				...state,
				overDueTaskPage: action.payload,
			};
		case actions.SET_USER_TASK_DETAILS:
			return {
				...state,
				taskDetails: {},
			};
		default:
			return state;
	}
}
