const actions = {
	CHANGE_ROLES_MODAL_VISIBLE: "CHANGE_ROLES_MODAL_VISIBLE",
	FETCH_USER_ROLES: "FETCH_USER_ROLES",
	FETCH_USER_ROLES_FAILURE: "FETCH_USER_ROLES_FAILURE",
	FETCH_USER_ROLES_SUCCESS: "FETCH_USER_ROLES_SUCCESS",
	FETCH_PERMISSIONS: "FETCH_PERMISSIONS",
	FETCH_PERMISSIONS_SUCCESS: "FETCH_PERMISSIONS_SUCCESS",
	FETCH_PERMISSIONS_FAILURE: "FETCH_PERMISSIONS_FAILURE",
	UPDATE_SELECTED_ROLES: "UPDATE_SELECTED_ROLES",
	SET_ROLES_PAGE_SIZE: "SET_ROLES_PAGE_SIZE",
	SET_ROLES_PAGINATION: "SET_ROLES_PAGINATION",
	CREATE_ROLE: "CREATE_ROLE",
	CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
	CREATE_ROLE_FAILURE: "CREATE_ROLE_FAILURE",
	UPDATE_ROLE: "UPDATE_ROLE",
	UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
	UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",
	SHOW_DELETE_CONFIRM_MODAL: "SHOW_DELETE_CONFIRM_MODAL",
	DELETE_ROLE: "DELETE_ROLE",
	DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
	DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",
	UPDATE_ROLE_DATA: "UPDATE_ROLE_DATA",
	GET_ROLE_DATA: "GET_ROLE_DATA",
	GET_ROLE_DATA_SUCCESS: "GET_ROLE_DATA_SUCCESS",
	GET_ROLE_DATA_FAILURE: "GET_ROLE_DATA_FAILURE",
	SET_ROLE_PERMISSIONS: "SET_ROLE_PERMISSIONS",
	SET_ROLE_SORT_ORDER: "SET_ROLE_SORT_ORDER",
	SET_ROLE_LOADER: "SET_ROLE_LOADER",
};
export default actions;
