import actions from "redux/employee/actions";
import {
	defaultLocation,
	defaultPage,
	defaultPageSize,
	defaultPosition,
	defaultShape,
	tabHome,
} from "components/constants";
import { getChatMessages, getCustomMessage, isValid } from "redux/helper";
import moment from "moment";

const initState = {
	isFetchingCompany: false,
	bgImageKey: "",
	logoKey: "",
	imageLoader: false,
	isUpdatingCompany: false,
	profileImageKey: null,
	company: {},
	isSendingMessage: false,
	employeeDetails: {},
	taskDetails: {},
	isFetchingTaskDetail: false,
	activeEmployeeTab: tabHome,
	employeeTasks: [],
	isFetchingEmployeeTasks: false,
	perPage: defaultPageSize,
	page: defaultPage,
	totalTasks: 0,
	isFetchingEmployee: false,
	profileImageUrl: null,
	isFetchingInitialEmployeeTasks: false,
	isUpdatingTask: false,
	isShowSelectionModal: false,
	isShowImageCapture: false,
	isShowConfetti: false,
	chatMessages: [],
	markAsCompleteBgColor: null,
	markAsCompleteTextColor: null,
	subDomain: null,
	nextTaskId: null,
	isFormSubmitted: true,
	pageStatisticsId: null,
};
export default function employeeReducer(state = initState, action) {
	switch (action.type) {
		case actions.FETCH_COMPANY_PROFILE_DETAILS:
			return {
				...state,
				isFetchingCompany: true,
			};
		case actions.FETCH_COMPANY_PROFILE_DETAILS_SUCCESS:
			const { chat_widget_position, chat_widget_shape, direction } =
				action.payload;
			return {
				...state,
				isFetchingCompany: false,
				company: {
					...action.payload,
					direction: isValid(direction) ? direction : defaultLocation,
					chat_widget_shape: isValid(chat_widget_shape)
						? chat_widget_shape
						: defaultShape,
					chat_widget_position: isValid(chat_widget_position)
						? chat_widget_position
						: defaultPosition,
				},
			};
		case actions.FETCH_COMPANY_PROFILE_DETAILS_FAILURE:
			return {
				...state,
				isFetchingCompany: false,
			};
		case actions.UPLOAD_PROFILE_IMAGE_SUCCESS:
			return {
				...state,
				profileImageKey: action.signedId,
				imageLoader: false,
			};
		case actions.SET_PROFILE_IMAGE_LOADER:
			return {
				...state,
				imageLoader: action.payload,
			};
		case actions.SEND_MESSAGE:
			return {
				...state,
				isSendingMessage: true,
				defaultChatMessage: action.payload.employee.message_content,
			};
		case actions.SEND_MESSAGE_SUCCESS:
			return {
				...state,
				isSendingMessage: false,
				defaultChatMessage: "",
				chatMessages: state.chatMessages.concat(action.payload),
			};
		case actions.SEND_MESSAGE_FAILURE:
			return {
				...state,
				isSendingMessage: false,
			};
		case actions.FETCH_EMPLOYEE_DETAILS:
			return {
				...state,
				isFetchingEmployee: true,
			};
		case actions.FETCH_EMPLOYEE_DETAILS_SUCCESS:
			return {
				...state,
				employeeDetails: action.payload,
				isFetchingEmployee: false,
				profileImageUrl: action.payload.avatar_url,
				chatMessages: getChatMessages(
					state.company,
					action.payload.chat_messages,
				),
			};
		case actions.FETCH_EMPLOYEE_DETAILS_FAILURE:
			return {
				...state,
				isFetchingEmployee: false,
			};
		case actions.FETCH_TASK_CONTENT:
			return {
				...state,
				isFetchingTaskDetail: true,
			};
		case actions.FETCH_TASK_CONTENT_SUCCESS:
			return {
				...state,
				taskDetails: action.payload,
				isFetchingTaskDetail: false,
				nextTaskId: action.payload.next_task_id,
			};
		case actions.FETCH_TASK_CONTENT_FAILURE:
			return {
				...state,
				isFetchingTaskDetail: false,
			};
		case actions.SET_ACTIVE_EMPLOYEE_TAB:
			return {
				...state,
				activeEmployeeTab: action.payload,
			};
		case actions.FETCH_EMPLOYEE_TASKS:
			return {
				...state,
				isFetchingEmployeeTasks: true,
				isFetchingInitialEmployeeTasks: action.initialFetch,
			};
		case actions.FETCH_EMPLOYEE_TASKS_SUCCESS:
			return {
				...state,
				employeeTasks: action.payload.tasks,
				isFetchingEmployeeTasks: false,
				isFetchingInitialEmployeeTasks: false,
				totalTasks: action.payload.total_records,
			};
		case actions.FETCH_EMPLOYEE_TASKS_FAILURE:
			return {
				...state,
				isFetchingEmployeeTasks: false,
				isFetchingInitialEmployeeTasks: false,
			};
		case actions.SET_EMPLOYEE_TASKS_PAGE:
			return {
				...state,
				page: action.payload,
			};
		case actions.SET_EMPLOYEE_TASKS_PAGE_SIZE:
			return {
				...state,
				perPage: action.payload,
			};
		case actions.UPDATE_EMPLOYEE_TASK:
			return {
				...state,
				isUpdatingTask: true,
			};
		case actions.UPDATE_EMPLOYEE_TASK_SUCCESS:
			return {
				...state,
				isUpdatingTask: false,
				taskDetails:
					parseInt(action.params.id) ===
					parseInt(action.payload.employee_event_id)
						? action.payload
						: state.taskDetails,
				isShowConfetti: action.params.payload.employee_status ? true : false,
			};
		case actions.UPDATE_EMPLOYEE_TASK_FAILURE:
			return {
				...state,
				isUpdatingTask: false,
			};
		case actions.SET_FORM_UPDATE_LOADER:
			return {
				...state,
				isUpdatingTask: action.payload,
			};
		case actions.REMOVE_UPLOADED_FILES:
			return {
				...state,
				isUpdatingTask: true,
			};
		case actions.REMOVE_UPLOADED_FILES_SUCCESS:
			return {
				...state,
				isUpdatingTask: false,
				taskDetails: {
					...state.taskDetails,
					files:
						action.ids.length === 1
							? state.taskDetails.files.filter(
									(file) => file.attachment_id !== action.ids[0],
							  )
							: [],
				},
			};
		case actions.REMOVE_UPLOADED_FILES_FAILURE:
			return {
				...state,
				isUpdatingTask: false,
			};
		case actions.SET_SHOW_SELECTION_MODAL:
			return {
				...state,
				isShowSelectionModal: action.payload,
			};
		case actions.SET_IMAGE_CAPTURE_MODAL:
			return {
				...state,
				isShowImageCapture: action.payload,
			};
		case actions.SET_CONFETTI_VISIBLE:
			return {
				...state,
				isShowConfetti: action.payload,
			};
		case actions.SET_TASK_BUTTON_COLOR:
			return {
				...state,
				markAsCompleteBgColor: action.bgColor,
				markAsCompleteTextColor: action.textColor,
			};
		case actions.SET_EMPLOYEE_TASKS:
			return {
				...state,
				employeeTasks: action.payload,
			};
		case actions.SET_SUB_DOMAIN:
			return {
				...state,
				subDomain: action.payload,
			};
		case actions.SET_EMPLOYEE_TASK_DETAILS:
			return {
				...state,
				taskDetails: action.payload,
			};
		case actions.SET_TASK_SUBMIT_STATUS:
			return {
				...state,
				isFormSubmitted: action.payload,
			};
		case actions.ADD_CUSTOM_MESSAGE:
			const messages = state.company.custom_message
				? state.chatMessages.concat(getCustomMessage(state.company, moment()))
				: state.chatMessages;
			return {
				...state,
				chatMessages: messages,
			};
		case actions.CREATE_PAGE_VIEWS_SUCCESS:
			return {
				...state,
				pageStatisticsId: action.payload.page_statistics_id,
			};
		case actions.UPDATE_PAGE_VIEWS_SUCCESS:
			return {
				...state,
				pageStatisticsId: null,
			};
		default:
			return state;
	}
}
