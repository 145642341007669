const actions = {
	FETCH_ADMIN_TASKS: "FETCH_ADMIN_TASKS",
	FETCH_ADMIN_TASKS_SUCCESS: "FETCH_ADMIN_TASKS_SUCCESS",
	FETCH_ADMIN_TASKS_FAILURE: "FETCH_ADMIN_TASKS_FAILURE",
	GET_ADMIN_TASK_DATA: "GET_ADMIN_TASK_DATA",
	GET_ADMIN_TASK_DATA_SUCCESS: "GET_ADMIN_TASK_DATA_SUCCESS",
	GET_ADMIN_TASK_DATA_FAILURE: "GET_ADMIN_TASK_DATA_FAILURE",
	SET_ADMIN_TASK_DRAWER_VISIBLE: "SET_ADMIN_TASK_DRAWER_VISIBLE",
	ADMIN_TASK_SELECTED_LIST: "ADMIN_TASK_SELECTED_LIST",
	SET_ADMIN_TASK_TABLE_SORT_ORDER: "SET_ADMIN_TASK_TABLE_SORT_ORDER",
	SET_ADMIN_TASK_TABLE_SIZE: "SET_ADMIN_TASK_TABLE_SIZE",
	SET_ADMIN_TASK_TABLE_CURRENT_PAGE: "SET_ADMIN_TASK_TABLE_CURRENT_PAGE",
	DELETE_ADMIN_TASKS: "DELETE_ADMIN_TASKS",
	DELETE_ADMIN_TASKS_SUCCESS: "DELETE_ADMIN_TASKS_SUCCESS",
	DELETE_ADMIN_TASKS_FAILURE: "DELETE_ADMIN_TASKS_FAILURE",
	UPDATE_ADMIN_CURRENT_TASK_ID: "UPDATE_ADMIN_CURRENT_TASK_ID",
	CREATE_ADMIN_TASK: "CREATE_ADMIN_TASK",
	UPDATE_ADMIN_TASK: "UPDATE_ADMIN_TASK",
	CREATE_ADMIN_TASK_SUCCESS: "CREATE_ADMIN_TASK_SUCCESS",
	CREATE_ADMIN_TASK_FAILURE: "CREATE_ADMIN_TASK_FAILURE",
	UPDATE_ADMIN_TASK_SUCCESS: "UPDATE_ADMIN_TASK_SUCCESS",
	UPDATE_ADMIN_TASK_FAILURE: "UPDATE_ADMIN_TASK_FAILURE",
	DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE:
		"DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE",
	RESET_CURRENT_TASK: "RESET_CURRENT_TASK",
	SET_ADMIN_TASK_APPROVE_UNAPPROVE_KEY: "SET_ADMIN_TASK_APPROVE_UNAPPROVE_KEY",
	APPROVE_OR_UNAPPROVE_ADMIN_TASK: "APPROVE_OR_UNAPPROVE_ADMIN_TASK",
	APPROVE_OR_UNAPPROVE_ADMIN_TASK_SUCCESS:
		"APPROVE_OR_UNAPPROVE_ADMIN_TASK_SUCCESS",
	APPROVE_OR_UNAPPROVE_ADMIN_TASK_FAILURE:
		"APPROVE_OR_UNAPPROVE_ADMIN_TASK_FAILURE",
	REMOVE_ATTACHMENTS: "REMOVE_ATTACHMENTS",
	REMOVE_ATTACHMENTS_SUCCESS: "REMOVE_ATTACHMENTS_SUCCESS",
	REMOVE_ATTACHMENTS_FAILURE: "REMOVE_ATTACHMENTS_FAILURE",
};

export default actions;
