import React from "react";
import { Avatar } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import TimeGroup from "components/Employee/TimeGroup";
import profileAvatar from "assets/images/profile-avatar.png";
import { ChatMessage } from "styles/Employee.style";

function MessageContent({ messages, message, index, isFilter }) {
	const { employeeDetails, company } = useSelector((state) => state.Employee);
	let displayTime = messages.length - 1 === index;
	if (messages.length > index + 1) {
		displayTime =
			displayTime ||
			moment(messages[index + 1].created_at).diff(
				moment(message.created_at),
				"minutes",
			) >= 5 ||
			company.custom_message;
	}
	const isCustomMessage = !message.employee_id;
	return (
		<ChatMessage
			chatColor={company.chat_widget_color}
			isFilter={isFilter}
			isCustomMessage={isCustomMessage}
		>
			<div className={"group"}>
				{isCustomMessage && (
					<Avatar
						src={company.logo_url ? company.logo_url : profileAvatar}
						size={30}
					/>
				)}
				<div className={"bubble"}>{message.message_content}</div>
				{!isCustomMessage && (
					<Avatar
						src={
							employeeDetails.avatar_url
								? employeeDetails.avatar_url
								: profileAvatar
						}
						size={30}
						style={{ visibility: displayTime ? "visible" : "hidden" }}
					/>
				)}
			</div>
			<TimeGroup
				message={message}
				displayTime={displayTime}
				isCustomMessage={isCustomMessage}
			/>
		</ChatMessage>
	);
}
export default MessageContent;
