import actions from "../moments/actions";
import { defaultSortKey, defaultSortOrder } from "../../components/constants";

const initState = {
	isMomentsModalVisible: false,
	all_template: [],
	recipientCount: [],
	employeesList: [],
	momentsList: [],
	totalRecords: 0,
	sidePanel: false,
	moment: "",
	empMoments: [],
	employeeId: [],
	listPerPage: 10,
	currentPage: 1,
	listSortKey: defaultSortKey,
	listSortValue: defaultSortOrder,
	pagesModalLoader: false,
	pageRenderingContent: "",
	pageRenderingName: "",
	pageToken: [],
	selectedEmployees: [],
	roles: [],
	selectedRoleId: "",
	roleValidated: "",
	contributionDrawer: false,
	createContributions: [],
	videoRecorderStatus: false,
	empPageToken: "",
	assetTokens: [],
	description: "",
	selectedMomentList: [],
};

export default function momentsReducer(state = initState, action) {
	switch (action.type) {
		case actions.CHANGE_MOMENT_MODAL_STATUS:
			return {
				...state,
				isMomentsModalVisible: action.payload,
				recipientCount: [],
				employeeId: [],
				pageToken: [],
				selectedEmployees: [],
				selectedRoleId: "",
			};
		case actions.GET_TEMPLATES_LIST:
			return {
				...state,
			};
		case actions.ALL_TEMPLATES:
			return {
				...state,
				all_template: action.payload,
			};
		case actions.ADD_RECIPIENT_COUNT:
			return {
				...state,
				recipientCount: action.payload.recipientCount,
				selectedEmployees: action.payload.selectedEmployees,
			};
		case actions.GET_EMPLOYEES_LIST:
			return {
				...state,
				employeesList: action.payload,
			};
		case actions.CREATE_MOMENT:
			return {
				...state,
			};
		case actions.GET_MOMENTS:
			return {
				...state,
			};
		case actions.MOMENTS:
			return {
				...state,
				momentsList: action.payload.moments,
				totalRecords: action.payload.total_records,
			};
		case actions.SET_MOMENT_SIDE_PANEL:
			return {
				...state,
				sidePanel: action.payload,
			};
		case actions.MOMENT:
			return {
				...state,
				moment: action.payload.moment,
				empMoments: action.payload.moment.emp_moments,
			};
		case actions.EMP_PAGE_TOKEN:
			return {
				...state,
				empPageToken: action.payload,
				// templateUrl: action.payload.templateUrl,
				// pageId: action.payload.pageId,
				// employeeId: action.payload.employeeId,
				// pageToken: action.payload.pageToken,
			};
		case actions.SET_LIST_PAGE_SIZE:
			return {
				...state,
				listPerPage: action.payload,
			};
		case actions.SET_MOMENT_PAGINATION:
			return {
				...state,
				currentPage: action.payload,
			};
		case actions.GET_EMP_PAGE_RENDERING_DATA:
			return {
				...state,
			};
		case actions.EMP_RENDERING_DATA:
			return {
				...state,
				pagesModalLoader: true,
				pageRenderingContent: action.payload.raw_json
					? [JSON.parse(action.payload.raw_json)]
					: [],
				pageRenderingName: action.payload.name,
			};
		case actions.GET_ROLES:
			return {
				...state,
			};
		case actions.COMPANY_ROLES:
			return {
				...state,
				roles: action.payload,
			};
		case actions.SET_ROLE_EMPMOMENT:
			return {
				...state,
				selectedRoleId: action.payload,
			};
		case actions.VALIDATED_EMPLOYEE_MOMENT:
			return {
				...state,
				roleValidated: `${action.payload.validate_status}`,
			};
		case actions.CONTRIBUTION_DRAWER_STATUS:
			return {
				...state,
				contributionDrawer: action.payload,
			};
		case actions.CONTRIBUTION_DATA:
			return {
				...state,
				createContributions: action.payload,
			};
		case actions.VIDEO_RECORDER_STATUS:
			return {
				...state,
				videoRecorderStatus: action.payload,
			};
		case actions.ASSET_TOKENS:
			return {
				...state,
				assetTokens: action.payload,
			};
		case actions.SET_DESCRIPTION:
			return {
				...state,
				description: action.payload,
			};
		case actions.MOMENT_SELECTED_LIST:
			return {
				...state,
				selectedMomentList: action.payload,
			};
		default:
			return state;
	}
}
