import React, { useEffect, useState } from "react";
import { Input, Dropdown, Button, Spin } from "antd";
import { SendOutlined, LoadingOutlined } from "@ant-design/icons";
import { getIconStyle, hexToRgbA } from "styles/helpers";
import { MessengerIconStyles } from "styles/Portal.style";
import { messengerIcons } from "components/constants";
import { ChatBoxStyles } from "styles/Employee.style";
import { getLocaleMessages } from "redux/helper";
import actions from "redux/employee/actions";
import { store } from "redux/store";
import variables from "styles/variables";
import PropTypes from "prop-types";
import MessageContent from "components/Employee/MessageContent";
import { scrollToBottom } from "components/utility/helper";
import InjectMessage from "components/utility/intlMessages";

const { TextArea } = Input;

function ChatWidget({
	company,
	isSendingMessage,
	defaultChatMessage,
	bottom,
	left,
	right,
	chatMessages,
	employeeDetails,
}) {
	const [chatVisible, setChatVisible] = useState(false),
		[chatMessage, setChatMessage] = useState(defaultChatMessage),
		isFilter = getIconStyle(company.chat_widget_color);

	function onChangeMessage(event) {
		setChatMessage(event.target.value);
	}

	useEffect(() => {
		setChatMessage(defaultChatMessage);
	}, [defaultChatMessage]);

	function onSendMessage() {
		if (!isSendingMessage && chatMessage && chatMessage.trim() !== "") {
			store.dispatch({
				type: actions.SEND_MESSAGE,
				payload: {
					employee: {
						message_content: chatMessage,
					},
				},
			});
		}
	}

	function onEnterMessage(event) {
		if (!event.shiftKey && event.keyCode === 13) {
			event.preventDefault();
			onSendMessage();
			return false;
		}
	}

	const onVisibleChange = (visible) => {
		setChatVisible(visible);
		if (visible) {
			setTimeout(function () {
				scrollToBottom();
			}, 200);
		}
	};
	return (
		<MessengerIconStyles
			isFilter={isFilter}
			position={"fixed"}
			bottom={bottom}
			left={left}
			right={right}
			shape={company.chat_widget_shape}
		>
			<Dropdown
				overlay={
					<ChatBoxStyles
						textColor={company.text_color}
						widgetColor={company.chat_widget_color}
						isFilter={isFilter}
					>
						<div className={"header"}>
							{messengerIcons[company.chat_widget_icon]}
							<div className={"name"}>
								<InjectMessage id={"employee.reachOut"} />
							</div>
						</div>
						<div className={"message-container"}>
							{chatMessages.length ? (
								<>
									{chatMessages.map((message, index) => (
										<MessageContent
											message={message}
											key={index}
											index={index}
											messages={chatMessages}
											isFilter={isFilter}
										/>
									))}
								</>
							) : (
								<div className={"custom-message"}>
									<img
										src={company.logo_url}
										alt={"logo"}
										style={{ maxHeight: "120px", maxWidth: "120px" }}
									/>
									<div>{company.launcher_welcome_message}</div>
								</div>
							)}
						</div>
						<div className={"composer"}>
							<TextArea
								autoSize={{ minRows: 2, maxRows: 6 }}
								placeholder={getLocaleMessages("placeholder.messageContent")}
								value={chatMessage}
								onChange={onChangeMessage}
								onKeyDown={onEnterMessage}
								maxLength={250}
							/>
							<div className={"send-button"}>
								<Button
									type={"primary"}
									size={"sm"}
									onClick={onSendMessage}
									style={{
										background: company.chat_widget_color,
										borderColor: company.chat_widget_color,
										color: isFilter
											? variables.WHITE_COLOR
											: variables.TEXT_COLOR,
									}}
								>
									{isSendingMessage ? (
										<Spin indicator={<LoadingOutlined />} />
									) : (
										<SendOutlined />
									)}
								</Button>
							</div>
						</div>
					</ChatBoxStyles>
				}
				placement={"topLeft"}
				trigger={"click"}
				visible={chatVisible}
				onVisibleChange={onVisibleChange}
			>
				<div
					style={{
						background: company.chat_widget_color.match("gradient")
							? company.chat_widget_color
							: hexToRgbA(company.chat_widget_color),
					}}
					className={"messenger-icon selected"}
					onClick={() => setChatVisible(!chatVisible)}
				>
					{messengerIcons[company.chat_widget_icon]}
				</div>
			</Dropdown>
		</MessengerIconStyles>
	);
}

ChatWidget.propTypes = {
	company: PropTypes.object,
	isSendingMessage: PropTypes.bool,
	defaultChatMessage: PropTypes.string,
	bottom: PropTypes.string,
	left: PropTypes.string,
	right: PropTypes.string,
	chatMessages: PropTypes.array,
};

ChatWidget.defaultProps = {
	company: {},
	isSendingMessage: false,
	defaultChatMessage: "",
	bottom: "",
	left: "",
	right: "",
	chatMessages: [],
};

export default ChatWidget;
