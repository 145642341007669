import { createGlobalStyle } from "styled-components";
import variables from "styles/variables";

export const ModalGlobalStyles = createGlobalStyle`
   .ant-modal-content {
    .ant-modal-close {
      color: ${variables.PLACEHOLDER_COLOR};
      .anticon-close {
        font-size: ${variables.BASE_FONT_SIZE};
      }
      &:hover {
        color: ${variables.DANGER_COLOR};
      }
    }
    .ant-modal-header {
      text-align: center;
      padding: 20px;
      .ant-modal-title {
        font-size: 18px;
        color: ${variables.TEXT_COLOR_DARK};
        font-weight: 600;
      }
    }
    .ant-modal-body {
      text-align: center;
      .check-box {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        .select-all-column {
          display: flex;
          margin-bottom: 10px;
          margin-left: 0px;
        }
        .ant-checkbox-checked::after {
          border: none;
         }
        .journey-name {
          display: inline-block;
          max-width: 350px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: no-wrap;
        }
      }
    }
    .ant-modal-footer {
      padding: 24px;
      text-align: center;
      .ant-tooltip-disabled-compatible-wrapper {
        margin-right: 8px;
      }
      .ant-btn {
        &.ant-btn-loading {
          padding-left: 15px;
          .anticon-loading {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .confirm-modal {
    .ant-modal-body {
      padding: 34px;
      img {
        margin-right: 10px;
      }
      div {
        vertical-align: middle;
      }
      .confirm-journey-count {
        font-weight: 600;
        padding: ${variables.SQ_SPACING_SMALL} 0;
      }
    }
    .ant-modal-footer {
      .ant-btn {
        min-width: 100px;
      }
    }
  }
  .send-invite-modal {
    .ant-modal-body {
      padding: 40px 70px;
      .text {
        .ant-typography {
          line-height: 1.5;
        }
      }
      #invite-user {
        .ant-form-item {
          text-align: left;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          .ant-form-item-label {
            padding-bottom: 0;
            label {
              height: 25px;
              color: ${variables.TEXT_COLOR};
            }
          }
          .ant-form-item-control {
          .ant-form-item-explain {
            min-height: unset;
            div {
              text-align: left;
            }
          }
         }
        }
      }
    }
  }
  .alert-modal {
    .alert-container {
      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: rgba(42, 40, 40, 0.85);
      display: flex;
      text-align: initial;
      div {
        line-height: inherit;
        padding: 5px;
      }
      .anticon {
        margin-right: 10px;
        margin-top: 5px;
        font-size: x-large;
        color: #faad14;
      }
    }
  }
  .add-employee-modal {
    .ant-modal-body {
      max-height: 500px;
      overflow-y: auto;
      padding: 25px 35px;
      .ant-form {
        .ant-form-item {
          .ant-form-item-label {
            padding : 0;
          }
          .ant-form-item-explain {
            text-align: left;
            padding: 3px;
          }
          .ant-picker {
            width: 100%;
          }
          .ant-select-selection-placeholder, .ant-select-selection-item {
            flex: initial !important;
            margin-left: 2px;
          }
        }
        .extra-fields {
          text-align: left;
          .heading {
            font-size: 16px;
            font-weight: 600;
            margin-right: 10px;
            margin: 10px 0;
          }
          .ant-space {
            width: 100%;
            .ant-space-item:not(:last-child) {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .change-password {
    .ant-modal-body {
        padding: 24px 0 0;
        .ant-form {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .form-group {
            width: 80%
          }
          .ant-form-item {
            &-label {
              padding: 0;
            }
            &:not(:last-child) {
              margin-bottom : ${variables.SQ_SPACING_SMALL};
            }
            .anticon-eye-invisible {
              vertical-align: -${parseInt(variables.SQ_SPACING_XS) + 1}px;
            }
            .ant-form-item-explain {
							margin-top:4px;
              text-align: left;
            }
          }
          .footer-with-button {
            border-top: 1px solid ${variables.MODAL_BORDER_COLOR};
            margin-top:  ${variables.SQ_SPACING_BASE};
            width: 100%;
            padding-top: 20px;
          }
        }
    }
  }
`;
