import { Card } from "antd";
import ReactPlayer from "react-player";
import { PlayCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import smileFace from "../../../assets/images/smile_face.svg";

const ExperienceCard = ({ item, index }) => {
	const [videoStatus, setVideoStatus] = useState("");
	return (
		<Card
			className={`css-1hfw926 element ${
				["light", "blue", "green"][Math.floor(Math.random() * (2 + 1))]
			}gradient`}
			style={{
				"--row": Number.isInteger(index / 2) ? index / 2 : index / 2 - 0.5,
				"animation-direction": Number.isInteger(index / 2)
					? "normal"
					: "reverse",
			}}
		>
			{item.video_url ? (
				<div
					className={"card_content"}
					onMouseEnter={() => setVideoStatus(`${index}`)}
					onMouseLeave={() => setVideoStatus("")}
				>
					{videoStatus === `${index}` ? (
						<>
							<ReactPlayer
								className={"video_wrapper"}
								url={item.video_url}
								height={"450px"}
								width={"100%"}
								playing={videoStatus === `${index}`}
								loop={true}
							/>
						</>
					) : (
						<ReactPlayer
							className={"video_wrapper"}
							url={item.video_url}
							height={"450px"}
							width={"100%"}
							muted={true}
							playing={true}
							loop={true}
						/>
					)}
					{videoStatus === `${index}` ? null : (
						<div className={"card_video-playbtnwrap"}>
							<PlayCircleOutlined />
						</div>
					)}
				</div>
			) : (
				<div className={"card_content text-card"}>
					<div className={"card_content-gifyimgwarap"}>
						{item.image_url ? (
							<div className={"gifyimgwarap-imgwarp"}>
								<img src={item.image_url} alt={"img"} />
							</div>
						) : null}

						{item.giphy_url ? (
							<div className={"gifyimgwarap-gifywarp"}>
								<img src={item.giphy_url} alt={"img"} />
							</div>
						) : null}
					</div>
					<div className={"card_text"} title={item.comment}>
						{item.comment}
					</div>

					<div className={"info"}>
						<div className={"info_imgwrap"}>
							<img
								src={
									item.profile_picture === undefined
										? smileFace
										: item.profile_picture
								}
								alt={"img"}
							/>
						</div>
						<div className={"info_textwarp"}>
							<h4>{item.full_name}</h4>
						</div>
					</div>
				</div>
			)}
		</Card>
	);
};

export default ExperienceCard;
