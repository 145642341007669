import React from "react";
import { menus, templateMenu } from "containers/Sidebar/menus";
import { IntlProvider } from "react-intl";
import {
	generateJourneyJSON,
	getMomentTime,
	getRootUrl,
	setDisplayWelcomePage,
	showNotification,
} from "components/utility/helper";
import { allMessages } from "i18n/I18nProvider";
import { store, history } from "redux/store";
import axios from "axios";
import journeyActions from "redux/journeys/actions";
import {
	approveText,
	hour12TimeFormat,
	onBoardEmployee,
	timeOut2000,
	timeOut3000,
	unApproveText,
} from "components/constants";
import { ArrowRightOutlined } from "@ant-design/icons";
import Helmet from "react-helmet";
import authActions from "redux/auth/actions";
import journeyTemplateActions from "redux/adminUser/journeys/actions";
import {
	BUILDER_RULE_UPDATION_TYPE,
	BUILDER_RULE_CREATION_TYPE,
	BUILDER_RULE_DELETION_TYPE,
	BUILDER_EVENT_CREATION_TYPE,
	BUILDER_EVENT_UPDATION_TYPE,
	BUILDER_EVENT_DELETION_TYPE,
} from "components/constants";
import Bugsnag from "@bugsnag/js";

const bugsNagErrorCodes = [500, 501, 502, 503, 504, 505];

export function getCurrentPath() {
	if (window.location.pathname) {
		const pathname = window.location.pathname;
		for (const menu of [...menus, ...templateMenu]) {
			if (menu.children) {
				for (const children of menu.children) {
					if (pathname.match(`/${children.key}`)) {
						return children.key;
					}
				}
			}
			if (pathname.match(`/${menu.key}`)) {
				return menu.key;
			}
		}
	}
	return menus[0].key;
}

export function handleException(error) {
	const { isLoggedIn, role } = store.getState().Auth;
	if (!error.response?.status) {
		// serverDownError();
	} else if (
		(error.response.config.url.includes("/refresh_token") ||
			error.response.config.url.includes("employees/refresh_token")) &&
		error.response.status === 401 &&
		isLoggedIn
	) {
		window.location.href = "/login";
	} else if (
		error.response.config.url === "/login" ||
		error.response.config.url === "/employees/login"
	) {
		showNotification({
			type: "error",
			message: getLocaleMessages("label.error"),
			description: error.response.data.errors.join(),
		});
	} else if (
		error.response.status === 400 &&
		error.response.config.url.split("/").includes("assign") &&
		error.response.config.url.split("/").includes("journeys")
	) {
		let duration =
			Object.keys(error.response.data.errors[0]).length <= 5
				? 4.5
				: Math.ceil(
						(Object.keys(error.response.data.errors[0]).length - 5) / 4 + 4.5,
				  ) > 30
				? 30
				: Math.ceil(
						(Object.keys(error.response.data.errors[0]).length - 5) / 4 + 4.5,
				  );
		showNotification({
			type: "error",
			message: getLocaleMessages("label.error"),
			description: Object.keys(error.response.data.errors[0]).map(
				(errorKey, index) => (
					<div key={index} className={"content"}>
						<span className={"title"}>{errorKey}</span> <ArrowRightOutlined />
						{error.response.data.errors[0][errorKey].join()}
					</div>
				),
			),
			duration: duration,
			className: "error-notification-assign",
		});
	} else if (error.response) {
		if (bugsNagErrorCodes.includes(error?.response?.status)) {
			Bugsnag.notify(error?.response?.data?.errors?.join());
			showNotification({
				type: "error",
				message: getLocaleMessages("label.error"),
				description: error.response.data.errors.join(),
			});
		} else if (
			error.response.config.url !== "/login" &&
			error.response.config.url !== "/employees/login" &&
			error.response.status === 404
		) {
			if (role === onBoardEmployee) {
				history.push("/employee/notFound");
			} else {
				history.push(`/${getRootUrl()}/notFound`);
			}
		} else {
			Bugsnag.notify(error.response.data.errors.join());
			showNotification({
				type: "error",
				message: getLocaleMessages("label.error"),
				description: error.response.data.errors.join(),
			});
		}
	}
}

export function getLocaleMessages(id) {
	const locale = store.getState().Auth.lang;
	const { intl } = new IntlProvider(
		{ locale, messages: allMessages[locale] },
		{},
	).state;
	let messages = intl?.formatMessage({ id });
	return messages;
}

export function customAPIRequest({ URL, headers, fileData }) {
	return axios
		.put(URL, fileData, {
			headers: { ...headers },
		})
		.then((response) => response);
}

export function fetchCompanyId() {
	return store.getState().Auth.companyId;
}

export function getCompanyUrl() {
	return `companies/${fetchCompanyId()}`;
}

export function calculatePageNumber(total, selectedKeys, perPage) {
	let pages = Math.floor((total - selectedKeys.length) / perPage),
		extra = (total - selectedKeys.length) % perPage;
	if (extra) {
		pages += 1;
	}
	pages = pages ? pages : 1;
	return pages;
}

export function addCreateModule(event, actionType) {
	const { journeyBuilderData } = store.getState().Journeys;
	switch (actionType) {
		case BUILDER_EVENT_CREATION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.id === event.rule_id) {
					rule.events.push(event);
				}
			});
			break;
		}
		case BUILDER_EVENT_UPDATION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.id === event.rule_id) {
					rule.events.forEach((element) => {
						if (element.id === event.id) {
							element = event;
						}
					});
				}
			});
			break;
		}
		default: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.id === event.rule_id) {
					rule.events = rule.events.filer((element) => {
						return element.id !== event.id;
					});
				}
			});
		}
	}
	return generateJourneyJSON(
		journeyBuilderData.rules,
		journeyBuilderData.first_rule_id,
	);
}

export function getAddedEvents(data) {
	const { journeyBuilderData } = store.getState().Journeys;
	let events = [];
	journeyBuilderData.rules.forEach((rule) => {
		events = [...events, ...rule.events];
	});
	return orderModules(events, data);
}

export function setUpdatedRules(entity, updateType) {
	const { journeyBuilderData } = store.getState().Journeys;
	let rules = [];
	switch (updateType) {
		case BUILDER_RULE_CREATION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.next_rule_id === entity.next_rule_id) {
					rule.next_rule_id = entity.id;
				}
				rules.push(rule);
			});
			rules.push(entity);
			break;
		}
		case BUILDER_RULE_UPDATION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.id === entity.id) {
					rule = entity;
				}
				rules.push(rule);
			});

			break;
		}
		case BUILDER_RULE_DELETION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.next_rule_id === entity.id) {
					rule.next_rule_id = entity.next_rule_id;
				}
				if (rule.id !== entity.id) rules.push(rule);
			});
			break;
		}
		case BUILDER_EVENT_CREATION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.id === entity.rule_id) {
					rule.events.push(entity);
				}
				rules.push(rule);
			});
			break;
		}
		case BUILDER_EVENT_UPDATION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.id === entity.rule_id) {
					const events = [];
					rule.events.forEach((event) => {
						if (event.id === entity.id) {
							event = entity;
						}
						events.push(event);
					});
					rule.events = events;
				}
				rules.push(rule);
			});
			break;
		}
		case BUILDER_EVENT_DELETION_TYPE: {
			journeyBuilderData.rules.forEach((rule) => {
				if (rule.id === entity.rule_id) {
					rule.events = rule.events.filter((event) => event.id !== entity.id);
				}
				rules.push(rule);
			});
			break;
		}
		default: {
			rules = journeyBuilderData.rules;
		}
	}
	if (journeyBuilderData.first_rule_id === -1) {
		let journey = journeyBuilderData;
		journey.first_rule_id = entity.id;
		journey.rules.push(entity);
		store.dispatch({
			type: journeyActions.UPDATE_JOURNEY_DATA,
			payload: journey,
		});
	} else {
		store.dispatch({
			type: journeyActions.UPDATE_JOURNEY_RULE_DATA,
			payload: {
				rules,
			},
		});
	}
	return {
		rules,
		firstRuleID:
			journeyBuilderData.first_rule_id === -1
				? entity.id
				: journeyBuilderData.first_rule_id,
	};
}

export function updateAddedEvents(data) {
	const builderData = getFilteredModules(data);
	return orderModules(builderData, data);
}

export function isValid(value) {
	return ![null, undefined].includes(value);
}

export function updateSelectedModule(id, moduleType) {
	store.dispatch({
		type: journeyActions.SET_CREATED_MODULE,
		payload: { id, moduleType },
	});
}

export function orderModules(events, data) {
	// add events with order of schedule time
	let currentDayEvents = events.filter(
			(event) => event.scheduled_day === data.scheduled_day,
		),
		otherEvents = events.filter(
			(event) => event.scheduled_day !== data.scheduled_day,
		);
	for (const [index, event] of currentDayEvents.entries()) {
		const currentModuleTime = getMomentTime(
				event.scheduled_time,
				hour12TimeFormat,
			),
			newModuleTime = getMomentTime(data.scheduled_time, hour12TimeFormat);
		let previousModuleTime = null;
		if (index - 1 > -1) {
			previousModuleTime = getMomentTime(
				currentDayEvents[index - 1].scheduled_time,
				hour12TimeFormat,
			);
		}
		if (index === 0 && newModuleTime.isBefore(currentModuleTime)) {
			currentDayEvents.unshift(data);
			break;
		} else if (
			(newModuleTime.isAfter(previousModuleTime) ||
				newModuleTime.isSame(previousModuleTime)) &&
			newModuleTime.isBefore(currentModuleTime)
		) {
			currentDayEvents.splice(index, 0, data);
			break;
		} else {
			if (index === currentDayEvents.length - 1) {
				currentDayEvents = currentDayEvents.concat(data);
			}
		}
	}
	if (!currentDayEvents.length) {
		currentDayEvents = currentDayEvents.concat(data);
	}
	return [...otherEvents, ...currentDayEvents];
}

export function getFilteredModules(data) {
	const { journeyBuilderData } = store.getState().Journeys;
	let filteredData = [];
	journeyBuilderData.rules.forEach((rule) => {
		let filteredRules = rule.events.filter((event) => event.id !== data.id);
		filteredData = [...filteredRules, ...filteredData];
	});
	return filteredData;
}

export function onDeleteJourney(keys, fromBuilder) {
	store.dispatch({
		type: journeyActions.DELETE_JOURNEY,
		payload: keys,
		fromBuilder,
	});
}

export function onDeleteJourneyTemplate(keys, fromBuilder) {
	store.dispatch({
		type: journeyTemplateActions.DELETE_JOURNEY_TEMPLATE,
		payload: keys,
		fromBuilder,
	});
}

export function handleCancelJourneyDelete() {
	store.dispatch({
		type: journeyActions.JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE,
		payload: false,
	});
}

export function getApprovalStatus(journeyData) {
	if (journeyData.status === "approved") {
		return unApproveText;
	} else {
		return approveText;
	}
}

export function getCurrentUserEmail() {
	return store.getState().Auth.email;
}

export function getURLQueryName(params, key) {
	const query = new URLSearchParams(params);
	return query.get(key);
}

export function handleRenderPageException(error) {
	if (error.response) {
		if (bugsNagErrorCodes.includes(error.response.status)) {
			history.push("/pages/error-page");
		} else if (error.response.status === 404) {
			history.push("/pages/notFound");
		}
	}
}

export function getEmployeeId() {
	return store.getState().Auth.employeeId;
}

export const ReactHelmet = ({ title }) => (
	<Helmet>
		<title>{`${title ? `${title} - ` : ""}${getLocaleMessages(
			"label.branding",
		)}`}</title>
	</Helmet>
);

export const ReactHelmetEmployee = ({ title }) => {
	const { company } = store.getState().Employee;
	return (
		<Helmet>
			<title>{`${title ? `${title} - ` : ""}${
				company.name ? company.name : company?.sub_domain
			}`}</title>
		</Helmet>
	);
};

export function setShowWelcomePage(name) {
	store.dispatch({
		type: authActions.SET_SHOW_WELCOME_PAGE,
		payload: true,
		name,
	});
	setTimeout(function () {
		store.dispatch({
			type: authActions.SET_SHOW_WELCOME_PAGE,
			payload: false,
			name,
		});
		setTimeout(function () {
			setDisplayWelcomePage(false);
		}, timeOut2000);
	}, timeOut3000);
}

export function addCreateModuleTemplate(events) {
	const { journeyBuilderData } = store.getState().AdminJourney;
	return generateJourneyJSON(journeyBuilderData.rules);
}

export function getAddedEventsTemplate(data) {
	const { journeyBuilderData } = store.getState().AdminJourney;
	let events = [];
	journeyBuilderData.rules.forEach((rule) => {
		events = [...events, ...rule.events];
	});
	return orderModules(events, data);
}

export function getFilteredModulesTemplate(data) {
	const { journeyBuilderData } = store.getState().AdminJourney;
	let filteredData = [];
	journeyBuilderData.rules.forEach((rule) => {
		let filteredRules = rule.events.filter((event) => event.id !== data.id);
		filteredData = [...filteredRules, ...filteredData];
	});
	return filteredData;
}

export function updateAddedEventsTemplate(data) {
	const builderData = getFilteredModulesTemplate(data);
	return orderModules(builderData, data);
}

export function getUserId() {
	return store.getState().Auth.userId;
}

export function generateTaskDetail(formData) {
	return formData.reduce((newData, actualData) => {
		newData[actualData.name] = [
			...(newData[actualData.name] || []),
			actualData,
		];
		return newData;
	}, {});
}

export function getChatMessages(company, messages) {
	if (company.custom_message) {
		let chatMessages = [];
		for (const message of messages) {
			chatMessages = chatMessages.concat([
				message,
				getCustomMessage(company, message.created_at),
			]);
		}
		return chatMessages;
	}
	return messages;
}

export function getCustomMessage(company, time) {
	return {
		message_content: company.custom_message,
		created_at: time,
	};
}

export function serverDownError() {
	// store.dispatch({ type: authActions.AUTHENTICATE_USER_FAILURE });
	// history.push('/server-down');
}
