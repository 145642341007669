import axios from "axios";
import settings from "config/settings";
import { getRoleType } from "components/utility/helper";
import { onBoardEmployee } from "components/constants";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = settings.HOST_URL;

axiosClient.defaults.headers = settings.headers;

axiosClient.defaults.withCredentials = true;

export function getRequest(URL) {
	return axiosClient.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL, payload) {
	return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
	return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function putRequest(URL, payload) {
	return axiosClient.put(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
	return axiosClient.delete(`/${URL}`).then((response) => response);
}

// Unauthorized API (response status 401) request token refreshed here, again same API request called.
axiosClient.interceptors.response.use(null, (error) => {
	if (
		error.config &&
		![
			"/login",
			"/employees/login",
			"/dashboards/create_page_views",
			"/dashboards/update_page_views",
		].includes(error.config.url) &&
		error.response &&
		error.response.status === 401
	) {
		return axios
			.post(
				`${
					getRoleType() === onBoardEmployee
						? `${settings.HOST_URL}/employees/refresh_token`
						: `${settings.HOST_URL}/refresh_token`
				}`,
			)
			.then(() => axios.request(error.config));
	}
	return Promise.reject(error);
});
