import React, { useState } from "react";
import { Menu, Dropdown, Upload, Spin, Avatar, Divider } from "antd";
import InjectMessage from "components/utility/intlMessages";
import actions from "redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "components/utility/helper";
import { getLocaleMessages } from "redux/helper";
import { LoadingOutlined } from "@ant-design/icons";
import variables from "styles/variables";
import ChangePasswordModal from "components/EmployeeTask/ChangePasswordModal";
import {
	appAdmin,
	appSuperAdmin,
	imageTypes,
	onBoardEmployee,
	onBoardUser,
} from "components/constants";
import profileAvatar from "assets/images/profile-avatar.png";
import { capitalize, startCase } from "lodash";

const content = (
	onLogout,
	uploadFileProps,
	loader,
	role,
	onChangePassword,
	name,
	email,
) => (
	<Menu>
		<Menu.Item className={"name"}>{capitalize(name)}</Menu.Item>
		<Menu.Item className={"email"}>{email}</Menu.Item>
		<Menu.Item className={"role"}>{startCase(role)}</Menu.Item>
		<Divider />
		<Menu.Item key="logout" onClick={onLogout} disabled={loader}>
			{loader && <LoadingOutlined />} <InjectMessage id={"profile.logout"} />
		</Menu.Item>
		{role === onBoardEmployee && (
			<Menu.Item key="changePassword" onClick={onChangePassword}>
				<InjectMessage id={"profile.changePassword"} />
			</Menu.Item>
		)}
		<Menu.Item key="editProfile" className={"upload-profile"}>
			<Upload {...uploadFileProps}>
				<InjectMessage id={"profile.editProfile"} />
			</Upload>
		</Menu.Item>
	</Menu>
);

function Profile({ onChangeProfile, size, imageUrl, profileLoader, maxSize }) {
	const { loader, role, name, email } = useSelector((state) => state.Auth);

	const [isDropDownVisible, setIsDropDownVisible] = useState(false);
	const dispatch = useDispatch();

	const onLogout = () => {
		if ([appSuperAdmin, appAdmin, onBoardUser].includes(role)) {
			dispatch({
				type: actions.LOGOUT_USER,
			});
		}
		if (role === onBoardEmployee) {
			dispatch({
				type: actions.LOGOUT_EMPLOYEE,
			});
		}
	};

	const onVisibleChange = (visible) => {
		setIsDropDownVisible(visible);
	};

	const onChangePassword = () => {
		dispatch({
			type: actions.CHANGE_PASSWORD_MODAL_VISIBLE,
			payload: true,
		});
		setIsDropDownVisible(false);
	};

	function onCustomRequest(info) {
		setIsDropDownVisible(false);
		onChangeProfile(info);
	}

	const uploadFileProps = {
		name: "file",
		accept: imageTypes,
		customRequest: onCustomRequest,
		showUploadList: false,
		beforeUpload,
	};

	function beforeUpload(file) {
		const isLt2M = file.size / 1024 / 1024 < maxSize;
		if (!isLt2M) {
			showNotification({
				type: "error",
				message: getLocaleMessages("message.fileSize"),
				description: getLocaleMessages(`description.fileSize${maxSize}`),
			});
		}
		return isLt2M;
	}

	return (
		<>
			<Dropdown
				placement="topCenter"
				overlayClassName={"profile-popover"}
				overlay={content(
					onLogout,
					uploadFileProps,
					loader,
					role,
					onChangePassword,
					name,
					email,
				)}
				trigger={["click"]}
				visible={isDropDownVisible}
				onVisibleChange={onVisibleChange}
			>
				<Avatar src={imageUrl ? imageUrl : profileAvatar} size={size} />
			</Dropdown>
			{profileLoader && (
				<Spin
					indicator={
						<LoadingOutlined style={{ color: variables.WHITE_COLOR }} />
					}
				/>
			)}
			<ChangePasswordModal />
		</>
	);
}

export default React.memo(Profile);
