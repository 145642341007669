import actions from "./actions";
import { defaultSortKey, defaultSortOrder } from "components/constants";
import { isValid } from "../helper";
import { generateTaskDetail } from "redux/helper";

const initState = {
	isImportModalVisible: false,
	listCurrentPage: 1,
	employeesList: [],
	importedList: [],
	employeeCurrentPage: 1,
	employeeSortKey: "",
	employeeSortValue: "",
	listSortKey: defaultSortKey,
	listSortValue: defaultSortOrder,
	listSearchKey: "",
	employeeSearchKey: "",
	selectedEmployeeKeys: [],
	selectedListKeys: [],
	totalLists: null,
	totalEmployees: null,
	listPerPage: 10,
	employeePerPage: 10,
	listLoader: false,
	listInitialLoader: false,
	employeesLoader: false,
	employeesInitialLoader: false,
	progressStatus: 0,
	importsConfirmVisible: false,
	importsDeleteLoader: false,
	employeesDeleteLoader: false,
	employeesConfirmVisible: false,
	selectedListId: null,
	fileUploadingStatus: null,
	employeeExtraColumn: [],
	employeeSelectedColumns: [],
	assignModalVisible: false,
	journeys: [],
	assignModalLoader: false,
	assignButtonLoader: false,
	resumeModalVisible: false,
	resumeModalLoader: false,
	pauseModalVisible: false,
	pauseModalLoader: false,
	journeySearchKey: "",
	journeyPage: 1,
	journeySearchLoader: false,
	journeyListLoader: false,
	fetchingEmployeeDetail: false,
	currentEmployeeId: null,
	currentEmployee: {},
	employeeDetail: {},
	employeeTaskTotalData: 0,
	employeeActiveList: null,
	employeesTaskList: [],
	employeeTaskListCurrentPage: 1,
	employeeTaskListSortKey: "due_date",
	employeeTaskListSortOrder: "desc",
	employeeTaskListPerPage: 10,
	employeeTaskTableLoader: false,
	currentEmployeeLoader: false,
	employeeTaskDetail: [],
	employeeCheckedList: [],
	reOpenModalVisible: false,
	reOpenModalLoader: false,
	employeeTaskDeleteModal: false,
	employeeTaskDeleteLoader: false,
	employeeMeetingList: [],
	meetingSearchKey: "",
	meetingPage: 1,
	meetingSearchLoader: false,
	meetingListLoader: false,
	assignMeetingModal: false,
	showEmployeeRemoveModal: false,
	employeeRemoveLoader: false,
	employeeActiveTab: sessionStorage.getItem("employeeActiveTab") || "",
	inProgressEmployeeList: [],
	inProgressEmployeeCurrentPage: 1,
	inProgressEmployeeTotalCount: null,
	selectedInProgressEmployeeListKeys: [],
	isShowAssignTask: false,
	employeeTaskList: [],
	taskSearchLoader: false,
	taskListLoader: false,
	taskPage: 1,
	taskSearchKey: "",
	currentImportId: sessionStorage.getItem("currentImportId") || null,
	taskInitialLoader: false,
	employeeType: null,
	importDetails: null,
	taskDetails: null,
	isShowEmployeeModal: false,
	isAddingEmployee: false,
	removeEmployeeForSelectedJourneyId: [],
	isGettingEmployeeLog: false,
	activityLogs: [],
	activityLogPage: 1,
	logInitialLoader: false,
	activityFromDate: "",
	activityToDate: "",
	lastLogId: null,
	resendInviteLoader: false,
	timeZone: [],
	slackUsers: [],
	addEmployeesList: [],
	addEmployeesPage: 1,
	filteredEmployeesList: [],
	filteredEmployeesPage: 1,
	filterEmployeesKey: "",
	isFetchingFilteredEmployees: false,
	defaultFilteredEmployees: [],
	scrollLoader: false,
	assignedEmployeesFilterJourneyId: null,
};

export default function employeesReducer(state = initState, action) {
	switch (action.type) {
		case actions.CHANGE_IMPORT_MODAL_STATUS: {
			return {
				...state,
				isImportModalVisible: action.payload,
				progressStatus: 0,
				fileUploadingStatus: "",
			};
		}
		case actions.UPDATE_SELECTED_LIST:
			return {
				...state,
				selectedListKeys: action.payload,
			};
		case actions.SET_LIST_SORT_VALUES:
			return {
				...state,
				listSortKey: action.sortKey,
				listSortValue: action.sortOrder,
			};
		case actions.SET_EMPLOYEE_SORT_VALUES:
			return {
				...state,
				employeeSortKey: action.sortKey,
				employeeSortValue: action.sortOrder,
			};
		case actions.SET_LIST_PAGINATION:
			return {
				...state,
				listCurrentPage: action.payload,
			};
		case actions.UPDATE_SELECTED_EMPLOYEES:
			return {
				...state,
				selectedEmployeeKeys: action.payload,
			};
		case actions.SET_EMPLOYEE_PAGINATION:
			return {
				...state,
				employeeCurrentPage: action.payload,
			};
		case actions.ON_SEARCH_LIST:
			return {
				...state,
				listSearchKey: action.payload,
			};
		case actions.ON_SEARCH_EMPLOYEES:
			return {
				...state,
				employeeSearchKey: action.payload,
			};
		case actions.FETCH_IMPORTED_LIST:
			return {
				...state,
				listLoader: true,
				listInitialLoader: action.initialFetch,
			};
		case actions.FETCH_IMPORTED_LIST_SUCCESS:
			return {
				...state,
				importedList: action.payload.imports,
				totalLists: action.payload.total_records,
				listLoader: false,
				listInitialLoader: false,
			};
		case actions.FETCH_IMPORTED_LIST_FAILURE:
			return {
				...state,
				listLoader: false,
			};
		case actions.FETCH_EMPLOYEES_LIST:
			sessionStorage.setItem("currentImportId", action.importId);
			return {
				...state,
				employeesLoader: true,
				employeesInitialLoader: action.initialFetch,
				currentImportId: action.importId,
			};
		case actions.FETCH_EMPLOYEES_LIST_SUCCESS:
			return {
				...state,
				employeesList: action.payload.employees,
				totalEmployees: action.payload.total_records,
				employeesLoader: false,
				employeesInitialLoader: false,
			};
		case actions.FETCH_EMPLOYEES_LIST_FAILURE:
			return {
				...state,
				employeesLoader: false,
			};
		case actions.SET_LIST_PAGE_SIZE:
			return {
				...state,
				listPerPage: action.payload,
			};
		case actions.SET_EMPLOYEE_PAGE_SIZE:
			return {
				...state,
				employeePerPage: action.payload,
			};
		case actions.GET_PRE_SIGNED_URL: {
			return {
				...state,
				progressStatus: 1,
				fileUploadingStatus: "prepareFile",
			};
		}
		case actions.GET_PRE_SIGNED_URL_FAILURE: {
			return {
				...state,
				progressStatus: 0,
				fileUploadingStatus: "",
			};
		}
		case actions.UPLOAD_TO_S3: {
			return {
				...state,
				progressStatus: 50,
				fileUploadingStatus: "fileUploading",
			};
		}
		case actions.UPLOAD_TO_S3_SUCCESS: {
			return {
				...state,
				progressStatus: 90,
			};
		}
		case actions.UPLOAD_TO_S3_FAILURE: {
			return {
				...state,
				progressStatus: 0,
				fileUploadingStatus: null,
			};
		}
		case actions.UPDATE_UPLOAD_STATUS: {
			return {
				...state,
				progressStatus: 95,
				fileUploadingStatus: "fileVerifying",
			};
		}
		case actions.UPDATE_UPLOAD_STATUS_SUCCESS: {
			return {
				...state,
				progressStatus: 100,
				fileUploadingStatus: "verificationSuccess",
			};
		}
		case actions.UPDATE_UPLOAD_STATUS_FAILURE: {
			return {
				...state,
				progressStatus: 0,
				fileUploadingStatus: null,
			};
		}
		case actions.UPDATE_IMPORTS_CONFIRMATION_MODAL_VISIBLE:
			return {
				...state,
				importsConfirmVisible: action.payload,
			};
		case actions.DELETE_IMPORTS:
			return {
				...state,
				importsDeleteLoader: true,
			};
		case actions.DELETE_EMPLOYEES:
			return {
				...state,
				employeesDeleteLoader: true,
			};
		case actions.SET_IMPORTS_DELETE_LOADER:
			return {
				...state,
				importsDeleteLoader: action.payload,
			};
		case actions.SET_EMPLOYEES_DELETE_LOADER:
			return {
				...state,
				employeesDeleteLoader: action.payload,
			};
		case actions.UPDATE_EMPLOYEES_CONFIRMATION_MODAL_VISIBLE:
			return {
				...state,
				employeesConfirmVisible: action.payload,
			};
		case actions.UPDATE_SELECTED_LIST_ID:
			return {
				...state,
				selectedListId: action.payload,
			};
		case actions.SET_FILE_UPLOADING_STATUS: {
			return {
				...state,
				fileUploadingStatus: null,
			};
		}
		case actions.GET_EMPLOYEE_EXTRA_COLUMNS_SUCCESS: {
			return {
				...state,
				employeeExtraColumn: action.payload,
			};
		}
		case actions.GET_EMPLOYEE_EXTRA_COLUMNS_FAILURE: {
			return {
				...state,
				employeeExtraColumn: [],
			};
		}
		case actions.SET_EMPLOYEE_SELECTED_COLUMNS: {
			return {
				...state,
				employeeSelectedColumns: action.payload,
			};
		}
		case actions.ASSIGN_JOURNEY_CONFIRM_MODAL_VISIBLE:
			return {
				...state,
				assignModalVisible: action.payload,
			};
		case actions.ASSIGN_JOURNEY:
			return {
				...state,
				assignButtonLoader: true,
			};
		case actions.ASSIGN_JOURNEY_SUCCESS:
			return {
				...state,
				assignButtonLoader: false,
				assignModalVisible: false,
				selectedEmployeeKeys: [],
				selectedInProgressEmployeeListKeys: [],
			};
		case actions.ASSIGN_JOURNEY_FAILURE:
			return {
				...state,
				assignButtonLoader: false,
			};
		case actions.FETCH_JOURNEY:
			return {
				...state,
				journeys:
					isValid(state.journeySearchKey) && state.journeyPage === 1
						? []
						: state.journeys,
				journeySearchLoader: action.isSearch,
				journeyListLoader: true,
			};
		case actions.FETCH_JOURNEY_SUCCESS: {
			const journeysList =
				state.journeyPage === 1
					? action.payload
					: state.journeys.concat(action.payload);
			return {
				...state,
				journeys: journeysList,
				journeySearchLoader: false,
				journeyListLoader: false,
			};
		}
		case actions.FETCH_JOURNEY_FAILURE:
			return {
				...state,
				journeySearchLoader: false,
				journeyListLoader: false,
			};
		case actions.RESUME_EMPLOYEES_FROM_JOURNEY_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				resumeModalVisible: action.payload,
				removeEmployeeForSelectedJourneyId: [],
			};
		}
		case actions.PAUSE_EMPLOYEES_FROM_JOURNEY_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				pauseModalVisible: action.payload,
				removeEmployeeForSelectedJourneyId: [],
			};
		}
		case actions.PAUSE_EMPLOYEES_FROM_JOURNEY: {
			return {
				...state,
				pauseModalLoader: true,
			};
		}
		case actions.PAUSE_EMPLOYEES_FROM_JOURNEY_SUCCESS: {
			return {
				...state,
				pauseModalLoader: false,
				pauseModalVisible: false,
				selectedEmployeeKeys: [],
				selectedInProgressEmployeeListKeys: [],
				removeEmployeeForSelectedJourneyId: [],
			};
		}
		case actions.PAUSE_EMPLOYEES_FROM_JOURNEY_FAILURE: {
			return {
				...state,
				pauseModalLoader: false,
				pauseModalVisible: false,
				removeEmployeeForSelectedJourneyId: [],
			};
		}
		case actions.RESUME_EMPLOYEES_FROM_JOURNEY: {
			return {
				...state,
				resumeModalLoader: true,
			};
		}
		case actions.RESUME_EMPLOYEES_FROM_JOURNEY_SUCCESS: {
			return {
				...state,
				resumeModalLoader: false,
				resumeModalVisible: false,
				selectedEmployeeKeys: [],
				selectedInProgressEmployeeListKeys: [],
				removeEmployeeForSelectedJourneyId: [],
			};
		}
		case actions.RESUME_EMPLOYEES_FROM_JOURNEY_FAILURE: {
			return {
				...state,
				resumeModalLoader: false,
				resumeModalVisible: false,
				removeEmployeeForSelectedJourneyId: [],
			};
		}
		case actions.SET_JOURNEY_PAGE:
			return {
				...state,
				journeyPage: action.payload,
			};
		case actions.SET_JOURNEY_SEARCH:
			return {
				...state,
				journeySearchKey: action.payload,
			};
		case actions.FETCH_EMPLOYEE_DETAIL:
			return {
				...state,
				fetchingEmployeeDetail: true,
			};
		case actions.UPDATE_CURRENT_EMPLOYEE_ID:
			return {
				...state,
				currentEmployeeId: action.payload,
			};
		case actions.FETCH_EMPLOYEE_DETAIL_SUCCESS:
			return {
				...state,
				fetchingEmployeeDetail: false,
				employeeDetail: action.payload,
			};
		case actions.FETCH_EMPLOYEE_DETAIL_FAILURE:
			return {
				...state,
				fetchingEmployeeDetail: false,
			};
		case actions.UPDATE_CURRENT_EMPLOYEE:
			return {
				...state,
				currentEmployee: action.payload,
			};
		case actions.UPDATE_EMPLOYEE_SUCCESS:
			return {
				...state,
				employeeDetail: action.payload,
				currentEmployee: action.payload,
			};
		case actions.SET_EMPLOYEE_ACTIVE_LIST: {
			return {
				...state,
				employeeActiveList: action.payload,
			};
		}
		case actions.GET_EMPLOYEE_TASK_LIST: {
			return {
				...state,
				employeeTaskTableLoader: true,
			};
		}
		case actions.GET_EMPLOYEE_TASK_LIST_SUCCESS: {
			let employeeCheckedList = [];
			action.payload.tasks.filter((task) => {
				if (task.status === "completed") {
					employeeCheckedList.push(task.employee_event_id);
				}
				return task;
			});
			return {
				...state,
				employeeCheckedList: employeeCheckedList,
				employeeTaskTableLoader: false,
				employeesTaskList: action.payload.tasks,
				employeeTaskTotalData: action.payload.total_records,
			};
		}
		case actions.GET_EMPLOYEE_TASK_LIST_FAILURE: {
			return {
				...state,
				employeeTaskTableLoader: false,
			};
		}
		case actions.SET_EMPLOYEE_TASK_LIST_SORT_VALUES: {
			return {
				...state,
				employeeTaskListSortKey: action.payload.sortKey,
				employeeTaskListSortOrder: action.payload.sortOrder,
			};
		}
		case actions.SET_EMPLOYEE_TASK_LIST_PAGINATION: {
			return {
				...state,
				employeeTaskListCurrentPage: action.payload,
			};
		}
		case actions.SET_EMPLOYEE_TASK_LIST_PAGE_SIZE: {
			return {
				...state,
				employeeTaskListPerPage: action.payload,
			};
		}
		case actions.GET_EMPLOYEE_TASK_DETAILS: {
			return {
				...state,
				currentEmployeeLoader: true,
			};
		}
		case actions.GET_EMPLOYEE_TASK_DETAILS_SUCCESS: {
			let nonTextAreaArray = [];
			let textAreaArray = [];
			action.payload.form_data.filter((employeeData) => {
				if (employeeData.type !== "textarea") {
					nonTextAreaArray.push(employeeData);
				} else {
					textAreaArray.push(employeeData);
				}
				return employeeData;
			});
			action.payload["form_data"] = [...nonTextAreaArray, ...textAreaArray];
			action.payload["form_data"] = generateTaskDetail(
				action.payload.form_data,
			);
			return {
				...state,
				currentEmployeeLoader: false,
				employeeTaskDetail: [action.payload],
			};
		}
		case actions.GET_EMPLOYEE_TASK_DETAILS_FAILURE: {
			return {
				...state,
				currentEmployeeLoader: false,
			};
		}
		case actions.RESET_EMPLOYEE_TASK_FORM_DATA: {
			return {
				...state,
				employeeTaskDetail: [],
			};
		}
		case actions.REOPEN_TASK_MODAL_VISIBLE: {
			return {
				...state,
				reOpenModalVisible: action.payload,
			};
		}
		case actions.REOPEN_EMPLOYEE_TASK: {
			return {
				...state,
				reOpenModalLoader: true,
			};
		}
		case actions.REOPEN_EMPLOYEE_TASK_SUCCESS: {
			action.payload["form_data"] = generateTaskDetail(
				action.payload.form_data,
			);
			return {
				...state,
				reOpenModalLoader: false,
				reOpenModalVisible: false,
				employeeTaskDetail: [action.payload],
			};
		}
		case actions.REOPEN_EMPLOYEE_TASK_FAILURE: {
			return {
				...state,
				reOpenModalLoader: false,
			};
		}
		case actions.EMPLOYEE_TASK_DELETE_MODAL_VISIBLE: {
			return {
				...state,
				employeeTaskDeleteModal: action.payload,
			};
		}
		case actions.DELETE_EMPLOYEE_TASK: {
			return {
				...state,
				employeeTaskDeleteLoader: true,
			};
		}
		case actions.DELETE_EMPLOYEE_TASK_SUCCESS: {
			return {
				...state,
				employeeTaskDeleteLoader: false,
				employeeTaskDeleteModal: false,
				employeeTaskDetail: [],
			};
		}
		case actions.DELETE_EMPLOYEE_TASK_FAILURE: {
			return {
				...state,
				employeeTaskDeleteLoader: false,
			};
		}
		case actions.ASSIGN_MEETING_CONFIRM_MODAL_VISIBLE:
			return {
				...state,
				assignMeetingModal: action.payload,
			};
		case actions.ASSIGN_MEETING:
			return {
				...state,
				assignButtonLoader: true,
			};
		case actions.ASSIGN_MEETING_SUCCESS:
			return {
				...state,
				assignButtonLoader: false,
				assignMeetingModal: false,
				selectedEmployeeKeys: [],
				selectedInProgressEmployeeListKeys: [],
			};
		case actions.ASSIGN_MEETING_FAILURE:
			return {
				...state,
				assignButtonLoader: false,
			};
		case actions.FETCH_MEETING: {
			return {
				...state,
				employeeMeetingList: state.isSearch ? [] : state.employeeMeetingList,
				meetingSearchLoader: action.isSearch,
				meetingListLoader: true,
			};
		}
		case actions.FETCH_MEETING_SUCCESS: {
			let meetingList =
				state.meetingPage === 1
					? action.payload
					: state.employeeMeetingList.concat(action.payload);
			return {
				...state,
				employeeMeetingList: meetingList,
				meetingSearchLoader: false,
				meetingListLoader: false,
			};
		}
		case actions.FETCH_MEETING_FAILURE: {
			return {
				...state,
				meetingSearchLoader: false,
				meetingListLoader: false,
			};
		}
		case actions.SET_MEETINGS_SEARCH: {
			return {
				...state,
				meetingSearchKey: action.payload,
			};
		}
		case actions.SET_MEETINGS_PAGE: {
			return {
				...state,
				meetingPage: action.payload,
			};
		}
		case actions.RESET_EMPLOYEE_DETAILS: {
			return {
				...state,
				currentEmployeeId: null,
				currentEmployee: {},
				employeeDetail: {},
				employeeTaskTotalData: 0,
				employeeActiveList: null,
				employeesTaskList: [],
				employeeTaskListCurrentPage: 1,
				employeeTaskListSortKey: "due_date",
				employeeTaskListSortOrder: "desc",
				employeeTaskListPerPage: 10,
				employeeCheckedList: [],
			};
		}
		case actions.REMOVE_EMPLOYEE_CONFIRM_MODAL_VISIBLE:
			return {
				...state,
				showEmployeeRemoveModal: action.payload,
				removeEmployeeForSelectedJourneyId: [],
			};
		case actions.REMOVE_EMPLOYEE_FROM_JOURNEY:
			return {
				...state,
				employeeRemoveLoader: true,
			};
		case actions.REMOVE_EMPLOYEE_FROM_JOURNEY_SUCCESS:
			return {
				...state,
				employeeRemoveLoader: false,
				showEmployeeRemoveModal: false,
				selectedInProgressEmployeeListKeys: [],
				selectedEmployeeKeys: [],
				removeEmployeeForSelectedJourneyId: [],
			};
		case actions.REMOVE_EMPLOYEE_FROM_JOURNEY_FAILURE:
			return {
				...state,
				employeeRemoveLoader: false,
			};
		case actions.SET_EMPLOYEE_ACTIVE_TAB: {
			sessionStorage.setItem("employeeActiveTab", action.payload);
			return {
				...state,
				employeeActiveTab: action.payload,
			};
		}
		case actions.SET_INPROGRESS_EMPLOYEE_SORT_VALUES:
			return {
				...state,
				employeeSortKey: action.sortKey,
				employeeSortValue: action.sortOrder,
			};
		case actions.SET_INPROGRESS_EMPLOYEE_PAGINATION:
			return {
				...state,
				employeeCurrentPage: action.payload,
			};
		case actions.ON_SEARCH_INPROGRESS_EMPLOYEES:
			return {
				...state,
				listSearchKey: action.payload,
			};
		case actions.FETCH_INPROGRESS_EMPLOYEE_LIST:
			return {
				...state,
				listLoader: true,
				listInitialLoader: action.initialFetch,
			};
		case actions.FETCH_INPROGRESS_EMPLOYEE_LIST_SUCCESS:
			return {
				...state,
				inProgressEmployeeList: action.payload.employees,
				inProgressEmployeeTotalCount: action.payload.total_records,
				listLoader: false,
				listInitialLoader: false,
			};
		case actions.FETCH_INPROGRESS_EMPLOYEE_LIST_FAILURE:
			return {
				...state,
				listLoader: false,
				listInitialLoader: false,
			};
		case actions.SET_INPROGRESS_EMPLOYEE_LIST_PAGINATION:
			return {
				...state,
				inProgressEmployeeCurrentPage: action.payload,
			};
		case actions.UPDATE_INPROGRESS_EMPLOYEE_SELECTED_LIST:
			return {
				...state,
				selectedInProgressEmployeeListKeys: action.payload,
			};
		case actions.DELETE_INPROGRESS_EMPLOYEE:
			return {
				...state,
				importsDeleteLoader: true,
			};
		case actions.PAUSE_INPROGRESS_EMPLOYEES_FROM_JOURNEY: {
			return {
				...state,
				pauseModalLoader: true,
			};
		}
		case actions.RESUME_INPROGRESS_EMPLOYEES_FROM_JOURNEY: {
			return {
				...state,
				resumeModalLoader: true,
			};
		}
		case actions.SET_ASSIGN_TASK_MODAL_VISIBLE:
			return {
				...state,
				isShowAssignTask: action.payload,
				employeeType: action.employeeType
					? action.employeeType
					: state.employeeType,
			};
		case actions.FETCH_ALL_TASKS:
			return {
				...state,
				employeeTaskList: state.isSearch ? [] : state.employeeTaskList,
				taskSearchLoader: action.isSearch,
				taskListLoader: true,
				taskInitialLoader: action.initialFetch,
			};
		case actions.FETCH_ALL_TASKS_SUCCESS: {
			let taskList =
				state.taskPage === 1
					? action.payload
					: state.employeeTaskList.concat(action.payload);
			return {
				...state,
				employeeTaskList: taskList,
				taskSearchLoader: false,
				taskListLoader: false,
				taskInitialLoader: false,
			};
		}
		case actions.FETCH_ALL_TASKS_FAILURE:
			return {
				...state,
				taskSearchLoader: false,
				taskListLoader: false,
				taskInitialLoader: false,
			};
		case actions.EMPLOYEES_SET_TASK_PAGE:
			return {
				...state,
				taskPage: action.payload,
			};
		case actions.ASSIGN_TASK:
			return {
				...state,
				assignButtonLoader: true,
			};
		case actions.ASSIGN_TASK_SUCCESS:
			return {
				...state,
				assignButtonLoader: false,
				isShowAssignTask: false,
				selectedEmployeeKeys: [],
				selectedInProgressEmployeeListKeys: [],
			};
		case actions.ASSIGN_TASK_FAILURE:
			return {
				...state,
				assignButtonLoader: false,
			};
		case actions.EMPLOYEES_SET_TASK_SEARCH:
			return {
				...state,
				taskSearchKey: action.payload,
			};
		case actions.EMPLOYEES_SET_SELECTED_TASK:
			return {
				...state,
				taskDetails: {
					...state.taskDetails,
					id: action.payload,
				},
			};
		case actions.FETCH_IMPORT_DETAILS:
			return {
				...state,
				isFetchingImportDetails: true,
			};
		case actions.FETCH_IMPORT_DETAILS_SUCCESS:
			return {
				...state,
				isFetchingImportDetails: false,
				importDetails: action.payload,
			};
		case actions.FETCH_IMPORT_DETAILS_FAILURE:
			return {
				...state,
				isFetchingImportDetails: false,
			};
		case actions.STORE_SELECTED_TASK_DETAILS:
			return {
				...state,
				taskDetails: action.payload,
			};
		case actions.SHOW_EMPLOYEE_MODAL:
			return {
				...state,
				isShowEmployeeModal: action.payload,
			};
		case actions.ADD_MANUAL_EMPLOYEE:
			return {
				...state,
				isAddingEmployee: true,
			};
		case actions.ADD_MANUAL_EMPLOYEE_SUCCESS:
			return {
				...state,
				isAddingEmployee: false,
				isShowEmployeeModal: false,
			};
		case actions.ADD_MANUAL_EMPLOYEE_FAILURE:
			return {
				...state,
				isAddingEmployee: false,
			};
		case actions.REMOVE_EMPLOYEE_FOR_SELECTED_JOURNEY:
			return {
				...state,
				removeEmployeeForSelectedJourneyId: action.payload,
			};
		case actions.GET_EMPLOYEE_ACTIVITY_LOG:
			return {
				...state,
				isGettingEmployeeLog: !action.initialFetch,
				logInitialLoader: action.initialFetch,
			};
		case actions.GET_EMPLOYEE_ACTIVITY_LOG_SUCCESS:
			return {
				...state,
				logInitialLoader: false,
				isGettingEmployeeLog: false,
				activityLogs: action.infiniteScroll
					? state.activityLogs.concat(action.payload)
					: action.payload,
			};
		case actions.GET_EMPLOYEE_ACTIVITY_LOG_FAILURE:
			return {
				...state,
				isGettingEmployeeLog: false,
				logInitialLoader: false,
			};
		case actions.SET_ACTIVITY_LOG_PAGE:
			return {
				...state,
				activityLogPage: action.payload,
			};
		case actions.UPDATE_ACTIVITY_DATE_RANGE:
			return {
				...state,
				activityFromDate: action.fromDate,
				activityToDate: action.toDate,
			};
		case actions.SET_CURRENT_LAST_LOG_ID:
			return {
				...state,
				lastLogId: action.payload,
			};
		case actions.RESEND_EMPLOYEE_INVITE:
			return {
				...state,
				resendInviteLoader: true,
			};
		case actions.RESEND_EMPLOYEE_INVITE_SUCCESS:
			return {
				...state,
				resendInviteLoader: false,
			};
		case actions.FETCH_TIMEZONE_SUCCESS:
			return {
				...state,
				timeZone: action.payload,
			};
		case actions.SET_SLACK_USERS:
			return {
				...state,
				slackUsers: action.payload,
			};
		case actions.SET_ADD_EMPLOYEES:
			return {
				...state,
				addEmployeesList: [
					...state.addEmployeesList,
					...action.payload.employeeList.employees,
				],
				addEmployeesPage: action.payload.currentPage,
			};
		case actions.SET_FILTERED_EMPLOYEES: {
			return {
				...state,
				filteredEmployeesList: action.payload.isPaginationQuery
					? [...state.filteredEmployeesList, ...action.payload.employees]
					: action.payload.employees,
				filteredEmployeesPage: action.payload.currentPage,
			};
		}
		case actions.SET_LOADING_FILTERED_EMPLOYEES: {
			return {
				...state,
				isFetchingFilteredEmployees: action.payload.value,
			};
		}
		case actions.SET_FILTER_EMPLOYEES_DATA: {
			return {
				...state,
				filterEmployeesKey:
					action.payload.key !== undefined && action.payload.key !== null
						? action.payload.key.trim()
						: state.filterEmployeesKey,
				filteredEmployeesPage:
					action.payload.page || state.filteredEmployeesPage,
				filteredEmployeesList: action.payload.onBlur
					? state.defaultFilteredEmployees
					: state.filteredEmployeesList,
			};
		}
		case actions.SET_DEFAULT_FILTERED_EMPLOYEES: {
			return {
				...state,
				defaultFilteredEmployees:
					action.payload.employees || state.defaultFilteredEmployees,
			};
		}
		case actions.SET_SCROLL_LOADER: {
			return {
				...state,
				scrollLoader: action.payload.value,
			};
		}
		case actions.SET_ASSIGNED_EMPLOYEES_FILTER_JOURNEY_ID: {
			const journeyFilter = action.payload;
			return {
				...state,
				assigendEmployeesFilterJourneyId:
					journeyFilter !== null ? { ...action.payload } : null,
			};
		}
		default:
			return state;
	}
}
