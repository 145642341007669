import styled, { createGlobalStyle } from "styled-components";
import variables from "styles/variables";

const SidebarContainer = styled.div`
	background: ${variables.SIDER_BACKGROUND_COLOR};
	color: ${variables.WHITE_COLOR};
	.logo-container {
		min-height: 140px;
		height: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
		.logo-image {
			width: 80px;
			height: 80px;
		}
		.logo-image-collapse {
			width: 60px;
			height: 60px;
		}
	}
	.ant-menu-inline-collapsed {
		.ant-menu-item {
			padding-top: 10px !important;
			/* padding: 10px 32px; */
			margin-bottom: 16px;
			.anticon {
				line-height: 0px;
			}
			.sidebar-menu-item {
				padding-left: 30px;
			}
		}
	}
	.ant-layout-sider {
		height: 100%;
		padding-bottom: 22px;
		&,
		.ant-menu {
			background: inherit;
			color: inherit;
			height: 100%;
			.ant-menu-item {
				font-size: 17px;
				font-weight: 500;
				line-height: 1.22;
				padding: 10px 32px;
				img {
					height: 20px;
					width: 20px;
					margin-right: ${variables.SQ_SPACING_BASE};
				}
				svg {
					font-size: 20px;
					color: ${variables.SIDEBAR_ICON_COLOR};
				}
				&:hover {
					background: ${variables.PRIMARY_COLOR};
					color: ${variables.WHITE_COLOR};
				}
				a {
					color: ${variables.WHITE_COLOR};
					span {
						vertical-align: middle;
					}
				}
				.anticon-fund-projection-screen,
				.anticon-snippets {
					color: ${variables.SIDEBAR_ICON_COLOR};
					font-size: ${parseInt(variables.BASE_FONT_SIZE) + 6}px;
					/* margin-right: ${variables.SQ_SPACING_BASE}; */
				}
			}
			.ant-menu-submenu {
				&.ant-menu-submenu-selected {
					color: ${variables.WHITE_COLOR};
				}
				&.ant-menu-submenu-active {
					&.ant-menu-submenu-open {
						color: ${variables.WHITE_COLOR};
					}
				}
				img {
					height: 20px;
					width: 20px;
					margin-right: 20px;
				}
				.ant-menu-submenu-title {
					font-size: 17px;
					font-weight: 500;
					padding: 10px 0 10px 64px !important;
					line-height: 1.22;
					text-overflow: unset;
					&:hover {
						color: ${variables.WHITE_COLOR};
						.ant-menu-submenu-arrow {
							color: ${variables.WHITE_COLOR} !important;
						}
					}
					.ant-menu-submenu-arrow {
						top: 60%;
					}
					.sidebar-menu-item {
						vertical-align: middle;
					}
				}
				.ant-menu-item {
					padding: 10px 104px !important;
				}
			}
			&.ant-menu-vertical {
				.ant-menu-item::after,
				.ant-menu-submenu::after {
					border-right: 0;
				}
				.ant-menu-item:not(:last-child) {
					margin-bottom: 16px;
				}
			}
			.ant-menu-vertical,
			.ant-menu-vertical,
			.ant-menu-vertical-left {
				border-right: 1px solid ${variables.SIDER_BACKGROUND_COLOR};
			}
			&:not(.ant-menu-horizontal) .ant-menu-item-selected {
				background: ${variables.PRIMARY_COLOR};
				color: ${variables.WHITE_COLOR};
			}
		}
		.ant-menu {
			max-height: calc(100% - 215px);
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
	.sidebar-menu-item {
		/* display: inline-block; */
		/* line-height: 1.5; */
		/* overflow: hidden; */
		/* text-overflow: ellipsis; */
		/* vertical-align: text-top; */
		/* margin: 0; */
		/* width: 135px; */
	}
`;

export const ProfileWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	opacity: ${(props) => (props.profileLoader ? 0.5 : 1)};
	margin-top: 15px;
	.ant-avatar {
		cursor: pointer;
		border: 1px solid ${variables.BORDER_COLOR};
		box-sizing: content-box;
	}
	.ant-spin {
		position: fixed;
		left: 117px;
		bottom: 42px;
	}
`;

export const SidebarGlobalStyles = createGlobalStyle`
  .ant-menu-submenu {
    .ant-menu-item {
      padding: 12px 16px;
      font-size: 15px;
    }
  }
`;

export default SidebarContainer;
