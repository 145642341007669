import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Menu } from "antd";
import IntlMessages from "components/utility/intlMessages";
import { fetchCompanyId } from "redux/helper";

const { Item } = Menu;

const stripTrailingSlash = (str) => {
	if (str.substr(-1) === "/") {
		return str.substr(0, str.length - 1);
	}
	return str;
};

function SidebarMenu({ singleOption, isAdmin, ...rest }) {
	let match = useRouteMatch();
	const { key, label, leftIcon, searchKey, type } = singleOption;
	const url = stripTrailingSlash(match.url);
	return (
		<Item key={key} {...rest}>
			<Link
				to={{
					pathname: `${url}/${fetchCompanyId()}/${key}`,
					search: searchKey,
				}}
			>
				<span className="sidebar-icon">
					{type === "icon" ? leftIcon : <img src={leftIcon} alt={key} />}
					<span className="sidebar-menu-item">
						{label && label.length > 0 && <IntlMessages id={label} />}
					</span>
				</span>
			</Link>
		</Item>
	);
}

export default React.memo(SidebarMenu);
