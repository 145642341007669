import Auth from "redux/auth/reducer";
import App from "redux/app/reducer";
import Employees from "redux/employees/reducer";
import Assets from "redux/assets/reducer";
import Dashboard from "redux/dashboard/reducer";
import Journeys from "redux/journeys/reducer";
import Users from "redux/settings/users/reducer";
import Templates from "redux/adminUser/templates/reducer";
import Portal from "redux/portal/reducer";
import Employee from "redux/employee/reducer";
import Meetings from "redux/meetings/reducer";
import AdminTask from "redux/adminUser/tasks/reducer";
import AdminMeetings from "redux/adminUser/meetings/reducer";
import AdminJourney from "redux/adminUser/journeys/reducer";
import Roles from "redux/settings/rolesAndPermissions/reducer";
import Task from "redux/Task/reducer";
import Reports from "redux/reports/reducer";
import Moments from "redux/moments/reducer";

export default {
	Auth,
	App,
	Employees,
	Assets,
	Journeys,
	Dashboard,
	Users,
	Templates,
	Portal,
	Employee,
	Meetings,
	AdminTask,
	AdminMeetings,
	AdminJourney,
	Roles,
	Task,
	Reports,
	Moments,
};
