import { store } from "redux/store";
import actions from "redux/auth/actions";
import { getRoleType } from "components/utility/helper";
import { onBoardEmployee } from "components/constants";

export default () =>
	getRoleType()
		? getRoleType() === onBoardEmployee
			? new Promise(() => {
					store.dispatch({ type: actions.VALIDATE_EMPLOYEE_AUTH_TOKEN });
			  })
			: new Promise(() => {
					store.dispatch({ type: actions.VALIDATE_AUTH_TOKEN });
			  })
		: new Promise(() => {
				return true;
		  });
