const actions = {
	AUTHENTICATE_USER: "AUTHENTICATE_USER",
	AUTHENTICATE_USER_SUCCESS: "AUTHENTICATE_USER_SUCCESS",
	AUTHENTICATE_USER_FAILURE: "AUTHENTICATE_USER_FAILURE",
	LOGOUT_USER: "LOGOUT_USER",
	LOGOUT_USER_FAILURE: "LOGOUT_USER_FAILURE",
	SEND_PASSWORD_RESET_LINK: "SEND_PASSWORD_RESET_LINK",
	SEND_PASSWORD_RESET_LINK_SUCCESS: "SEND_PASSWORD_RESET_LINK_SUCCESS",
	SEND_PASSWORD_RESET_LINK_FAILURE: "SEND_PASSWORD_RESET_LINK_FAILURE",
	RESET_PASSWORD: "RESET_PASSWORD",
	RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
	RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
	VALIDATE_AUTH_TOKEN: "VALIDATE_AUTH_TOKEN",
	UPDATE_PROFILE_IMAGE: "UPDATE_PROFILE_IMAGE",
	UPDATE_PROFILE_IMAGE_SUCCESS: "UPDATE_PROFILE_IMAGE_SUCCESS",
	UPDATE_PROFILE_IMAGE_FAILURE: "UPDATE_PROFILE_IMAGE_FAILURE",
	EMPLOYEE_CHANGE_PASSWORD: "EMPLOYEE_CHANGE_PASSWORD",
	EMPLOYEE_CHANGE_PASSWORD_SUCCESS: "EMPLOYEE_CHANGE_PASSWORD_SUCCESS",
	EMPLOYEE_CHANGE_PASSWORD_FAILURE: "EMPLOYEE_CHANGE_PASSWORD_FAILURE",
	CHANGE_PASSWORD_MODAL_VISIBLE: "CHANGE_PASSWORD_MODAL_VISIBLE",
	AUTHENTICATE_EMPLOYEE: "AUTHENTICATE_EMPLOYEE",
	AUTHENTICATE_EMPLOYEE_SUCCESS: "AUTHENTICATE_EMPLOYEE_SUCCESS",
	AUTHENTICATE_EMPLOYEE_FAILURE: "AUTHENTICATE_EMPLOYEE_FAILURE",
	LOGOUT_EMPLOYEE: "LOGOUT_EMPLOYEE",
	LOGOUT_EMPLOYEE_FAILURE: "LOGOUT_EMPLOYEE_FAILURE",
	EMPLOYEE_SEND_PASSWORD_RESET_LINK: "EMPLOYEE_SEND_PASSWORD_RESET_LINK",
	EMPLOYEE_SEND_PASSWORD_RESET_LINK_SUCCESS:
		"EMPLOYEE_SEND_PASSWORD_RESET_LINK_SUCCESS",
	EMPLOYEE_SEND_PASSWORD_RESET_LINK_FAILURE:
		"EMPLOYEE_SEND_PASSWORD_RESET_LINK_FAILURE",
	EMPLOYEE_RESET_PASSWORD: "EMPLOYEE_RESET_PASSWORD",
	EMPLOYEE_RESET_PASSWORD_SUCCESS: "EMPLOYEE_RESET_PASSWORD_SUCCESS",
	EMPLOYEE_RESET_PASSWORD_FAILURE: "EMPLOYEE_RESET_PASSWORD_FAILURE",
	VALIDATE_EMPLOYEE_AUTH_TOKEN: "VALIDATE_EMPLOYEE_AUTH_TOKEN",
	SET_SHOW_WELCOME_PAGE: "SET_SHOW_WELCOME_PAGE",
	SET_DISPLAY_WELCOME_PAGE: "SET_DISPLAY_WELCOME_PAGE",
	GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",
	GET_USER_PERMISSIONS_SUCCESS: "GET_USER_PERMISSIONS_SUCCESS",
	GET_USER_PERMISSIONS_FAILURE: "GET_USER_PERMISSIONS_FAILURE",
	UPDATE_EMAIL_SETTINGS_DETAILS: "UPDATE_EMAIL_SETTINGS_DETAILS",
	UPDATE_COMPANY_EMAIL_DATA_SUCCESS: "UPDATE_COMPANY_EMAIL_DATA_SUCCESS",
	UPDATE_DOMAIN_DETAILS: "UPDATE_DOMAIN_DETAILS",
	LOGIN_WITH_OTP: "LOGIN_WITH_OTP",
	LOGIN_WITH_OTP_SUCCESS: "LOGIN_WITH_OTP_SUCCESS",
	LOGIN_WITH_OTP_FAILURE: "LOGIN_WITH_OTP_FAILURE",
	OTP_AUTHENTICATION: "OTP_AUTHENTICATION",
	OTP_AUTHENTICATION_SUCCESS: "OTP_AUTHENTICATION_SUCCESS",
	OTP_AUTHENTICATION_FAILURE: "OTP_AUTHENTICATION_FAILURE",
	SET_OTP_TIMER_EXPIRE: "SET_OTP_TIMER_EXPIRE",
	BACK_TO_LOGIN: "BACK_TO_LOGIN",
	UPDATE_TWILIO_CONFIG: "UPDATE_TWILIO_CONFIG",
};

export default actions;
