import React from "react";
import { TimeGroupStyles } from "styles/Employee.style";
import TimeAgo from "components/Employee/TimeAgo";

function TimeGroup({ displayTime, message, isCustomMessage }) {
	if (displayTime) {
		return (
			<TimeGroupStyles isCustomMessage={isCustomMessage}>
				<TimeAgo time={message.created_at} />
			</TimeGroupStyles>
		);
	}
	return null;
}
export default TimeGroup;
