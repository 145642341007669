import actions from "./actions";
import {
	assetMessage,
	assetPage,
	assetTask,
	assetTemplate,
	defaultPage,
	defaultPageSize,
} from "components/constants";

const initState = {
	messageCurrentPage: 1,
	assetsMessageList: [],
	totalMessageData: 0,
	pageSize: defaultPageSize,
	pageHeaderLoader: false,
	messageTableLoader: false,
	messageDrawerVisible: false,
	emailDrawerVisible: false,
	selectedMessageList: [],
	messageModalVisible: false,
	assetsModalLoader: false,
	messageSortKey: "created_at",
	messageSortOrder: "desc",
	assetsActiveList: "",
	formLoader: false,
	assetID: null,
	emailJson: null,
	contentName: "",
	emailModifiedAt: null,
	assetsToken: [],
	emailHeaderLoader: false,
	emailContentHtml: null,
	emailFormType: null,
	messageName: null,
	messageContent: null,
	messageFormType: null,
	messageAssetToken: [],
	messageDrawerLoader: false,
	updatedEmailContent: {},
	contentSubject: "",
	pagesPageSize: defaultPageSize,
	totalPagesData: 0,
	pagesCurrentPage: 1,
	pagesSortOrder: "desc",
	selectedPagesList: [],
	pagesSortKey: "modified_at",
	assetsPagesList: [],
	pagesTableLoader: false,
	pagesJson: null,
	pagesContentName: "",
	pagesModifiedAt: null,
	pagesFormType: null,
	pagesDrawerVisible: false,
	pagesModalLoader: false,
	pageRenderingContent: "",
	pageRenderingName: "",
	pageRenderingId: "",
	pageRenderingLoader: false,
	pagesUrl: [],
	messagePageLoader: false,
	isPagesUrl: false,
	previewDrawerVisible: false,
	previewAsset: {
		type: "",
		name: "",
		subject: "",
		content: "",
		files: [],
	},
	templateDrawerVisible: false,
	templateJson: null,
	templateContentName: "",
	templateModifiedAt: null,
	templateContentHtml: null,
	templateFormType: null,
	templateModalLoader: false,
	tasksTableLoader: false,
	tasksList: [],
	taskDrawerVisible: false,
	taskFormType: null,
	totalTasks: 0,
	selectedTaskList: [],
	tasksSortKey: "modified_at",
	tasksSortOrder: "desc",
	tasksPageSize: defaultPageSize,
	tasksCurrentPage: defaultPage,
	deleteTaskLoader: false,
	currentTaskId: null,
	currentTask: {},
	createTaskLoader: false,
	taskJson: {},
	selectTemplateDrawerVisible: false,
	existingTemplate: {},
	templateDrawerList: [],
	templateDrawerLoader: false,
	templateDrawerData: 0,
	templateDrawerCurrentPage: 1,
	templateDrawerSelectedId: null,
	loadDesign: false,
	totalTasksTemplate: 0,
	tasksTemplateCurrentPage: 1,
	tasksTemplateSortOrder: "desc",
	tasksTemplateSortKey: "created_at",
	tasksTemplatePageSize: 10,
	taskTemplateId: null,
	taskModalLoader: false,
	duplicateTaskModal: false,
	attachmentDrawerVisible: false,
	emailFiles: [],
	attachmentModalVisible: false,
	progressStatus: 0,
	templatesActiveList: assetMessage,
	isTemplate: false,
	assetTemplateType: null,
	assetSearchText: "",
	previewLoader: false,
	companyId: null,
	trelloDrawerVisible: false,
	trelloFormType: null,
	createTrelloLoader: false,
	currentTrello: {},
	trelloAssets: [],
	ticketingAssets: [],
	selectedTrelloAssetsList: [],
	projectList: [],
	slackDrawerVisible: false,
	slackFormType: null,
	slackAssets: [],
	selectedSlackAssets: [],
	ticketingMerges: [],
	isSimpleEmail: false,
	microsoftTeamDrawerVisible: false,
	microsoftTeamFormType: null,
	microsoftTeamAssets: [],
	selectedMicrosoftTeamAssetsList: [],
	microsoftTeamFormData: {},
	slackFormData: {},
	totalMicrosoftTeamAssets: 0,
};

export default function assetsReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_MESSAGE_LIST: {
			return {
				...state,
				messageTableLoader: true,
				pageHeaderLoader: true,
			};
		}
		case actions.GET_MESSAGE_LIST_SUCCESS: {
			return {
				...state,
				messageTableLoader: false,
				assetsMessageList: action.payload.assets,
				totalMessageData: action.payload.total_records,
				pageHeaderLoader: false,
			};
		}
		case actions.GET_MESSAGE_LIST_FAILURE: {
			return {
				...state,
				messageTableLoader: false,
				pageHeaderLoader: false,
			};
		}
		case actions.SET_MESSAGE_DRAWER_VISIBLE: {
			return {
				...state,
				messageDrawerVisible: action.payload.visible,
				messageFormType: action.payload.visible
					? action.payload.formType
					: null,
				formLoader: action.payload.visible ? state.formLoader : false,
			};
		}
		case actions.SET_EMAIL_DRAWER_VISIBLE: {
			return {
				...state,
				emailDrawerVisible: action.payload.visible,
				emailFormType: action.payload.visible ? action.payload.formType : null,
				formLoader: action.payload.visible ? state.formLoader : false,
				contentName:
					state.emailFormType !== "edit" && !action.payload.visible
						? null
						: state.contentName,
				contentSubject:
					state.emailFormType !== "edit" && !action.payload.visible
						? null
						: state.contentSubject,
			};
		}
		case actions.MESSAGE_SELECTED_LIST: {
			return {
				...state,
				selectedMessageList: action.payload,
			};
		}
		case actions.DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				messageModalVisible: action.payload,
			};
		}
		case actions.DELETE_MESSAGE_LIST: {
			return {
				...state,
				assetsModalLoader: true,
			};
		}
		case actions.DELETE_MESSAGE_LIST_SUCCESS: {
			return {
				...state,
				assetsModalLoader: false,
				messageModalVisible: false,
				selectedMessageList: [],
			};
		}
		case actions.DELETE_MESSAGE_LIST_FAILURE: {
			return {
				...state,
				assetsModalLoader: false,
			};
		}
		case actions.SET_MESSAGE_TABLE_SIZE: {
			return {
				...state,
				pageSize: action.payload,
			};
		}
		case actions.SET_MESSAGE_TABLE_CURRENT_PAGE: {
			return {
				...state,
				messageCurrentPage: action.payload,
			};
		}
		case actions.SET_MESSAGE_TABLE_SORT_ORDER: {
			return {
				...state,
				messageSortKey: action.payload.sortKey,
				messageSortOrder: action.payload.sortOrder,
			};
		}
		case actions.SET_ASSETS_ACTIVE_LIST: {
			return {
				...state,
				assetsActiveList: action.payload,
			};
		}
		case actions.CREATE_NEW_EMAIL_OR_MESSAGE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_EMAIL_OR_MESSAGE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				emailDrawerVisible: false,
				emailFormType: null,
				messageDrawerVisible: false,
				messageFormType: null,
				updatedEmailContent: action.payload.asset,
				assetsActiveList: assetMessage,
				templateDrawerList: [],
				templateDrawerData: 0,
				existingTemplate: {},
				selectTemplateDrawerVisible: false,
				templateDrawerCurrentPage: 1,
				templateDrawerSelectedId: null,
			};
		}
		case actions.CREATE_NEW_EMAIL_OR_MESSAGE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.GET_EMAIL_DATA: {
			return {
				...state,
				contentName: "",
				emailJson: null,
				emailModifiedAt: null,
				emailHeaderLoader: true,
				contentSubject: "",
				loadDesign: false,
			};
		}
		case actions.GET_EMAIL_DATA_SUCCESS: {
			return {
				...state,
				emailJson: action.payload.asset.raw_json
					? [JSON.parse(action.payload.asset.raw_json)]
					: [],
				emailFiles: action.payload.asset.files,
				assetID: action.payload.asset.id,
				contentName: action.payload.asset.name,
				emailModifiedAt: action.payload.asset.modified_at,
				emailContentHtml: action.payload.asset.content,
				emailHeaderLoader: false,
				contentSubject: action.payload.asset.subject,
				loadDesign: true,
				isTemplate: action.payload.asset.is_template,
				isSimpleEmail: action.payload.asset.is_simple_email,
			};
		}
		case actions.GET_EMAIL_DATA_FAILURE: {
			return {
				...state,
				emailHeaderLoader: false,
			};
		}
		case actions.UPDATE_EMAIL_OR_MESSAGE_CONTENT: {
			return {
				...state,
				formLoader:
					state.emailDrawerVisible || state.messageDrawerVisible
						? true
						: state.formLoader,
				loadDesign: false,
			};
		}
		case actions.UPDATE_EMAIL_OR_MESSAGE_CONTENT_SUCCESS: {
			return {
				...state,
				contentName: action.payload.asset.name,
				emailModifiedAt: action.payload.asset.modified_at,
				emailJson: action.payload.asset.raw_json
					? [JSON.parse(action.payload.asset.raw_json)]
					: [],
				emailFiles: action.payload.asset.files,
				emailContentHtml: action.payload.asset.content,
				formLoader: false,
				emailDrawerVisible: false,
				emailFormType: null,
				messageDrawerVisible: false,
				updatedEmailContent: action.payload.asset,
				contentSubject: action.payload.asset.subject,
				loadDesign: false,
				progressStatus: 0,
				isSimpleEmail: action.payload.isSimpleEmail,
			};
		}
		case actions.UPDATE_EMAIL_OR_MESSAGE_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
				emailDrawerVisible: false,
				progressStatus: 0,
			};
		}
		case actions.RESET_EMAIL_DATA: {
			return {
				...state,
				assetID: null,
				emailJson: null,
				contentName: "",
				emailModifiedAt: "",
				emailContentHtml: "",
				assetsToken: [],
				contentSubject: "",
				pagesModifiedAt: null,
				pagesJson: null,
				pagesContentName: "",
				pagesContentHtml: "",
				isPagesUrl: false,
				pagesUrl: [],
				templateContentName: "",
				templateJson: null,
				templateModifiedAt: null,
				loadDesign: false,
				emailFiles: [],
				progressStatus: 0,
				isTemplate: false,
			};
		}
		case actions.GET_ASSETS_TOKEN: {
			return {
				...state,
				messageDrawerLoader: true,
			};
		}
		case actions.GET_ASSETS_TOKEN_SUCCESS: {
			return {
				...state,
				assetsToken: action.payload,
				messageDrawerLoader: false,
			};
		}
		case actions.GET_ASSETS_TOKEN_FAILURE: {
			return {
				...state,
				assetsToken: [],
				messageDrawerLoader: false,
			};
		}
		case actions.SET_MESSAGE_DATA: {
			return {
				...state,
				assetID: action.payload.id,
				messageName: action.payload.name,
				messageContent: action.payload.content,
			};
		}
		case actions.RESET_MESSAGE_DATA: {
			return {
				...state,
				assetID: null,
				messageName: null,
				messageContent: null,
				assetsToken: [],
				pagesUrl: [],
			};
		}
		case actions.SET_PAGES_TABLE_SIZE: {
			return {
				...state,
				pagesPageSize: action.payload,
			};
		}
		case actions.SET_PAGES_TABLE_CURRENT_PAGE: {
			return {
				...state,
				pagesCurrentPage: action.payload,
			};
		}
		case actions.SET_PAGES_TABLE_SORT_ORDER: {
			return {
				...state,
				pagesSortKey: action.payload.sortKey,
				pagesSortOrder: action.payload.sortOrder,
			};
		}
		case actions.GET_PAGES_LIST: {
			return {
				...state,
				pagesTableLoader: true,
				pageHeaderLoader: true,
			};
		}
		case actions.GET_PAGES_LIST_SUCCESS: {
			return {
				...state,
				pagesTableLoader: false,
				assetsPagesList: action.payload.pages,
				totalPagesData: action.payload.total_records,
				pageHeaderLoader: false,
			};
		}
		case actions.GET_PAGES_LIST_FAILURE: {
			return {
				...state,
				pagesTableLoader: false,
				pageHeaderLoader: false,
			};
		}
		case actions.SET_PAGES_DRAWER_VISIBLE: {
			return {
				...state,
				pagesDrawerVisible: action.payload.visible,
				pagesFormType: action.payload.visible ? action.payload.formType : null,
				formLoader: action.payload.visible ? state.formLoader : false,
				pagesContentName:
					state.pagesFormType !== "edit" && !action.payload.visible
						? null
						: state.pagesContentName,
			};
		}
		case actions.DELETE_PAGES_LIST: {
			return {
				...state,
				pagesModalLoader: true,
			};
		}
		case actions.DELETE_PAGES_LIST_SUCCESS: {
			return {
				...state,
				pagesModalLoader: false,
				messageModalVisible: false,
				selectedPagesList: [],
			};
		}
		case actions.DELETE_PAGES_LIST_FAILURE: {
			return {
				...state,
				pagesModalLoader: false,
			};
		}
		case actions.PAGES_SELECTED_LIST: {
			return {
				...state,
				selectedPagesList: action.payload,
			};
		}
		case actions.CREATE_NEW_PAGE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_PAGE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				pagesDrawerVisible: false,
				pagesFormType: null,
				assetsActiveList: assetPage,
				templateDrawerList: [],
				templateDrawerData: 0,
				existingTemplate: {},
				selectTemplateDrawerVisible: false,
				templateDrawerCurrentPage: 1,
				templateDrawerSelectedId: null,
			};
		}
		case actions.CREATE_NEW_PAGE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}

		case actions.GET_PAGE_DATA: {
			return {
				...state,
				pagesContentName: "",
				pagesJson: null,
				pagesModifiedAt: null,
				emailHeaderLoader: true,
				loadDesign: false,
			};
		}
		case actions.GET_PAGE_DATA_SUCCESS: {
			return {
				...state,
				pagesJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				assetID: action.payload.page.id,
				pagesContentName: action.payload.page.name,
				pagesModifiedAt: action.payload.page.modified_at,
				pagesContentHtml: action.payload.page.content,
				pagesHeaderLoader: false,
				emailHeaderLoader: false,
				loadDesign: true,
				isTemplate: action.payload.page.is_template,
			};
		}
		case actions.GET_PAGE_DATA_FAILURE: {
			return {
				...state,
				emailHeaderLoader: false,
			};
		}
		case actions.UPDATE_PAGE_CONTENT: {
			return {
				...state,
				formLoader: state.pagesDrawerVisible ? true : state.formLoader,
				loadDesign: false,
			};
		}
		case actions.UPDATE_PAGE_CONTENT_SUCCESS: {
			return {
				...state,
				pagesContentName: action.payload.page.name,
				pagesModifiedAt: action.payload.page.modified_at,
				pagesJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				pagesContentHtml: action.payload.page.content,
				formLoader: false,
				pagesFormType: null,
				pagesDrawerVisible: false,
				loadDesign: false,
			};
		}
		case actions.UPDATE_PAGE_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
				pagesDrawerVisible: false,
			};
		}
		case actions.GET_PAGE_RENDERING_DATA: {
			return {
				...state,
				pageRenderingLoader: true,
			};
		}
		case actions.GET_PAGE_RENDERING_DATA_SUCCESS: {
			return {
				...state,
				pageRenderingLoader: false,
				pageRenderingName: action.payload.page.name,
				pageRenderingContent: action.payload.page.content,
				pageRenderingId: action.payload.id,
				companyId: action.payload.page.company_id,
			};
		}
		case actions.GET_PAGE_RENDERING_DATA_FAILURE: {
			return {
				...state,
				pageRenderingLoader: false,
			};
		}
		case actions.RESET_PAGE_RENDERING: {
			return {
				...state,
				pageRenderingLoader: false,
				pageRenderingName: "",
				pageRenderingContent: "",
			};
		}
		case actions.GET_PAGE_URL_LIST: {
			return {
				...state,
				messagePageLoader: true,
				isPagesUrl: true,
			};
		}
		case actions.GET_PAGE_URL_LIST_SUCCESS: {
			return {
				...state,
				messagePageLoader: false,
				pagesUrl: action.payload,
				isPagesUrl: false,
			};
		}
		case actions.GET_PAGE_URL_LIST_FAILURE: {
			return {
				...state,
				messagePageLoader: false,
				isPagesUrl: false,
			};
		}
		case actions.EMAIL_DUPLICATE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.EMAIL_DUPLICATE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				emailDrawerVisible: false,
			};
		}
		case actions.EMAIL_DUPLICATE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.PAGE_DUPLICATE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.PAGE_DUPLICATE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				pagesDrawerVisible: false,
			};
		}
		case actions.PAGE_DUPLICATE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.SET_EMAIL_DATA: {
			return {
				...state,
				assetID: action.payload.id,
				contentName: action.payload.name,
				contentSubject: action.payload.subject,
				emailContentHtml: action.payload.content,
				emailFiles: action.payload.files,
			};
		}
		case actions.SET_PAGE_DATA: {
			return {
				...state,
				assetID: action.payload.id,
				pagesContentName: action.payload.name,
			};
		}
		case actions.RESET_PREVIEW_DATA: {
			return {
				...state,
				previewAsset: {
					type: "",
					name: "",
					subject: "",
					content: "",
					files: [],
				},
			};
		}
		case actions.SET_PREVIEW_DATA: {
			const { type, name, subject, content, files } = action.payload;
			return {
				...state,
				previewAsset: {
					type: type,
					name: name,
					subject: subject,
					content: content,
					files: files ? files : [],
				},
			};
		}
		case actions.SET_PREVIEW_DRAWER_VISIBLE: {
			return {
				...state,
				previewDrawerVisible: action.payload,
			};
		}
		case actions.SET_ASSET_TEMPLATE_DRAWER_VISIBLE: {
			return {
				...state,
				templateDrawerVisible: action.payload.visible,
				templateFormType: action.payload.visible
					? action.payload.formType
					: null,
				formLoader: action.payload.visible ? state.formLoader : false,
			};
		}
		case actions.CREATE_NEW_ASSET_TEMPLATE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_ASSET_TEMPLATE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				templateDrawerVisible: false,
				templateFormType: null,
				assetsActiveList: assetTemplate,
			};
		}
		case actions.CREATE_NEW_ASSET_TEMPLATE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.GET_ASSET_TEMPLATE_DATA: {
			return {
				...state,
				templateContentName: "",
				templateJson: null,
				templateModifiedAt: null,
				emailHeaderLoader: true,
				loadDesign: false,
			};
		}
		case actions.GET_ASSET_TEMPLATE_DATA_SUCCESS: {
			return {
				...state,
				templateJson: action.payload.template.raw_json
					? [JSON.parse(action.payload.template.raw_json)]
					: [],
				assetID: action.payload.template.id,
				templateContentName: action.payload.template.name,
				templateModifiedAt: action.payload.template.modified_at,
				templateContentHtml: action.payload.template.content,
				emailHeaderLoader: false,
				loadDesign: true,
			};
		}
		case actions.GET_ASSET_TEMPLATE_DATA_FAILURE: {
			return {
				...state,
				emailHeaderLoader: false,
			};
		}
		case actions.UPDATE_ASSET_TEMPLATE_CONTENT: {
			return {
				...state,
				formLoader: state.templateDrawerVisible ? true : state.formLoader,
				loadDesign: false,
			};
		}
		case actions.UPDATE_ASSET_TEMPLATE_CONTENT_SUCCESS: {
			return {
				...state,
				templateContentName: action.payload.template.name,
				templateModifiedAt: action.payload.template.modified_at,
				templateJson: action.payload.template.raw_json
					? [JSON.parse(action.payload.template.raw_json)]
					: [],
				templateContentHtml: action.payload.template.content,
				formLoader: false,
				templateDrawerVisible: false,
				templateFormType: null,
				loadDesign: false,
			};
		}
		case actions.UPDATE_ASSET_TEMPLATE_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
				templateDrawerVisible: false,
			};
		}
		case actions.FETCH_TASKS:
			return {
				...state,
				tasksTableLoader: true,
				pageHeaderLoader: true,
			};
		case actions.FETCH_TASKS_SUCCESS:
			return {
				...state,
				tasksTableLoader: false,
				pageHeaderLoader: false,
				tasksList: action.payload.tasks,
				totalTasks: action.payload.total_records,
			};
		case actions.FETCH_TASKS_FAILURE:
			return {
				...state,
				tasksTableLoader: false,
				pageHeaderLoader: false,
			};
		case actions.SET_TASK_DRAWER_VISIBLE:
			return {
				...state,
				taskDrawerVisible: action.payload.visible,
				taskFormType: action.payload.visible ? action.payload.formType : null,
			};
		case actions.TASK_SELECTED_LIST:
			return {
				...state,
				selectedTaskList: action.payload,
			};
		case actions.SET_TASK_TABLE_SORT_ORDER:
			return {
				...state,
				tasksSortKey: action.payload.sortKey,
				tasksSortOrder: action.payload.sortOrder,
			};
		case actions.SET_TASK_TABLE_SIZE:
			return {
				...state,
				tasksPageSize: action.payload,
			};
		case actions.SET_TASK_TABLE_CURRENT_PAGE:
			return {
				...state,
				tasksCurrentPage: action.payload,
			};
		case actions.DELETE_TASKS:
			return {
				...state,
				deleteTaskLoader: true,
			};
		case actions.DELETE_TASKS_SUCCESS:
			return {
				...state,
				deleteTaskLoader: false,
				messageModalVisible: false,
				selectedTaskList: [],
			};
		case actions.DELETE_TASKS_FAILURE:
			return {
				...state,
				deleteTaskLoader: false,
			};
		case actions.GET_TASK_DATA_SUCCESS:
			return {
				...state,
				currentTask: action.payload,
				taskJson: action.payload.raw_json,
				currentTaskLoader: false,
			};
		case actions.GET_TASK_DATA_FAILURE:
			return {
				...state,
				currentTaskLoader: false,
			};
		case actions.GET_TASK_DATA:
			return {
				...state,
				currentTaskLoader: true,
			};
		case actions.UPDATE_CURRENT_TASK_ID:
			return {
				...state,
				currentTaskId: action.payload,
			};
		case actions.CREATE_TASK_SUCCESS:
			return {
				...state,
				createTaskLoader: false,
				taskDrawerVisible: false,
				assetsActiveList: assetTask,
			};
		case actions.CREATE_TASK_FAILURE:
			return {
				...state,
				createTaskLoader: false,
			};
		case actions.CREATE_TASK:
			return {
				...state,
				createTaskLoader: true,
			};
		case actions.UPDATE_TASK:
			return {
				...state,
				createTaskLoader: state.taskDrawerVisible ? true : false,
			};
		case actions.UPDATE_TASK_SUCCESS:
			return {
				...state,
				createTaskLoader: false,
				taskDrawerVisible: false,
				currentTask: action.payload,
				progressStatus: 0,
			};
		case actions.UPDATE_TASK_FAILURE:
			return {
				...state,
				createTaskLoader: false,
				progressStatus: 0,
			};
		case actions.RESET_CURRENT_TASK:
			return {
				...state,
				currentTask: {},
				progressStatus: 0,
			};
		case actions.SELECT_TEMPLATE_DRAWER_VISIBLE: {
			return {
				...state,
				selectTemplateDrawerVisible: action.payload,
			};
		}
		case actions.SET_EXISTING_TEMPLATE_DATA: {
			return {
				...state,
				existingTemplate: { ...action.payload },
			};
		}
		case actions.GET_SELECT_TEMPLATE_LIST: {
			return {
				...state,
				templateDrawerLoader: true,
			};
		}
		case actions.GET_SELECT_TEMPLATE_LIST_SUCCESS: {
			return {
				...state,
				templateDrawerLoader: false,
				templateDrawerData: action.totalRecords,
				templateDrawerList: [...state.templateDrawerList, ...action.payload],
			};
		}
		case actions.GET_SELECT_TEMPLATE_LIST_FAILURE: {
			return {
				...state,
				templateDrawerLoader: false,
			};
		}
		case actions.RESET_ASSET_TEMPLATE_DATA: {
			return {
				...state,
				templateDrawerLoader: false,
				templateDrawerList: [],
				templateDrawerData: 0,
				existingTemplate: {},
				templateDrawerCurrentPage: 1,
				templateDrawerSelectedId: null,
			};
		}
		case actions.SET_SELECT_TEMPLATE_CURRENT_PAGE: {
			return {
				...state,
				templateDrawerCurrentPage: action.payload,
			};
		}
		case actions.SET_SELECT_TEMPLATE_ID: {
			return {
				...state,
				templateDrawerSelectedId: action.payload,
			};
		}
		case actions.FETCH_TASKS_TEMPLATE:
			return {
				...state,
				tasksTemplateTableLoader: true,
				pageHeaderLoader: true,
			};
		case actions.FETCH_TASKS_TEMPLATE_SUCCESS: {
			return {
				...state,
				tasksTemplateTableLoader: false,
				pageHeaderLoader: false,
				tasksTemplateList: action.payload.tasks,
				totalTasksTemplate: action.payload.total_records,
			};
		}
		case actions.FETCH_TASKS_TEMPLATE_FAILURE: {
			return {
				...state,
				tasksTemplateTableLoader: false,
				pageHeaderLoader: false,
			};
		}
		case actions.SET_TASK_TEMPLATE_TABLE_SORT_ORDER:
			return {
				...state,
				tasksTemplateSortKey: action.payload.sortKey,
				tasksTemplateSortOrder: action.payload.sortOrder,
			};
		case actions.SET_TASK_TEMPLATE_TABLE_SIZE:
			return {
				...state,
				tasksTemplatePageSize: action.payload,
			};
		case actions.SET_TASK_TEMPLATE_TABLE_CURRENT_PAGE:
			return {
				...state,
				tasksTemplateCurrentPage: action.payload,
			};
		case actions.SET_TASK_TEMPLATE_ID: {
			return {
				...state,
				taskTemplateId: action.payload,
			};
		}
		case actions.TASK_DUPLICATE_MODAL_VISIBLE: {
			return {
				...state,
				duplicateTaskModal: action.payload,
			};
		}
		case actions.DUPLICATE_TASK_TEMPLATE: {
			return {
				...state,
				taskModalLoader: true,
			};
		}
		case actions.DUPLICATE_TASK_TEMPLATE_SUCCESS: {
			return {
				...state,
				taskModalLoader: false,
				duplicateTaskModal: false,
				taskTemplateId: null,
			};
		}
		case actions.DUPLICATE_TASK_TEMPLATE_FAILURE: {
			return {
				...state,
				taskModalLoader: false,
			};
		}
		case actions.SET_ATTACHMENTS_DRAWER_VISIBLE: {
			return {
				...state,
				attachmentDrawerVisible: action.payload,
			};
		}
		case actions.REMOVE_EMAIL_UPLOADED_FILES: {
			return {
				...state,
				assetsModalLoader: true,
			};
		}
		case actions.REMOVE_EMAIL_UPLOADED_FILES_SUCCESS: {
			return {
				...state,
				assetsModalLoader: false,
				attachmentModalVisible: false,
				emailFiles: state.emailFiles.filter(
					(file) => !action.payload.includes(file.attachment_id),
				),
			};
		}
		case actions.REMOVE_EMAIL_UPLOADED_FILES_FAILURE: {
			return {
				...state,
				assetsModalLoader: false,
			};
		}
		case actions.SET_DELETE_ATTACHMENTS_MODAL_VISIBLE: {
			return {
				...state,
				attachmentModalVisible: action.payload,
			};
		}
		case actions.REMOVE_TASK_UPLOADED_FILES: {
			return {
				...state,
				assetsModalLoader: true,
			};
		}
		case actions.REMOVE_TASK_UPLOADED_FILES_SUCCESS: {
			return {
				...state,
				assetsModalLoader: false,
				attachmentModalVisible: false,
				currentTask: {
					...state.currentTask,
					files: state.currentTask.files.filter(
						(file) => !action.payload.includes(file.attachment_id),
					),
				},
			};
		}
		case actions.REMOVE_TASK_UPLOADED_FILES_FAILURE: {
			return {
				...state,
				assetsModalLoader: false,
			};
		}
		case actions.SET_ASSET_FILE_PROGRESS_STATUS: {
			return {
				...state,
				progressStatus: action.payload,
			};
		}
		case actions.SET_TEMPLATES_TAB_ACTIVE:
			return {
				...state,
				templatesActiveList: action.payload,
			};
		case actions.SET_TEMPLATE_TYPE:
			return {
				...state,
				assetTemplateType: action.payload,
			};
		case actions.ASSETS_SET_SEARCH_TEXT:
			return {
				...state,
				assetSearchText: action.payload,
			};
		case actions.SET_PREVIEW_LOADER:
			return {
				...state,
				previewLoader: action.payload,
			};
		case actions.SET_TICKETING_DRAWER_VISIBLE:
			return {
				...state,
				trelloDrawerVisible: action.payload.visible,
				trelloFormType: action.payload.visible ? action.payload.formType : null,
			};
		case actions.SET_TICKETING_ASSETS:
			return {
				...state,
				ticketingAssets: action.payload,
			};
		case actions.TRELLO_ASSETS_SELECTED_LIST: {
			return {
				...state,
				selectedTrelloAssetsList: action.payload,
			};
		}
		case actions.SET_PROJECT_LIST: {
			return {
				...state,
				projectList: action.payload,
			};
		}
		case actions.SET_SLACK_DRAWER_VISIBLE:
			return {
				...state,
				slackDrawerVisible: action.payload.visible,
				slackFormType: action.payload.visible ? action.payload.formType : null,
				slackFormData: action.payload.data || {},
			};
		case actions.SET_MICROSOFT_TEAM_DRAWER:
			return {
				...state,
				microsoftTeamDrawerVisible: action.payload.visible,
				microsoftTeamFormType: action.payload.visible
					? action.payload.formType
					: null,
				microsoftTeamFormData: action.payload.data,
			};
		case actions.SET_SLACK_ASSETS:
			return {
				...state,
				slackAssets: action.payload,
			};
		case actions.SLACK_ASSETS_SELECTED_LIST:
			return {
				...state,
				selectedSlackAssets: action.payload,
			};
		case actions.SET_TICKETING_MERGES:
			return {
				...state,
				ticketingMerges: action.payload,
			};
		case actions.SET_MICROSOFT_TEAM_ASSETS:
			return {
				...state,
				microsoftTeamAssets: action.payload.microsoft_team_assets,
				totalMicrosoftTeamAssets: action.payload.total_records,
			};
		case actions.MICROSOFT_TEAM_ASSETS_SELECTED_LIST:
			return {
				...state,
				selectedMicrosoftTeamAssetsList: action.payload,
			};
		default:
			return state;
	}
}
