import React, { useEffect, useState } from "react";
import { timeDifference } from "components/utility/helper";

function TimeAgo(props) {
	const [time, setTime] = useState(timeDifference(props.time, true)),
		[intervalId, setIntervalId] = useState(null);

	useEffect(() => {
		const interval = setInterval(function () {
			setTime(timeDifference(props.time, true));
		}, 0);
		setIntervalId(interval);
	}, [props.time]);
	useEffect(
		() =>
			function cleanup() {
				clearInterval(intervalId);
			},
		[intervalId],
	);

	return <span>{time}</span>;
}

export default TimeAgo;
