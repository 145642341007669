import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import InitialLoaderStyle from "./InitialLoader.Style";

export const InitialLoader = ({ dashboard }) => (
	<InitialLoaderStyle dashboard={dashboard}>
		<Spin indicator={<LoadingOutlined />} className={"initial-loading"} />
	</InitialLoaderStyle>
);
