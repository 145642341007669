import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/settings/users/actions";
import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest,
	patchRequest,
} from "helpers/axiosClient";
import {
	calculatePageNumber,
	getCompanyUrl,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import { showNotification } from "components/utility/helper";
import { store } from "redux/store";
import { notFoundCode } from "components/constants";
import { startCase } from "lodash";
import authActions from "../../auth/actions";

import Bugsnag from "@bugsnag/js";
export function* fetchUsers() {
	const { usersCurrentPage, usersPerPage } = store.getState().Users;
	const { currentKey } = store.getState().App;
	try {
		const { data } = yield call(() =>
			getRequest(
				currentKey === "employees"
					? `${getCompanyUrl()}/users`
					: `${getCompanyUrl()}/users?page=${usersCurrentPage}&per_page=${usersPerPage}`,
				{},
			),
		);
		yield put({
			type: actions.FETCH_USERS_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
	}
}
export function* inviteUser(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/users`, params.payload),
		);
		yield put({
			type: actions.INVITE_USER_SUCCESS,
		});
		yield put({ type: actions.FETCH_USERS });
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.INVITE_USER_FAILURE,
		});
	}
}

export function* deleteUsers(params) {
	try {
		const { totalUsers, selectedUsersKeys, usersPerPage, usersCurrentPage } =
			store.getState().Users;
		const { data } = yield call(() =>
			putRequest(`${getCompanyUrl()}/users`, params.payload),
		);
		yield put({
			type: actions.DELETE_USERS_SUCCESS,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		let pages = calculatePageNumber(
				totalUsers,
				selectedUsersKeys,
				usersPerPage,
			),
			page = usersCurrentPage;
		if (usersCurrentPage > pages) {
			page = pages;
			yield put({ type: actions.SET_USERS_PAGINATION, payload: page });
		}
		yield put({ type: actions.FETCH_USERS });
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_USERS_FAILURE,
		});
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.USER_DELETE_CONFIRM_MODAL_VISIBLE,
				payload: false,
			});
		}
	}
}

export function* resendInvite(params) {
	try {
		const { data } = yield call(() =>
			putRequest(`${getCompanyUrl()}/users/resend_email`, params.payload),
		);
		yield put({
			type: actions.RESEND_INVITE_SUCCESS,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.RESEND_INVITE_FAILURE,
		});
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.RESEND_CONFIRM_MODAL_VISIBLE,
				payload: false,
			});
		}
	}
}

export function* fetchCompanyRoles(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/roles`, params.payload),
		);
		yield put({
			type: actions.FETCH_COMPANY_ROLES_SUCCESS,
			payload: data.data.roles,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_COMPANY_ROLES_FAILURE,
		});
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.RESEND_CONFIRM_MODAL_VISIBLE,
				payload: false,
			});
		}
	}
}

export function* fetchMergeToken() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/merges/create_link_token`),
		);
		yield put({
			type: actions.MERGE_TOKEN,
			payload: data.data,
		});
		yield put({
			type: actions.SET_FETCHING_LINK_TOKEN,
			payload: false,
		});
	} catch (error) {
		yield put({
			type: actions.MERGE_TOKEN,
			payload: "",
		});
		yield put({
			type: actions.SET_FETCHING_LINK_TOKEN,
			payload: false,
		});
	}
}

export function* createMergeToken(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/merges`, params.payload),
		);
		store.dispatch({ type: actions.FETCH_MERGES });
		yield put({
			type: actions.MERGE_TOKEN,
			payload: data.data.merge,
		});
		yield put({
			type: actions.SET_FETCHING_LINK_TOKEN,
			payload: false,
		});
	} catch (error) {
		yield put({
			type: actions.SET_FETCHING_LINK_TOKEN,
			payload: true,
		});
		yield put({
			type: actions.GET_MERGE_TOKEN,
		});
		handleException(error);
	}
}

export function* updateMergeIntegration(params) {
	try {
		const { data } = yield call(() =>
			putRequest(
				`${getCompanyUrl()}/merges/${params.payload.id}`,
				params.payload,
			),
		);
		store.dispatch({ type: actions.FETCH_MERGES });
		yield put({
			type: actions.MERGE_TOKEN,
			payload: data.data.merge,
		});
		yield put({
			type: actions.SET_FETCHING_LINK_TOKEN,
			payload: false,
		});
	} catch (error) {
		yield put({
			type: actions.SET_FETCHING_LINK_TOKEN,
			payload: true,
		});
		yield put({
			type: actions.GET_MERGE_TOKEN,
		});
		handleException(error);
	}
}

export function* createTrelloTicket(params) {
	try {
		yield call(() =>
			postRequest(
				`${getCompanyUrl()}/merges/create_trello_ticket`,
				params.payload,
			),
		);
		showNotification({
			type: "success",
			message: "Success",
			description: "Trello Ticket Created.",
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchMerges() {
	try {
		const { data } = yield call(() => getRequest(`${getCompanyUrl()}/merges`));
		yield put({
			type: actions.USER_MERGES,
			payload: data.data.merges,
		});
		if (data.merges.find((item) => item.category === "ats")) {
			store.dispatch({
				type: actions.FETCH_ATS_CANDIDATES,
				payload: {
					id: data.merges.find((item) => item.category === "ats").id,
				},
			});
		}
	} catch (error) {
		handleException(error);
	}
}

export function* deleteIntMerges() {
	try {
		const { selectedMergeInt } = store.getState().Users;
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/merges/destroy_merges_projects`, {
				ids: selectedMergeInt,
			}),
		);
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({ type: actions.FETCH_MERGES });
	} catch (error) {
		handleException(error);
	}
}

export function* unlinkIntMerges(params) {
	try {
		const { data } = yield call(() =>
			deleteRequest(`${getCompanyUrl()}/merges/${params.payload.id}`),
		);
		showNotification({
			type: "success",
			message: "Integrations unlinked successfully!",
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.SET_FETCHING_LINK_TOKEN,
			payload: true,
		});
		yield put({ type: actions.GET_MERGE_TOKEN });
		yield put({ type: actions.FETCH_MERGES });
	} catch (error) {
		handleException(error);
	}
}

export function* fetchSlack() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/slacks/fetch_slack`),
		);
		if (data.data.slack) {
			yield put({
				type: actions.SET_SLACK_INTEGRATION,
				payload: data.data.slack,
			});
		}
		yield put({
			type: actions.SET_SLACK_INTEGRATION_COUNT,
			payload: data.data.slacks_count,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* createSlackInt(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/slacks`, params.payload),
		);
		showNotification({
			type: "success",
			message: "Success",
			description: "Slack Integrated Successfully.",
		});
	} catch (e) {
		handleException(e);
	} finally {
		setTimeout(() => {
			window.close();
		}, 3000);
	}
}

export function* updateSlackInt(params) {
	try {
		const { data } = yield call(
			putRequest(
				`${getCompanyUrl()}/slacks/${params.payload.id}`,
				params.payload,
			),
		);
		showNotification({
			type: "success",
			message: "Success",
			description: "Slack Updated Successfully.",
		});
	} catch (e) {
		handleException(e);
	} finally {
		setTimeout(() => {
			window.close();
		}, 3000);
	}
}

export function* createIntegration(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/integrations`, params.payload),
		);
		showNotification({
			type: "success",
			message: "Success!",
			description: `${startCase(
				params.payload.integration_type,
			)} integrated successfully!`,
		});
	} catch (e) {
		handleException(e);
	} finally {
		setTimeout(() => {
			window.close();
		}, 3000);
	}
}

export function* updateIntegration(params) {
	try {
		const { data } = yield call(() =>
			putRequest(
				`${getCompanyUrl()}/integrations/${params.payload.id}`,
				params.payload,
			),
		);
		showNotification({
			type: "success",
			message: "Success!",
			description: `${startCase(
				params.payload.integration_type,
			)} integrated successfully!`,
		});
	} catch (e) {
		showNotification({
			type: "error",
			message: "Failure!",
			description: `Something went wrong while integrating ${startCase(
				params.payload.integration_type,
			)}!`,
		});
		handleException(e);
	} finally {
		setTimeout(() => {
			window.close();
		}, 3000);
	}
}

export function* destroyIntegration(params) {
	try {
		const { data } = yield call(() =>
			deleteRequest(`${getCompanyUrl()}/integrations/${params.payload}`),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: data.data.message,
		});
		yield put({
			type: actions.FETCH_USER_INTEGRATION,
			payload: { type: ["gmail", "outlook"] },
		});
	} catch (e) {
		handleException(e);
	}
}

export function* fetchIntegrations(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/integrations/fetch_integrations`, {
				integration_type: params.payload.type,
			}),
		);
		// yield put({
		// 	type: actions.SET_EMAIL_INTEGRATIONS,
		// 	payload: data.integrations,
		// });
	} catch (e) {
		handleException(e);
	}
}

export function* fetchUserIntegrations(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/integrations/fetch_integration`, {
				integration_type: params.payload.type,
			}),
		);
		yield put({
			type: actions.SET_EMAIL_INTEGRATIONS,
			payload: data.data.integrations,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* destroySlack(params) {
	try {
		const { data } = yield call(() =>
			deleteRequest(`${getCompanyUrl()}/slacks/${params.payload.id}`),
		);
		yield put({
			type: actions.SET_SLACK_INTEGRATION,
			payload: null,
		});
		showNotification({
			type: "success",
			message: "Success",
			description: data.data.message,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* fetchAtsCandidates(params) {
	try {
		const page = params.payload.page || 1,
			perPage = params.payload.perPage || 10;
		const { data } = yield call(() =>
			postRequest(
				`${getCompanyUrl()}/merges/ats_candidates?query[sorts]=&page=${page}&per_page=${perPage}`,
				{ id: params.payload.id },
			),
		);
		yield put({
			type: actions.SET_ATS_CANDIDATES,
			payload: data.data,
		});
	} catch (e) {
		handleException(e);
	}
}
export function* fetchInterviewStages() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/ats_interview_stages`),
		);
		yield put({
			type: actions.SET_INTERVIEW_STAGES,
			payload: data.data,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* fetchCurrentStage() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/ats_interview_stages/current_hired_stage`),
		);
		yield put({
			type: actions.SET_CURRENT_HIRED_STAGE,
			payload: data.data,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* setHiredStage(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/ats_interview_stages`, {
				id: params.payload.id,
			}),
		);
		yield put({
			type: actions.FETCH_CURRENT_STAGE,
		});
		showNotification({
			type: "success",
			message: "Success",
			description: "Successfully set the interview stage!",
		});
	} catch (e) {
		handleException(e);
	}
}

export function* importSelectedCandidates() {
	try {
		const { selectedAtsCandidates, userMerges } = store.getState().Users;
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/merges/import_ats_candidates`, {
				merge_id: userMerges.find((item) => item.category === "ats").id,
				candidate_ids: selectedAtsCandidates,
			}),
		);
		showNotification({
			type: "success",
			message: "Success",
			description: data.data.message,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* createCustomRelations(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/custom_relations`, params.payload),
		);
		showNotification({
			type: "success",
			message: "Success",
			description: data.data.message,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* fetchCustomFields() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/custom_relations`),
		);
		yield put({
			type: actions.SET_CUSTOM_FIELDS,
			payload: data.data.custom_relations,
		});
	} catch (e) {
		handleException(e);
	}
}

export function* setTwilioConfig(params) {
	try {
		const { data } = yield call(() =>
			patchRequest(`${getCompanyUrl()}`, params.payload),
		);
		yield put({
			type: authActions.UPDATE_TWILIO_CONFIG,
			payload: data.data.company,
		});
		yield put({
			type: actions.SET_TWILIO_LOADING,
			payload: false,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (e) {
		handleException(e);
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actions.FETCH_USERS, fetchUsers)]);
	yield all([takeEvery(actions.INVITE_USER, inviteUser)]);
	yield all([takeEvery(actions.DELETE_USERS, deleteUsers)]);
	yield all([takeEvery(actions.RESEND_INVITE, resendInvite)]);
	yield all([takeEvery(actions.FETCH_COMPANY_ROLES, fetchCompanyRoles)]);
	yield all([takeEvery(actions.GET_MERGE_TOKEN, fetchMergeToken)]);
	yield all([takeEvery(actions.CREATE_MERGE_TOKEN, createMergeToken)]);
	yield all([
		takeEvery(actions.UPDATE_MERGE_INTEGRATION, updateMergeIntegration),
	]);
	yield all([takeEvery(actions.CREATE_TRELLO_TICKET, createTrelloTicket)]);
	yield all([takeEvery(actions.FETCH_MERGES, fetchMerges)]);
	yield all([takeEvery(actions.DELETE_INT_MERGES, deleteIntMerges)]);
	yield all([takeEvery(actions.UNLINK_INT_MERGES, unlinkIntMerges)]);
	yield all([takeEvery(actions.FETCH_SLACK, fetchSlack)]);
	yield all([takeEvery(actions.CREATE_SLACK_INT, createSlackInt)]);
	yield all([takeEvery(actions.UPDATE_SLACK_INT, updateSlackInt)]);
	yield all([takeEvery(actions.CREATE_INTEGRATION_TOKEN, createIntegration)]);
	yield all([takeEvery(actions.UPDATE_INTEGRATION_TOKEN, updateIntegration)]);
	yield all([takeEvery(actions.DESTROY_EMAIL_INTEGRATION, destroyIntegration)]);
	yield all([takeEvery(actions.FETCH_INTEGRATIONS, fetchIntegrations)]);
	yield all([takeEvery(actions.FETCH_USER_INTEGRATION, fetchUserIntegrations)]);
	yield all([takeEvery(actions.DESTROY_SLACK, destroySlack)]);
	yield all([takeEvery(actions.FETCH_ATS_CANDIDATES, fetchAtsCandidates)]);
	yield all([
		takeEvery(actions.IMPORT_SELECTED_CANDIDATES, importSelectedCandidates),
	]);
	yield all([takeEvery(actions.FETCH_CUSTOM_FIELDS, fetchCustomFields)]);
	yield all([takeEvery(actions.FETCH_INTERVIEW_STAGES, fetchInterviewStages)]);
	yield all([takeEvery(actions.SET_HIRED_STAGE, setHiredStage)]);
	yield all([takeEvery(actions.FETCH_CURRENT_STAGE, fetchCurrentStage)]);
	yield all([takeEvery(actions.SET_TWILIO_CONFIG, setTwilioConfig)]);
}
