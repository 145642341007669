import actions from "./actions";
import { defaultPage, defaultPageSize } from "components/constants";

const initState = {
	pageHeaderLoader: false,
	tasksTableLoader: false,
	tasksList: [],
	taskDrawerVisible: false,
	taskFormType: null,
	totalTasks: 0,
	selectedTaskList: [],
	tasksSortKey: "modified_at",
	tasksSortOrder: "desc",
	tasksPageSize: defaultPageSize,
	tasksCurrentPage: defaultPage,
	deleteTaskLoader: false,
	currentTaskId: null,
	currentTask: {},
	createTaskLoader: false,
	taskJson: {},
	deleteModalVisible: false,
	taskActionKey: "",
	deleteAttachmentLoader: false,
};

export default function tasksReducer(state = initState, action) {
	switch (action.type) {
		case actions.FETCH_ADMIN_TASKS:
			return {
				...state,
				tasksTableLoader: true,
				pageHeaderLoader: true,
			};
		case actions.FETCH_ADMIN_TASKS_SUCCESS:
			return {
				...state,
				tasksTableLoader: false,
				pageHeaderLoader: false,
				tasksList: action.payload.tasks,
				totalTasks: action.payload.total_records,
			};
		case actions.FETCH_ADMIN_TASKS_FAILURE:
			return {
				...state,
				tasksTableLoader: false,
				pageHeaderLoader: false,
			};
		case actions.SET_ADMIN_TASK_DRAWER_VISIBLE:
			return {
				...state,
				taskDrawerVisible: action.payload.visible,
				taskFormType: action.payload.visible ? action.payload.formType : null,
			};
		case actions.ADMIN_TASK_SELECTED_LIST:
			return {
				...state,
				selectedTaskList: action.payload,
			};
		case actions.SET_ADMIN_TASK_TABLE_SORT_ORDER:
			return {
				...state,
				tasksSortKey: action.payload.sortKey,
				tasksSortOrder: action.payload.sortOrder,
			};
		case actions.SET_ADMIN_TASK_TABLE_SIZE:
			return {
				...state,
				tasksPageSize: action.payload,
			};
		case actions.SET_ADMIN_TASK_TABLE_CURRENT_PAGE:
			return {
				...state,
				tasksCurrentPage: action.payload,
			};
		case actions.DELETE_ADMIN_TASKS:
			return {
				...state,
				deleteTaskLoader: true,
			};
		case actions.DELETE_ADMIN_TASKS_SUCCESS:
			return {
				...state,
				deleteTaskLoader: false,
				deleteModalVisible: false,
				selectedTaskList: [],
			};
		case actions.DELETE_ADMIN_TASKS_FAILURE:
			return {
				...state,
				deleteTaskLoader: false,
			};
		case actions.GET_ADMIN_TASK_DATA_SUCCESS:
			return {
				...state,
				currentTask: action.payload,
				taskJson: action.payload.raw_json,
				currentTaskLoader: false,
			};
		case actions.GET_ADMIN_TASK_DATA_FAILURE:
			return {
				...state,
				currentTaskLoader: false,
			};
		case actions.GET_ADMIN_TASK_DATA:
			return {
				...state,
				currentTaskLoader: true,
			};
		case actions.UPDATE_ADMIN_CURRENT_TASK_ID:
			return {
				...state,
				currentTaskId: action.payload,
			};
		case actions.CREATE_ADMIN_TASK_SUCCESS:
			return {
				...state,
				createTaskLoader: false,
				taskDrawerVisible: false,
			};
		case actions.CREATE_ADMIN_TASK_FAILURE:
			return {
				...state,
				createTaskLoader: false,
			};
		case actions.CREATE_ADMIN_TASK:
			return {
				...state,
				createTaskLoader: true,
			};
		case actions.UPDATE_ADMIN_TASK:
			return {
				...state,
				createTaskLoader: state.taskDrawerVisible,
			};
		case actions.UPDATE_ADMIN_TASK_SUCCESS:
			return {
				...state,
				createTaskLoader: false,
				taskDrawerVisible: false,
				currentTask: action.payload,
			};
		case actions.UPDATE_ADMIN_TASK_FAILURE:
			return {
				...state,
				createTaskLoader: false,
			};
		case actions.RESET_CURRENT_TASK:
			return {
				...state,
				currentTask: {},
			};
		case actions.DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				deleteModalVisible: action.payload,
			};
		}
		case actions.SET_ADMIN_TASK_APPROVE_UNAPPROVE_KEY: {
			return {
				...state,
				taskActionKey: action.payload,
			};
		}
		case actions.APPROVE_OR_UNAPPROVE_ADMIN_TASK: {
			return {
				...state,
				deleteTaskLoader: true,
			};
		}
		case actions.APPROVE_OR_UNAPPROVE_ADMIN_TASK_SUCCESS: {
			return {
				...state,
				deleteTaskLoader: false,
				taskActionKey: "",
				selectedTaskList: [],
			};
		}
		case actions.APPROVE_OR_UNAPPROVE_ADMIN_TASK_FAILURE: {
			return {
				...state,
				deleteTaskLoader: false,
			};
		}
		case actions.REMOVE_ATTACHMENTS: {
			return {
				...state,
				deleteAttachmentLoader: true,
			};
		}
		case actions.REMOVE_ATTACHMENTS_SUCCESS: {
			return {
				...state,
				deleteAttachmentLoader: false,
				currentTask: {
					...state.currentTask,
					files: state.currentTask.files.filter(
						(file) => !action.payload.includes(file.attachment_id),
					),
				},
			};
		}
		case actions.REMOVE_ATTACHMENTS_FAILURE: {
			return {
				...state,
				deleteAttachmentLoader: false,
			};
		}
		default:
			return state;
	}
}
