import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/journeys/actions";
import { store } from "redux/store";
import {
	getRequest,
	postRequest,
	putRequest,
	patchRequest,
} from "helpers/axiosClient";
import {
	getCompanyUrl,
	getLocaleMessages,
	handleException,
	setUpdatedRules,
} from "redux/helper";
import {
	BUILDER_RULE_CREATION_TYPE,
	BUILDER_RULE_UPDATION_TYPE,
	BUILDER_EVENT_CREATION_TYPE,
	BUILDER_EVENT_UPDATION_TYPE,
} from "components/constants";
import {
	fetchCurrentJourneyID,
	generateJourneyJSON,
	getRootUrl,
	setSelectedAsset,
	showNotification,
} from "components/utility/helper";
import { dropdownListPerPage, notFoundCode } from "components/constants";
import { history } from "redux/store";

export function* fetchJourneys(params) {
	try {
		const {
				CurrentPage,
				listSearchKey,
				listSortKey,
				listSortValue,
				listPerPage,
				isSelectAllJourneys,
			} = store.getState().Journeys,
			searchQuery = params.searchText || listSearchKey,
			page = params.page || CurrentPage,
			sortKey = params.sortKey || listSortKey,
			sortOrder = params.sortOrder || listSortValue,
			perPage = params.perPage || listPerPage;
		let url = `${getCompanyUrl()}/journeys?query[name_cont]=${searchQuery.trim()}&query[sorts][]=${sortKey} ${sortOrder}&query[sorts][]=id ${sortOrder}&page=${page}&per_page=${perPage}`;

		const { data } = yield call(() => getRequest(url));
		// select all received data when the selectAll is checked and records per page got changed
		if (params.isPageChanges && isSelectAllJourneys) {
			const selectedKeys = data.data.journeys.map((list) => list.id);
			yield put({
				type: actions.UPDATE_SELECTED_JOURNEY,
				payload: selectedKeys,
			});
		}
		yield put({
			type: actions.FETCH_JOURNEYS_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_JOURNEYS_FAILURE });
		handleException(error);
	}
}

export function* createNewJourney(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/journeys`, params.payload),
		);
		yield put({ type: actions.FETCH_JOURNEYS });
		yield put({
			type: actions.CREATE_NEW_JOURNEY_SUCCESS,
		});
		if (params.fromDashboard) {
			history.push({
				pathname: `/${getRootUrl()}/journeys`,
				search: "journey_type=journey",
			});
		}
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_JOURNEY_FAILURE,
		});
	}
}

export function* fetchTimezone() {
	try {
		const timezoneResponse = yield call(() => getRequest("timezones"));
		yield put({
			type: actions.FETCH_TIMEZONE_SUCCESS,
			payload: timezoneResponse.data.data.timezones,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* deleteJourney(params) {
	try {
		const { data } = yield call(() =>
			putRequest(`${getCompanyUrl()}/journeys`, {
				ids: params.payload,
			}),
		);
		yield put({
			type: actions.FETCH_JOURNEYS,
		});
		yield put({
			type: actions.DELETE_JOURNEY_SUCCESS,
			payload: "journey",
		});
		if (params.fromBuilder) {
			history.push({
				pathname: `/${getRootUrl()}/journeys`,
				search: `journey_type=journey`,
			});
		} else {
			yield put({
				type: actions.FETCH_JOURNEYS,
			});
			yield put({
				type: actions.UPDATE_SELECTED_JOURNEY,
				payload: [],
			});
		}
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE,
				payload: false,
			});
		}
		handleException(error);
		yield put({
			type: actions.DELETE_JOURNEY_FAILURE,
		});
	}
}

export function* approveJourney(params) {
	try {
		const { data } = yield call(() =>
			putRequest(`${getCompanyUrl()}/journeys/status`, params.payload),
		);
		if (params.isFromTable) {
			yield put({ type: actions.FETCH_JOURNEYS });
			yield put({
				type: actions.UPDATE_SELECTED_JOURNEY,
				payload: [],
			});
		}
		yield put({
			type: actions.APPROVE_JOURNEY_SUCCESS,
			payload: params.payload,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.APPROVE_JOURNEY_FAILURE });
	}
}

export function* fetchJourneyBuilderDetails(params) {
	try {
		let url = `${getCompanyUrl()}/journeys/${params.journeyId}`;

		const { data } = yield call(() => getRequest(url)),
			{ journey } = data.data;
		yield put({
			type: actions.FETCH_JOURNEYS_BUILDER_DETAILS_SUCCESS,
			payload: journey,
		});
		yield put({
			type: actions.UPDATE_BUILDER_JSON,
			payload: generateJourneyJSON(journey.rules, journey.first_rule_id),
		});
	} catch (error) {
		handleException(error);
	}
}

export function* addBuilderModule(params) {
	try {
		let url = `${getCompanyUrl()}/journeys/${fetchCurrentJourneyID()}/events`;

		const { data } = yield call(() => postRequest(url, params.payload));

		const event = data.data.event;

		yield put({
			type: actions.ADD_OR_UPDATE_BUILDER_MODULE_SUCCESS,
			payload: {
				modified_at: event.updated_at,
			},
		});
		const { rules, firstRuleID } = setUpdatedRules(
			event,
			BUILDER_EVENT_CREATION_TYPE,
		);
		yield put({
			type: actions.UPDATE_BUILDER_JSON,
			payload: generateJourneyJSON(rules, firstRuleID),
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({ type: actions.ADD_OR_UPDATE_BUILDER_MODULE_FAILURE });
		handleException(error);
	}
}

export function* addBuilderRule(params) {
	try {
		let url = `${getCompanyUrl()}/journeys/${fetchCurrentJourneyID()}/rules`;

		const { data } = yield call(() => postRequest(url, params.payload));
		const rule = data.data.rule;
		yield put({
			type: actions.ADD_OR_UPDATE_RULE_SUCCESS,
			payload: {
				modified_at: rule.updated_at,
			},
		});
		const { rules, firstRuleID } = setUpdatedRules(
			rule,
			BUILDER_RULE_CREATION_TYPE,
		);
		yield put({
			type: actions.UPDATE_BUILDER_JSON,
			payload: generateJourneyJSON(rules, firstRuleID),
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({ type: actions.ADD_OR_UPDATE_RULE_FAILURE });
		handleException(error);
	}
}
export function* updateBuilderRule(params) {
	try {
		let url = `${getCompanyUrl()}/journeys/${fetchCurrentJourneyID()}/rules/${
			params.payload.id
		}`;
		const { data } = yield call(() => putRequest(url, params.payload));
		const rule = data.data.rule;
		yield put({
			type: actions.ADD_OR_UPDATE_RULE_SUCCESS,
			payload: {
				modified_at: rule.updated_at,
			},
		});
		const { rules, firstRuleID } = setUpdatedRules(
			rule,
			BUILDER_RULE_UPDATION_TYPE,
		);
		yield put({
			type: actions.UPDATE_BUILDER_JSON,
			payload: generateJourneyJSON(rules, firstRuleID),
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({ type: actions.ADD_OR_UPDATE_RULE_FAILURE });
		handleException(error);
	}
}

export function* removeBuilderRule(params) {
	try {
		let url = `${getCompanyUrl()}/journeys/${fetchCurrentJourneyID()}/rules`;
		const { data } = yield call(() => putRequest(url, params.payload));
		yield put({
			type: actions.REMOVE_BUILDER_RULE_SUCCESS,
		});
		const journey = data.data.journey;
		yield put({
			type: actions.UPDATE_JOURNEY_DATA,
			payload: journey,
		});
		yield put({
			type: actions.UPDATE_BUILDER_JSON,
			payload: generateJourneyJSON(journey.rules, journey.first_rule_id),
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({
			type: actions.REMOVE_BUILDER_RULE_FAILURE,
		});
		handleException(error);
	}
}

export function* fetchAssets(params) {
	const { addModuleType, assetSearchKey, assetPage } =
		store.getState().Journeys;
	try {
		let url = `${getCompanyUrl()}/assets?query[asset_type_eq]=${addModuleType}&per_page=${dropdownListPerPage}&page=${assetPage}&query[name_cont]=${encodeURI(
			assetSearchKey.trim(),
		)}&query[is_template_eq]=false`;

		const { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_ASSETS_SUCCESS,
			payload: data.data.assets,
		});
		if (params.moduleContent) {
			setSelectedAsset(params.node);
		}
	} catch (error) {
		yield put({ type: actions.FETCH_ASSETS_FAILURE });
		handleException(error);
	}
}

export function* updateBuilderModule(params) {
	try {
		let url = `${getCompanyUrl()}/journeys/${fetchCurrentJourneyID()}/events/${
			params.id
		}`;

		const { data } = yield call(() => putRequest(url, params.payload));

		const event = data.data.event;

		yield put({
			type: actions.ADD_OR_UPDATE_BUILDER_MODULE_SUCCESS,
			payload: {
				modified_at: event.modified_at,
			},
		});

		const { rules, firstRuleID } = setUpdatedRules(
			event,
			BUILDER_EVENT_UPDATION_TYPE,
		);

		yield put({
			type: actions.UPDATE_BUILDER_JSON,
			payload: generateJourneyJSON(rules, firstRuleID),
		});
		store.dispatch({ type: actions.SET_ASSET_SEARCH, payload: "" });
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({ type: actions.ADD_OR_UPDATE_BUILDER_MODULE_FAILURE });
		handleException(error);
	}
}

export function* deleteModules(params) {
	try {
		const { deleteModule } = store.getState().Journeys;
		let url = `${getCompanyUrl()}/journeys/${fetchCurrentJourneyID()}/events`;
		const payload = {
			...params.payload,
			rule_id: deleteModule.parentRuleID,
		};
		const { data } = yield call(() => putRequest(url, payload));
		const journey = data.data.journey;
		yield put({
			type: actions.DELETE_MODULES_SUCCESS,
			payload: {
				modified_at: journey.modified_at,
			},
		});
		yield put({
			type: actions.UPDATE_JOURNEY_DATA,
			payload: journey,
		});
		yield put({
			type: actions.UPDATE_BUILDER_JSON,
			payload: generateJourneyJSON(journey.rules, journey.first_rule_id),
		});

		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({ type: actions.DELETE_MODULES_FAILURE });
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.UPDATE_MODULE_DELETE_CONFIRM,
				payload: false,
			});
		}
		handleException(error);
	}
}

export function* fetchRoles() {
	try {
		let url = `${getCompanyUrl()}/roles`;
		const { data } = yield call(() => getRequest(url));

		yield put({
			type: actions.FETCH_ROLES_SUCCESS,
			payload: data.data.roles,
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.FETCH_ROLES_FAILURE });
	}
}

export function* fetchTasks(params) {
	const { taskSearchKey, taskPage } = store.getState().Journeys;
	const { eventId } = params.node === undefined ? params : params.node;
	try {
		let url = `${getCompanyUrl()}/tasks?per_page=${dropdownListPerPage}&page=${taskPage}&query[name_cont]=${encodeURI(
			taskSearchKey.trim(),
		)}&event_id=${eventId !== undefined ? eventId : ""}`;

		const { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_JOURNEY_TASKS_SUCCESS,
			payload: data.data,
		});
		if (params.moduleContent) {
			setSelectedAsset(params.node);
		}
	} catch (error) {
		yield put({ type: actions.FETCH_JOURNEY_TASKS_FAILURE });
		handleException(error);
	}
}

export function* addJourneyMeeting(params) {
	try {
		const {
			assign_to,
			scheduled_day,
			scheduled_time,
			days_before_due,
			end_time,
			reminder,
			...restMeeting
		} = params.payload;
		const meetingPayload = {
			meeting: {
				...restMeeting,
				meeting_type: "journey",
				from: scheduled_time,
				to: end_time,
			},
		};
		let url = `${getCompanyUrl()}/meetings`;

		const { data } = yield call(() => postRequest(url, meetingPayload));
		const { name, description, ...rest } = params.payload;
		const eventPayload = {
			event: {
				meeting_id: data.data.meeting.id,
				reminder: params.meetingReminder,
				...rest,
			},
		};
		yield put({
			type: actions.ADD_BUILDER_MODULE,
			payload: eventPayload,
		});
	} catch (error) {
		yield put({ type: actions.ADD_JOURNEY_MEETING_FAILURE });
		handleException(error);
	}
}

export function* updateJourneyMeeting(params) {
	try {
		const {
			assign_to,
			scheduled_day,
			scheduled_time,
			days_before_due,
			end_time,
			reminder,
			...restMeeting
		} = params.payload;
		const meetingPayload = {
			meeting: {
				...restMeeting,
				from: scheduled_time,
				to: end_time,
			},
		};
		let url = `${getCompanyUrl()}/meetings/${params.meetingId}`;

		yield call(() => putRequest(url, meetingPayload));
		const { name, description, ...rest } = params.payload;
		const eventPayload = {
			event: {
				...rest,
				reminder: params.meetingReminder,
			},
		};
		yield put({
			type: actions.UPDATE_BUILDER_MODULE,
			payload: eventPayload,
			id: params.eventId,
		});
	} catch (error) {
		yield put({ type: actions.UPDATE_JOURNEY_MEETING_FAILURE });
		handleException(error);
	}
}

export function* updateJourney(params) {
	try {
		let url = `${getCompanyUrl()}/journeys/${fetchCurrentJourneyID()}`;

		const { data } = yield call(() => putRequest(url, params.payload));
		yield put({
			type: actions.UPDATE_JOURNEY_SUCCESS,
			payload: data.data.journey,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		store.dispatch({ type: actions.SET_EDIT_JOURNEY_MODAL, payload: false });
	} catch (error) {
		yield put({ type: actions.UPDATE_JOURNEY_FAILURE });
		handleException(error);
	}
}

export function* fetchUsers(params) {
	try {
		const { data } = yield call(() => getRequest(`${getCompanyUrl()}/users`));
		yield put({
			type: actions.FETCH_JOURNEY_USERS_SUCCESS,
			payload: data.data.users,
		});
		if (params.moduleContent) {
			setSelectedAsset({ content: params.moduleContent });
		}
	} catch (error) {
		yield put({ type: actions.FETCH_JOURNEY_USERS_FAILURE });
		handleException(error);
	}
}

export function* fetchJourneysTemplate(params) {
	try {
		const {
			journeyTemplateCurrentPage,
			journeyTemplateSortKey,
			journeyTemplateSortOrder,
			journeyTemplatePerSize,
		} = store.getState().Journeys;
		let url = `admin/journeys?query[sorts][]=${journeyTemplateSortKey} ${journeyTemplateSortOrder}&query[sorts][]=id ${journeyTemplateSortOrder}&page=${journeyTemplateCurrentPage}&per_page=${journeyTemplatePerSize}&query[published_eq]=true`;

		const { data } = yield call(() => getRequest(url));
		// select all received data when the selectAll is checked and records per page got changed
		yield put({
			type: actions.FETCH_JOURNEYS_ADMIN_TEMPLATE_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_JOURNEYS_ADMIN_TEMPLATE_FAILURE });
		handleException(error);
	}
}

export function* duplicateJourney(params) {
	try {
		const { copyJourneyContent } = store.getState().Journeys;
		yield call(() =>
			postRequest(
				`${getCompanyUrl()}/journeys/${copyJourneyContent.id}/duplicate`,
				params.payload,
			),
		);
		yield put({
			type: actions.DUPLICATE_JOURNEY_SUCCESS,
		});
		yield put({
			type: actions.SET_ADMIN_JOURNEY_DETAILS,
			payload: {},
		});
		history.push({ search: "journey_type=journey" });
	} catch (error) {
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.CHANGE_JOURNEY_MODAL_STATUS,
				payload: false,
			});
		}
		handleException(error);
		yield put({
			type: actions.DUPLICATE_JOURNEY_FAILURE,
		});
	}
}

export function* fetchCompanyDetails() {
	try {
		const response = yield call(() => getRequest("profile"));
		yield put({
			type: actions.COMPANY_DETAILS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchMerges() {
	try {
		const { data } = yield call(() => getRequest(`${getCompanyUrl()}/merges`));
		yield put({
			type: actions.USER_MERGES,
			payload: data.data.merges,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchTrello() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/ticketing_assets`),
		);
		yield put({
			type: actions.SET_TICKETING_ASSETS,
			payload: response.data.data.ticketing_assets,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchSlackAsset() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/slack_assets`),
		);
		yield put({
			type: actions.SET_SLACK_ASSETS,
			payload: response.data.slack_assets,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchSlack() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/slacks/fetch_slack_users`),
		);
		yield put({
			type: actions.SET_SLACK_USERS,
			payload: response.data.data.slack_users,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchTrelloUsers() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/merges/fetch_ticketing_users`),
		);
		yield put({
			type: actions.SET_TICKETING_USERS,
			payload: response.data.ticketing_users,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchGmailAddress() {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/integrations/fetch_integrations`, {
				integration_type: ["gmail", "outlook"],
			}),
		);
		yield put({
			type: actions.SET_GMAIL_ADDRESS,
			payload: response.data.data.integrations,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchIntSlackUsers() {
	try {
		const response = yield call(() => getRequest(`${getCompanyUrl()}/slacks`));
		yield put({
			type: actions.SET_INT_SLACK_USERS,
			payload: response.data.data.slacks,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchTicketingMerge() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/merges/fetch_ticketing`),
		);
		yield put({
			type: actions.SET_TICKETING_MERGE,
			payload: data.merges,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchOutlookAddress(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/integrations/fetch_integrations`, {
				integration_type: params.payload,
			}),
		);
		yield put({
			type: actions.SET_OUTLOOK_ADDRESS,
			payload: response.data.data.integrations,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchMicrosoftUsers() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/microsoft_team_users`),
		);
		yield put({
			type: actions.SET_MICROSOFT_TEAM_USERS,
			payload: data.data.microsoft_team_users,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* updateAutoAddCondition(params) {
	try {
		const { auto_add_condition_id } =
			store.getState().Journeys.journeyBuilderData;
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/auto_add_conditions/${auto_add_condition_id}`,
				{
					auto_add_condition: params.payload.autoAddCondition,
				},
			),
		);
		yield put({
			type: actions.SET_FILTERED_EMPLOYEES_DETAILS,
			payload: response.data.data.filtered_employees,
		});
		yield put({
			type: actions.SET_AUTO_ADD_CONDITION,
			payload: response.data.data.auto_add_condition,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getAutoAddMatchedEmployees(params) {
	try {
		const response = yield call(() =>
			postRequest(
				`${getCompanyUrl()}/auto_add_conditions/fetch_filtered_employees`,
				{
					auto_add_condition: params.payload,
				},
			),
		);
		yield put({
			type: actions.SET_FILTERED_EMPLOYEES_DETAILS,
			payload: response.data.data,
		});
		yield put({
			type: actions.SET_IS_FILTERING_EMPLOYEES,
			payload: false,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchRulesList() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/journeys/rule_list`),
		);
		yield put({
			type: actions.SET_RULES_LIST,
			payload: response.data.data.rule_list,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchCustomRelationsList() {
	try {
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/custom_relations/custom_employee_relations`,
			),
		);
		yield put({
			type: actions.SET_CUSTOM_RELATIONS_LIST,
			payload: response.data.data.custom_employee_relations,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchAutoAddFields() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/auto_add_conditions/auto_add_fields`),
		);
		yield put({
			type: actions.SET_AUTO_ADD_FIELDS,
			payload: response.data.data.fields,
		});
		yield put({
			type: actions.SET_AUTO_ADD_FIELDS_LOADER,
			payload: false,
		});
		const { isFetchingAutoAddQuery } = store.getState().Journeys;
		if (!isFetchingAutoAddQuery) {
			yield put({
				type: actions.SET_QUERY_BUILDER_LOADER,
				payload: false,
			});
		}
	} catch (error) {
		handleException(error);
	}
}

export function* fetchAutoAddCondition() {
	try {
		const { auto_add_condition_id } =
			store.getState().Journeys.journeyBuilderData;
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/auto_add_conditions/${auto_add_condition_id}`,
			),
		);
		yield put({
			type: actions.SET_AUTO_ADD_CONDITION,
			payload: response.data.data.auto_add_condition,
		});
		yield put({
			type: actions.SET_AUTO_ADD_QUERY_LOADER,
			payload: false,
		});
		const { isFetchingAutoAddFields } = store.getState().Journeys;
		if (!isFetchingAutoAddFields) {
			yield put({
				type: actions.SET_QUERY_BUILDER_LOADER,
				payload: false,
			});
		}
	} catch (error) {
		handleException(error);
	}
}

export function* fetchJourneyAnalytics(params) {
	try {
		const { journeyId, query } = params.payload;
		const filterQuery = {
			date_gteq: query.date_gteq,
			date_lt: query.date_lt,
			employee_ids: query.employee_ids,
		};
		yield put({
			type: actions.SET_JOURNEY_ANALYTICS_LOADER,
			payload: {
				statsLoading: true,
				interactionsLoading: true,
			},
		});
		yield put({
			type: actions.FETCH_ANALYTICS_STATS,
			payload: {
				journeyId: journeyId,
				query: filterQuery,
			},
		});
		yield put({
			type: actions.FETCH_ANALYTICS_INTERACTIONS,
			payload: {
				journeyId: journeyId,
				query: filterQuery,
			},
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchJourneyAnalyticsStats(params) {
	try {
		const response = yield call(() =>
			postRequest(
				`${getCompanyUrl()}/journeys/${
					params.payload.journeyId
				}/journey_analytics_events`,
				params.payload.query,
			),
		);
		yield put({
			type: actions.SET_JOURNEY_ANALYTICS,
			payload: response.data.data,
		});

		yield put({
			type: actions.SET_JOURNEY_ANALYTICS_LOADER,
			payload: {
				statsLoading: false,
			},
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchJourneyAnalyticsInteractions(params) {
	try {
		const response = yield call(() =>
			postRequest(
				`${getCompanyUrl()}/journeys/${params.payload.journeyId}/interactions`,
				params.payload.query,
			),
		);
		yield put({
			type: actions.SET_JOURNEY_ANALYTICS,
			payload: response.data.data,
		});

		yield put({
			type: actions.SET_JOURNEY_ANALYTICS_LOADER,
			payload: {
				interactionsLoading: false,
			},
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchEventComparisonData(params) {
	try {
		const { query } = params.payload;
		const { eventAnalyticsComparisonData } = store.getState().Journeys;
		const filterQuery = {
			date_gteq: query.date_gteq,
			date_lt: query.date_lt,
			employee_ids: query.employee_ids,
		};

		const response = yield call(() =>
			postRequest(
				`${getCompanyUrl()}/journeys/${
					params.payload.journeyId
				}/journey_analytics_events/compare`,
				{
					journey_id: params.payload.journeyId,
					event_id: params.payload.event_id,
					compare_key: params.payload.compare_key,
					...filterQuery,
				},
			),
		);
		const comparisonData = response.data.data;
		yield put({
			type: actions.SET_EVENT_ANALYTICS_COMPARISON_DATA,
			payload: {
				[comparisonData.event_id]: {
					loading: false,
					compareKey: comparisonData.compare_key,
					data: {
						clicks: comparisonData.clicks,
						opens: comparisonData.opens,
						unique_clicks: comparisonData.unique_clicks,
						unique_opens: comparisonData.unique_opens,
					},
				},
			},
		});
	} catch (error) {
		handleException(error);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_JOURNEYS, fetchJourneys),
		takeEvery(
			actions.FETCH_JOURNEY_BUILDER_DETAILS,
			fetchJourneyBuilderDetails,
		),
		takeEvery(actions.ADD_BUILDER_MODULE, addBuilderModule),
		takeEvery(actions.ADD_BUILDER_RULE, addBuilderRule),
		takeEvery(actions.ADD_JOURNEY_MEETING, addJourneyMeeting),
		takeEvery(actions.APPROVE_JOURNEY, approveJourney),
		takeEvery(actions.CREATE_NEW_JOURNEY, createNewJourney),
		takeEvery(actions.DELETE_JOURNEY, deleteJourney),
		takeEvery(actions.DELETE_MODULES, deleteModules),
		takeEvery(actions.DUPLICATE_JOURNEY, duplicateJourney),
		takeEvery(actions.FETCH_ASSETS, fetchAssets),
		takeEvery(actions.FETCH_GMAIL_ADDRESS, fetchGmailAddress),
		takeEvery(actions.FETCH_INT_SLACK_USERS, fetchIntSlackUsers),
		takeEvery(actions.FETCH_JOURNEY_TASKS, fetchTasks),
		takeEvery(actions.FETCH_JOURNEY_USERS, fetchUsers),
		takeEvery(actions.FETCH_JOURNEYS_ADMIN_TEMPLATE, fetchJourneysTemplate),
		takeEvery(actions.FETCH_MERGES, fetchMerges),
		takeEvery(actions.FETCH_ROLES, fetchRoles),
		takeEvery(actions.FETCH_SLACK_ASSET, fetchSlackAsset),
		takeEvery(actions.FETCH_SLACK, fetchSlack),
		takeEvery(actions.FETCH_TICKETING_MERGE, fetchTicketingMerge),
		takeEvery(actions.FETCH_TICKETING_USERS, fetchTrelloUsers),
		takeEvery(actions.FETCH_TICKETING, fetchTrello),
		takeEvery(actions.FETCH_TIMEZONE, fetchTimezone),
		takeEvery(actions.GET_COMPANY_DETAILS, fetchCompanyDetails),
		takeEvery(actions.REMOVE_BUILDER_RULE, removeBuilderRule),
		takeEvery(actions.UPDATE_BUILDER_MODULE, updateBuilderModule),
		takeEvery(actions.UPDATE_BUILDER_RULE, updateBuilderRule),
		takeEvery(actions.UPDATE_JOURNEY_MEETING, updateJourneyMeeting),
		takeEvery(actions.UPDATE_JOURNEY, updateJourney),
		takeEvery(actions.FETCH_OUTLOOK_ADDRESS, fetchOutlookAddress),
		takeEvery(actions.FETCH_MICROSOFT_USERS, fetchMicrosoftUsers),
		takeEvery(actions.UPDATE_AUTO_ADD_CONDITION, updateAutoAddCondition),
		takeEvery(
			actions.GET_AUTO_ADD_MATCHED_EMPLOYEES,
			getAutoAddMatchedEmployees,
		),
		takeEvery(actions.FETCH_RULES_LIST, fetchRulesList),
		takeEvery(actions.FETCH_CUSTOM_RELATIONS_LIST, fetchCustomRelationsList),
		takeEvery(actions.FETCH_AUTO_ADD_FIELDS, fetchAutoAddFields),
		takeEvery(actions.FETCH_AUTO_ADD_CONDITION, fetchAutoAddCondition),
		takeEvery(actions.FETCH_JOURNEY_ANALYTICS, fetchJourneyAnalytics),
		takeEvery(actions.FETCH_ANALYTICS_STATS, fetchJourneyAnalyticsStats),
		takeEvery(
			actions.FETCH_ANALYTICS_INTERACTIONS,
			fetchJourneyAnalyticsInteractions,
		),
		takeEvery(actions.FETCH_EVENT_COMPARISON_DATA, fetchEventComparisonData),
	]);
}
