import actions from "redux/reports/actions";

const initState = {
	setFilter: "",
	onBoardChartData: [],
	chartLoader: false,
	engagementEmailData: [],
	engagementPageData: [],
	emailLoader: false,
	pageLoader: false,
	engagementScoreChart: [],
	engagementScore: null,
	selectiveDate: {},
	employeesOnboardedCount: null,
	onBoardChartCategories: [],
};

export default function reportReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_DAYS_FILTER: {
			return {
				...state,
				setFilter: action.payload,
			};
		}
		case actions.FETCH_ON_BOARD_CHART_DATA_SUCCESS: {
			const { employees_onboarded_graph, employees_onboarded } = action.payload;
			return {
				...state,
				onBoardChartData: employees_onboarded_graph.values,
				onBoardChartCategories: employees_onboarded_graph.categories,
				employeesOnboardedCount: employees_onboarded,
			};
		}
		case actions.CHART_LOADER: {
			return {
				...state,
				chartLoader: action.payload,
			};
		}
		case actions.FETCH_MOST_ENGAGEMENT_EMAIL_SUCCESS: {
			return {
				...state,
				engagementEmailData: action.payload,
				emailLoader: false,
			};
		}
		case actions.FETCH_MOST_ENGAGEMENT_EMAIL_FAILURE: {
			return {
				...state,
				emailLoader: false,
			};
		}
		case actions.SET_EMAIL_LOADER: {
			return {
				...state,
				emailLoader: action.payload,
			};
		}
		case actions.SET_PAGE_LOADER: {
			return {
				...state,
				pageLoader: action.payload,
			};
		}
		case actions.FETCH_MOST_ENGAGEMENT_PAGE_SUCCESS: {
			return {
				...state,
				engagementPageData: action.payload,
				pageLoader: false,
			};
		}
		case actions.FETCH_MOST_ENGAGEMENT_PAGE_FAILURE: {
			return {
				...state,
				pageLoader: false,
			};
		}
		case actions.FETCH_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS: {
			const { engagement_score_graph, engagement_score } = action.payload;
			return {
				...state,
				engagementScoreChart: engagement_score_graph,
				engagementScore: engagement_score ? engagement_score : 0,
				chartLoader: false,
			};
		}
		case actions.FETCH_ENGAGEMENT_SCORE_CHART_DATA_FAILURE: {
			return {
				...state,
				chartLoader: false,
			};
		}
		case actions.GET_DAYS_RANGE: {
			return {
				...state,
				selectiveDateRange: action.payload,
			};
		}
		default:
			return state;
	}
}
