import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/adminUser/meetings/actions";
import { store } from "redux/store";
import { getRequest, patchRequest, postRequest } from "helpers/axiosClient";
import {
	calculatePageNumber,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import { showNotification } from "components/utility/helper";

export function* getMeetingsList(params) {
	try {
		const {
			pageSize,
			meetingsSortOrder,
			meetingsSortKey,
			meetingsCurrentPage,
		} = store.getState().AdminMeetings;
		const response = yield call(() =>
			getRequest(
				`admin/meetings?query[sorts][]=${meetingsSortKey} ${meetingsSortOrder}&query[sorts][]=id ${meetingsSortOrder}&page=${meetingsCurrentPage}&per_page=${pageSize}&query[meeting_type_eq]=general`,
			),
		);
		yield put({
			type: actions.GET_ADMIN_MEETINGS_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_ADMIN_MEETINGS_LIST_FAILURE,
		});
	}
}

export function* deleteMeetingsList(params) {
	try {
		const {
			selectedMeetingsList,
			meetingsCurrentPage,
			totalMeetingsData,
			pageSize,
		} = store.getState().AdminMeetings;
		const response = yield call(() =>
			patchRequest("admin/meetings", {
				ids: selectedMeetingsList,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.DELETE_ADMIN_MEETINGS_LIST_SUCCESS,
		});
		if (
			meetingsCurrentPage >
			Math.ceil((totalMeetingsData - selectedMeetingsList.length) / pageSize)
		) {
			let pages = calculatePageNumber(
				totalMeetingsData,
				selectedMeetingsList,
				pageSize,
			);
			yield put({
				type: actions.SET_ADMIN_MEETINGS_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.GET_ADMIN_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_ADMIN_MEETINGS_LIST_FAILURE,
		});
	}
}

export function* createNewMeetings(params) {
	try {
		const response = yield call(() =>
			postRequest("admin/meetings", {
				meeting: { ...params.payload, is_template: true },
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});

		yield put({
			type: actions.CREATE_NEW_ADMIN_MEETINGS_SUCCESS,
			payload: response.data.data,
		});

		yield put({
			type: actions.GET_ADMIN_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_ADMIN_MEETINGS_FAILURE,
		});
	}
}

export function* updateMeetings(params) {
	try {
		const { meetingId } = store.getState().AdminMeetings;
		const response = yield call(() =>
			patchRequest(`admin/meetings/${meetingId}`, {
				meeting: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.UPDATE_ADMIN_MEETINGS_CONTENT_SUCCESS,
			payload: response.data.data,
		});
		yield put({
			type: actions.RESET_ADMIN_MEETINGS_DATA,
		});
		yield put({
			type: actions.GET_ADMIN_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_ADMIN_MEETINGS_CONTENT_FAILURE,
		});
	}
}

export function* publishedMeetings() {
	try {
		const { selectedMeetingsList, meetingActionKey } =
			store.getState().AdminMeetings;
		const response = yield call(() =>
			patchRequest("admin/meetings/publish", {
				ids: selectedMeetingsList,
				publish: meetingActionKey === "approve",
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_SUCCESS,
		});
		yield put({
			type: actions.GET_ADMIN_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_FAILURE,
		});
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_ADMIN_MEETINGS_LIST, getMeetingsList),
		takeEvery(actions.DELETE_ADMIN_MEETINGS_LIST, deleteMeetingsList),
		takeEvery(actions.CREATE_NEW_ADMIN_MEETINGS, createNewMeetings),
		takeEvery(actions.UPDATE_ADMIN_MEETINGS_CONTENT, updateMeetings),
		takeEvery(actions.APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS, publishedMeetings),
	]);
}
