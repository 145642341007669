import actions from "./actions";
import { defaultPage, defaultPageSize } from "components/constants";

const initState = {
	customEmails: null,
	duplicateMeetingModal: false,
	duration: null,
	emailDrawerVisible: false,
	employeeIds: [],
	employeeListLoader: false,
	employeePage: defaultPage,
	employees: [],
	employeeSearchText: "",
	formLoader: false,
	from: null,
	isGettingEmployees: false,
	isRemindMeeting: null,
	location: null,
	meetingActionKey: null,
	meetingActiveList: "",
	meetingDate: null,
	meetingDescription: null,
	meetingDuration: null,
	meetingId: null,
	meetingName: null,
	meetingReminder: null,
	meetingScheduleDate: null,
	meetingScheduleTime: null,
	meetingsContent: null,
	meetingsCurrentPage: 1,
	meetingsDrawerLoader: false,
	meetingsDrawerVisible: false,
	meetingsFormType: null,
	meetingsList: [],
	meetingsModalLoader: false,
	meetingsModalVisible: false,
	meetingsName: null,
	meetingsSortKey: "scheduled_at",
	meetingsSortOrder: "asc",
	meetingsTableLoader: false,
	meetingsTemplateCurrentPage: 1,
	meetingsTemplateList: [],
	meetingsTemplatePageSize: defaultPageSize,
	meetingsTemplateSortKey: "scheduled_at",
	meetingsTemplateSortOrder: "asc",
	meetingsTemplateTableLoader: false,
	meetingTemplateId: null,
	pageHeaderLoader: false,
	pageSize: defaultPageSize,
	selectedMeetingsList: [],
	to: null,
	totalMeetingsData: 0,
	totalMeetingsTemplate: 0,
	upComingMeetingLoader: false,
	upComingMeetings: [],
};

export default function meetingsReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_MEETINGS_LIST: {
			return {
				...state,
				meetingsTableLoader: true,
				pageHeaderLoader: true,
			};
		}
		case actions.GET_MEETINGS_LIST_SUCCESS: {
			return {
				...state,
				meetingsTableLoader: false,
				meetingsList: action.payload.meetings,
				totalMeetingsData: action.payload.total_records,
				pageHeaderLoader: false,
			};
		}
		case actions.GET_MEETINGS_LIST_FAILURE: {
			return {
				...state,
				meetingsTableLoader: false,
				pageHeaderLoader: false,
			};
		}
		case actions.SET_MEETINGS_DRAWER_VISIBLE: {
			return {
				...state,
				meetingsDrawerVisible: action.payload.visible,
				meetingsFormType: action.payload.visible
					? action.payload.formType
					: null,
				formLoader: action.payload.visible ? state.formLoader : false,
			};
		}
		case actions.MEETINGS_SELECTED_LIST: {
			return {
				...state,
				selectedMeetingsList: action.payload,
			};
		}
		case actions.DELETE_MEETINGS_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				meetingsModalVisible: action.payload.visible,
				meetingActionKey: action.payload.actionKey
					? action.payload.actionKey
					: null,
			};
		}
		case actions.DELETE_MEETINGS_LIST: {
			return {
				...state,
				meetingsModalLoader: true,
			};
		}
		case actions.DELETE_MEETINGS_LIST_SUCCESS: {
			return {
				...state,
				meetingsModalLoader: false,
				meetingsModalVisible: false,
				selectedMeetingsList: [],
				meetingActionKey: null,
			};
		}
		case actions.DELETE_MEETINGS_LIST_FAILURE: {
			return {
				...state,
				meetingsModalLoader: false,
			};
		}
		case actions.SET_MEETINGS_TABLE_SIZE: {
			return {
				...state,
				pageSize: action.payload,
			};
		}
		case actions.SET_MEETINGS_TABLE_CURRENT_PAGE: {
			return {
				...state,
				meetingsCurrentPage: action.payload,
			};
		}
		case actions.SET_MEETINGS_TABLE_SORT_ORDER: {
			return {
				...state,
				meetingsSortKey: action.payload.sortKey,
				meetingsSortOrder: action.payload.sortOrder,
			};
		}
		case actions.CREATE_NEW_MEETINGS: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_MEETINGS_SUCCESS: {
			return {
				...state,
				formLoader: false,
				meetingsDrawerVisible: false,
				meetingsFormType: null,
				meetingActiveList: "meeting",
			};
		}
		case actions.CREATE_NEW_MEETINGS_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.SET_MEETING_DATA: {
			const {
				name,
				id,
				description,
				remindBefore,
				scheduledAt,
				reminder,
				location,
				duration,
				customEmails,
				employeeIds,
				from,
				to,
			} = action.payload;
			return {
				...state,
				meetingId: id,
				meetingName: name,
				meetingDescription: description,
				meetingScheduleDate: scheduledAt,
				meetingReminder: remindBefore,
				isRemindMeeting: reminder,
				location,
				duration,
				customEmails,
				employeeIds,
				from,
				to,
			};
		}
		case actions.RESET_MEETING_DATA: {
			return {
				...state,
				meetingId: null,
				meetingName: null,
				meetingDescription: null,
				meetingScheduleDate: null,
				meetingReminder: null,
				isRemindMeeting: null,
			};
		}
		case actions.UPDATE_MEETINGS_CONTENT: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.UPDATE_MEETINGS_CONTENT_SUCCESS: {
			return {
				...state,
				formLoader: false,
				meetingsDrawerVisible: false,
				meetingsFormType: null,
			};
		}
		case actions.UPDATE_MEETINGS_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.APPROVE_OR_CANCEL_MEETING_LIST: {
			return {
				...state,
				meetingsModalLoader: true,
			};
		}
		case actions.APPROVE_OR_CANCEL_MEETING_LIST_SUCCESS: {
			return {
				...state,
				meetingsModalLoader: false,
				meetingsModalVisible: false,
				selectedMeetingsList: [],
				meetingActionKey: null,
			};
		}
		case actions.APPROVE_OR_CANCEL_MEETING_LIST_FAILURE: {
			return {
				...state,
				meetingsModalLoader: false,
			};
		}
		case actions.SET_MEETINGS_TEMPLATE_TABLE_SIZE: {
			return {
				...state,
				meetingsTemplatePageSize: action.payload,
			};
		}
		case actions.SET_MEETINGS_TEMPLATE_TABLE_CURRENT_PAGE: {
			return {
				...state,
				meetingsTemplateCurrentPage: action.payload,
			};
		}
		case actions.SET_MEETINGS_TEMPLATE_TABLE_SORT_ORDER: {
			return {
				...state,
				meetingsTemplateSortKey: action.payload.sortKey,
				meetingsTemplateSortOrder: action.payload.sortOrder,
			};
		}
		case actions.GET_MEETINGS_TEMPLATE_LIST: {
			return {
				...state,
				meetingsTemplateTableLoader: true,
				pageHeaderLoader: true,
			};
		}
		case actions.GET_MEETINGS_TEMPLATE_LIST_SUCCESS: {
			return {
				...state,
				meetingsTemplateTableLoader: false,
				meetingsTemplateList: action.payload.meetings,
				totalMeetingsTemplate: action.payload.total_records,
				pageHeaderLoader: false,
			};
		}
		case actions.GET_MEETINGS_TEMPLATE_LIST_FAILURE: {
			return {
				...state,
				meetingsTemplateTableLoader: false,
				pageHeaderLoader: false,
			};
		}
		case actions.SET_MEETING_ACTIVE_LIST: {
			return {
				...state,
				meetingActiveList: action.payload,
			};
		}
		case actions.SET_MEETING_TEMPLATE_ID: {
			return {
				...state,
				meetingTemplateId: action.payload,
			};
		}
		case actions.MEETING_DUPLICATE_MODAL_VISIBLE: {
			return {
				...state,
				duplicateMeetingModal: action.payload,
			};
		}
		case actions.DUPLICATE_MEETING_TEMPLATE: {
			return {
				...state,
				meetingsModalLoader: true,
			};
		}
		case actions.DUPLICATE_MEETING_TEMPLATE_SUCCESS: {
			return {
				...state,
				meetingsModalLoader: false,
				duplicateMeetingModal: false,
				meetingTemplateId: null,
				meetingActiveList: "meeting",
			};
		}
		case actions.DUPLICATE_MEETING_TEMPLATE_FAILURE: {
			return {
				...state,
				meetingsModalLoader: false,
			};
		}
		case actions.MEETINGS_GET_EMPLOYEES:
			return {
				...state,
				isGettingEmployees: action.initialFetch,
				employeeListLoader: true,
			};
		case actions.MEETINGS_GET_EMPLOYEES_SUCCESS:
			return {
				...state,
				isGettingEmployees: false,
				employeeListLoader: false,
				employees:
					state.employeePage === defaultPage || state.employeeSearchText
						? action.payload.employees
						: state.employees.concat(action.payload.employees),
			};
		case actions.MEETINGS_GET_EMPLOYEES_FAILURE:
			return {
				...state,
				isGettingEmployees: false,
				employeeListLoader: false,
			};
		case actions.MEETINGS_SET_EMPLOYEE_PAGE:
			return {
				...state,
				employeePage: action.payload,
			};
		case actions.MEETINGS_SET_EMPLOYEES_SEARCH_TEXT:
			return {
				...state,
				employeeSearchText: action.payload,
			};
		case actions.CREATE_MEETING_TIME_DETAILS:
			return {
				...state,
				meetingDuration: action.payload.duration,
				meetingScheduleTime: action.payload.time,
				meetingDate: action.payload.date,
			};
		case actions.FETCH_UPCOMING_MEETINGS_SUCCESS:
			return {
				...state,
				upComingMeetings: action.payload.meetings,
				upComingMeetingLoader: false,
			};
		case actions.FETCH_UPCOMING_MEETINGS:
			return {
				...state,
				upComingMeetingLoader: action.payload,
			};
		case actions.FETCH_UPCOMING_MEETINGS_FAILURE:
			return {
				...state,
				upComingMeetingLoader: false,
			};
		case actions.RESET_CREATE_MEETING_TIME_DETAILS:
			return {
				...state,
				meetingScheduleTime: null,
				meetingDate: null,
			};
		default:
			return state;
	}
}
