import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/adminUser/journeys/actions";
import { history, store } from "redux/store";
import {
	getRequest,
	postRequest,
	putRequest,
	patchRequest,
} from "helpers/axiosClient";
import {
	addCreateModuleTemplate,
	getAddedEventsTemplate,
	getFilteredModulesTemplate,
	getLocaleMessages,
	handleException,
	updateAddedEventsTemplate,
} from "redux/helper";
import {
	fetchCurrentJourneyTemplateID,
	generateJourneyJSON,
	getRootUrl,
	setSelectedTemplate,
	showNotification,
} from "components/utility/helper";
import { dropdownListPerPage, notFoundCode } from "components/constants";

export function* fetchJourneys(params) {
	try {
		const {
				CurrentPage,
				listSearchKey,
				listSortKey,
				listSortValue,
				listPerPage,
			} = store.getState().AdminJourney,
			searchQuery = params.searchText || listSearchKey,
			page = params.page || CurrentPage,
			sortKey = params.sortKey || listSortKey,
			sortOrder = params.sortOrder || listSortValue,
			perPage = params.perPage || listPerPage;
		let url = `admin/journeys?query[name_cont]=${searchQuery.trim()}&query[sorts][]=${sortKey} ${sortOrder}&query[sorts][]=id ${sortOrder}&page=${page}&per_page=${perPage}`;

		const { data } = yield call(() => getRequest(url));
		// select all received data when the selectAll is checked and records per page got changed
		yield put({
			type: actions.FETCH_JOURNEYS_TEMPLATE_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_JOURNEYS_TEMPLATE_FAILURE });
		handleException(error);
	}
}

export function* createNewJourney(params) {
	try {
		const { data } = yield call(() =>
			postRequest("admin/journeys", params.payload),
		);
		yield put({ type: actions.FETCH_JOURNEYS_TEMPLATE });
		yield put({
			type: actions.CREATE_NEW_JOURNEY_TEMPLATE_SUCCESS,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_JOURNEY_TEMPLATE_FAILURE,
		});
	}
}

export function* fetchTimezone() {
	try {
		const timezoneResponse = yield call(() => getRequest("timezones"));
		yield put({
			type: actions.FETCH_TIMEZONE_SUCCESS,
			payload: timezoneResponse.data.data.timezones,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* deleteJourney(params) {
	try {
		const { data } = yield call(() =>
			patchRequest("admin/journeys", {
				ids: params.payload,
			}),
		);
		yield put({
			type: actions.DELETE_JOURNEY_TEMPLATE_SUCCESS,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		if (params.fromBuilder) {
			history.push({ pathname: `/${getRootUrl()}/journeys` });
		} else {
			yield put({
				type: actions.FETCH_JOURNEYS_TEMPLATE,
			});
		}
	} catch (error) {
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE,
				payload: false,
			});
		}
		handleException(error);
		yield put({
			type: actions.DELETE_JOURNEY_TEMPLATE_FAILURE,
		});
	}
}

export function* approveJourneyTemplate(params) {
	try {
		const { data } = yield call(() =>
			patchRequest("admin/journeys/publish", params.payload),
		);
		yield put({
			type: actions.APPROVE_OR_UNAPPROVE_JOURNEY_SUCCESS,
			payload: params.payload,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		if (!params.fromBuilder) {
			yield put({
				type: actions.FETCH_JOURNEYS_TEMPLATE,
			});
		}
	} catch (error) {
		handleException(error);
		yield put({ type: actions.APPROVE_OR_UNAPPROVE_JOURNEY_FAILURE });
	}
}

export function* fetchJourneyBuilderDetails(params) {
	try {
		let url = `admin/journeys/${params.journeyId}`;

		const { data } = yield call(() => getRequest(url)),
			{ journey } = data.data;
		yield put({
			type: actions.FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_SUCCESS,
			payload: journey,
		});
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_JSON,
			payload: generateJourneyJSON(journey.rules),
		});
	} catch (error) {
		yield put({
			type: actions.FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_FAILURE,
		});
		handleException(error);
	}
}

export function* addBuilderModule(params) {
	try {
		let url = `admin/journeys/${fetchCurrentJourneyTemplateID()}/events`;

		const { data } = yield call(() => postRequest(url, params.payload));
		yield put({
			type: actions.ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_SUCCESS,
			payload: data.data.event,
		});
		const events = getAddedEventsTemplate(data.data.event);
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_JSON,
			payload: addCreateModuleTemplate(events),
		});
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_EVENTS,
			payload: events,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({
			type: actions.ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_FAILURE,
		});
		handleException(error);
	}
}

export function* fetchAssets() {
	const { assetSearchKey, assetPage } = store.getState().AdminJourney;
	try {
		let url = `admin/assets?per_page=${dropdownListPerPage}&page=${assetPage}&query[name_cont]=${encodeURI(
			assetSearchKey.trim(),
		)}`;

		const { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_ASSETS_TEMPLATE_SUCCESS,
			payload: data.data.assets,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_ASSETS_TEMPLATE_FAILURE });
		handleException(error);
	}
}

export function* updateBuilderModule(params) {
	try {
		let url = `admin/journeys/${fetchCurrentJourneyTemplateID()}/events/${
			params.id
		}`;

		const { data } = yield call(() => putRequest(url, params.payload));
		yield put({
			type: actions.ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_SUCCESS,
			payload: data.data.event,
		});
		const events = updateAddedEventsTemplate(data.data.event);
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_JSON,
			payload: addCreateModuleTemplate(events),
		});
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_EVENTS,
			payload: events,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		store.dispatch({ type: actions.SET_ASSET_SEARCH, payload: "" });
	} catch (error) {
		yield put({
			type: actions.ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_FAILURE,
		});
		handleException(error);
	}
}

export function* deleteModules(params) {
	try {
		const { deleteModule } = store.getState().AdminJourney;
		let url = `admin/journeys/${fetchCurrentJourneyTemplateID()}/events`;
		const { data } = yield call(() => putRequest(url, params.payload));

		yield put({
			type: actions.DELETE_MODULES_TEMPLATE_SUCCESS,
			payload: data.data.journey,
		});
		const events = getFilteredModulesTemplate({ id: deleteModule.eventId });
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_JSON,
			payload: addCreateModuleTemplate(events),
		});
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_EVENTS,
			payload: events,
		});

		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({ type: actions.DELETE_MODULES_TEMPLATE_FAILURE });
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.UPDATE_MODULE_DELETE_CONFIRM,
				payload: false,
			});
		}
		handleException(error);
	}
}

export function* fetchTasks(params) {
	const { taskSearchKey, taskPage } = store.getState().AdminJourney;
	try {
		let url = `admin/tasks?per_page=${dropdownListPerPage}&page=${taskPage}&query[name_cont]=${encodeURI(
			taskSearchKey.trim(),
		)}`;

		const { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_JOURNEY_TEMPLATE_TASKS_SUCCESS,
			payload: data.data.tasks,
		});
		if (params.moduleContent) {
			setSelectedTemplate({ content: params.moduleContent });
		}
	} catch (error) {
		yield put({ type: actions.FETCH_JOURNEY_TEMPLATE_TASKS_FAILURE });
		handleException(error);
	}
}

export function* addJourneyMeeting(params) {
	try {
		const {
			scheduled_day,
			scheduled_time,
			days_before_due,
			end_time,
			reminder,
			...restMeeting
		} = params.payload;
		const meetingPayload = {
			meeting: {
				...restMeeting,
				meeting_type: "journey",
				from: scheduled_time,
				to: end_time,
			},
		};
		let url = "admin/meetings";

		const { data } = yield call(() => postRequest(url, meetingPayload));
		const { name, description, ...rest } = params.payload;
		const eventPayload = {
			event: {
				meeting_id: data.data.meeting.id,
				...rest,
			},
		};
		yield put({
			type: actions.ADD_TEMPLATE_BUILDER_MODULE,
			payload: eventPayload,
		});
	} catch (error) {
		yield put({ type: actions.ADD_JOURNEY_MEETING_TEMPLATE_FAILURE });
		handleException(error);
	}
}

export function* updateJourneyMeeting(params) {
	try {
		const {
			scheduled_day,
			scheduled_time,
			days_before_due,
			end_time,
			reminder,
			...restMeeting
		} = params.payload;
		const meetingPayload = {
			meeting: {
				...restMeeting,
				from: scheduled_time,
				to: end_time,
			},
		};
		let url = `admin/meetings/${params.meetingId}`;

		yield call(() => putRequest(url, meetingPayload));
		const { name, description, ...rest } = params.payload;
		const eventPayload = {
			event: {
				...rest,
			},
		};
		yield put({
			type: actions.UPDATE_TEMPLATE_BUILDER_MODULE,
			payload: eventPayload,
			id: params.eventId,
		});
	} catch (error) {
		yield put({ type: actions.UPDATE_JOURNEY_MEETING_TEMPLATE_FAILURE });
		handleException(error);
	}
}

export function* updateJourney(params) {
	try {
		let url = `admin/journeys/${fetchCurrentJourneyTemplateID()}`;

		const { data } = yield call(() => putRequest(url, params.payload));
		yield put({
			type: actions.UPDATE_JOURNEY_TEMPLATE_SUCCESS,
			payload: data.data.journey,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		store.dispatch({ type: actions.SET_EDIT_JOURNEY_MODAL, payload: false });
	} catch (error) {
		yield put({ type: actions.UPDATE_JOURNEY_TEMPLATE_FAILURE });
		handleException(error);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_JOURNEYS_TEMPLATE, fetchJourneys),
		takeEvery(
			actions.FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS,
			fetchJourneyBuilderDetails,
		),
		takeEvery(actions.ADD_TEMPLATE_BUILDER_MODULE, addBuilderModule),
		takeEvery(actions.FETCH_ASSETS_TEMPLATE, fetchAssets),
		takeEvery(actions.CREATE_NEW_JOURNEY_TEMPLATE, createNewJourney),
		takeEvery(actions.APPROVE_OR_UNAPPROVE_JOURNEY, approveJourneyTemplate),
		takeEvery(actions.FETCH_TIMEZONE, fetchTimezone),
		takeEvery(actions.DELETE_JOURNEY_TEMPLATE, deleteJourney),
		takeEvery(actions.UPDATE_TEMPLATE_BUILDER_MODULE, updateBuilderModule),
		takeEvery(actions.DELETE_MODULES_TEMPLATE, deleteModules),
		takeEvery(actions.FETCH_JOURNEY_TEMPLATE_TASKS, fetchTasks),
		takeEvery(actions.ADD_JOURNEY_MEETING_TEMPLATE, addJourneyMeeting),
		takeEvery(actions.UPDATE_JOURNEY_MEETING_TEMPLATE, updateJourneyMeeting),
		takeEvery(actions.UPDATE_JOURNEY_TEMPLATE, updateJourney),
	]);
}
