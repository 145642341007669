import styled, { createGlobalStyle } from "styled-components";
import withDirection from "./withDirection";
import variables from "styles/variables";
import { mapStyles } from "styles/helpers";

const GlobalStyles = createGlobalStyle`
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}
body,
  #root,
  .ant-layout {
    height: 100%;
    overflow: hidden;
  }
  .ant-layout-content {
    padding: 25px 25px 0px;
    @media (max-width: 520px) {
       padding: 15px 15px 0px;
    }

    height: 100%;
    overflow-y: auto;
    .layout-content {
      min-height: 100%;
      padding: 30px;
      border-radius: 4px;
      background: ${variables.WHITE_COLOR};
      .m-b-40 {
        margin-bottom: 40px;
      }
      .m-r-10 {
        margin-right: 10px;
      }
    }
  }
  .text-align-center {
    text-align: center;
  }
  .text-align-left {
    text-align: left;
  }
  .ant-notification {
    margin-right: 0px !important;
    height: auto;
    width: 616px;
    border-radius: 4px;
  }
  .ant-notification-notice {
    padding-bottom: ${parseInt(variables.SQ_SPACING_LARGE) - 10}px;
  }
  .error-notification {
    border: solid 1px ${variables.DANGER_COLOR_SECONDARY};
    background-color: ${variables.DANGER_COLOR_LIGHT};
    &-assign {
      word-break: break-word;
      div.content {
        font-size: ${variables.BASE_FONT_SIZE};
        line-height: 1.3;
        
        &:first-child {
          margin-top: ${variables.SQ_SPACING_SMALL};
        }
        span.title {
          font-weight: 600;
        }
        &:not(:last-child){
          margin-bottom: ${variables.SQ_SPACING_SMALL};
        }
        &:last-child {
            margin-bottom: ${parseInt(variables.SQ_SPACING_LARGE) - 10}px;
        }
        .anticon-arrow-right {
          margin: 0 ${variables.SQ_SPACING_XS};
        }
      }
      &.ant-notification-notice {
        padding-bottom: 0;
        .ant-notification-notice-description {
          max-height: 300px;
          overflow-y: auto;
        }
      }
    } 
  }
  .success-notification {
    border: solid 1px ${variables.SUCCESS_COLOR_SECONDARY};
    background-color: ${variables.SUCCESS_COLOR_LIGHT};
  }
  .ant-select-dropdown {
      .ant-select-item-option-content {
        line-height: unset;
      }
  }
  .ant-select-item-empty {
    text-align: center;
  }
  .ant-picker-dropdown {
      .ant-picker-ranges {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 7px 15px;
        .ant-picker-now {
          display: none;
        }
      }
  }
  .m-r-10 {
   margin-right: ${variables.SQ_SPACING_SMALL};
  }
  .m-r-20 {
    margin-right: ${variables.SQ_SPACING_BASE};
  }
  .m-b-10 {
   margin-bottom: ${variables.SQ_SPACING_SMALL};
  }
  .m-b-20 {
    margin-bottom: 20px !important;
  }
  .m-l-10 {
    margin-left: 10px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-40 {
    margin-top: 40px;
  }
  .m-t-10 {
    margin-top: 10px !important;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .w-68 {
    width: 68px !important;
  }
  .f-s-16 {
    font-size: 16px;
  }
  .primary-color {
    color: ${variables.PRIMARY_COLOR};
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .select-due-date {
    .ant-select-item-option {
      min-width: 65px;
    }
  }
  .dropdown-spinner-center {
    .spinner {
      text-align: center;
    }
  }
  .link {
        color: ${variables.PRIMARY_COLOR};
        cursor: pointer;
        margin-top: 15px;
      }
  .dropdown-tokens.ant-dropdown .ant-dropdown-menu {
    max-height: 300px;
    height: auto;
    overflow: auto;
    &-item {
      white-space: normal;
      width: 300px;
      word-break: break-word;
    }
  }
  .header-dropdown {
    color: ${variables.PRIMARY_COLOR};
    border-color: ${variables.PRIMARY_COLOR};
  }
  .ant-select-multiple {
     .ant-select-selection-item {
        align-items: center;
         max-width: 95%;
     }
     .ant-select-selector {
        padding: 0 11px;
        input {
           padding: 0;
        }
     }
  }
  .create-module {
     display: inline-block;
     cursor: pointer;
     color: ${variables.PRIMARY_COLOR};
     font-size: ${variables.MODAL_FONT_SIZE};
     margin-bottom: 10px;
  }
  .ant-select-multiple {
    &.ant-select-disabled {
      .ant-select-selection-item-remove {
        cursor: not-allowed;
      }
    }
  }
	& .ant-dropdown-menu-sub{
		max-height: 300px;
		overflow: auto;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		scrollbar-width: thin;
	}
`;

export const TableHeaderGlobalStyles = createGlobalStyle`
.table-header-dropdown {
  .ant-dropdown-menu,
  .ant-menu {
    box-shadow: 0 0 8px 6px ${variables.BOX_SHADOW_COLOR};
    background-color: ${variables.WHITE_COLOR};
    border-radius: ${variables.BORDER_RADIUS};
    margin: ${variables.BORDER_RADIUS} 0;
    .ant-menu-item {
      margin: 0;
    }
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left,
  .ant-dropdown-menu {
    border-right: 0;
    .ant-menu-item, .ant-dropdown-menu-item {
      height: auto;
      line-height: normal;
      margin: 0;
      padding-bottom: ${variables.SQ_SPACING_SMALL};
      padding-left:${variables.SQ_SPACING_BASE};
      padding-right:${variables.SQ_SPACING_BASE};
      padding-top: ${variables.SQ_SPACING_SMALL};
    }
  }
}
.assets-dropdown {
  .ant-dropdown-menu {
    &-item {
      color: ${variables.PRIMARY_COLOR};
    }
  }
}

.dropdown-actions {
   .ant-dropdown-menu {
     &-item {
       &:hover {
         background: ${variables.WHITE_COLOR};
         color: ${variables.PRIMARY_COLOR};
       }
     }
   }
}
.reason-tooltip {
  max-width: 350px; 
  .ant-popover-title {
    text-align: center;
    padding-top: ${variables.SQ_SPACING_SMALL};
    padding-bottom: ${variables.SQ_SPACING_SMALL} ;
    padding-left: ${parseInt(variables.SQ_SPACING_BASE) - 5}px;
    padding-right: ${parseInt(variables.SQ_SPACING_BASE) - 5}px;
    font-weight: 600;
    font-size: ${variables.CONTENT_FONT_SIZE};
    min-height: auto;
  }
  .ant-popover-inner-content {
    max-height: 200px;
    font-size: ${parseInt(variables.BASE_FONT_SIZE) + 1}px;
    color: ${variables.TEXT_COLOR_LIGHT};
    overflow: auto;
    padding-top: ${parseInt(variables.SQ_SPACING_BASE) - 5}px;
    padding-bottom: ${parseInt(variables.SQ_SPACING_BASE) - 5}px ;
    padding-left: ${variables.SQ_SPACING_SMALL};  
    padding-right: ${variables.SQ_SPACING_SMALL};
    line-height: 1.4;
  }
}
.task-details-content {
  height: calc(100vh - 160px);
  overflow: auto;
}`;

export const EmailBuilderLayoutStyle = createGlobalStyle`
.ant-layout-content{
  background-color: ${variables.WHITE_COLOR};
  .layout-content {
    background-color: ${variables.EMAIL_BACKGROUND_COLOR};
    overflow-x: scroll;
    padding: 30px;
    #editor {
      height: calc(100vh - 140px);
      overflow: auto;
      width: 100%;
    }
  }
}
`;

export const LayoutGlobalStyle = createGlobalStyle`
.ant-layout-content {
  background-color: ${variables.EMAIL_BACKGROUND_COLOR};
  .layout-content {
    background-color: ${variables.WHITE_COLOR};
    overflow-x: hidden;
    padding: 30px;
  }
}
`;

export const FileStyles = styled.div`
&.employee-files {
display: flex;
justify-content: center;
}
  .ant-collapse-header{
    font-size: ${parseInt(variables.BASE_FONT_SIZE) + 2}px;
  }
  .form-details, 
  .files-images {
    .ant-collapse-header{
      background-color: ${variables.EMAIL_BACKGROUND_COLOR};
    }
    .ant-collapse-content.ant-collapse-content-active {
      padding-top: ${parseInt(variables.SQ_SPACING_BASE) + 10}px; 
      padding-bottom: ${variables.SQ_SPACING_BASE};
      padding-left: ${variables.SQ_SPACING_BASE};
      padding-right ${variables.SQ_SPACING_BASE};
    }
    .ant-collapse-content-box,
    .empty-text {
      padding: 0;
    }
  }
  
  .form-details {
    .ant-collapse-content.ant-collapse-content-active,
     .empty-text{
      padding-bottom: ${variables.SQ_SPACING_SMALL};
    }
  }
  
  .file-tasks {
    img {
      height: ${(props) => (props.iconWidth ? props.iconWidth : "90px")};
      width:  ${(props) => (props.iconWidth ? props.iconWidth : "90px")};
      margin: ${variables.SQ_SPACING_SMALL};
    }
    span.custom-anticon {
      text-align: left;
      svg {
        color: ${variables.PRIMARY_COLOR};
        height: ${(props) => (props.iconWidth ? props.iconWidth : "90px")};
        width:  ${(props) => (props.iconWidth ? props.iconWidth : "90px")};
      }
      margin: ${variables.SQ_SPACING_SMALL};
    }
    .empty-text {
      text-align: center;
    }
    .file-actions {
      position: absolute;
      opacity: 0;
      top: 40%;
      left: 30%;
      z-index: 10;
      span.anticon svg{
        cursor: pointer;
        color: ${variables.WHITE_COLOR};
        font-size: ${parseInt(variables.BASE_FONT_SIZE) + 4}px;
        &:not(last-child) {
          margin-right: ${variables.SQ_SPACING_SMALL};
        }
      }
      span.anticon.delete-icon svg {
        margin-left: ${parseInt(variables.SQ_SPACING_SMALL) + 5}px;
        margin-right: 0;
      }
      .hidden {
        visibility: hidden;
        margin-right: -${parseInt(variables.SQ_SPACING_SMALL) + 2}px;
      }
    }
    .file-attachments {
      position: absolute;
      z-index: 1;
      height: ${(props) => (props.iconWidth ? props.iconWidth : "90px")};
      width:  ${(props) => (props.iconWidth ? props.iconWidth : "90px")};
      background-color: ${variables.SHADOW_MEDIUM};
      opacity: 0;
      transition: all .3s;
      margin: ${variables.SQ_SPACING_SMALL};
    }
    .file-container {
      display: inline-block;
      height: ${(props) =>
				props.containerWidth ? props.containerWidth : "110px"};
      width:  ${(props) =>
				props.containerWidth ? props.containerWidth : "110px"};
      float: left;
      margin-bottom: ${parseInt(variables.SQ_SPACING_SMALL) - 2}px;
      margin-right: ${parseInt(variables.SQ_SPACING_SMALL) - 2}px;
      border-radius: ${variables.BORDER_RADIUS};
      border: 1px solid ${variables.BORDER_COLOR};
      position: relative;
      &:hover .file-actions {
        opacity: 1;
      }
      &:hover .file-attachments {
        opacity: 1;
      }
    }
  }
  .file-form {
    margin-bottom: ${variables.SQ_SPACING_BASE};
    .label,
    .value {
      word-break: break-all;
    }
    .label {
      color: ${variables.TEXT_COLOR_DARK};
      font-size: ${parseInt(variables.BASE_FONT_SIZE) + 2}px;
      font-weight: 600;
    }
    .value {
      font-size: ${parseInt(variables.BASE_FONT_SIZE) + 1}px;
      margin-top: ${variables.SQ_SPACING_XS};
    }
    @media and (min-width: 992px) {
      padding-right: ${variables.SQ_SPACING_SMALL};
    }
  }
`;

export const PopoverStyle = createGlobalStyle`
  .extra-columns {
    max-width: 350px;
    .ant-popover-inner{
      .ant-popover-inner-content {
        height: auto;
        max-height: 300px;
        min-width:330px;
        padding-top: 0;
        overflow: auto;
        .search-with-input{
          background: ${variables.WHITE_COLOR};
          padding-top: ${variables.SQ_SPACING_SMALL};
          position: fixed;
          z-index: 2;
        }
        .ant-input-search {
          width: 295px;
        }
        .empty-text {
          color: ${variables.TEXT_COLOR_DARK} ;
          padding-bottom: ${variables.SQ_SPACING_BASE};
          padding-top: ${parseInt(variables.SQ_SPACING_LARGE) + 20}px;
          text-align: center;
          width: 100%;
          .anticon-inbox { 
            font-size: 20px;
            margin-right: ${variables.SQ_SPACING_SMALL};
            vertical-align: sub;
            svg {
             color: ${variables.PREVIEW_BG_COLOR};
            }
          }
        }
        .ant-input-search {
          margin-bottom: ${parseInt(variables.SQ_SPACING_BASE) - 5}px;
          .ant-input-suffix {
            .anticon svg {
              vertical-align: -${parseInt(variables.SQ_SPACING_XS)}px;
            }
          }
        }
        .select-all-column {
          background: ${variables.WHITE_COLOR};
          display: block;
          margin-top: ${parseInt(variables.SQ_SPACING_LARGE) + 5}px;
          padding-bottom: ${variables.SQ_SPACING_SMALL};
          padding-top: ${variables.SQ_SPACING_SMALL};
          position: fixed;
          width: 295px;
          z-index: 2;
          .ant-checkbox-inner {
            border-radius: ${parseInt(variables.BORDER_RADIUS) - 1}px;
            height: 20px;
            width: 20px;
            &::after {
              height: 11px;
              width: 7px;
            }
          }
          .ant-checkbox-indeterminate .ant-checkbox-inner {
            background: ${variables.PRIMARY_COLOR};
            &::after {
              background: ${variables.WHITE_COLOR};
              height: 2px;
            }
          }
        }
        .ant-checkbox-group {
          .ant-checkbox-group-item {
            display: block;
            margin: 0;
            width: 295px;
            word-break: break-all;
            .extra-column-label {
              display: inline-block;
              line-height: 1.4;
              vertical-align: text-top;
              width: 85%;
            }
            &:not(:last-child){
              margin-bottom: ${variables.SQ_SPACING_SMALL};
            }
            .ant-checkbox-inner {
              border-radius: ${parseInt(variables.BORDER_RADIUS) - 1}px;
              height: 20px;
              width: 20px;
              &::after {
                height: 11px;
                width: 7px;
                top: 42%;
              }
            }
          }
        }
      }
    }
  }
`;

export const PageRenderingStyle = createGlobalStyle`
  .page-rendering {
    .ant-layout-content {
      background: ${variables.WHITE_COLOR};
      height: 100%;
      overflow: auto;
      padding: 0;
      h2.ant-typography {
        background: ${variables.WHITE_COLOR};
        border-bottom: 1px solid ${variables.BORDER_COLOR};
        margin: 0;
        padding: ${variables.SQ_SPACING_SMALL} 0;
        position: fixed;
        top: 0px;
        width: 100%;
        word-break: break-word;
      }
    }
  }
`;

export const FormGlobalStyle = createGlobalStyle`
.ant-form {
  .ant-form-item {
    margin-bottom: 3px;
    .ant-form-item-label {
      padding: 0 0 5px;
      label {
        height: unset;
        font-weight: 500;
      }
    }
    .ant-form-item-explain {
      min-height: unset;
      padding-top: 4px;
    }
    .ant-typography {
      &.ant-typography-secondary {
        line-height: 1.5;
        font-size: 13px;
        color: #524f4f;
        display: inline-block;
      }
    }
    .places {
       position: relative;
      .location-search-input {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        font-variant: tabular-nums;
        list-style: none;
        -webkit-font-feature-settings: 'tnum', "tnum";
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 4px 11px;
        color: ${variables.TEXT_COLOR};
        font-size: 14px;
        line-height: 1.5715;
        background-color: ${variables.WHITE_COLOR};
        background-image: none;
        border: 1px solid ${variables.BORDER_COLOR};
        border-radius: ${variables.BORDER_RADIUS_BASE};
        outline: none;
        &:active, &:hover, &:focus {
          border-color: ${variables.PRIMARY_COLOR};
        } 
      }
      .autocomplete-dropdown-container {
        position: absolute;
        top:40px;
        left: 0;
        box-shadow: 0 0 8px 6px ${variables.BOX_SHADOW_COLOR};
        background: ${variables.WHITE_COLOR};
        z-index: 9;
        width: 100%;
        .suggestion-item {
          padding: 10px;
          .options {
            display: flex;
            .anticon-environment {
              margin-right: 8px;
              font-size: 15px;
            }
          }
        }
        .ant-spin {
          display: flex;
          justify-content: center;
          margin: 10px;
        }
      }
      & + div {
        ${mapStyles()}
      }
    }
    .ant-radio-group {
      flex-direction: row;
    }
  }
}
`;

export const TemplateGlobalModalStyle = createGlobalStyle`
.template-confirmation {
  &.ant-modal-confirm .ant-modal-body {
    padding: ${parseInt(variables.SQ_SPACING_LARGE) - 10}px;
    text-align: left;
    .ant-modal-confirm-btns {
      float: none;
      padding-top: ${variables.SQ_SPACING_BASE};
      text-align: center;
      margin: ${variables.SQ_SPACING_BASE} 0 0;
      .ant-btn {
        &-primary:focus {
          background-color: ${variables.PRIMARY_COLOR};
          border-color: ${variables.PRIMARY_COLOR};
        }
        &-primary:hover {
          background-color: ${variables.PRIMARY_HOVER_COLOR};
          border-color: ${variables.PRIMARY_HOVER_COLOR};
        }
      }
    }
  }
}
`;
export const PreviewModalGlobalStyles = createGlobalStyle`
.preview-modal {
  overflow: hidden;
  .ant-col {
    max-width: ${(props) => (!props.isDesktopView ? "100%" : null)};
    flex:${(props) => (!props.isDesktopView ? "0 0 100%" : null)};
  }
  .ant-modal-content {
     display: flex;
     flex-direction: column;
     align-items: center;
     .ant-card {
        box-shadow: ${variables.SHADOW_MEDIUM} 0px 0px 5px 0px;
     }
     .ant-modal-body {
        width: ${(props) => (props.isDesktopView ? "100%" : "420px")};
        padding: 30px;
        color: ${(props) => props.textColor};
         background: ${(props) =>
						props.bgImage
							? `url(${props.bgImage}) no-repeat`
							: variables.WHITE_COLOR};
         background-size: 100% 100%;
         height: calc(100vh - 80px);
         overflow-y: auto;
         border-radius: ${variables.BORDER_RADIUS_BASE};
         border: 1px solid ${variables.BORDER_COLOR};
         & > div {
          pointer-events: none;
         }
         iframe {
          overflow: hidden;
         }
      }
      .ant-modal-footer {
        border-top: 0;
      }
  }
}
`;
export const DirectionMapGlobalStyles = createGlobalStyle`
.ant-layout-content {
  padding: 0;
  & > div {
    padding: 0;
    ${mapStyles()}
  }
}
`;

export const FormItemReminder = styled.div`
	.reminder {
		margin: 0 0;
	}
	.ant-typography {
		&.ant-typography-secondary {
			margin-left: ${parseInt(variables.SQ_SPACING_BASE) + 5}px;
			margin-top: ${variables.SQ_SPACING_SMALL};
			line-height: 1.5;
			font-size: ${variables.MODAL_FONT_SIZE};
			color: ${variables.TEXT_COLOR};
			display: inline-block;
		}
	}
	.reminders {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		&:not(:first-child) {
			margin-top: 15px;
		}
		label {
			color: ${variables.TEXT_COLOR_DARK};
			font-size: 14px;
			white-space: nowrap;
		}
		.ant-form-item {
			margin: 0 ${variables.SQ_SPACING_XS};
			.ant-select {
				max-width: 75px;
			}
			.ant-form-item-explain {
				width: 150px;
				position: absolute;
				top: 35px;
			}
		}
	}
`;

export const DomainSettingsStyles = styled.div`
	.domain-update {
		max-width: 800px;
		margin: 40px;
		.remove {
			float: right;
			cursor: pointer;
			svg {
				font-size: 20px;
			}
			&:hover {
				.anticon-close-circle {
					color: ${variables.DANGER_COLOR};
				}
			}
		}
		.verified {
			display: inline-block;
			align-items: center;
			font-size: 16px;
			.anticon {
				color: ${variables.SUCCESS_COLOR};
				font-size: 20px;
				margin-right: 5px;
			}
		}
		.verify-progress {
			display: flex;
			align-items: center;
			font-size: 16px;
			.anticon {
				color: ${variables.PRIMARY_COLOR};
				font-size: 20px;
				margin-right: 5px;
			}
		}
		.edit {
			height: 40px;
			width: 40px;
			display: flex;
			justfy-content: center;
			align-items: center;
			cursor: pointer;
			&:hover {
				color: ${variables.PRIMARY_COLOR};
			}
		}
	}
`;

export default withDirection(GlobalStyles);
