import asyncComponent from "helpers/asyncComponent";

export const userPublicRoutes = [
	{
		path: "/",
		component: asyncComponent(() => import("containers/OnBoarding/Login")),
	},
	{
		path: "/login",
		component: asyncComponent(() => import("containers/OnBoarding/Login")),
	},
	{
		path: "/forgot-password",
		component: asyncComponent(() =>
			import("containers/OnBoarding/ForgotPassword"),
		),
	},
	{
		path: "/reset-password/:token",
		component: asyncComponent(() =>
			import("containers/OnBoarding/ResetPassword"),
		),
	},
	{
		path: "/MomentExperience",
		component: asyncComponent(() => import("containers/MomentExperience")),
	},
	{
		path: "/moment/:empMomentToken/contribute",
		component: asyncComponent(() =>
			import("containers/MomentsThatMatter/MomentContribution"),
		),
	},
	{
		path: "/moment_contribution_prototype",
		component: asyncComponent(() => import("containers/MomentExperience")),
	},
	{
		path: "/pages/:page_token",
		component: asyncComponent(() => import("containers/Assets/RenderPage")),
	},
	{
		path: "/pages/error-page",
		component: asyncComponent(() => import("containers/Pages/Error500")),
	},
	{
		path: "/server-down",
		component: asyncComponent(() => import("containers/Pages/ServerDown")),
	},
	{
		path: "*",
		component: asyncComponent(() => import("containers/Pages/NotFound")),
	},
];

export const employeePublicRoutes = [
	{
		path: "/",
		component: asyncComponent(() =>
			import("containers/OnBoarding/Employee/Login"),
		),
	},
	{
		path: "/login",
		component: asyncComponent(() =>
			import("containers/OnBoarding/Employee/Login"),
		),
	},
	{
		path: "/moment/:empMomentToken",
		component: asyncComponent(() =>
			import("containers/MomentsThatMatter/FinalMomentExperience"),
		),
	},
	{
		path: "/moment/:empMomentToken/auth",
		component: asyncComponent(() =>
			import("containers/OnBoarding/Employee/Login"),
		),
	},
	{
		path: "/moment/:empMomentToken/contribute",
		component: asyncComponent(() =>
			import("containers/MomentsThatMatter/MomentContribution"),
		),
	},
	{
		path: "/forgot-password",
		component: asyncComponent(() =>
			import("containers/OnBoarding/Employee/ForgotPassword"),
		),
	},
	{
		path: "/:page_token",
		component: asyncComponent(() => import("containers/Assets/RenderPage")),
	},
	{
		path: "/reset-password/:token",
		component: asyncComponent(() =>
			import("containers/OnBoarding/Employee/ResetPassword"),
		),
	},
	{
		path: "/landing/:page_token",
		component: asyncComponent(() => import("containers/Assets/RenderPage")),
	},
	{
		path: "/pages/error-page",
		component: asyncComponent(() => import("containers/Pages/Error500")),
	},
	{
		path: "/server-down",
		component: asyncComponent(() => import("containers/Pages/ServerDown")),
	},
	{
		path: "*",
		component: asyncComponent(() => import("containers/Pages/NotFound")),
	},
];
