import actions from "./actions";
import { defaultPageSize } from "components/constants";

const initState = {
	meetingsCurrentPage: 1,
	meetingsList: [],
	totalMeetingsData: 0,
	pageSize: defaultPageSize,
	pageHeaderLoader: false,
	meetingsTableLoader: false,
	meetingsDrawerVisible: false,
	emailDrawerVisible: false,
	selectedMeetingsList: [],
	meetingsModalVisible: false,
	meetingsModalLoader: false,
	meetingsSortKey: "scheduled_at",
	meetingsSortOrder: "asc",
	formLoader: false,
	meetingsName: null,
	meetingsContent: null,
	meetingsFormType: null,
	meetingsDrawerLoader: false,
	meetingId: null,
	meetingName: null,
	meetingDescription: null,
	meetingScheduleDate: null,
	meetingReminder: null,
	isRemindMeeting: null,
	meetingActionKey: "",
	location: null,
	duration: null,
	customEmails: null,
	from: null,
	to: null,
};

export default function meetingsReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_ADMIN_MEETINGS_LIST: {
			return {
				...state,
				meetingsTableLoader: true,
				pageHeaderLoader: true,
			};
		}
		case actions.GET_ADMIN_MEETINGS_LIST_SUCCESS: {
			return {
				...state,
				meetingsTableLoader: false,
				meetingsList: action.payload.meetings,
				totalMeetingsData: action.payload.total_records,
				pageHeaderLoader: false,
			};
		}
		case actions.GET_ADMIN_MEETINGS_LIST_FAILURE: {
			return {
				...state,
				meetingsTableLoader: false,
				pageHeaderLoader: false,
			};
		}
		case actions.SET_ADMIN_MEETINGS_DRAWER_VISIBLE: {
			return {
				...state,
				meetingsDrawerVisible: action.payload.visible,
				meetingsFormType: action.payload.visible
					? action.payload.formType
					: null,
				formLoader: action.payload.visible ? state.formLoader : false,
			};
		}
		case actions.ADMIN_MEETINGS_SELECTED_LIST: {
			return {
				...state,
				selectedMeetingsList: action.payload,
			};
		}
		case actions.DELETE_ADMIN_MEETINGS_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				meetingsModalVisible: action.payload,
			};
		}
		case actions.DELETE_ADMIN_MEETINGS_LIST: {
			return {
				...state,
				meetingsModalLoader: true,
			};
		}
		case actions.DELETE_ADMIN_MEETINGS_LIST_SUCCESS: {
			return {
				...state,
				meetingsModalLoader: false,
				meetingsModalVisible: false,
				selectedMeetingsList: [],
			};
		}
		case actions.DELETE_ADMIN_MEETINGS_LIST_FAILURE: {
			return {
				...state,
				meetingsModalLoader: false,
			};
		}
		case actions.SET_ADMIN_MEETINGS_TABLE_SIZE: {
			return {
				...state,
				pageSize: action.payload,
			};
		}
		case actions.SET_ADMIN_MEETINGS_TABLE_CURRENT_PAGE: {
			return {
				...state,
				meetingsCurrentPage: action.payload,
			};
		}
		case actions.SET_ADMIN_MEETINGS_TABLE_SORT_ORDER: {
			return {
				...state,
				meetingsSortKey: action.payload.sortKey,
				meetingsSortOrder: action.payload.sortOrder,
			};
		}
		case actions.CREATE_NEW_ADMIN_MEETINGS: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_ADMIN_MEETINGS_SUCCESS: {
			return {
				...state,
				formLoader: false,
				meetingsDrawerVisible: false,
				meetingsFormType: null,
			};
		}
		case actions.CREATE_NEW_ADMIN_MEETINGS_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.SET_ADMIN_MEETING_DATA: {
			const {
				name,
				id,
				description,
				remindBefore,
				scheduledAt,
				location,
				duration,
				customEmails,
				reminder,
				from,
				to,
			} = action.payload;
			return {
				...state,
				meetingId: id,
				meetingName: name,
				meetingDescription: description,
				meetingScheduleDate: scheduledAt,
				meetingReminder: remindBefore,
				isRemindMeeting: reminder,
				location: location,
				duration: duration,
				customEmails: customEmails,
				from,
				to,
			};
		}
		case actions.RESET_ADMIN_MEETING_DATA: {
			return {
				...state,
				meetingId: null,
				meetingName: null,
				meetingDescription: null,
				meetingScheduleDate: null,
				meetingReminder: null,
				isRemindMeeting: null,
				location: null,
				duration: null,
				customEmails: null,
			};
		}
		case actions.UPDATE_ADMIN_MEETINGS_CONTENT: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.UPDATE_ADMIN_MEETINGS_CONTENT_SUCCESS: {
			return {
				...state,
				formLoader: false,
				meetingsDrawerVisible: false,
				meetingsFormType: null,
			};
		}
		case actions.UPDATE_ADMIN_MEETINGS_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.SET_ADMIN_MEETINGS_APPROVE_UNAPPROVE_KEY: {
			return {
				...state,
				meetingActionKey: action.payload,
			};
		}
		case actions.APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS: {
			return {
				...state,
				meetingsModalLoader: true,
			};
		}
		case actions.APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_SUCCESS: {
			return {
				...state,
				meetingsModalLoader: false,
				meetingActionKey: "",
				selectedMeetingsList: [],
			};
		}
		case actions.APPROVE_OR_UNAPPROVE_ADMIN_MEETINGS_FAILURE: {
			return {
				...state,
				meetingsModalLoader: false,
			};
		}
		default:
			return state;
	}
}
