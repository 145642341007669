import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/portal/actions";
import actionsUsers from "redux/settings/users/actions";
import { store, history } from "redux/store";
import {
	getRequest,
	patchRequest,
	postRequest,
	putRequest,
	deleteRequest,
} from "helpers/axiosClient";
import {
	calculatePageNumber,
	customAPIRequest,
	fetchCompanyId,
	getCompanyUrl,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import { showNotification, constructPageURL } from "components/utility/helper";
import { getRootUrl } from "components/utility/helper";
import authActions from "redux/auth/actions";

export function* getHomePageList(params) {
	try {
		const {
			homePageSize,
			homePageSortOrder,
			homePageSortKey,
			homePageCurrentPage,
		} = store.getState().Portal;
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/pages?query[sorts][]=${homePageSortKey} ${homePageSortOrder}&query[sorts][]=id ${homePageSortOrder}&page=${homePageCurrentPage}&per_page=${homePageSize}&query[page_type_not_eq]=asset`,
			),
		);
		yield put({
			type: actions.GET_HOME_PAGE_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_HOME_PAGE_LIST_FAILURE,
		});
	}
}

export function* deleteHomePageList(params) {
	try {
		const {
			selectedHomePageList,
			homePageCurrentPage,
			totalHomePageData,
			homePageSize,
		} = store.getState().Portal;
		const response = yield call(() =>
			putRequest(`${getCompanyUrl()}/pages`, {
				ids: selectedHomePageList,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.DELETE_HOME_PAGE_LIST_SUCCESS,
		});
		if (
			homePageCurrentPage >
			Math.ceil(
				(totalHomePageData - selectedHomePageList.length) / homePageSize,
			)
		) {
			let pages = calculatePageNumber(
				totalHomePageData,
				selectedHomePageList,
				homePageSize,
			);
			yield put({
				type: actions.SET_HOME_PAGE_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.GET_HOME_PAGE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_HOME_PAGE_LIST_FAILURE,
		});
	}
}

export function* createNewHomePage(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/pages`, {
				page: { ...params.payload, page_type: "home" },
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.CREATE_NEW_HOME_PAGE_SUCCESS,
			payload: response.data.data,
		});
		history.push({
			pathname: `/${getRootUrl()}/portal/${
				response.data.data.page.id
			}/homePage`,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_HOME_PAGE_FAILURE,
		});
	}
}

export function* getHomePageData(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/pages/${params.payload}`),
		);
		yield put({
			type: actions.GET_HOME_PAGE_DATA_SUCCESS,
			payload: response.data.data,
		});
		yield put({
			type: actions.GET_HOME_PAGE_TOKEN,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_HOME_PAGE_DATA_FAILURE,
		});
	}
}

export function* updateHomePageContent(params) {
	try {
		const { portalID, formLoader } = store.getState().Portal;
		const response = yield call(() =>
			putRequest(`${getCompanyUrl()}/pages/${portalID}`, {
				page: { ...params.payload, page_type: "home" },
			}),
		);
		if (formLoader) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		yield put({
			type: actions.UPDATE_HOME_PAGE_CONTENT_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_HOME_PAGE_CONTENT_FAILURE,
		});
	}
}

export function* getHomePageToken() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/pages/names?query[page_type_eq]=landing`),
		);
		yield put({
			type: actions.GET_HOME_PAGE_TOKEN_SUCCESS,
			payload: response.data.data.pages.length
				? constructPageURL(response.data.data.pages, true)
				: [],
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_HOME_PAGE_TOKEN_FAILURE,
		});
	}
}

export function* createNewLandingPage(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/pages`, {
				page: { ...params.payload, page_type: "landing" },
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.CREATE_NEW_LANDING_PAGE_SUCCESS,
			payload: response.data.data,
		});
		history.push({
			pathname: `/${getRootUrl()}/portal/${
				response.data.data.page.id
			}/landingPage`,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_LANDING_PAGE_FAILURE,
		});
	}
}

export function* getLandingPageData(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/pages/${params.payload}`),
		);
		yield put({
			type: actions.GET_LANDING_PAGE_DATA_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_LANDING_PAGE_DATA_FAILURE,
		});
	}
}

export function* updateLandingPageContent(params) {
	try {
		const { portalID, formLoader } = store.getState().Portal;
		const response = yield call(() =>
			putRequest(`${getCompanyUrl()}/pages/${portalID}`, {
				page: { ...params.payload, page_type: "landing" },
			}),
		);
		if (formLoader) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		yield put({
			type: actions.UPDATE_LANDING_PAGE_CONTENT_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_LANDING_PAGE_CONTENT_FAILURE,
		});
	}
}

export function* publishHomePage() {
	try {
		const { setPublishData, selectedHomePageList } = store.getState().Portal;
		const response = yield call(() =>
			putRequest(`${getCompanyUrl()}/pages/${setPublishData.id}/publish`, {
				published: setPublishData.published,
			}),
		);
		if (setPublishData.published) {
			yield put({
				type: actions.HOME_PAGE_SELECTED_LIST,
				payload: selectedHomePageList.filter(
					(pageList) => pageList !== setPublishData.id,
				),
			});
		}
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.PUBLISH_HOME_PAGE_SUCCESS,
		});
		yield put({
			type: actions.GET_HOME_PAGE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.PUBLISH_HOME_PAGE_SUCCESS,
		});
	}
}

export function* fetchCompanyDetails() {
	try {
		const { data } = yield call(() =>
			getRequest(`companies?id=${fetchCompanyId()}`),
		);
		yield put({
			type: actions.FETCH_COMPANY_DETAILS_SUCCESS,
			payload: data.data.company,
		});
	} catch (error) {
		yield put({
			type: actions.FETCH_COMPANY_DETAILS_FAILURE,
		});
		handleException(error);
	}
}

export function* updateCompanyDetails(params) {
	try {
		const { currentKey } = store.getState().App;
		const { data } = yield call(() =>
			patchRequest(`${getCompanyUrl()}`, params.payload),
		);
		yield put({
			type: actions.UPDATE_COMPANY_DETAILS_SUCCESS,
			payload: data.data.company,
		});
		if (currentKey === "settings") {
			yield put({
				type: authActions.UPDATE_COMPANY_EMAIL_DATA_SUCCESS,
				payload: data.data.company,
			});
		}
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({
			type: actions.UPDATE_COMPANY_DETAILS_FAILURE,
		});
		handleException(error);
	}
}

export function* uploadImageFile(params) {
	try {
		const { data } = yield call(() =>
			postRequest("direct_uploads", {
				blob: params.payload.blob,
				isPublicAccessible: params.payload.isPublicAccessible,
			}),
		);
		yield put({
			type: actions.UPLOAD_IMAGE_TO_S3,
			payload: {
				URL: data.data.direct_upload.url,
				headers: data.data.direct_upload.headers,
				signed_id: data.data.direct_upload.signed_id,
				fileData: params.payload.fileData,
			},
			imageType: params.imageType,
		});
	} catch (error) {
		yield put({
			type: actions.UPLOAD_IMAGE_FILE_FAILURE,
		});
		handleException(error);
	}
}

export function* uploadToS3(params) {
	try {
		const { currentKey } = store.getState().App;
		yield call(() => customAPIRequest({ ...params.payload }));
		yield put({
			type: actions.UPLOAD_IMAGE_FILE_SUCCESS,
			signedId: params.payload.signed_id,
			imageType: params.imageType,
		});
		if (currentKey === "settings") {
			yield put({
				type: authActions.UPDATE_EMAIL_SETTINGS_DETAILS,
				companyEmailLogo: params.payload.signed_id,
			});
		}
	} catch (error) {
		yield put({
			type: actions.UPLOAD_IMAGE_FILE_FAILURE,
		});
		handleException(error);
	}
}

export function* createCompanyDomain(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/sendgrid_domains`, params.payload),
		);
		yield put({
			type: actions.CREATE_COMPANY_DOMAIN_SUCCESS,
			payload: data.data,
		});
		yield put({
			type: actions.FETCH_COMPANY_DETAILS,
		});
		yield put({
			type: authActions.UPDATE_DOMAIN_DETAILS,
			payload: data?.data,
		});
	} catch (error) {
		yield put({
			type: actions.CREATE_COMPANY_DOMAIN_FAILURE,
		});
		handleException(error);
	}
}

export function* updateCompanyDomain(params) {
	try {
		const { data } = yield call(() =>
			putRequest(`${getCompanyUrl()}/sendgrid_domains`, params.payload),
		);
		yield put({
			type: actions.UPDATE_COMPANY_DOMAIN_SUCCESS,
			payload: data.data,
		});
		yield put({
			type: authActions.UPDATE_DOMAIN_DETAILS,
			payload: data?.data,
		});
	} catch (error) {
		yield put({
			type: actions.UPDATE_COMPANY_DOMAIN_FAILURE,
		});
		handleException(error);
	}
}

export function* verifyCompanyDomain(params) {
	try {
		const { companyDomainDetails, sendGridDomainCreated } =
			store.getState().Auth;
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/sendgrid_domains/validate`),
		);
		yield put({
			type: actions.VERIFY_COMPANY_DOMAIN_SUCCESS,
			payload: data.data,
		});
		yield put({
			type: authActions.UPDATE_DOMAIN_DETAILS,
			payload: {
				sendgrid_domain_status: "verified",
				sendgrid_details: companyDomainDetails,
				sendgrid_domain_created: sendGridDomainCreated,
			},
		});
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: getLocaleMessages("verifySuccess"),
			duration: 8,
		});
	} catch (error) {
		yield put({
			type: actions.VERIFY_COMPANY_DOMAIN_FAILURE,
		});
		showNotification({
			type: "error",
			message: getLocaleMessages("label.error"),
			description: getLocaleMessages("label.verifyFailed"),
			duration: 8,
		});
	}
}

export function* deleteCompanyDomain(params) {
	try {
		const { data } = yield call(() =>
			deleteRequest(`${getCompanyUrl()}/sendgrid_domains`),
		);
		yield put({
			type: actions.DELETE_COMPANY_DOMAIN_SUCCESS,
			payload: data.data,
		});
		yield put({
			type: authActions.UPDATE_DOMAIN_DETAILS,
			payload: data?.data,
		});
	} catch (error) {
		yield put({
			type: actions.DELETE_COMPANY_DOMAIN_SUCCESS,
		});
		handleException(error);
	}
}

export function* validateRemovePrefix(params) {
	const id = params.payload;
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/sendgrid_prefixes/validate_events`, id),
		);
		yield put({
			type: actions.REMOVE_TEMP_PREFIX,
			payload: true,
		});
	} catch (e) {
		yield put({
			type: actions.REMOVE_TEMP_PREFIX,
			payload: { status: "error", id },
		});
		handleException(e);
	}
}

export function* createCustomRelations(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/custom_relations/create_update_destroy`, {
				custom_relations: params.payload,
			}),
		);
		store.dispatch({
			type: actionsUsers.SET_CUSTOM_FIELDS,
			payload: data.data.custom_relations,
		});
		showNotification({
			type: "success",
			message: "Success",
			description: data.data.message,
		});
		yield put({
			type: actionsUsers.FETCH_CUSTOM_FIELDS,
		});
	} catch (e) {
		handleException(e);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_HOME_PAGE_LIST, getHomePageList),
		takeEvery(actions.DELETE_HOME_PAGE_LIST, deleteHomePageList),
		takeEvery(actions.CREATE_NEW_HOME_PAGE, createNewHomePage),
		takeEvery(actions.GET_HOME_PAGE_DATA, getHomePageData),
		takeEvery(actions.UPDATE_HOME_PAGE_CONTENT, updateHomePageContent),
		takeEvery(actions.GET_HOME_PAGE_TOKEN, getHomePageToken),
		takeEvery(actions.CREATE_NEW_LANDING_PAGE, createNewLandingPage),
		takeEvery(actions.GET_LANDING_PAGE_DATA, getLandingPageData),
		takeEvery(actions.UPDATE_LANDING_PAGE_CONTENT, updateLandingPageContent),
		takeEvery(actions.PUBLISH_HOME_PAGE, publishHomePage),
		takeEvery(actions.FETCH_COMPANY_DETAILS, fetchCompanyDetails),
		takeEvery(actions.UPDATE_COMPANY_DETAILS, updateCompanyDetails),
		takeEvery(actions.UPLOAD_IMAGE_FILE, uploadImageFile),
		takeEvery(actions.UPLOAD_IMAGE_TO_S3, uploadToS3),
		takeEvery(actions.CREATE_COMPANY_DOMAIN, createCompanyDomain),
		takeEvery(actions.UPDATE_COMPANY_DOMAIN, updateCompanyDomain),
		takeEvery(actions.VERIFY_COMPANY_DOMAIN, verifyCompanyDomain),
		takeEvery(actions.DELETE_COMPANY_DOMAIN, deleteCompanyDomain),
		takeEvery(actions.VALIDATE_REMOVE_PREFIX, validateRemovePrefix),
		takeEvery(actions.CREATE_CUSTOM_RELATIONS, createCustomRelations),
	]);
}
