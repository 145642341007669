import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { history, store } from "redux/store";
import PublicRoutes from "router";
import actions from "redux/employee/actions";
import { EmployeeOnboarding } from "styles/Shared.style";
import { ReactHelmet, ReactHelmetEmployee } from "redux/helper";
import { sqFavicon } from "components/constants";
import WelcomePage from "components/Shared/WelcomePage";
import { isEmployeeApp } from "components/utility/helper";

function Routes() {
	const subDomain = window.location.host.split(".")[1]
			? window.location.host.split(".")[0]
			: false,
		{ company, isShowWelcomePage } = useSelector((state) => ({
			...state.Employee,
			...state.Auth,
		}));
	const currentAddress = window.location;
	localStorage.setItem("fullUrl", currentAddress);

	useEffect(() => {
		if (isEmployeeApp(subDomain)) {
			store.dispatch({ type: actions.SET_SUB_DOMAIN, payload: subDomain });
			store.dispatch({
				type: actions.FETCH_COMPANY_PROFILE_DETAILS,
				subDomain,
			});
		}
	}, [subDomain]);
	useEffect(() => {
		// dynamic favicon creation with company logo
		let link =
			document.querySelector('link[rel="shortcut icon"]') ||
			document.querySelector('link[rel="icon"]');

		if (!link) {
			link = document.createElement("link");
			link.id = "favicon";
			link.rel = "shortcut icon";
			document.head.appendChild(link);
		}
		if (isEmployeeApp(subDomain)) {
			link.href = company.logo_url;
		} else {
			link.href = sqFavicon;
		}
	}, [company, subDomain]);
	return (
		<>
			{/*dynamic title creation with company name*/}
			{subDomain ? <ReactHelmetEmployee /> : <ReactHelmet />}
			<EmployeeOnboarding
				textColor={company.text_color}
				bgImage={company.background_image_url}
				isEmployeeApp={isEmployeeApp}
				isShowWelcomePage={isShowWelcomePage}
			>
				<PublicRoutes history={history} subDomain={subDomain} />
				<WelcomePage />
			</EmployeeOnboarding>
		</>
	);
}

export default Routes;
