import styled, { createGlobalStyle } from "styled-components";
import variables from "styles/variables";
import { Badge, Skeleton, List } from "antd";

export const HeaderStyle = styled.div`
	.details-card.dt-card
		.ant-form-item-control-input-content
		.ant-form-item-control-input-content {
		border: none !important;
	}
	.details-card.dt-card .ant-form-item.details {
		margin-bottom: 0;
	}
	.details-card.dt-card .ant-form-item.details .ant-select-selector {
		border: none;
		padding: 0;
	}
	.details-card.dt-card .ant-card-body .ant-form .container .anticon {
		top: 0 !important;
	}
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	h4.ant-typography {
		margin-bottom: 5px;
	}
	&.customize-header {
		display: block;
	}
	margin-bottom: 20px;
	.back {
		display: block;
		width: 35px;
		margin-left: ${parseInt(variables.SQ_SPACING_BASE) + 5}px;
		vertical-align: top;
		span {
			display: block;
			text-align: center;
		}
	}
	.employee-details {
		.basic-info {
			height: calc(100vh - 160px);
			overflow: auto;
			scrollbar-width: none;
			.start-date {
				display: flex;
				justify-content: center;
				flex-direction: column;
				width: fit-content;
				margin-left: ${variables.SQ_SPACING_BASE};
				.content-day {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					position: relative;
				}
			}
		}
		.basic-info::-webkit-scrollbar {
			display: none;
		}
		width: 260px;
		margin-left: ${variables.SQ_SPACING_BASE};
		display: inline-block;
		vertical-align: top;
		padding: 0 ${variables.SQ_SPACING_SMALL} 0 0;
		.ant-avatar.ant-avatar-image {
			width: 80px;
			height: 80px;
			margin-bottom: ${variables.SQ_SPACING_SMALL};
			margin-left: ${parseInt(variables.SQ_SPACING_MEDIUM) + 5}px;
		}
		h4.ant-typography {
			word-break: break-word;
			margin-left: ${parseInt(variables.SQ_SPACING_SMALL) + 4}px;
		}
		.label {
			margin-top: ${parseInt(variables.SQ_SPACING_BASE) + 10}px;
		}
		.content {
			margin-top: ${variables.SQ_SPACING_XS};
		}
		.label,
		.content {
			color: ${variables.TEXT_COLOR};
			word-break: break-word;
		}
		.content-journey {
			a {
				color: ${variables.PRIMARY_COLOR};
			}
			margin-top: ${variables.SQ_SPACING_XS};
			word-break: break-word;
			cursor: pointer;
		}
		.content-day {
			color: ${variables.TEXT_COLOR};
		}
		.content-task {
			margin: ${variables.SQ_SPACING_BASE} 0 0 0;
			font-weight: 600;
		}
		.task-assigned-details {
			margin-top: ${variables.SQ_SPACING_BASE};
			.ant-progress-text {
				top: 35%;
				word-break: break-word;
				padding: 0 ${variables.SQ_SPACING_SMALL};
				.label {
					padding: 0;
					font-size: ${parseInt(variables.BASE_FONT_SIZE) + 2}px;
				}
			}
			.label {
				color: ${variables.TEXT_COLOR_DARK};
				font-size: ${parseInt(variables.BASE_FONT_SIZE) + 1}px;
				padding: 0 ${parseInt(variables.SQ_SPACING_BASE) - 5}px;
			}
			.content {
				font-size: ${parseInt(variables.BASE_FONT_SIZE) - 2}px;
			}
		}
		.ant-progress {
			margin-left: 0px;
		}
		.ant-skeleton {
			margin-bottom: ${variables.SQ_SPACING_BASE};
			&-avatar {
				width: 125px;
				height: 125px;
			}
			&-paragraph li {
				height: 20px;
			}
		}
	}
	.employee-tabs {
		width: calc(100% - 280px);
		display: inline-block;
		.ant-tabs-top-bar {
			border: 0;
			margin: 0;
			margin-top: ${variables.SQ_SPACING_BASE};
			margin-bottom: ${variables.SQ_SPACING_LARGE};
			text-align: center;
		}
		.ant-tabs-ink-bar {
			background-color: ${variables.PROGRESS_PRIMARY_COLOR};
			width: 80px;
			height: 3px;
		}
		.ant-tabs-nav .ant-tabs-tab {
			color: ${variables.TEXT_COLOR_DARK};
			padding: ${parseInt(variables.SQ_SPACING_XS) - 1}px
				${variables.SQ_SPACING_XS};
			font-size: ${parseInt(variables.BASE_FONT_SIZE) + 1}px;
			font-weight: 600;
			&-active {
				color: ${variables.PROGRESS_PRIMARY_COLOR};
			}
			&:hover {
				color: ${variables.PROGRESS_PRIMARY_COLOR};
			}
		}
		.ant-tabs-top-content.ant-tabs-content-animated {
			&:focus {
				outline: 0;
			}
			.ant-tabs-tabpane-active,
			.ant-tabs-tabpane-inactive {
				&:focus {
					outline: 0;
				}
			}
		}
	}
`;
export const CounterStyles = styled.div`
	position: relative;
	& > div {
		text-align: right;
		color: ${variables.TEXT_COLOR_LIGHT};
		span {
			font-weight: 600;
		}
		position: absolute;
		right: 2px;
	}
`;
export const ProfileGlobalStyles = createGlobalStyle`
.profile-popover {
    width: ${variables.USER_POPOVER_WIDTH} !important;
    .name{
      color: ${variables.BLACK_COLOR} !important;
      width: ${parseInt(variables.USER_POPOVER_WIDTH) - 5}px !important;
      font-size: large !important;
      padding-bottom: 2px !important;
      font-weight: bolder;
      overflow: hidden;
      cursor: default;
      text-overflow: ellipsis !important;
    }
    .email{
      color: ${variables.BLACK_COLOR} !important;
      width: ${parseInt(variables.USER_POPOVER_WIDTH) - 5}px !important;
      padding-top: 3px !important;
      padding-bottom: 0px !important;
      overflow: hidden;
      text-overflow: ellipsis !important;
      cursor: default;
    }
    .role{
      color: ${variables.BLACK_COLOR} !important;
      cursor: default;
    }
    .ant-divider-horizontal{
      margin: 0px;
      height: 2px;
    }
    .ant-dropdown-menu {
      margin-bottom: ${variables.SQ_SPACING_SMALL};
      padding: 1px;
      .ant-dropdown-menu-item {
        border-right: 0;
        line-height: 16px;
        height: unset;
        color: ${variables.PRIMARY_COLOR};
        margin: 0;
        .ant-upload {
          color: ${variables.PRIMARY_COLOR};
        }
        padding: ${parseInt(variables.SQ_SPACING_SMALL) + 5}px;
        &.upload-profile {
         padding: 0;
        }
      }
      .ant-dropdown-menu-item-only-child {
        span.ant-upload {
          display: block;
          padding: ${parseInt(variables.SQ_SPACING_SMALL) + 5}px;
          padding-right:  ${variables.SQ_SPACING_LARGE};
        }
      }
      .ant-dropdown-menu-item-disabled.ant-dropdown-menu-item {
        background: ${variables.PLACEHOLDER_COLOR};
        .anticon {
           font-size: ${parseInt(variables.BASE_FONT_SIZE) + 2}px;
        }
      }
    }
  }
`;
export const SkeletonLg = styled(Skeleton)`
	& {
		height: ${(props) => (props.height ? props.height : "100%")};
		.ant-skeleton-content {
			height: 100%;
			.ant-skeleton-paragraph {
				height: 100%;
				li {
					height: 100%;
					border-radius: ${variables.BORDER_RADIUS_BASE};
				}
			}
		}
	}
`;
export const EmployeeOnboarding = styled.div`
	height: 100%;
	.ant-layout {
		opacity: ${(props) => (props.isShowWelcomePage ? 0 : 1)};
		.ant-layout-content {
			${(props) =>
				props.isEmployeeApp
					? `
  color: ${props.textColor};
  background: url(${props.bgImage}) center center no-repeat;
  background-size: 100% 100%;`
					: ""}
		}
	}
`;

export const WelcomePageStyles = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 13%;
	left: 0;
	text-align: center;
	font-weight: 600;
	font-size: 24px;
	background: ${variables.WHITE_COLOR};
	display: ${(props) => (props.displayWelcomeText ? "block" : "none")};
	${(props) =>
		props.isShowWelcomePage
			? `transition: 0.5s;
  transition-timing-function: ease-out;
  opacity: 1;
  transform: translateY(0%);`
			: `
  transition-timing-function: ease-in;
  transition: 1s; opacity:0;`};
	@media (max-width: 520px) {
		font-size: 16px;
	}
	.wave {
		animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
		animation-duration: 2.5s; /* Change to speed up or slow down */
		animation-iteration-count: infinite; /* Never stop waving :) */
		transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
		display: inline-block;
		margin-bottom: 15px;
		font-size: 50px;
	}

	@keyframes wave-animation {
		0% {
			transform: rotate(0deg);
		}
		10% {
			transform: rotate(14deg);
		} /* The following five values can be played with to make the waving more or less extreme */
		20% {
			transform: rotate(-8deg);
		}
		30% {
			transform: rotate(14deg);
		}
		40% {
			transform: rotate(-4deg);
		}
		50% {
			transform: rotate(10deg);
		}
		60% {
			transform: rotate(0deg);
		} /* Reset for the last half to pause */
		100% {
			transform: rotate(0deg);
		}
	}
`;

export const BadgeStyles = styled(Badge)`
	margin-left: 5px;
	sup {
		padding: 4px;
		line-height: 15px;
		font-weight: 600;
	}
`;

export const ListStyles = styled(List)`
	.ant-spin-container {
		max-height: 195px;
		min-height: 195px;
		overflow-y: auto;
	}
`;
export const CurrentOverdueTasksHeader = styled.div`
	margin-bottom: 0px !important;
	.tabs {
		.ant-tabs-nav-scroll {
			display: flex;
			justify-content: center;
			max-height: 38px;
		}
		div {
			color: ${variables.PRIMARY_COLOR};
			font-weight: 400;
		}
		.ant-tabs {
			max-height: 38px;
			margin-top: 20px;
		}
	}
`;

export const UserTaskStyles = styled.div`
	height: calc(100vh - 140px);
`;
export const DashboardTaskListStyles = styled.div`
	margin-right: 10px;
	display: flex;
	align-items: center;
	.ant-avatar {
		margin-right: 15px;
		flex-shrink: 0;
	}
	.name {
		margin-top: 7px;
		font-size: 12px;
	}
`;

export const DueStyles = styled.div`
	min-width: fit-content;
	margin-left: 10px;
	font-size: 12px;
	font-style: italic;
	color: ${variables.TEXT_COLOR_SECONDARY};
`;
