import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/meetings/actions";
import { store } from "redux/store";
import { getRequest, patchRequest, postRequest } from "helpers/axiosClient";
import {
	calculatePageNumber,
	getCompanyUrl,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import { showNotification } from "components/utility/helper";
import { assetTypeMeeting, notFoundCode } from "components/constants";
import { history } from "redux/store";

export function* getMeetingsList(params) {
	try {
		const {
			pageSize,
			meetingsSortOrder,
			meetingsSortKey,
			meetingsCurrentPage,
		} = store.getState().Meetings;
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/meetings?query[sorts][]=${meetingsSortKey} ${meetingsSortOrder}&query[sorts][]=id ${meetingsSortOrder}&page=${meetingsCurrentPage}&per_page=${pageSize}&query[meeting_type_eq]=general`,
			),
		);
		yield put({
			type: actions.GET_MEETINGS_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_MEETINGS_LIST_FAILURE,
		});
	}
}

export function* deleteMeetingsList(params) {
	try {
		const {
			selectedMeetingsList,
			meetingsCurrentPage,
			totalMeetingsData,
			pageSize,
		} = store.getState().Meetings;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/meetings`, {
				ids: selectedMeetingsList,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.DELETE_MEETINGS_LIST_SUCCESS,
		});
		if (
			meetingsCurrentPage >
			Math.ceil((totalMeetingsData - selectedMeetingsList.length) / pageSize)
		) {
			let pages = calculatePageNumber(
				totalMeetingsData,
				selectedMeetingsList,
				pageSize,
			);
			yield put({
				type: actions.SET_MEETINGS_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.GET_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_MEETINGS_LIST_FAILURE,
		});
	}
}

export function* createNewMeetings(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/meetings`, {
				meeting: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: `${getLocaleMessages("label.inviteMeetings")}${" "}
          ${
						params.payload.employee_ids?.length
							? params.payload.employee_ids?.length
							: 0
					}${" "}${getLocaleMessages("label.attendees")}`,
		});

		yield put({
			type: actions.CREATE_NEW_MEETINGS_SUCCESS,
			payload: response.data.data,
		});

		yield put({
			type: actions.GET_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_MEETINGS_FAILURE,
		});
	}
}

export function* updateMeetings(params) {
	try {
		const { meetingId } = store.getState().Meetings;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/meetings/${meetingId}`, {
				meeting: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.UPDATE_MEETINGS_CONTENT_SUCCESS,
			payload: response.data.data,
		});
		yield put({
			type: actions.RESET_MEETINGS_DATA,
		});
		yield put({
			type: actions.GET_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_MEETINGS_CONTENT_FAILURE,
		});
	}
}

export function* cancelMeetingsList() {
	try {
		const { selectedMeetingsList, meetingActionKey } =
			store.getState().Meetings;
		let data = {
			ids: selectedMeetingsList,
			status: meetingActionKey === "cancel" ? "cancel" : "active",
		};
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/meetings/status`, { ...data }),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.APPROVE_OR_CANCEL_MEETING_LIST_SUCCESS,
		});
		yield put({
			type: actions.GET_MEETINGS_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.APPROVE_OR_CANCEL_MEETING_LIST_FAILURE,
		});
	}
}

export function* getMeetingsTemplateList() {
	try {
		const {
			meetingsTemplatePageSize,
			meetingsTemplateSortOrder,
			meetingsTemplateSortKey,
			meetingsTemplateCurrentPage,
		} = store.getState().Meetings;
		const response = yield call(() =>
			getRequest(
				`admin/meetings?query[sorts][]=${meetingsTemplateSortKey} ${meetingsTemplateSortOrder}&query[sorts][]=id ${meetingsTemplateSortOrder}&page=${meetingsTemplateCurrentPage}&per_page=${meetingsTemplatePageSize}&query[meeting_type_eq]=general&query[published_eq]=true`,
			),
		);
		yield put({
			type: actions.GET_MEETINGS_TEMPLATE_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_MEETINGS_TEMPLATE_LIST_FAILURE,
		});
	}
}

export function* duplicateMeeting() {
	try {
		const { meetingTemplateId } = store.getState().Meetings;
		yield call(() =>
			postRequest(`${getCompanyUrl()}/meetings/${meetingTemplateId}/duplicate`),
		);
		yield put({
			type: actions.DUPLICATE_MEETING_TEMPLATE_SUCCESS,
		});
		history.push({ search: `meeting_type=${assetTypeMeeting}` });
	} catch (error) {
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.MEETING_DUPLICATE_MODAL_VISIBLE,
				payload: false,
			});
		}
		handleException(error);
		yield put({
			type: actions.DUPLICATE_MEETING_TEMPLATE_FAILURE,
		});
	}
}

export function* getEmployees() {
	try {
		const { employeePage, employeeSearchText } = store.getState().Meetings;

		let url = `${getCompanyUrl()}/employees?page=${employeePage}&per_page=100&query[email_cont]=${encodeURI(
			employeeSearchText.trim(),
		)}`;

		let { data } = yield call(() => getRequest(url));

		yield put({
			type: actions.MEETINGS_GET_EMPLOYEES_SUCCESS,
			payload: data.data,
		});
		if (data.data.employees.length) {
			yield put({
				type: actions.MEETINGS_SET_EMPLOYEE_PAGE,
				payload: employeePage + 1,
			});
			yield put({ type: actions.MEETINGS_GET_EMPLOYEES });
		}
	} catch (error) {
		yield put({ type: actions.MEETINGS_GET_EMPLOYEES_FAILURE });
		handleException(error);
	}
}

export function* getUpComingMeetings() {
	try {
		const { userId } = store.getState().Auth;
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/upcoming_meetings?user_id=${userId}`),
		);
		yield put({
			type: actions.FETCH_UPCOMING_MEETINGS_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_UPCOMING_MEETINGS_FAILURE,
		});
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_MEETINGS_LIST, getMeetingsList),
		takeEvery(actions.DELETE_MEETINGS_LIST, deleteMeetingsList),
		takeEvery(actions.CREATE_NEW_MEETINGS, createNewMeetings),
		takeEvery(actions.UPDATE_MEETINGS_CONTENT, updateMeetings),
		takeEvery(actions.APPROVE_OR_CANCEL_MEETING_LIST, cancelMeetingsList),
		takeEvery(actions.GET_MEETINGS_TEMPLATE_LIST, getMeetingsTemplateList),
		takeEvery(actions.DUPLICATE_MEETING_TEMPLATE, duplicateMeeting),
		takeEvery(actions.MEETINGS_GET_EMPLOYEES, getEmployees),
		takeEvery(actions.FETCH_UPCOMING_MEETINGS, getUpComingMeetings),
	]);
}
