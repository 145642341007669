import React, { useEffect, useState } from "react";
import MomentExperience from "./MomentExperience";
import { handleException, ReactHelmet } from "../../redux/helper";
import { getRequest } from "../../helpers/axiosClient";
import { useParams, useRouteMatch } from "react-router-dom";
// import { useRouteMatch } from 'react-router';

function FinalMomentExperience() {
	const { empMomentToken } = useParams();
	const urlPath = useRouteMatch().path;
	const [contributions, setContributions] = useState({});

	useEffect(() => {
		if (urlPath.includes("/auth")) {
			getRequest(
				`contributions/auth_final_contribution_index?page_token=${empMomentToken}`,
			)
				.then((response) => {
					setContributions(response.data.data);
				})
				.catch((error) => handleException(error));
		} else {
			getRequest(
				`contributions/final_contribution_index?page_token=${empMomentToken}`,
			)
				.then((response) => {
					setContributions(response.data.data);
				})
				.catch((error) => handleException(error));
		}
	}, [urlPath, empMomentToken]);

	return (
		<>
			<ReactHelmet title={"Final Moment Experience"} />
			<MomentExperience contributions={contributions} />
		</>
	);
}

export default React.memo(FinalMomentExperience);
