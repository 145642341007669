import React, { useEffect } from "react";

const MomentExperienceHeader = ({ contributions, from }) => {
	useEffect(() => {
		document.getElementById("demo").innerHTML = from
			? `Add a message or video below to wish ${contributions.employee_name} on this special occassion`
			: contributions.description !== undefined
			? contributions.description
			: null;
	}, [contributions, from]);

	return (
		<div className="header_info">
			<div className={"logo_warp"}>
				{contributions.company_logo ? (
					<img
						src={contributions.company_logo}
						className={"exp_logo"}
						alt={"Company Logo"}
					/>
				) : null}
			</div>
			<div className="welcomeInfo">
				<h1 id={"demo"}></h1>
			</div>
		</div>
	);
};

export default MomentExperienceHeader;
