import React from "react";
import { useDispatch } from "react-redux";
import actions from "../../../redux/settings/users/actions";
import { showNotification } from "components/utility/helper";

let apiCount = 0;
function RedirectSlackPage() {
	const location = window.location;
	const searchParams = new URLSearchParams(location.search);
	const code = searchParams.get("code");
	const state = searchParams.get("state");
	const error = searchParams.get("error");
	const authState = localStorage.getItem("slack-oauth-state");
	localStorage.removeItem("slack-oauth-state");
	const dispatch = useDispatch();
	if (apiCount < 1) {
		apiCount = 1;
		if (code !== null && state === authState && error === null) {
			const isSlackUpdate = JSON.parse(localStorage.getItem("isSlackUpdate"));
			const slackId = JSON.parse(localStorage.getItem("slackId"));
			localStorage.removeItem("isSlackUpdate");
			localStorage.removeItem("slackId");
			if (isSlackUpdate) {
				dispatch({
					type: actions.UPDATE_SLACK_INT,
					payload: {
						id: slackId,
						slack: {
							code: code,
						},
					},
				});
			} else {
				dispatch({
					type: actions.CREATE_SLACK_INT,
					payload: {
						slack: {
							code: code,
						},
					},
				});
			}
		} else {
			showNotification({
				type: "error",
				message: error || "Error",
				description: "Failed to integrate slack!",
			});
		}
	}
	return <>{error && <div>Failed to integrate slack</div>}</>;
}

export default RedirectSlackPage;
