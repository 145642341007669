import React from "react";
// import bugsnag from "@bugsnag/js";
// import bugsnagReact from "@bugsnag/plugin-react";
import settings from "config/settings";
import { getCurrentUserEmail } from "redux/helper";
import Error500 from "containers/Pages/Error500";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
	apiKey: settings.BUGS_NAG_KEY,
	plugins: [new BugsnagPluginReact()],
	onError: function (event) {
		event.setUser({ email: getCurrentUserEmail() });
	},
});

// window.bugsnagClient = bugsnag(settings.BUGS_NAG_KEY);
// window.bugsnagClient.use(bugsnagReact, React);
// window.bugsnagClient.getPlugin("react");

class ErrorBoundary extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Bugsnag.notify(new Error(error));
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <Error500 />;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;

// const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

// export default ErrorBoundary;
