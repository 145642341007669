import React, { useEffect } from "react";
import { MomentGlobalStyles } from "../../styles/moment.style";
import MomentExperienceHeader from "../../components/MomentsThatMatter/MomentExperience/MomentExperienceHeader";
import MomentExperienceCards from "../../components/MomentsThatMatter/MomentExperience/MomentExperienceCards";
import logo from "../../assets/images/logo.svg";

import {
	createBalloons,
	initConfetti,
	renderConfetti,
} from "../../components/utility/helper";

function MomentExperience(props) {
	const contributions = props.contributions;

	useEffect(() => {
		if (contributions.length === undefined) {
			createBalloons(5);
			initConfetti();
			renderConfetti();
		}
	}, [contributions.contributions]);
	return (
		<>
			<MomentGlobalStyles />
			<div className="parent">
				<div className="subParent">
					<canvas className="confetti" id="canvas"></canvas>
					<div id="balloon-container" className={"confetti"}></div>
					<div className={"subParentContent"}>
						<div className="subParent-inner">
							<MomentExperienceHeader
								contributions={contributions}
								from={false}
							/>
							<div className={"outerCard"}>
								{contributions.contributions !== undefined ? (
									<MomentExperienceCards contributions={contributions} />
								) : (
									<div />
								)}
							</div>
							<div className="footer_info">
								<div className={"logo_warp"}>
									<img
										src={contributions.company_logo}
										className={"exp_logo"}
										alt={"Company Logo"}
									/>
								</div>
								<div className={"powered_by"}>
									<img src={logo} alt={"powered by"} />
									<spam>Powered by Squadsy</spam>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default MomentExperience;
