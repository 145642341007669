import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/reports/actions";
import { store } from "redux/store";
import { getRequest } from "helpers/axiosClient";
import { getCompanyUrl } from "redux/helper";

export function* getOnBoardChartData(params) {
	try {
		const { selectiveDateRange } = store.getState().Reports;
		let response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/dashboards/employees_onboarded?query[updated_at_gteq]=${
					selectiveDateRange?.from
				}&query[updated_at_lteq]=${selectiveDateRange?.to}`,
			),
		);
		yield put({
			type: actions.FETCH_ON_BOARD_CHART_DATA_SUCCESS,
			payload: response.data.data,
		});
		yield put({ type: actions.CHART_LOADER, payload: false });
	} catch (error) {
		yield put({
			type: actions.FETCH_ON_BOARD_CHART_DATA_FAILURE,
		});
	}
}

export function* getEngagementMailData(params) {
	try {
		const { selectiveDateRange } = store.getState().Reports;
		let url = `${getCompanyUrl()}/dashboards/email_views?query[updated_at_gteq]=${
			selectiveDateRange?.from
		}&query[updated_at_lteq]=${selectiveDateRange?.to}`;
		let { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_MOST_ENGAGEMENT_EMAIL_SUCCESS,
			payload: data.data.assets,
		});
		yield put({ type: actions.SET_EMAIL_LOADER, payload: false });
	} catch (error) {
		yield put({
			type: actions.FETCH_MOST_ENGAGEMENT_EMAIL_FAILURE,
		});
		store.dispatch({ type: actions.SET_EMAIL_LOADER, payload: false });
	}
}

export function* getEngagementPageData(params) {
	try {
		const { selectiveDateRange } = store.getState().Reports;
		let url = `${getCompanyUrl()}/dashboards/page_views?query[updated_at_gteq]=${
			selectiveDateRange?.from
		}&query[updated_at_lteq]=${selectiveDateRange?.to}`;
		let { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_MOST_ENGAGEMENT_PAGE_SUCCESS,
			payload: data.data.assets,
		});
		yield put({ type: actions.SET_PAGE_LOADER, payload: false });
	} catch (error) {
		yield put({
			type: actions.FETCH_MOST_ENGAGEMENT_PAGE_FAILURE,
		});
		yield put({ type: actions.SET_PAGE_LOADER, payload: false });
	}
}
export function* getEngagementScoreChartData(params) {
	try {
		const { selectiveDateRange } = store.getState().Reports;
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/dashboards/engagement_score?query[updated_at_gteq]=${
					selectiveDateRange?.from
				}&query[updated_at_lteq]=${selectiveDateRange?.to}`,
			),
		);
		yield put({
			type: actions.FETCH_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS,
			payload: response.data.data,
		});
		yield put({ type: actions.CHART_LOADER, payload: false });
	} catch (error) {
		yield put({
			type: actions.FETCH_ON_BOARD_CHART_DATA_FAILURE,
		});
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_ON_BOARD_CHART_DATA, getOnBoardChartData),
		takeEvery(actions.FETCH_MOST_ENGAGEMENT_EMAIL, getEngagementMailData),
		takeEvery(actions.FETCH_MOST_ENGAGEMENT_PAGE, getEngagementPageData),
		takeEvery(
			actions.FETCH_ENGAGEMENT_SCORE_CHART_DATA,
			getEngagementScoreChartData,
		),
	]);
}
