import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/employee/actions";
import { getRequest, patchRequest, postRequest } from "helpers/axiosClient";
import {
	customAPIRequest,
	getCompanyUrl,
	getEmployeeId,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import { scrollToBottom, showNotification } from "components/utility/helper";
import { store } from "redux/store";
import settings from "config/settings";
import taskActions from "redux/Task/actions";
import { timeOut2000 } from "components/constants";

export function* fetchCompanyDetails(params) {
	try {
		let queryParams = "";
		if (params.id) {
			queryParams = `id=${params.id}`;
		} else {
			queryParams = `sub_domain=${params.subDomain}`;
		}
		const { data } = yield call(() => getRequest(`companies?${queryParams}`));
		yield put({
			type: actions.FETCH_COMPANY_PROFILE_DETAILS_SUCCESS,
			payload: data.data.company,
		});
	} catch (error) {
		yield put({
			type: actions.FETCH_COMPANY_PROFILE_DETAILS_FAILURE,
		});
		localStorage.setItem("login_type", null);
		window.location.href = settings.APP_BASE_URL;
		handleException(error);
	}
}

export function* uploadImageFile(params) {
	try {
		const { data } = yield call(() =>
			postRequest("direct_uploads", {
				blob: params.payload.blob,
				isPublicAccessible: params.payload.isPublicAccessible || false,
			}),
		);
		yield put({
			type: actions.UPLOAD_PROFILE_IMAGE_TO_S3,
			payload: {
				URL: data.data.direct_upload.url,
				headers: data.data.direct_upload.headers,
				signed_id: data.data.signed_id,
				fileData: params.payload.fileData,
			},
			callBackAction: params.callBackAction,
		});
	} catch (error) {
		yield put({
			type: actions.SET_PROFILE_IMAGE_LOADER,
			payload: false,
		});
		handleException(error);
	}
}

export function* uploadToS3(params) {
	try {
		yield call(() => customAPIRequest({ ...params.payload }));
		params.callBackAction(params.payload.signed_id);
	} catch (error) {
		yield put({
			type: actions.SET_PROFILE_IMAGE_LOADER,
			payload: false,
		});
		handleException(error);
	}
}

export function* SendProfileImageKey(params) {
	try {
		const { data } = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${getEmployeeId()}`,
				params.payload,
			),
		);
		yield put({
			type: actions.SET_PROFILE_IMAGE_LOADER,
			payload: false,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		yield put({
			type: actions.SET_PROFILE_IMAGE_LOADER,
			payload: false,
		});
		handleException(error);
	}
}

export function* sendMessage(params) {
	try {
		const { company } = store.getState().Employee;
		const { data } = yield call(() =>
			postRequest(
				`${getCompanyUrl()}/employees/${getEmployeeId()}/message`,
				params.payload,
			),
		);
		yield put({
			type: actions.SEND_MESSAGE_SUCCESS,
			payload: data.data.messages[data.data.messages.length - 1],
		});
		scrollToBottom();
		setTimeout(function () {
			if (company.custom_message) {
				store.dispatch({ type: actions.ADD_CUSTOM_MESSAGE });
				scrollToBottom();
			}
		}, timeOut2000);
	} catch (error) {
		yield put({
			type: actions.SEND_MESSAGE_FAILURE,
			payload: false,
		});
		handleException(error);
	}
}

export function* fetchEmployeeDetails() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/employees/${getEmployeeId()}`),
		);
		yield put({
			type: actions.FETCH_EMPLOYEE_DETAILS_SUCCESS,
			payload: data.data.employee,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_EMPLOYEE_DETAILS_FAILURE,
		});
	}
}

export function* fetchTaskDetails(params) {
	try {
		const { data } = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/employees/${getEmployeeId()}/tasks/${params.id}`,
			),
		);
		yield put({
			type: actions.FETCH_TASK_CONTENT_SUCCESS,
			payload: data.data.task,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_TASK_CONTENT_FAILURE,
		});
	}
}

export function* updateEmployeeTask(params) {
	try {
		const { data } = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${getEmployeeId()}/tasks/${params.id}`,
				{ employee_event: params.payload },
			),
		);
		if (!params.payload.employee_status) {
			showNotification({
				type: "success",
				message: data.data.message,
				description: getLocaleMessages("label.successDescription"),
			});
		}
		yield put({
			type: actions.FETCH_TASK_CONTENT,
			id: params.id,
		});
		yield put({
			type: actions.UPDATE_EMPLOYEE_TASK_SUCCESS,
			payload: data.data.task,
			params,
		});
		if (params.payload.form_data) {
			store.dispatch({ type: actions.SET_TASK_SUBMIT_STATUS, payload: true });
		}
		// generate files preview for captured image
		if (params.employeeTaskCallback) {
			params.employeeTaskCallback(data.data.task.files);
		}
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_EMPLOYEE_TASK_FAILURE,
		});
	}
}

export function* fetchEmployeeTasks(params) {
	try {
		const { page, perPage } = store.getState().Employee;
		const { data } = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/employees/${getEmployeeId()}/tasks?page=${page}&per_page=${perPage}&query[employee_status_not_eq]=initial`,
			),
		);
		yield put({
			type: actions.FETCH_EMPLOYEE_TASKS_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_EMPLOYEE_TASKS_FAILURE,
		});
	}
}

export function* removeUploadedFiles(params) {
	try {
		const { data } = yield call(() =>
			patchRequest(`${getCompanyUrl()}/tasks/destroy_attachments`, {
				ids: params.ids,
			}),
		);
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		if (params.isEmployee) {
			yield put({
				type: actions.REMOVE_UPLOADED_FILES_SUCCESS,
				payload: data.data,
				ids: params.ids,
			});
		} else {
			yield put({
				type: taskActions.REMOVE_USER_UPLOADED_FILES_SUCCESS,
				payload: data.data,
				ids: params.ids,
			});
		}
	} catch (error) {
		handleException(error);
		if (params.isEmployee) {
			yield put({
				type: taskActions.REMOVE_UPLOADED_FILES_FAILURE,
			});
		} else {
			yield put({
				type: actions.REMOVE_USER_UPLOADED_FILES_FAILURE,
			});
		}
	}
}

export function* createPageViews(params) {
	try {
		const { companyId } = store.getState().Assets;
		let url = "";
		if (companyId === null) {
			url = `companies/${companyId}/dashboards/create_page_views`;
		} else {
			url = `${getCompanyUrl()}/dashboards/create_page_views`;
		}
		const { data } = yield call(() => postRequest(url, params.payload));
		yield put({
			type: actions.CREATE_PAGE_VIEWS_SUCCESS,
			payload: data.data.page_statistics,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* updatePageViews(params) {
	try {
		yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/dashboards/update_page_views`,
				params.payload,
			),
		);
		yield put({ type: actions.UPDATE_PAGE_VIEWS_SUCCESS });
	} catch (error) {
		handleException(error);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_COMPANY_PROFILE_DETAILS, fetchCompanyDetails),
		takeEvery(actions.UPLOAD_PROFILE_IMAGE, uploadImageFile),
		takeEvery(actions.UPLOAD_PROFILE_IMAGE_TO_S3, uploadToS3),
		takeEvery(actions.SEND_PROFILE_IMAGE_KEY, SendProfileImageKey),
		takeEvery(actions.SEND_MESSAGE, sendMessage),
		takeEvery(actions.FETCH_EMPLOYEE_DETAILS, fetchEmployeeDetails),
		takeEvery(actions.FETCH_TASK_CONTENT, fetchTaskDetails),
		takeEvery(actions.UPDATE_EMPLOYEE_TASK, updateEmployeeTask),
		takeEvery(actions.FETCH_EMPLOYEE_TASKS, fetchEmployeeTasks),
		takeEvery(actions.REMOVE_UPLOADED_FILES, removeUploadedFiles),
		takeEvery(actions.CREATE_PAGE_VIEWS, createPageViews),
		takeEvery(actions.UPDATE_PAGE_VIEWS, updatePageViews),
	]);
}
