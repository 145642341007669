import styled from "styled-components";

const initialLoaderStyle = styled.div`
	height: 100%;
	.initial-loading {
		align-items: center;
		display: flex;
		height: ${(props) => (props.dashboard ? "400px" : "100%")};
		justify-content: center;
		.ant-spin-dot {
			font-size: 50px;
		}
	}
`;

export default initialLoaderStyle;
