import { createGlobalStyle } from "styled-components";
import videCameraIcon from "./video-camera.png";
import chatIcon from "./chat.png";
import addIcon from "./add.png";
import smileIcon from "./smile.png";
import cameraIcon from "./camera.png";

export const MomentGlobalStyles = createGlobalStyle`

.welcomeInfo  h1 {
    font-size: 22px;
    color: #000;
}

.pre_n_view-btnwrap {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .preview_exp {
        &-btnwarp {
            text-align: center;
            margin-left: 10px;
        }
        &-btn {
            // overflow: hidden;
            padding: 0;
            background: none;
            border: none;
            height: 62px;
            display: flex;
            animation: c;
            justify-content: center;
            &:focus, &:hover, &:active {
                border: none;
                box-shadow: none;
                outline: none;
            }
            &::after {
                content: none;
            }
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
            }
        }
    } 
}

.addMsg_buttonwarp {
        text-align: center;
      }
      .grad-btn,
      .grad-btn-shadow {
        border-radius: 6px;
        background: linear-gradient(
          130deg,
          #382cad,
          #53a1fd,
          #91d190,
          #382cad,
          #53a1fd,
          #91d190
        );
        transition: all 0.3s ease;
        background-size: 450%;
        animation: grad-btn-anim 25s linear infinite;
        border: none;
        position: relative;
      }
      .grad-btn:hover,
      .grad-btn-shadow:hover {
        transform: scale(0.98);
      }
      .grad-btn {
        margin: 0;
        padding: 16px 30px;
        text-align: center;
        color: #ffffffe6;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: bold;
        height: auto;
        min-height: 62px;
      }
      .grad-btn-text {
        line-height: 1;
        letter-spacing: 0.5px;
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
      .addMsg_buttonwarp .grad-btn-text::before {
        content: "";
        background: url(${addIcon});
        width: 30px;
        background-size: contain;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
        filter: brightness(0) invert(1);
      }
      .grad-btn-shadow {
        position: absolute;
        z-index: -1;
        filter: blur(30px);
        opacity: 0.8;
        left: 0;
        right: 0;
        top: 15px;
        bottom: -15px;
      }
      .modal_footer-btnwrap .grad-btn {
        width: 100%;
      }
      #comment {
          font-size: 24px;
          font-weight: bold;
          color: #3127b0;
          min-height: 300px;
          border: none;
          resize: none;
          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
        &::-webkit-input-placeholder { 
          color: #b2b2b3;
          font-size: 24px;
          font-weight: bold;
        }
        
        &:-ms-input-placeholder {
          color: #b2b2b3;
          font-size: 24px;
          font-weight: bold;
        }
        
        %::placeholder {
          color: #b2b2b3;
          font-size: 24px;
          font-weight: bold;
        }
      }
      @keyframes grad-btn-anim {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }



.singleCard .main-wrap {
    width: 100%;
}

.main-wrap {
    margin-top: 85px;
}

:root {
    --marquee-width: 100%;
    --marquee-height: 940px;
    /* --marquee-elements: 12; */ /* defined with JavaScript */
    /* --marquee-elementss: 12; */ /* defined with JavaScript */
    --marquee-elements-displayed: 4;
    --marquee-elements-displayedtwo: 2;
    --marquee-element-width: calc(var(--marquee-height) / var(--marquee-elements-displayed));
    --marquee-element-widthtwo: calc(var(--marquee-height) / var(--marquee-elements-displayedtwo));
    --marquee-animation-duration: calc(var(--marquee-elements) * 4s);
    --marquee-animation-duration2: calc(var(--marquee-elementss) * 4s);
  }

  .cardWrap.desktop-slider.animationCards {
    gap: 0;
  }
  
  .marquee {
    width: var(--marquee-width);
    height: var(--marquee-height);
    overflow: hidden;
    position: relative;
  }
  .marquee-content, .marquee-content2 {
    list-style: none;
  }

  .marquee-content {
    animation: scrolling var(--marquee-animation-duration) linear infinite;
  }
  
  .marquee-content2 {
    animation: invertscrolling var(--marquee-animation-duration2) linear infinite;
  }

  .cardWrap.desktop-slider.animationCards:hover .marquee-content, .cardWrap.desktop-slider.animationCards:hover .marquee-content2 {
    animation-play-state: paused;
  }

  @keyframes scrolling {
    0% { transform: translateY(0); }
    100% { transform: translateY(calc(-2 * var(--marquee-element-width) * var(--marquee-elements))); }
  }

  @keyframes invertscrolling {
    0% { transform: translateY(calc(-1 * var(--marquee-element-widthtwo) * var(--marquee-elementss))); }
    100% { transform: translateY(0); }
  }

  .marquee-content li, .marquee-content2 li {
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    flex-shrink: 0;
    height: 470px;
    max-height: 100%;
    white-space: nowrap;
    padding: 10px;
  }

//   .marquee-content li {
//     margin-bottom: 15px;
//   }

//   .marquee-content2 li {
//     margin-bottom: 15px;
//   }

  .marquee-content li .ant-card, .marquee-content2 li .ant-card {
    width: 100%;
    margin: 0;
    height: auto !important;
    min-height: 100% !important;
  }


.cardWrap {
    &-static {
        .ant-row {
            padding: 10px;        
            .ant-list-item {
                border: none;
                border-radius: 12px;
                box-shadow: 0 0 6px 2px rgb(0 0 0 / 30%);
            }
        }
        .ant-card {
            border-radius: 12px;
            overflow: hidden;
            // min-height: auto;
            &.bluegradient {
                padding-bottom: 90px;
            }
        }
        
        .ant-card-body {
            padding: 0;
            .card_content {
                // padding: 24px;
                color: #0a12e6;
                font-size: 26px;
                line-height: 22px;
                font-weight: bold;
                &-gifyimgwarap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
                img {
                    width: 100%;
                    // height: 230px;
                    // object-fit: cover;
                    // object-position: center;
                }
                .card_text {
                    padding: 24px 24px 0;
                }
                .info {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 24px;
                    &_imgwrap {
                        margin-right: 10px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50px;
                        overflow: hidden;
                        box-shadow: 0 0 5px #00000063;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    &_textwarp {
                        h4 {
                            font-size: 14px;
                            font-weight: bold;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}
  
.parent {
    width: 100%;
    margin: 0 auto;
    background-image: linear-gradient(160deg,rgb(255 0 147),rgb(255 1 182),rgb(226 0 255),rgb(255 255 255));
    position: relative;
    overflow-y: auto;
    height: 100%;
}

.subParent {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    background: #fff;
    position: relative;
    overflow: hidden;
}

h1 {
    color: #001e6d;
    font-weight: bold;
    font-size: 55px;
    text-align: center;
    margin: 0 0 15px;
}
.logo_warp {
    text-align: center;
    margin-bottom: 30px
}
.powered_by {
    text-align: center;
    margin-top: 10px;
}
.powered_by img {
    vertical-align: middle;
    border-style: none;
    width: 7%;
} 

.header_info h4 {
    color: #000;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    line-height: 1.6;
    font-family: unset;
}

span.personName {
    font-size: 45px;
    text-align: center;
    display: block;
    color: #0a12e6;
    font-weight: bold;
}

.welcomeInfo {
    margin: 50px 0;
}

.welcomeInfo h4 {
    color: #000;
    font-size: 35px;
    width: auto;
    margin: 0 auto;
}

span.profileInfo {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    display: block;
    color: #0a12e6;
    margin: 0 0 20px;
    font-family: inherit;
}
.requirement {
    margin-bottom: 40px;
}
.requirement h4 {
    font-size: 30px;
    // font-weight: 1000;
    color: #000;
}
.cardWrap {
    --gap: 1.5rem;
    --slide-height: calc(100% + var(--gap));
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    transform-style: preserve-3d;
    will-change: transform;
    position: relative;
    overflow:hidden;
    &:hover {
    animation-play-state: paused;
  }
}

.cardWrap-inner .right {
    animation: marquee-horizontal-alt 5s linear infinite;
}

.exp_logo {
    height: auto;
    width: 150px;
    max-width: 100%;
    min-height: 60px;
}

.cards {
    animation-name: animation-rtpy4v;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: calc(var(--duration) * 1s);
    animation-delay: calc(var(--row) * var(--duration) / 4 * -1s);
    will-change: transform;
}

// @keyframes animation-rtpy4v {
// 0% {
//     transform: translateY( calc(var(--slide-height) * (var(--row) + 1) * -1) );
// }
//
// 100% {
//     transform: translateY( calc(var(--slide-height) * (3 - var(--row))) );
// }
// }

.cardWrap-inner {
    padding: 10px;
    display: grid;
    grid-gap: 15px;
    grid-auto-rows: minmax(min-content, max-content);
}
.cardWrap .ant-card {
    border: none;
    border-radius: 12px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 0 0 6px 2px rgb(0 0 0 / 30%);
}
.cardWrap .ant-card-body {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    height: 100%;
}

.cardWrap .ant-card-body .card_content {
    color: #0a12e6;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
}

    // font-size: 26px;
    // line-height: 22px;
    // font-weight: bold;
}
.card_content img {
    max-width: 100%;
}
.cardWrap .ant-card-body .info {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    position: absolute;
    bottom: 24px;
}

// .cardWrap .ant-card-body .info img {
//     margin-right: 10px;
//     border-radius: 16px;
//     display: block;
//     width: 45px;
//     height: 40px;
// }

// .cardWrap .ant-card-body .info h4 {
//     font-size: 14px;
//     font-weight: bold;
//     color: #000;
// }
.cardWrap .ant-card-body .info p {
    font-size: 12px;
}
.cardWrap {
    .ant-card-body {
        .info {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 24px;
            &_imgwrap {
                margin-right: 10px;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                overflow: hidden;
                box-shadow: 0 0 5px #00000063;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &_textwarp {
                h4 {
                    font-size: 14px;
                    font-weight: bold;
                    color: #000;
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
}



.card_video .ant-card-body {
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
}

.card_video .card_content {
    margin: 0 !important;
    min-height: 350px;
}

.card_video img {
    width: 100%;
    object-fit: cover;
    // height: 350px;
}

.bluegradient {
    background-image: linear-gradient(to top, rgb(163 221 255), rgb(216 240 255)) !important;
}

.greengradient {
    background-image: linear-gradient(to top,rgb(130 249 171),rgb(230 250 255)) !important;
}

.lightgradient {
    background-image: linear-gradient(to bottom,rgb(228 231 232),rgb(233 208 208)) !important;
}

.bluegreengradient {
    background-image: linear-gradient(to bottom,rgb(234 230 255),rgb(145 248 173)) !important;
}

.footer_info {
    margin: 40px 0 0;
    display: block;
}

.footer_info h2 {
    color: #001e6d;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    margin: 0 0 15px;
}

.footer_info p {
    color: #001e6d;
    font-size: 20px;
    text-align: center;
}

#balloon-container {
  // box-sizing: border-box;
  //   display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  inset: 0 0 -100% 0;
  z-index: 2;
}
.subParent-inner {
   position: relative;
   z-index: 2;
}

.balloon {
  height: 125px;
  width: 105px;
  border-radius: 75% 75% 70% 70%;
  position: relative;
}

.balloon:before {
  content: "";
  height: 75px;
  width: 1px;
  padding: 1px;
  background-color: #FDFD96;
  display: block;
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  margin: auto;
  
}

.balloon:after {
    content: "▲";
    text-align: center;
    display: block;
    position: absolute;
    color: inherit;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
}

@keyframes float {
  from {transform: translateY(100vh);}
  to {transform: translateY(-300vh);}
}

@keyframes marquee-horizontal-alt {
  0% { transform: translateY(0%);}
  100% { transform: translateY(-100%);}
}

.outerCard {
overflow:hidden;
border-radius: 5px;
    overflow:hidden;
    border-radius: 5px;
    &.previewed_cards-wrapper {
        // .video_wrapper {
        //     min-height: 500px;
        // }
    }
}
.confetti{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.subParentContent {
    padding: 40px 80px;
}

.video_wrapper {
    overflow: hidden;
    position: relative;
}
.card_video-playbtnwrap {
    position: absolute;
    inset: 0;
    display: flex;40px
    z-index: 2;
    cursor: pointer;
}
.card_video-playbtnwrap svg {
    fill: #fff;
    width: 80px;
    height: 80px;
}

.video_wrapper video {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}
.css-1k2aww2 {
    --header: 100%;
    --gap: 1rem;
    --slide-height: calc(100% + var(--gap));
    top: calc(-1 * var(--header));
    height: 70rem;
    position: relative;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: grid;
    padding: 10px;
    grid-gap: var(--gap);
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: minmax(auto, 420px) minmax(auto, 420px);
    &:hover .css-1hfw926 {
    animation-play-state: paused;
  }
}
.css-1hfw926 {
    min-height: 450px;
    --duration: 30;
    // -webkit-animation-name: animation-rtpy4v;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: calc(var(--duration) * 1s);
    animation-delay: calc(var(--row) * var(--duration) / 4 * -1s);
    will-change: transform;
}

.card_text {
    line-height: 36px;
    margin-top: 5px;
}

// 02-08-2022 Start

// .modal_footer-btnwrap {
//      margin-top: 36px;   
// }
button[data-qa="start-replaying"] {
    position: absolute;
    z-index: 99;
    bottom: 0px;
    font-size: 22px;
    color: #000;
    opacity: 0.6;
    text-transform: uppercase;
}
.modalRecord .euUDIH {
    position: absolute;
    bottom: 0;
    background: #fff;
    height: 40px;
    top: auto;
}
.modalRecord .heQTiL {
    background: #ffffff !important;
    max-width: 100%;
}
.cardWrap .ant-card-body .info h4 {
    font-size: 20px;
}
.singleCard {
    display: flex;
    justify-content: center;
}
.singleCard .ant-card {
    width: 100%;
}
.css-1k2aww2.singleCard,
.css-1k2aww2.threeCards {
    --gap: none;
}
.threeCards {
    margin: -10px;02
    margin: 10px;
}
.slick-slider.invertSlider .slick-slide,
.slick-slider.invertSlider .slick-list {
 transform: rotate(180deg);
}
.cardWrap .ant-card {
    margin-bottom: 18px;
}
// .selfPhoto .ant-upload {
//     background: #d8d8d8 !important;
//     display: flex !important;
//     justify-content: center !important;
//     align-items: center !important;
//     font-size: 70px !important;
//     padding: 0px 8px !important;
//     border-radius: 8px; 
//     max-width: 70px;
// }
// .selfPhoto .ant-upload .ant-upload::before {
//     width: 0 !important;
//     margin: 0 !important;
// }
.ant-form-item-control-input-content {
    // margin-right: 10px;
}
img#selfPhoto {
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 8px;
}
.singleCard .slider-row {
    width: 100%;
}
.singleCard .slider-row:nth-of-type(2) {
    display: none;
}
.cardWrap .ant-card-body {
    padding: 0 !important;
}
.card_content.text-card {
    padding: 24px 24px 0;
}
.ant-modal.videoModal {
    width: 70% !important;  
    height: 95% !important;  
}
.ant-modal.videoModal .ant-modal-content,
.ant-modal.videoModal form.ant-form.ant-form-horizontal,
.ant-modal.videoModal .ant-modal-body>div,
.ant-modal.videoModal .ant-modal-body,
.ant-modal.videoModal .video_camera-wrap .ant-row.ant-form-item,
.ant-modal.videoModal .ant-col.ant-form-item-control,
.ant-modal.videoModal .ant-form-item-control-input,
.ant-modal.videoModal .ant-form-item-control-input-content,
.ant-modal.videoModal .video_camera-wrap .ant-form-item-control-input-content {
    height: 99% !important;
}
.ant-form-item {
    margin-bottom: 0;
}
.ant-modal.videoModal .video_camera-wrap {
    height: 90%;
    // height: 95%;
}
.ant-modal-wrap .ant-modal-close-x {
    position: absolute;
    top: -8px;
    left: -48px;
}
.modal_footer-btnwrap .grad-btn {
    display: flex;
    align-items: center;
}
.modal_footer-btnwrap .grad-btn .anticon-spin {
    width: 30px;
    height: 30px;
}
.modal_footer-btnwrap .grad-btn .anticon-spin svg {
    font-size: 20px;
}
.outerCard.previewed_cards-wrapper .video_wrapper {
    border-radius: 12px;
}
.welcomeInfo h1 {
   line-height: 1.5;
}
.ant-modal {
    // top: 50%;
    // transform: translateY(-50%);
    padding-bottom: 0;
}
.footer-with-button .grad-btn { 
    text-align: center;
    justify-content: center;
}
.video_camera-wrap .ant-form-item-control-input-content>* {
    min-height: unset !important;
}
.moblie-slider {
    display: none;
}
span.anticon.anticon-play-circle {   
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: flex-end;
    margin: 0px auto 60px;
}
.modalRecord .cENmDr {  
    min-height: calc(100% - 20px); 
    height: calc(100% - 20px); 
}
.modalRecord .jsdGfK {
    top: -40px !important;
    position: absolute;
}
.ant-form-item-control-input-content {
    margin-right: 10px;
}
.ant-modal.gif-modal {
    margin-top: 70px;
    max-width: 470px !important;
    width: 90% !important;
}
.ant-modal.gif-modal .ant-modal-content {
    max-height: 380px;
    height: 100%;
}
.ant-modal.gif-modal .ant-modal-content div#scrollableDiv {
    height: 280px !important;
}
.image-wrapper {
    display: flex;
    &-inner {
        margin-bottom: 10px;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
.image-wrapper img {
    border-radius: 8px;   
    max-width: 100%;
}
.image-wrapper .ant-typography {
    font-size: 20px;
    opacity: 0.5;
    text-align: left;
}
.threeCards .slider-row {
    margin: 10px;
}
.ant-modal.videoModal {
    top: 40px;
}






@media (max-width: 1199px) {
    #comment {
        min-height: 200px;
    }
}

@media (max-width: 991px) {
    .ant-modal {
        top: 40px;
    }
    .cardWrap .ant-card,
    // .outerCard.previewed_cards-wrapper .video_wrapper {
    //     min-height: 400px;
    //     height: 400px !important;
    // }
}

@media (max-width: 767px) {
    .welcomeInfo {
        margin: 30px 0;
    }  
  
}
@media (max-width: 575px) {
    .selfPhoto .ant-upload {
      font-size: 55px !important;
    }
    .desktop-slider {
        display: none;
    }
    .moblie-slider {
        display: block;
    }
    .ant-modal-wrap .ant-modal-close-x {
        left: -40px;
    }
    .footer-with-button .grad-btn {
        font-size: 18px;
    }
    .cardWrap .ant-card,
    .outerCard.previewed_cards-wrapper .video_wrapper {
        min-height: 350px;
        height: 350px !important;
    }
    .logo_warp {
         margin-top: 30px;
    }
    .welcomeInfo h1 {
        font-size: 18px;        
    }
    .grad-btn {
        font-size: 16px;
        min-height: 30px !important;
        padding: 10px;
        display: flex;
        align-items: center;
    }
    
    .addMsg_buttonwarp .grad-btn-text::before {
        width: 20px;
        background-repeat: no-repeat;
        background-position: 50% 48%;
    }
    .welcomeInfo {
        margin: 20px 0;
    }
    // .powered_by img {
    //     vertical-align: middle;
    //     border-style: none;
    //     width: 7%;
    // }  
    // .powered_by {
    //     text-align: center;
    //     margin-top: 10px;
    // }
     .ant-modal.videoModal {
         width: 90% !important;
     }
 }

 @media (max-width: 371px) { 
    .addtext_header button, .addtext_header-btnwrap .ant-upload .ant-upload {
        font-size: 14px;
        padding: 10px;
    }
    .ant-modal-body {
        padding: 20px;
    }

 }
 
 // 02-08-2022 Ends














@keyframes animation-rtpy4v {
    0% {
        transform: translateY( calc(var(--slide-height) * (var(--row) + 1) * -1) );
    }
    100% {
        transform: translateY( calc(var(--slide-height) * (3 - var(--row))) );
    }
}

@media (max-width: 1279px) {
    .subParent {
        max-width: 752px;
    }
    .subParentContent {
        padding: 40px;
    }
}
@media (max-width: 767px) {
    
    .subParentContent {
        padding: 20px;
    }
}
@media (max-width: 575px) {
    .css-1hfw926 {
        // animation-direction: normal !important;
        animation-direction: reverse !important;
    }
    .subParentContent {
        padding: 0 10px;
    }
    .welcomeInfo h4 {
        font-size: 20px;
    }
    .header_info h4 {
        font-size: 20px;
        line-height: 1.3;
    }
    
    .cardWrap.css-1k2aww2 {
        // grid-gap: 15px;
        // grid-template-columns: repeat(1,1fr);
    }
    .css-1hfw926 {
        animation-delay: calc(var(--row) * var(--duration) / 8 * -1s);
    }
    .cardWrap .ant-card {
        &:nth-child(1) {
            --row: 0 !important;
        }
        &:nth-child(2) {
            --row: 1 !important;
        }
        &:nth-child(3) {
            --row: 2 !important;
        }
        &:nth-child(4) {
            --row: 3 !important;
        }
        &:nth-child(5) {
            --row: 4 !important;
        }
        &:nth-child(6) {
            --row: 5 !important;
        }
        &:nth-child(7) {
            --row: 6 !important;
        }
        &:nth-child(8) {
            --row: 7 !important;
        }
    }
    @keyframes animation-rtpy4v {
        0% {
            transform: translateY( calc(var(--slide-height) * (var(--row) + 1) * -1) );
        }
        100% {
            transform: translateY( calc(var(--slide-height) * (7 - var(--row))) );
        }
    }
}


`;

export const ContributionDrawerStyles = createGlobalStyle`

.switch-camera-view__SVGWrapper-sc-13l3hbu-0 {
    display: none;
}

.cardWrap {
    .ant-card-body {
        .card_content {
            &-gifyimgwarap {
                display: flex;
                // align-items: center;
                max-height: 150px;
                min-height: 150px;
                overflow: hidden;
                grid-gap: 10px;
                &>* {
                    flex: 1;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            .card_text {
                margin-top: 5px;
                white-space: normal;
                height: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
            .info {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 24px;
                &_imgwrap {
                    margin-right: 10px;
                    width: 80px;
                    height: 80px;
                    border-radius: 50px;
                    overflow: hidden;
                    box-shadow: 0 0 5px #00000063;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &_textwarp {
                    h4 {
                        font-size: 14px;
                        font-weight: bold;
                        color: #000;
                    }
                }
            }
        }
    }
}
.ant-select-selector {
    height: 200px;
}
.welcomeInfo  h1 {
    font-size: 22px;
    color: #000;
}
.pre_n_view-btnwrap {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .preview_exp {
        &-btnwarp {
            text-align: center;
            margin-left: 10px;
        }
        &-btn {
            // overflow: hidden;
            padding: 0;
            background: none;
            border: none;
            height: 62px;
            display: flex;
            animation: c;
            justify-content: center;
            &:focus, &:hover, &:active {
                border: none;
                box-shadow: none;
                outline: none;
            }
            &::after {
                content: none;
            }
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
            }
        }
    } 
}


.addtext_header {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    button, &-btnwrap .ant-upload .ant-upload {
        background: #0084ff;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        border: none;
        line-height: 1;
        border-radius: 10px;
        padding: 10px 20px;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover, &:focus, &:active {
            background: #6c63ff;
            color: #fff;
            opacity: 1;
        }
        &::before {
            content: '';
            position: static;
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: 8px;
            opacity: 1;
        }
    }
    .ant-btn {
        margin-right: 10px;
        &::before {
            background: url(${smileIcon});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .ant-upload {
        &::before {
            background: url(${cameraIcon});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .ant-form-item {
        margin: 0;
    }
    
}
.btn_boxwrap-inner button{
    height: auto;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    padding: 15px;
    opacity: 1;
    display: flex;
    align-items: center;
}
.btn_boxwrap-inner:not(:last-child) {
    display: flex;
    align-items: center;
   border-bottom: 1px solid #ddd;
}
.video_btnwarp button::before {
    content: '';
    position: static;
    background: url(${videCameraIcon});
    width: 45px;
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin-right: 15px;
    opacity: 1;
}
.text_btnwarp button span {
    line-height: 1;
}
.text_btnwarp button::before {
    content: '';
    position: static;
    background: url(${chatIcon});
    width: 45px;
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin-right: 15px;
    opacity: 1;
}
.ant-modal-content {
    border-radius: 20px;
}
.photo_n_name {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    .ant-form-item-control-input-content {
        margin: 0;
    }
    .up_photo {
        overflow: hidden;
        margin-right: 15px;
        width: 65px;
        height: 65px;
        background: #ddd;
        display: block;
        border-radius: 6px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::before, &::after {
            content: '';
            width: 8px;
            height: 36px;
            background: #fff;
            display: block;
            position: absolute;
        }
        &::after {
            transform: rotate(90deg);
        }
        & *{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
            color: transparent;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    input {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        border: none;
        border-bottom: 3px solid #d8d8d8;
        border-radius: 0;
        &:focus {
            outline: none important;
            box-shadow: none !important;
        }
    }
}
.lottie_files {
    border: none !important;
    input {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        border: none;
        border-bottom: 3px solid #d8d8d8;
        border-radius: 0;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}
.video_camera-wrap {
    // max-width: 370px;
    max-width: 100%;   
    margin: 0 auto;
    .ant-form-item-control-input-content>* {
        min-height: 450px;
    }
}
.footer-with-button {
    max-width: 370px;
    margin: 0 auto;
    .grad-btn {
        font-size: 28px;
    }
}

// Gradient button Css and animation

.addMsg_buttonwarp {
        text-align: center;
      }
      .grad-btn,
      .grad-btn-shadow {
        border-radius: 6px;
        background: linear-gradient(
          130deg,
          #382cad,
          #53a1fd,
          #91d190,
          #382cad,
          #53a1fd,
          #91d190
        );
        transition: all 0.3s ease;
        background-size: 450%;
        animation: grad-btn-anim 25s linear infinite;
        border: none;
        position: relative;
      }
      .grad-btn:hover,
      .grad-btn-shadow:hover {
        transform: scale(0.98);
      }
      .grad-btn {
        margin: 0;
        padding: 16px 30px;
        text-align: center;
        color: #ffffffe6;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: bold;
        height: auto;
        min-height: 62px;
      }
      .grad-btn-text {
        line-height: 1;
        letter-spacing: 0.5px;
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
      .addMsg_buttonwarp .grad-btn-text::before {
        content: "";
        background: url(${addIcon});
        width: 30px;
        background-size: contain;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
        filter: brightness(0) invert(1);
      }
      .grad-btn-shadow {
        position: absolute;
        z-index: -1;
        filter: blur(30px);
        opacity: 0.8;
        left: 0;
        right: 0;
        top: 15px;
        bottom: -15px;
      }
      .modal_footer-btnwrap .grad-btn {
        width: 100%;
      }
      #comment {
          font-size: 24px;
          font-weight: bold;
          color: #3127b0;
          min-height: 300px;
          border: none;
          resize: none;
          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
        &::-webkit-input-placeholder { 
          color: #b2b2b3;
          font-size: 24px;
          font-weight: bold;
        }
        
        &:-ms-input-placeholder {
          color: #b2b2b3;
          font-size: 24px;
          font-weight: bold;
        }
        
        %::placeholder {
          color: #b2b2b3;
          font-size: 24px;
          font-weight: bold;
        }
      }
      @keyframes grad-btn-anim {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }

`;
