import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/settings/rolesAndPermissions/actions";
import {
	getRequest,
	patchRequest,
	postRequest,
	putRequest,
} from "helpers/axiosClient";
import {
	calculatePageNumber,
	getCompanyUrl,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import {
	showDeleteRoleConfirm,
	showNotification,
} from "components/utility/helper";
import { store } from "redux/store";
import { notFoundCode } from "components/constants";
import rolesActions from "redux/settings/rolesAndPermissions/actions";

export function* fetchRoles() {
	const { rolesCurrentPage, rolesPerPage, roleSortKey, roleSortOrder } =
		store.getState().Roles;
	try {
		const { data } = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/roles?page=${rolesCurrentPage}&per_page=${rolesPerPage}&query[sorts][]=${roleSortKey} ${roleSortOrder}`,
			),
		);
		yield put({
			type: actions.FETCH_USER_ROLES_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.FETCH_USER_ROLES_FAILURE });
	}
}

export function* fetchPermissions() {
	try {
		const { data } = yield call(() => getRequest("admin/permissions", {}));
		yield put({
			type: actions.FETCH_PERMISSIONS_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.FETCH_PERMISSIONS_FAILURE });
	}
}

export function* createRole(params) {
	try {
		const { data } = yield call(() =>
			postRequest(`${getCompanyUrl()}/roles`, params.payload),
		);
		yield put({
			type: actions.CREATE_ROLE_SUCCESS,
		});
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: data.data.message,
		});
		yield put({
			type: rolesActions.CHANGE_ROLES_MODAL_VISIBLE,
			payload: false,
		});
		yield put({ type: actions.FETCH_USER_ROLES });
	} catch (error) {
		handleException(error);
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: rolesActions.CHANGE_ROLES_MODAL_VISIBLE,
				payload: false,
			});
		}
		yield put({ type: actions.CREATE_ROLE_FAILURE });
	}
}

export function* updateRole(params) {
	try {
		const { currentRole } = store.getState().Roles;
		const { data } = yield call(() =>
			putRequest(`${getCompanyUrl()}/roles/${currentRole.id}`, params.payload),
		);
		yield put({
			type: actions.UPDATE_ROLE_SUCCESS,
		});
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: data.data.message,
		});
		yield put({
			type: rolesActions.CHANGE_ROLES_MODAL_VISIBLE,
			payload: false,
		});
		yield put({ type: actions.FETCH_USER_ROLES });
	} catch (error) {
		handleException(error);
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: rolesActions.CHANGE_ROLES_MODAL_VISIBLE,
				payload: false,
			});
		}
		yield put({ type: actions.UPDATE_ROLE_FAILURE });
	}
}

export function* deleteRole(params) {
	try {
		const { rolesCurrentPage, rolesPerPage, totalRoles, selectedRolesKeys } =
			store.getState().Roles;
		const { data } = yield call(() =>
			patchRequest(`${getCompanyUrl()}/roles`, params.payload),
		);
		showDeleteRoleConfirm(false);
		yield put({
			type: actions.DELETE_ROLE_SUCCESS,
		});
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: data.data.message,
		});
		if (
			rolesCurrentPage >
			Math.ceil((totalRoles - selectedRolesKeys.length) / rolesPerPage)
		) {
			let pages = calculatePageNumber(
				totalRoles,
				selectedRolesKeys,
				rolesPerPage,
			);
			yield put({
				type: actions.SET_ROLES_PAGINATION,
				payload: pages,
			});
		}
		yield put({ type: actions.FETCH_USER_ROLES });
	} catch (error) {
		handleException(error);
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			showDeleteRoleConfirm(false);
		}
		yield put({ type: actions.DELETE_ROLE_FAILURE });
	}
}

export function* getRoleData(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/roles/${params.id}`),
		);
		yield put({
			type: actions.GET_ROLE_DATA_SUCCESS,
			payload: data.data.role,
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.GET_ROLE_DATA_FAILURE });
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actions.FETCH_USER_ROLES, fetchRoles)]);
	yield all([takeEvery(actions.FETCH_PERMISSIONS, fetchPermissions)]);
	yield all([takeEvery(actions.CREATE_ROLE, createRole)]);
	yield all([takeEvery(actions.UPDATE_ROLE, updateRole)]);
	yield all([takeEvery(actions.DELETE_ROLE, deleteRole)]);
	yield all([takeEvery(actions.GET_ROLE_DATA, getRoleData)]);
}
