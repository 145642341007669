const actions = {
	FETCH_DASHBOARD: "FETCH_DASHBOARD",
	FETCH_DASHBOARD_SUCCESS: "FETCH_DASHBOARD_SUCCESS",
	FETCH_DASHBOARD_FAILURE: "FETCH_DASHBOARD_FAILURE",
	FETCH_DASHBOARD_ENGAGEMENT_CHART: "FETCH_DASHBOARD_ENGAGEMENT_CHART",
	FETCH_DASHBOARD_ENGAGEMENT_CHART_SUCCESS:
		"FETCH_DASHBOARD_ENGAGEMENT_CHART_SUCCESS",
	FETCH_DASHBOARD_ENGAGEMENT_CHART_FAILURE:
		"FETCH_DASHBOARD_ENGAGEMENT_CHART_FAILURE",
	FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA:
		"FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA",
	FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS:
		"FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS",
	FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_FAILURE:
		"FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_FAILURE",
	SET_CHART_FILTER: "SET_CHART_FILTER",
};
export default actions;
