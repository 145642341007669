import React from "react";
import { WelcomePageStyles } from "styles/Shared.style";
import { useSelector } from "react-redux";
import InjectMessage from "components/utility/intlMessages";
function WelcomePage() {
	const { userName, isShowWelcomePage, displayWelcomeText } = useSelector(
		(state) => ({
			...state.Employee,
			...state.Auth,
		}),
	);
	return (
		<WelcomePageStyles
			isShowWelcomePage={isShowWelcomePage}
			displayWelcomeText={displayWelcomeText}
		>
			<div>
				<span className={"wave"} role={"img"} aria-label={"hand"}>
					👋
				</span>
			</div>
			<InjectMessage id={"label.welcome"} />, {userName}
		</WelcomePageStyles>
	);
}
export default WelcomePage;
