import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/adminUser/tasks/actions";
import assetActions from "redux/assets/actions";
import { store, history } from "redux/store";
import {
	getRequest,
	patchRequest,
	postRequest,
	putRequest,
} from "helpers/axiosClient";
import {
	calculatePageNumber,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import { showNotification } from "components/utility/helper";
import { notFoundCode } from "components/constants";

export function* getTasks() {
	try {
		const { tasksPageSize, tasksSortOrder, tasksSortKey, tasksCurrentPage } =
			store.getState().AdminTask;
		const { data } = yield call(() =>
			getRequest(
				`admin/tasks?query[sorts][]=${tasksSortKey} ${tasksSortOrder}&query[sorts][]=id ${tasksSortOrder}&page=${tasksCurrentPage}&per_page=${tasksPageSize}`,
			),
		);
		yield put({
			type: actions.FETCH_ADMIN_TASKS_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_ADMIN_TASKS_FAILURE,
		});
	}
}

export function* getTaskData(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`admin/tasks/${params.payload}`),
		);
		yield put({
			type: actions.GET_ADMIN_TASK_DATA_SUCCESS,
			payload: data.data.task,
		});
		yield put({
			type: assetActions.GET_ASSETS_TOKEN,
			payload: "email",
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_ADMIN_TASK_DATA_FAILURE,
		});
	}
}

export function* deleteTask() {
	try {
		const { selectedTaskList, tasksCurrentPage, totalTasks, tasksPageSize } =
			store.getState().AdminTask;
		const response = yield call(() =>
			patchRequest("admin/tasks", {
				ids: selectedTaskList,
			}),
		);
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.DELETE_ADMIN_TASKS_SUCCESS,
		});
		if (
			tasksCurrentPage >
			Math.ceil((totalTasks - selectedTaskList.length) / tasksPageSize)
		) {
			let pages = calculatePageNumber(
				totalTasks,
				selectedTaskList,
				tasksPageSize,
			);
			yield put({
				type: actions.SET_ADMIN_TASK_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.FETCH_ADMIN_TASKS,
		});
	} catch (error) {
		handleException(error);
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE,
				payload: false,
			});
		}
		yield put({
			type: actions.DELETE_ADMIN_TASKS_FAILURE,
		});
	}
}
export function* createTask(params) {
	try {
		const response = yield call(() =>
			postRequest("admin/tasks", {
				task: { ...params.payload, is_template: true },
			}),
		);
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.CREATE_ADMIN_TASK_SUCCESS,
		});
		history.push({
			pathname: `tasks/${response.data.data.task.id}`,
		});
	} catch (error) {
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.SET_ADMIN_TASK_DRAWER_VISIBLE,
				payload: {
					visible: false,
					formType: "create",
				},
			});
		}
		handleException(error);
		yield put({
			type: actions.CREATE_ADMIN_TASK_FAILURE,
		});
	}
}

export function* updateTask(params) {
	try {
		const { currentTaskId, createTaskLoader } = store.getState().AdminTask;
		const { data } = yield call(() =>
			putRequest(`admin/tasks/${currentTaskId}`, {
				task: params.payload,
			}),
		);
		if (createTaskLoader) {
			showNotification({
				type: "success",
				message: data.data.message,
				description: getLocaleMessages("label.successDescription"),
			});
		}
		yield put({
			type: assetActions.SET_ASSET_FILE_PROGRESS_STATUS,
			payload: 0,
		});
		yield put({
			type: actions.UPDATE_ADMIN_TASK_SUCCESS,
			payload: data.data.task,
		});
	} catch (error) {
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.SET_ADMIN_TASK_DRAWER_VISIBLE,
				payload: {
					visible: false,
					formType: "create",
				},
			});
		}
		handleException(error);
		yield put({
			type: actions.UPDATE_ADMIN_TASK_FAILURE,
		});
	}
}

export function* publishedTask() {
	try {
		const { selectedTaskList, taskActionKey } = store.getState().AdminTask;
		const response = yield call(() =>
			patchRequest("admin/tasks/publish", {
				ids: selectedTaskList,
				publish: taskActionKey === "approve",
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.APPROVE_OR_UNAPPROVE_ADMIN_TASK_SUCCESS,
		});
		yield put({
			type: actions.FETCH_ADMIN_TASKS,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.APPROVE_OR_UNAPPROVE_ADMIN_TASK_FAILURE,
		});
	}
}

export function* removeAttachments(params) {
	try {
		const { data } = yield call(() =>
			patchRequest("admin/tasks/destroy_attachments", {
				ids: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.REMOVE_ATTACHMENTS_SUCCESS,
			payload: params.payload,
		});
		yield put({
			type: assetActions.SET_DELETE_ATTACHMENTS_MODAL_VISIBLE,
			payload: false,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.REMOVE_ATTACHMENTS_FAILURE,
		});
		yield put({
			type: assetActions.SET_DELETE_ATTACHMENTS_MODAL_VISIBLE,
			payload: false,
		});
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_ADMIN_TASKS, getTasks),
		takeEvery(actions.GET_ADMIN_TASK_DATA, getTaskData),
		takeEvery(actions.DELETE_ADMIN_TASKS, deleteTask),
		takeEvery(actions.CREATE_ADMIN_TASK, createTask),
		takeEvery(actions.UPDATE_ADMIN_TASK, updateTask),
		takeEvery(actions.APPROVE_OR_UNAPPROVE_ADMIN_TASK, publishedTask),
		takeEvery(actions.REMOVE_ATTACHMENTS, removeAttachments),
	]);
}
