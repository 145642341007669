const actions = {
	FETCH_USER_TASKS: "FETCH_USER_TASKS",
	FETCH_USER_TASKS_SUCCESS: "FETCH_USER_TASKS_SUCCESS",
	FETCH_USER_TASKS_FAILURE: "FETCH_USER_TASKS_FAILURE",
	SET_TASK_PAGE_SIZE: "SET_TASK_PAGE_SIZE",
	SET_TASK_PAGINATION: "SET_TASK_PAGINATION",
	SET_TASK_SORT_VALUES: "SET_TASK_SORT_VALUES",
	FETCH_USER_TASK_CONTENT: "FETCH_USER_TASK_CONTENT",
	FETCH_USER_TASK_CONTENT_SUCCESS: "FETCH_USER_TASK_CONTENT_SUCCESS",
	FETCH_USER_TASK_CONTENT_FAILURE: "FETCH_USER_TASK_CONTENT_FAILURE",
	UPDATE_USER_TASK: "UPDATE_USER_TASK",
	UPDATE_USER_TASK_SUCCESS: "UPDATE_USER_TASK_SUCCESS",
	UPDATE_USER_TASK_FAILURE: "UPDATE_USER_TASK_FAILURE",
	REMOVE_USER_UPLOADED_FILES_SUCCESS: "REMOVE_USER_UPLOADED_FILES_SUCCESS",
	REMOVE_USER_UPLOADED_FILES_FAILURE: "REMOVE_USER_UPLOADED_FILES_FAILURE",
	FETCH_OVERDUE_TASKS: "FETCH_OVERDUE_TASKS",
	FETCH_OVERDUE_TASKS_SUCCESS: "FETCH_OVERDUE_TASKS_SUCCESS",
	FETCH_OVERDUE_TASKS_FAILURE: "FETCH_OVERDUE_TASKS_FAILURE",
	SET_OVER_DUE_TASK_PAGINATION: "SET_OVER_DUE_TASK_PAGINATION",
	TASK_SET_CONFETTI_VISIBLE: "TASK_SET_CONFETTI_VISIBLE",
	SET_USER_TASK_DETAILS: "SET_USER_TASK_DETAILS",
};

export default actions;
