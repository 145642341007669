import actions from "./actions";
import profileAvatar from "assets/images/profile-avatar.png";

const initState = {
	dashboardLoader: false,
	dashboardData: [],
	quoteContent: null,
	quoteAuthor: null,
	engagementChart: [],
	engagementChartLoader: false,
	dashboardEngagementScoreChart: [],
	engagementScore: 0,
	scoreGrowth: false,
	engagementLoader: false,
	setFilter: null,
};

export default function dashboardReducer(state = initState, action) {
	switch (action.type) {
		case actions.FETCH_DASHBOARD: {
			return {
				...state,
				dashboardLoader: true,
			};
		}
		case actions.FETCH_DASHBOARD_SUCCESS: {
			return {
				...state,
				dashboardData: action.payload.dashboard,
				quoteContent: action.payload.dashboard.quote.content,
				quoteAuthor: action.payload.dashboard.quote.author,
				dashboardLoader: false,
			};
		}
		case actions.FETCH_DASHBOARD_FAILURE: {
			return {
				...state,
				dashboardLoader: false,
				engagementChartLoader: false,
			};
		}
		case actions.FETCH_DASHBOARD_ENGAGEMENT_CHART: {
			return {
				...state,
				engagementChartLoader: true,
			};
		}
		case actions.FETCH_DASHBOARD_ENGAGEMENT_CHART_SUCCESS: {
			return {
				...state,
				engagementChart: [
					action.payload.map((item) => ({
						name: item.name,
						marker: {
							symbol: `url(${item.image_url ? item.image_url : profileAvatar})`,
							width: 45,
							height: 45,
							enabled: true,
						},
						data: [item.data],
					})),
				],
				engagementChartLoader: false,
			};
		}
		case actions.FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA: {
			return {
				...state,
				engagementLoader: true,
			};
		}
		case actions.FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS: {
			return {
				...state,
				dashboardEngagementScoreChart: action.payload.chart,
				engagementScore: action.payload.engagementScore,
				scoreGrowth: action.payload.scoreGrowth,
				engagementLoader: false,
			};
		}
		case actions.FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_FAILURE: {
			return {
				...state,
				engagementLoader: false,
			};
		}
		case actions.SET_CHART_FILTER: {
			return {
				...state,
				setFilter: action.payload,
			};
		}
		default:
			return state;
	}
}
