const actions = {
	CHANGE_JOURNEY_MODAL_STATUS: "CHANGE_JOURNEY_MODAL_STATUS",
	FETCH_JOURNEYS_TEMPLATE: "FETCH_JOURNEYS_TEMPLATE",
	FETCH_JOURNEYS_TEMPLATE_SUCCESS: "FETCH_JOURNEYS_TEMPLATE_SUCCESS",
	FETCH_JOURNEYS_TEMPLATE_FAILURE: "FETCH_JOURNEYS_TEMPLATE_FAILURE",
	SET_LIST_MODULE_DRAWER_VISIBLE: "SET_LIST_MODULE_DRAWER_VISIBLE",
	UPDATE_ADD_MODULE_DRAWER_INFO: "UPDATE_ADD_MODULE_DRAWER_INFO",
	FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS:
		"FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS",
	FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_SUCCESS:
		"FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_SUCCESS",
	FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_FAILURE:
		"FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_FAILURE",
	UPDATE_TEMPLATE_BUILDER_JSON: "UPDATE_TEMPLATE_BUILDER_JSON",
	ADD_TEMPLATE_BUILDER_MODULE: "ADD_TEMPLATE_BUILDER_MODULE",
	UPDATE_CURRENT_JOURNEY_TEMPLATE_ID: "UPDATE_CURRENT_JOURNEY_TEMPLATE_ID",
	UPDATE_CURRENT_NODE_DATA: "UPDATE_CURRENT_NODE_DATA",
	ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_SUCCESS:
		"ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_SUCCESS",
	ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_FAILURE:
		"ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_FAILURE",
	FETCH_ASSETS_TEMPLATE: "FETCH_ASSETS_TEMPLATE",
	FETCH_ASSETS_TEMPLATE_SUCCESS: "FETCH_ASSETS_TEMPLATE_SUCCESS",
	CREATE_NEW_JOURNEY_TEMPLATE: "CREATE_NEW_JOURNEY_TEMPLATE",
	FETCH_TIMEZONE: "FETCH_TIMEZONE",
	FETCH_TIMEZONE_SUCCESS: "FETCH_TIMEZONE_SUCCESS",
	UPDATE_SELECTED_JOURNEY_TEMPLATE: "UPDATE_SELECTED_JOURNEY_TEMPLATE",
	SET_LIST_TEMPLATE_PAGE_SIZE: "SET_LIST_TEMPLATE_PAGE_SIZE",
	SET_JOURNEY_TEMPLATE_PAGINATION: "SET_JOURNEY_TEMPLATE_PAGINATION",
	SET_JOURNEY_TEMPLATE_SORT_VALUES: "SET_JOURNEY_TEMPLATE_SORT_VALUES",
	ON_SEARCH_JOURNEYS_TEMPLATE: "ON_SEARCH_JOURNEYS_TEMPLATE",
	FETCH_ASSETS_TEMPLATE_FAILURE: "FETCH_ASSETS_TEMPLATE_FAILURE",
	SET_CREATED_MODULE: "SET_CREATED_MODULE",
	SET_ASSET_PAGE: "SET_ASSET_PAGE",
	SET_ASSET_SEARCH: "SET_ASSET_SEARCH",
	UPDATE_TEMPLATE_BUILDER_EVENTS: "UPDATE_TEMPLATE_BUILDER_EVENTS",
	UPDATE_TEMPLATE_BUILDER_MODULE: "UPDATE_TEMPLATE_BUILDER_MODULE",
	UPDATE_TEMPLATE_CURRENT_MODULE_ACTION:
		"UPDATE_TEMPLATE_CURRENT_MODULE_ACTION",
	UPDATE_SELECTED_ASSET: "UPDATE_SELECTED_ASSET",
	APPROVE_JOURNEY_CONFIRM_MODAL_VISIBLE:
		"APPROVE_JOURNEY_CONFIRM_MODAL_VISIBLE",
	CREATE_NEW_JOURNEY_TEMPLATE_SUCCESS: "CREATE_NEW_JOURNEY_TEMPLATE_SUCCESS",
	CREATE_NEW_JOURNEY_TEMPLATE_FAILURE: "CREATE_NEW_JOURNEY_TEMPLATE_FAILURE",
	APPROVE_OR_UNAPPROVE_JOURNEY: "APPROVE_OR_UNAPPROVE_JOURNEY",
	APPROVE_OR_UNAPPROVE_JOURNEY_SUCCESS: "APPROVE_OR_UNAPPROVE_JOURNEY_SUCCESS",
	APPROVE_OR_UNAPPROVE_JOURNEY_FAILURE: "APPROVE_OR_UNAPPROVE_JOURNEY_FAILURE",
	DELETE_JOURNEY_TEMPLATE: "DELETE_JOURNEY_TEMPLATE",
	DELETE_JOURNEY_TEMPLATE_SUCCESS: "DELETE_JOURNEY_TEMPLATE_SUCCESS",
	DELETE_JOURNEY_TEMPLATE_FAILURE: "DELETE_JOURNEY_TEMPLATE_FAILURE",
	JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE: "JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE",
	UPDATE_MODULE_DELETE_CONFIRM: "UPDATE_MODULE_DELETE_CONFIRM",
	SET_MODULE_DELETE_DATA: "SET_MODULE_DELETE_DATA",
	DELETE_MODULES_TEMPLATE: "DELETE_MODULES_TEMPLATE",
	DELETE_MODULES_TEMPLATE_SUCCESS: "DELETE_MODULES_TEMPLATE_SUCCESS",
	DELETE_MODULES_TEMPLATE_FAILURE: "DELETE_MODULES_TEMPLATE_FAILURE",
	UPDATE_TRANSFORM: "UPDATE_TRANSFORM",
	FETCH_JOURNEY_TEMPLATE_TASKS: "FETCH_JOURNEY_TEMPLATE_TASKS",
	FETCH_JOURNEY_TEMPLATE_TASKS_SUCCESS: "FETCH_JOURNEY_TEMPLATE_TASKS_SUCCESS",
	FETCH_JOURNEY_TEMPLATE_TASKS_FAILURE: "FETCH_JOURNEY_TEMPLATE_TASKS_FAILURE",
	UPDATE_TASK_TEMPLATE_DUES: "UPDATE_TASK_TEMPLATE_DUES",
	SET_TASK_PAGE: "SET_TASK_PAGE",
	SET_TASK_SEARCH: "SET_TASK_SEARCH",
	SET_REMINDER_TEMPLATE_OPTIONS: "SET_REMINDER_TEMPLATE_OPTIONS",
	SET_REMINDER_TEMPLATE_DEFAULTS: "SET_REMINDER_TEMPLATE_DEFAULTS",
	FETCH_JOURNEY_MEETINGS: "FETCH_JOURNEY_MEETINGS",
	SET_MEETINGS_SEARCH: "SET_MEETINGS_SEARCH",
	FETCH_JOURNEY_MEETINGS_SUCCESS: "FETCH_JOURNEY_MEETINGS_SUCCESS",
	FETCH_JOURNEY_MEETINGS_FAILURE: "FETCH_JOURNEY_MEETINGS_FAILURE",
	UPDATE_MEETINGS_TEMPLATE_REMINDER: "UPDATE_MEETINGS_TEMPLATE_REMINDER",
	ADD_JOURNEY_MEETING_TEMPLATE: "ADD_JOURNEY_MEETING_TEMPLATE",
	ADD_JOURNEY_MEETING_TEMPLATE_FAILURE: "ADD_JOURNEY_MEETING_TEMPLATE_FAILURE",
	UPDATE_JOURNEY_MEETING_TEMPLATE: "UPDATE_JOURNEY_MEETING_TEMPLATE",
	UPDATE_JOURNEY_MEETING_TEMPLATE_FAILURE:
		"UPDATE_JOURNEY_MEETING_TEMPLATE_FAILURE",
	SET_EDIT_JOURNEY_MODAL: "SET_EDIT_JOURNEY_MODAL",
	UPDATE_JOURNEY_TEMPLATE: "UPDATE_JOURNEY_TEMPLATE",
	UPDATE_JOURNEY_TEMPLATE_SUCCESS: "UPDATE_JOURNEY_TEMPLATE_SUCCESS",
	UPDATE_JOURNEY_TEMPLATE_FAILURE: "UPDATE_JOURNEY_TEMPLATE_FAILURE",
	SET_ADMIN_JOURNEY_APPROVE_UNAPPROVE_KEY:
		"SET_ADMIN_JOURNEY_APPROVE_UNAPPROVE_KEY",
	SET_APPROVAL_MODAL_VISIBLE: "SET_APPROVAL_MODAL_VISIBLE",
};
export default actions;
