import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/adminUser/templates/actions";
import { store, history } from "redux/store";
import {
	getRequest,
	patchRequest,
	postRequest,
	putRequest,
} from "helpers/axiosClient";
import {
	calculatePageNumber,
	getLocaleMessages,
	handleException,
} from "redux/helper";
import { showNotification } from "components/utility/helper";

export function* getEmailTemplateList() {
	try {
		const {
			emailTemplatePageSize,
			emailTemplateSortOrder,
			emailTemplateSortKey,
			emailTemplateCurrentPage,
		} = store.getState().Templates;
		const response = yield call(() =>
			getRequest(
				`admin/assets?query[sorts][]=${emailTemplateSortKey} ${emailTemplateSortOrder}&query[sorts][]=id ${emailTemplateSortOrder}&page=${emailTemplateCurrentPage}&per_page=${emailTemplatePageSize}`,
			),
		);
		yield put({
			type: actions.GET_EMAIL_TEMPLATE_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_EMAIL_TEMPLATE_LIST_FAILURE,
		});
	}
}

export function* deleteEmailTemplateList() {
	try {
		const {
			selectedEmailTemplateList,
			emailTemplateCurrentPage,
			emailTemplateData,
			emailTemplatePageSize,
		} = store.getState().Templates;
		const response = yield call(() =>
			patchRequest("admin/assets", {
				ids: selectedEmailTemplateList,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.DELETE_EMAIL_TEMPLATE_LIST_SUCCESS,
		});
		if (
			emailTemplateCurrentPage >
			Math.ceil(
				(emailTemplateData - selectedEmailTemplateList.length) /
					emailTemplatePageSize,
			)
		) {
			let pages = calculatePageNumber(
				emailTemplateData,
				selectedEmailTemplateList,
				emailTemplatePageSize,
			);
			yield put({
				type: actions.SET_EMAIL_TEMPLATE_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.GET_EMAIL_TEMPLATE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_EMAIL_TEMPLATE_LIST_FAILURE,
		});
	}
}

export function* createNewEmailTemplate(params) {
	try {
		const response = yield call(() =>
			postRequest("admin/assets", {
				asset: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.CREATE_NEW_EMAIL_TEMPLATE_SUCCESS,
			payload: response.data.data,
		});
		history.push(`templates/${response.data.data.asset.id}/email`);
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_EMAIL_TEMPLATE_FAILURE,
		});
	}
}

export function* getEmailTemplateData(params) {
	try {
		const response = yield call(() =>
			getRequest(`admin/assets/${params.payload}`),
		);
		yield put({
			type: actions.GET_EMAIL_TEMPLATE_DATA_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_EMAIL_TEMPLATE_DATA_FAILURE,
		});
	}
}

export function* updateEmailTemplateContent(params) {
	try {
		const { templateID, formLoader } = store.getState().Templates;
		const response = yield call(() =>
			patchRequest(`admin/assets/${templateID}`, {
				asset: params.payload,
			}),
		);
		if (formLoader) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		yield put({
			type: actions.UPDATE_EMAIL_TEMPLATE_CONTENT_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_EMAIL_TEMPLATE_CONTENT_FAILURE,
		});
	}
}

export function* approveEmailTemplate() {
	try {
		const { selectedEmailTemplateList } = store.getState().Templates;
		const response = yield call(() =>
			patchRequest("admin/assets/publish", {
				ids: selectedEmailTemplateList,
				publish: true,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.APPROVE_EMAIL_TEMPLATE_SUCCESS,
		});
		yield put({
			type: actions.GET_EMAIL_TEMPLATE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.APPROVE_EMAIL_TEMPLATE_FAILURE,
		});
	}
}

export function* unApproveEmailTemplate() {
	try {
		const { selectedEmailTemplateList } = store.getState().Templates;
		const response = yield call(() =>
			patchRequest("admin/assets/publish", {
				ids: selectedEmailTemplateList,
				publish: false,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.UNAPPROVE_EMAIL_TEMPLATE_SUCCESS,
		});
		yield put({
			type: actions.GET_EMAIL_TEMPLATE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UNAPPROVE_EMAIL_TEMPLATE_FAILURE,
		});
	}
}

export function* getPagesTemplateList() {
	try {
		const {
			pagesTemplatePageSize,
			pagesTemplateSortOrder,
			pagesTemplateSortKey,
			pagesTemplateCurrentPage,
		} = store.getState().Templates;
		const response = yield call(() =>
			getRequest(
				`admin/pages?query[sorts][]=${pagesTemplateSortKey} ${pagesTemplateSortOrder}&query[sorts][]=id ${pagesTemplateSortOrder}&page=${pagesTemplateCurrentPage}&per_page=${pagesTemplatePageSize}`,
			),
		);
		yield put({
			type: actions.GET_PAGES_TEMPLATE_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_PAGES_TEMPLATE_LIST_FAILURE,
		});
	}
}

export function* deletePagesTemplateList() {
	try {
		const {
			selectedPagesTemplateList,
			pagesTemplateCurrentPage,
			pagesTemplateData,
			emailTemplatePageSize,
		} = store.getState().Templates;
		const response = yield call(() =>
			putRequest("admin/pages", {
				ids: selectedPagesTemplateList,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.DELETE_PAGES_TEMPLATE_LIST_SUCCESS,
		});
		if (
			pagesTemplateCurrentPage >
			Math.ceil(
				(pagesTemplateData - selectedPagesTemplateList.length) /
					emailTemplatePageSize,
			)
		) {
			let pages = calculatePageNumber(
				pagesTemplateData,
				selectedPagesTemplateList,
				emailTemplatePageSize,
			);
			yield put({
				type: actions.SET_PAGES_TEMPLATE_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.GET_PAGES_TEMPLATE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_PAGES_TEMPLATE_LIST_FAILURE,
		});
	}
}

export function* createNewPageTemplate(params) {
	try {
		const response = yield call(() =>
			postRequest("admin/pages", {
				page: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.CREATE_NEW_PAGE_TEMPLATE_SUCCESS,
			payload: response.data.data,
		});
		history.push(`templates/${response.data.data.page.id}/page`);
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_PAGE_TEMPLATE_FAILURE,
		});
	}
}

export function* getPageTemplateData(params) {
	try {
		const response = yield call(() =>
			getRequest(`admin/pages/${params.payload}`),
		);
		yield put({
			type: actions.GET_PAGE_TEMPLATE_DATA_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_PAGE_TEMPLATE_DATA_FAILURE,
		});
	}
}

export function* updatePageTemplateContent(params) {
	try {
		const { templateID, formLoader } = store.getState().Templates;
		const response = yield call(() =>
			patchRequest(`admin/pages/${templateID}`, {
				page: params.payload,
			}),
		);
		if (formLoader) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		yield put({
			type: actions.UPDATE_PAGE_TEMPLATE_CONTENT_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_PAGE_TEMPLATE_CONTENT_FAILURE,
		});
	}
}

export function* approvePagesTemplate() {
	try {
		const { selectedPagesTemplateList } = store.getState().Templates;
		const response = yield call(() =>
			patchRequest("admin/pages/publish", {
				ids: selectedPagesTemplateList,
				publish: true,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.APPROVE_PAGES_TEMPLATE_SUCCESS,
		});
		yield put({
			type: actions.GET_PAGES_TEMPLATE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.APPROVE_PAGES_TEMPLATE_FAILURE,
		});
	}
}

export function* unApprovePagesTemplate() {
	try {
		const { selectedPagesTemplateList } = store.getState().Templates;
		const response = yield call(() =>
			patchRequest("admin/pages/publish", {
				ids: selectedPagesTemplateList,
				publish: false,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.UNAPPROVE_PAGES_TEMPLATE_SUCCESS,
		});
		yield put({
			type: actions.GET_PAGES_TEMPLATE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UNAPPROVE_PAGES_TEMPLATE_FAILURE,
		});
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_EMAIL_TEMPLATE_LIST, getEmailTemplateList),
		takeEvery(actions.DELETE_EMAIL_TEMPLATE_LIST, deleteEmailTemplateList),
		takeEvery(actions.CREATE_NEW_EMAIL_TEMPLATE, createNewEmailTemplate),
		takeEvery(actions.GET_EMAIL_TEMPLATE_DATA, getEmailTemplateData),
		takeEvery(
			actions.UPDATE_EMAIL_TEMPLATE_CONTENT,
			updateEmailTemplateContent,
		),
		takeEvery(actions.APPROVE_EMAIL_TEMPLATE, approveEmailTemplate),
		takeEvery(actions.UNAPPROVE_EMAIL_TEMPLATE, unApproveEmailTemplate),
		takeEvery(actions.GET_PAGES_TEMPLATE_LIST, getPagesTemplateList),
		takeEvery(actions.DELETE_PAGES_TEMPLATE_LIST, deletePagesTemplateList),
		takeEvery(actions.CREATE_NEW_PAGE_TEMPLATE, createNewPageTemplate),
		takeEvery(actions.GET_PAGE_TEMPLATE_DATA, getPageTemplateData),
		takeEvery(actions.UPDATE_PAGE_TEMPLATE_CONTENT, updatePageTemplateContent),
		takeEvery(actions.APPROVE_PAGES_TEMPLATE, approvePagesTemplate),
		takeEvery(actions.UNAPPROVE_PAGES_TEMPLATE, unApprovePagesTemplate),
	]);
}
