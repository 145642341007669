const actions = {
	SET_DAYS_FILTER: "SET_DAYS_FILTER",
	FETCH_ON_BOARD_CHART_DATA: "FETCH_ON_BOARD_CHART_DATA",
	FETCH_ON_BOARD_CHART_DATA_SUCCESS: "FETCH_ON_BOARD_CHART_DATA_SUCCESS",
	FETCH_ON_BOARD_CHART_DATA_FAILURE: "FETCH_ON_BOARD_CHART_DATA_FAILURE",
	FETCH_ENGAGEMENT_SCORE_CHART_DATA: "FETCH_ENGAGEMENT_SCORE_CHART_DATA",
	FETCH_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS:
		"FETCH_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS",
	FETCH_ENGAGEMENT_SCORE_CHART_DATA_FAILURE:
		"FETCH_ENGAGEMENT_SCORE_CHART_DATA_FAILURE",
	CHART_LOADER: "CHART_LOADER",
	FETCH_MOST_ENGAGEMENT_EMAIL: "FETCH_MOST_ENGAGEMENT_EMAIL",
	FETCH_MOST_ENGAGEMENT_EMAIL_SUCCESS: "FETCH_MOST_ENGAGEMENT_EMAIL_SUCCESS",
	FETCH_MOST_ENGAGEMENT_EMAIL_FAILURE: "FETCH_MOST_ENGAGEMENT_EMAIL_FAILURE",
	FETCH_MOST_ENGAGEMENT_PAGE: "FETCH_MOST_ENGAGEMENT_PAGE",
	FETCH_MOST_ENGAGEMENT_PAGE_SUCCESS: "FETCH_MOST_ENGAGEMENT_PAGE_SUCCESS",
	FETCH_MOST_ENGAGEMENT_PAGE_FAILURE: "FETCH_MOST_ENGAGEMENT_PAGE_FAILURE",
	SET_EMAIL_LOADER: "SET_EMAIL_LOADER",
	SET_PAGE_LOADER: "SET_PAGE_LOADER",
	GET_DAYS_RANGE: "GET_DAYS_RANGE",
};

export default actions;
