import asyncComponent from "helpers/asyncComponent";

export const userRoutes = [
	{
		path: "dashboard",
		component: asyncComponent(() => import("containers/Dashboard/Dashboard")),
	},
	{
		path: "employees",
		component: asyncComponent(() => import("containers/Employees/Imports")),
	},
	{
		path: "employees/list/:id",
		component: asyncComponent(() => import("containers/Employees/Employees")),
	},
	{
		path: "list/:importId/employees/:employeeId",
		component: asyncComponent(() =>
			import("containers/Employees/EmployeeView"),
		),
	},
	{
		path: "list/employees/:employeeId",
		component: asyncComponent(() =>
			import("containers/Employees/EmployeeView"),
		),
	},
	{
		path: "employees/:employeeId",
		component: asyncComponent(() =>
			import("containers/Employees/EmployeeView"),
		),
	},
	{
		path: "journeys",
		component: asyncComponent(() => import("containers/Journeys/Journeys")),
	},
	{
		path: "journeys/:id",
		component: asyncComponent(() =>
			import("containers/Journeys/JourneyBuilder"),
		),
	},
	{
		path: "assets",
		component: asyncComponent(() => import("containers/Assets/Assets")),
	},
	{
		path: "settings",
		component: asyncComponent(() => import("containers/Settings/Settings")),
	},
	{
		path: "gmail-integration",
		component: asyncComponent(() =>
			import("components/Settings/EmailComps/GmailIntegration"),
		),
	},
	{
		path: "assets/:taskID/tasks/builder",
		component: asyncComponent(() => import("containers/Assets/TaskBuilder")),
	},
	{
		path: "assets/:emailID/:assetType",
		component: asyncComponent(() => import("containers/Assets/EmailBuilder")),
	},
	{
		path: "portal",
		component: asyncComponent(() => import("containers/Portal/Portal")),
	},
	{
		path: "portal/:portalID/:portal_type",
		component: asyncComponent(() => import("containers/Portal/PortalBuilder")),
	},
	{
		path: "list/:importId/employees/:employeeId/:taskId/:type",
		component: asyncComponent(() =>
			import("components/Employees/TaskDetails/Details"),
		),
	},
	{
		path: "list/employees/:employeeId/:taskId/:type",
		component: asyncComponent(() =>
			import("components/Employees/TaskDetails/Details"),
		),
	},
	{
		path: "meetings",
		component: asyncComponent(() => import("containers/Meetings/Meetings")),
	},
	{
		path: "tasks",
		component: asyncComponent(() => import("containers/Tasks/TaskTable")),
	},
	{
		path: "tasks/:journeyEventId",
		component: asyncComponent(() => import("containers/Tasks/Task")),
	},
	{
		path: "reports",
		component: asyncComponent(() => import("containers/Reports/Reports")),
	},
	{
		path: "moments_that_matter",
		component: asyncComponent(() =>
			import("containers/MomentsThatMatter/MomentsThatMatter"),
		),
	},
	{
		path: "moments_that_matter/:employeeId/:page_token/:assetType",
		component: asyncComponent(() =>
			import("containers/MomentsThatMatter/RenderEmpPage"),
		),
	},
	{
		path: "*",
		component: asyncComponent(() => import("containers/Pages/NotFound")),
	},
];

export const employeeRoutes = [
	{
		path: ":employeeId/dashboard",
		component: asyncComponent(() => import("containers/Employee/Employee")),
	},
	{
		path: ":employeeId/task/:journeyEventId",
		component: asyncComponent(() => import("containers/Employee/Task")),
	},
	{
		path: "landing/:page_token",
		component: asyncComponent(() => import("containers/Employee/LandingPage")),
	},
	{
		path: ":employeeId/get-directions",
		component: asyncComponent(() => import("containers/Employee/DirectionMap")),
	},
	{
		path: "*",
		component: asyncComponent(() => import("containers/Pages/NotFound")),
	},
];
