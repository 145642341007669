const variables = {
	SIDER_BACKGROUND_COLOR: "#002140",
	WHITE_COLOR: "#ffffff",
	PRIMARY_COLOR: "#6c63ff",
	ICON_COLOR: "#a6b1bc",
	BORDER_RADIUS: "4px",
	PLACEHOLDER_COLOR: "rgba(0, 0, 0, 0.25)",
	BASE_FONT_SIZE: "14px",
	MODAL_FONT_SIZE: "12px",
	CONTENT_FONT_SIZE: "16px",
	TITLE_FONT_SIZE: "18px",
	BORDER_COLOR: "rgba(0, 0, 0, 0.15)",
	SQ_SPACING_BASE: "20px",
	SQ_SPACING_SMALL: "10px",
	SQ_SPACING_XS: "5px",
	SQ_SPACING_LARGE: "40px",
	SQ_SPACING_MEDIUM: "15px",
	SQ_SPACING_PADDING: "25px",
	SQ_SPACING_PADDING_LARGE: "100px",
	MENU_BORDER_COLOR: "#d9d9d9",
	PROGRESS_TRAIL_COLOR: "#c0f0fb",
	SUCCESS_COLOR: "#52c41a",
	DANGER_COLOR: "#f5222d",
	WARNING_COLOR: "#faad14",
	DEFAULT_PROGRESS_COLOR: "#ffe318",
	PROGRESS_PRIMARY_COLOR: "#1890ff",
	SUCCESS_COLOR_SECONDARY: "#b7eb8f",
	SUCCESS_COLOR_LIGHT: "#f6ffed",
	DANGER_COLOR_SECONDARY: "#ffa39e",
	DANGER_COLOR_LIGHT: "#fff1f0",
	TEXT_COLOR_DARK: "rgba(13, 13, 13, 0.85)",
	PAGE_HEADER_HEIGHT: "220px",
	PRIMARY_HOVER_COLOR: "#968cff",
	BACKGROUND_COLOR: "#f5f5f5",
	EMAIL_BACKGROUND_COLOR: "#f0f2f5",
	HERDER_BG_COLOR: "#fafafa",
	TEXT_COLOR_LIGHT: "rgba(0, 0, 0, 0.65)",
	TEXT_FOOTER_COLOR: "rgb(45 34 53 / 67%)",
	ACTION_TEXT_COLOR: "#875887",
	BOX_SHADOW_COLOR: "rgba(230, 230, 230, 0.5)",
	DRAWER_WIDTH_MD: 500,
	DRAWER_WIDTH_LG: 510,
	DRAWER_WIDTH_XL: 540,
	DRAWER_WIDTH_XXL: 572,
	DRAWER_WIDTH_XXXL: 640,
	LIST_HEADER_COLOR: "rgba(13, 13, 13, 0.85)",
	BUTTON_DANGER_COLOR: "#ff4d4f",
	BUTTON_DANGER_COLOR_HOVER: "#ff7875",
	PREVIEW_BG_COLOR: "#1989fe",
	BORDER_RADIUS_BASE: "4px",
	TEXT_COLOR: "#524f4f",
	CARD_BORDER: "#cbc8c8",
	QUOTES_COLOR: "#9d9898",
	ICON_UNAPPROVED_BG_COLOR: "#a4ea00",
	MODAL_WIDTH: 580,
	MODAL_WIDTH_LG: 800,
	TABLE_WIDTH: 800,
	TABLE_SCROLL_X: 900,
	MODAL_BORDER_COLOR: "#f0f0f0",
	BLACK_COLOR: "#333333",
	SIDEBAR_ICON_COLOR: "#9ca8b5",
	TEXT_COLOR_SECONDARY: "#585858",
	SHADOW_SECONDARY: "rgba(106, 104, 104, 0.5)",
	SHADOW_MEDIUM: "rgba(146, 146, 146, 0.5)",
	GRAY_COLOR_LIGHT: "#d8d8d8",
	SHADOW_LIGHT: "rgba(0,0,0,0.2)",
	SPACE_15: 15,
	SPACE_20: 20,
	SQ_SPACING_25: "25px",
	COLOR_TRANSPARENT: "rgb(175, 185, 206)",
	CHAT_WIDGET_SPACE: "55px",
	DISABLED_COLOR: "rgb(0, 0, 0, 0.55)",
	LABEL_COLOR: "rgba(0, 0, 0, 0.85)",
	BG_COLOR_SECONDARY: "#f3f0ff",
	USER_POPOVER_WIDTH: "240px",
	UP_RIGHT_TRIANGLE: "#00b16a",
	CHART_GRID_LINE: "#bad7ff",
	REPORTS_GRID_LINE: "#e9e9e9",
	LINE_CHART: "#2fc25b",
	VIEW_COUNTS_COLOR: "rgba(86, 80, 80, 0.85)",
	DANGER_RED: "#FF1713",
};

export default variables;
