import { initialBuilderHeight } from "components/constants";
import variables from "styles/variables";

export function getDirection() {
	let direction = "ltr";
	if (typeof window !== "undefined") {
		direction = document.getElementsByTagName("html")[0].getAttribute("dir");
	}
	return direction;
}
export function getMediaBgSize() {
	return `background: linear-gradient(
        90deg,
        ${variables.WHITE_COLOR} 5px,
        transparent 1%
      )
      center,
    linear-gradient(${variables.WHITE_COLOR} 4px, transparent 1%) center,
    ${variables.BORDER_COLOR};
    background: -ms-linear-gradient(
        90deg,
        ${variables.WHITE_COLOR} 5px,
        transparent 1%
      )
      center,
    linear-gradient(${variables.WHITE_COLOR} 4px, transparent 1%) center,
    ${variables.BORDER_COLOR};
  background-size: 6px 5px;`;
}
export function getMediaLeftSpacing() {
	return `@media (min-width: 1600px) {
    margin-left: 40px;
  }
  @media (min-width: 1920px) {
    margin-left: 80px;
  }
  @media (min-width: 2560px) {
    margin-left: 100px;
  }`;
}
export function getMediaWidthSpacing() {
	return `@media (min-width: 1600px) {
    width: 400px;
  }
  @media (min-width: 1920px) {
    width: 450px;
  }`;
}
export function getMediaSkeletonWidth() {
	return `@media (min-width: 1600px) {
    max-width: 776px;
  }
  @media (min-width: 1920px) {
    max-width: 854px;
  }`;
}
export function getCursor() {
	return ` cursor: move !important; /* fallback if grab cursor is unsupported */
      cursor: grab !important;
      cursor: -moz-grab !important;
      cursor: -webkit-grab !important;`;
}

export function setBuilderInitialHeight(builderHeight) {
	return builderHeight > initialBuilderHeight
		? initialBuilderHeight
		: builderHeight;
}
export function hexToRgbA(hex) {
	let c;
	c = hex.substring(1).split("");
	if (c.length === 3) {
		c = [c[0], c[0], c[1], c[1], c[2], c[2]];
	}
	c = `0x${c.join("")}`;
	return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},1)`;
	// throw new Error('Bad Hex');
}

export function colorSelection(color) {
	let rgbValues = color
		.replace("rgba(", "")
		.replace("rgb(", "")
		.replace(")", "")
		.split(",");
	if (rgbValues.length >= 3) {
		let colorContrast = Math.round(
			(parseInt(rgbValues[0]) * 299 +
				parseInt(rgbValues[1]) * 587 +
				parseInt(rgbValues[2]) * 114) /
				1000,
		);
		let textColor = parseInt(colorContrast) > 125 ? "#2D3138" : "#FFFFFF";

		if (rgbValues[3] !== undefined && colorContrast < 125) {
			if (parseFloat(rgbValues[3]) <= 0.5) {
				textColor = "#2D3138";
			}
		}
		return textColor;
	} else {
		let backgroundRgba = hexToRgbA(color);
		rgbValues = backgroundRgba
			.replace("rgba(", "")
			.replace("rgb(", "")
			.replace(")", "")
			.split(",");
		if (rgbValues.length) {
			let colorContrast = Math.round(
				(parseInt(rgbValues[0]) * 299 +
					parseInt(rgbValues[1]) * 587 +
					parseInt(rgbValues[2]) * 114) /
					1000,
			);
			return colorContrast > 125 ? "#2D3138" : "#FFFFFF";
		}
	}
}

export function getIconStyle(color) {
	if (color.match("gradient") || colorSelection(color) === "#FFFFFF") {
		return true;
	}
	return false;
}

export function disabledStyles() {
	return `background: ${variables.HERDER_BG_COLOR};
              color: ${variables.DISABLED_COLOR};
              cursor: not-allowed;`;
}

export function mapStyles() {
	return `.gm-style-mtc {
       label {
          vertical-align: bottom !important;
          line-height: 0.8;
       }
       span {
          img {
              height: 20px !important;
          }
       }
    }`;
}
