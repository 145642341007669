import { cloneDeep } from "lodash";
import actions from "./actions";
import {
	chartData,
	defaultSortKey,
	defaultSortOrder,
	moduleActionTypeCreate,
} from "components/constants";
import { isValid } from "redux/helper";

const initState = {
	isJourneyModalVisible: false,
	journeys: [],
	journeysInitialLoader: false,
	journeyLoader: false,
	totalJourneys: null,
	addModuleVisible: false,
	addEmailModuleVisible: false,
	addSmsModuleVisible: false,
	journeyBuilderData: {},
	journeyBuilderJSON: cloneDeep(chartData),
	journeyBuilderInitialLoader: false,
	currentJourneyID: null,
	currentNode: null,
	addModuleLoader: false,
	addModuleType: null,
	assets: [],
	timeZone: [],
	CurrentPage: 1,
	listSearchKey: "",
	listSortKey: defaultSortKey,
	listSortValue: defaultSortOrder,
	listPerPage: 10,
	isSelectAllJourneys: false,
	selectedJourneyKeys: [],
	journeyApprovalModalVisible: false,
	journeyModalLoader: false,
	assetsListLoader: false,
	createdModule: {},
	assetsInitialListLoader: false,
	assetPage: 1,
	taskPage: 1,
	assetSearchKey: "",
	taskSearchKey: "",
	moduleOptionsVisible: false,
	moduleActionType: moduleActionTypeCreate,
	editModuleData: {},
	editModuleType: null,
	selectedAsset: {},
	assetSearchLoader: false,
	moduleDeleteConfirmVisible: false,
	deleteModule: {},
	deleteModuleLoader: false,
	isShowUpArrow: false,
	roles: [],
	taskInitialLoader: false,
	tasks: [],
	taskDues: [],
	taskListLoader: false,
	taskSearchLoader: false,
	beforeDueDates: [],
	afterDueDates: [],
	reminders: {},
	meetingReminders: [],
	editJourneyVisible: false,
	journeyApproveKey: "",
	isPublished: false,
};

export default function journeyReducer(state = initState, action) {
	switch (action.type) {
		case actions.CHANGE_JOURNEY_MODAL_STATUS:
			return {
				...state,
				isJourneyModalVisible: action.payload,
			};
		case actions.FETCH_JOURNEYS_TEMPLATE:
			return {
				...state,
				journeyLoader: true,
				journeysInitialLoader: action.isInitialFetch,
			};
		case actions.FETCH_JOURNEYS_TEMPLATE_SUCCESS:
			return {
				...state,
				journeyLoader: false,
				journeysInitialLoader: false,
				journeys: action.payload.journeys,
				totalJourneys: action.payload.total_records,
			};
		case actions.FETCH_JOURNEYS_TEMPLATE_FAILURE:
			return {
				...state,
				journeyLoader: false,
				journeysInitialLoader: false,
			};
		case actions.SET_LIST_MODULE_DRAWER_VISIBLE:
			return {
				...state,
				addModuleVisible: action.payload,
				selectedAsset: !action.payload && {},
			};
		case actions.UPDATE_ADD_MODULE_DRAWER_INFO:
			return {
				...state,
				addEmailModuleVisible: action.payload,
				addModuleType: action.moduleType || null,
				assetPage: action.page,
			};
		case actions.FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_SUCCESS:
			return {
				...state,
				journeyBuilderData: action.payload,
				journeyBuilderInitialLoader: false,
				isPublished: action.payload.published,
			};
		case actions.FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS_FAILURE:
			return {
				...state,
				journeyBuilderInitialLoader: false,
			};
		case actions.FETCH_JOURNEY_TEMPLATE_BUILDER_DETAILS:
			return {
				...state,
				journeyBuilderInitialLoader: true,
			};
		case actions.UPDATE_TEMPLATE_BUILDER_JSON:
			return {
				...state,
				journeyBuilderJSON: cloneDeep(action.payload.chartData),
			};
		case actions.UPDATE_CURRENT_JOURNEY_TEMPLATE_ID:
			return {
				...state,
				currentJourneyID: action.payload,
			};
		case actions.UPDATE_CURRENT_NODE_DATA:
			return {
				...state,
				currentNode: action.payload,
			};
		case actions.ADD_TEMPLATE_BUILDER_MODULE:
			return {
				...state,
				addModuleLoader: true,
			};
		case actions.UPDATE_TEMPLATE_BUILDER_MODULE:
			return {
				...state,
				addModuleLoader: true,
			};
		case actions.ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_SUCCESS:
			return {
				...state,
				addEmailModuleVisible: false,
				addModuleLoader: false,
				journeyBuilderData: {
					...state.journeyBuilderData,
					modified_at: action.payload.modified_at,
				},
			};
		case actions.ADD_OR_UPDATE_TEMPLATE_BUILDER_MODULE_FAILURE:
			return {
				...state,
				addModuleLoader: false,
			};
		case actions.FETCH_ASSETS_TEMPLATE_SUCCESS:
			const assetsList =
				state.assetPage === 1
					? action.payload
					: state.assets.concat(action.payload);
			return {
				...state,
				assets: assetsList,
				assetsListLoader: false,
				assetsInitialListLoader: false,
				assetSearchLoader: false,
			};
		case actions.FETCH_ASSETS_TEMPLATE:
			return {
				...state,
				assetsListLoader: true,
				assetsInitialListLoader: action.initialFetch,
				assets:
					isValid(state.assetSearchKey) && state.assetPage === 1
						? []
						: state.assets,
				assetSearchLoader: action.isSearch,
			};
		case actions.FETCH_ASSETS_TEMPLATE_FAILURE:
			return {
				...state,
				assetsListLoader: false,
				assetsInitialListLoader: false,
				assetSearchLoader: false,
			};
		case actions.SET_CREATED_MODULE:
			return {
				...state,
				createdModule: action.payload,
			};
		case actions.SET_ASSET_PAGE:
			return {
				...state,
				assetPage: action.payload,
			};
		case actions.SET_TASK_PAGE:
			return {
				...state,
				taskPage: action.payload,
			};
		case actions.SET_ASSET_SEARCH:
			return {
				...state,
				assetSearchKey: action.payload,
			};
		case actions.SET_TASK_SEARCH:
			return {
				...state,
				taskSearchKey: action.payload,
			};
		case actions.UPDATE_TEMPLATE_BUILDER_EVENTS:
			return {
				...state,
				journeyBuilderData: {
					...state.journeyBuilderData,
					events: action.payload,
				},
			};
		case actions.UPDATE_TEMPLATE_CURRENT_MODULE_ACTION:
			return {
				...state,
				moduleActionType: action.actionType,
				editModuleData: action.editModuleData,
				editModuleType: action.editModuleType,
			};
		case actions.UPDATE_SELECTED_ASSET:
			return {
				...state,
				selectedAsset: action.payload,
			};
		case actions.FETCH_TIMEZONE_SUCCESS:
			return {
				...state,
				timeZone: action.payload,
			};
		case actions.UPDATE_SELECTED_JOURNEY_TEMPLATE: {
			return {
				...state,
				selectedJourneyKeys: action.payload,
			};
		}
		case actions.SET_LIST_TEMPLATE_PAGE_SIZE:
			return {
				...state,
				listPerPage: action.payload,
			};
		case actions.SET_JOURNEY_TEMPLATE_PAGINATION:
			return {
				...state,
				CurrentPage: action.payload,
			};
		case actions.SET_JOURNEY_TEMPLATE_SORT_VALUES:
			return {
				...state,
				listSortKey: action.sortKey,
				listSortValue: action.sortOrder,
			};
		case actions.ON_SEARCH_JOURNEYS_TEMPLATE:
			return {
				...state,
				listSearchKey: action.payload,
			};
		case actions.UPDATE_MODULE_DELETE_CONFIRM:
			return {
				...state,
				moduleDeleteConfirmVisible: action.payload,
			};
		case actions.SET_MODULE_DELETE_DATA:
			return {
				...state,
				deleteModule: action.payload,
			};
		case actions.DELETE_MODULES_TEMPLATE:
			return {
				...state,
				deleteModuleLoader: true,
			};
		case actions.DELETE_MODULES_TEMPLATE_SUCCESS:
			return {
				...state,
				moduleDeleteConfirmVisible: false,
				deleteModuleLoader: false,
				journeyBuilderData: {
					...state.journeyBuilderData,
					modified_at: action.payload.modified_at,
				},
			};
		case actions.DELETE_MODULES_TEMPLATE_FAILURE:
			return {
				...state,
				deleteModuleLoader: false,
			};
		case actions.CREATE_NEW_JOURNEY_TEMPLATE:
			return {
				...state,
				journeyModalLoader: true,
			};
		case actions.CREATE_NEW_JOURNEY_TEMPLATE_SUCCESS:
			return {
				...state,
				journeyModalLoader: false,
				isJourneyModalVisible: false,
			};
		case actions.CREATE_NEW_JOURNEY_TEMPLATE_FAILURE:
			return {
				...state,
				journeyModalLoader: false,
			};
		case actions.APPROVE_OR_UNAPPROVE_JOURNEY:
			return {
				...state,
				journeyModalLoader: true,
			};
		case actions.APPROVE_OR_UNAPPROVE_JOURNEY_SUCCESS:
			return {
				...state,
				journeyModalLoader: false,
				journeyApprovalModalVisible: false,
				selectedJourneyKeys: [],
				journeyApproveKey: "",
				isPublished: action.payload.publish,
			};
		case actions.APPROVE_OR_UNAPPROVE_JOURNEY_FAILURE:
			return {
				...state,
				journeyModalLoader: false,
			};
		case actions.JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE:
			return {
				...state,
				deleteModalVisible: action.payload,
			};
		case actions.DELETE_JOURNEY_TEMPLATE:
			return {
				...state,
				journeyModalLoader: true,
			};
		case actions.DELETE_JOURNEY_TEMPLATE_SUCCESS: {
			return {
				...state,
				journeyModalLoader: false,
				deleteModalVisible: false,
				selectedJourneyKeys: [],
			};
		}
		case actions.DELETE_JOURNEY_TEMPLATE_FAILURE: {
			return {
				...state,
				journeyModalLoader: false,
			};
		}
		case actions.UPDATE_TRANSFORM:
			return {
				...state,
				isShowUpArrow: action.payload,
			};
		case actions.FETCH_JOURNEY_TEMPLATE_TASKS:
			return {
				...state,
				taskInitialLoader: action.initialFetch,
				taskListLoader: true,
				tasks:
					isValid(state.taskSearchKey) && state.taskPage === 1
						? []
						: state.tasks,
				taskSearchLoader: action.isSearch,
			};
		case actions.FETCH_JOURNEY_TEMPLATE_TASKS_SUCCESS:
			const taskList =
				state.taskPage === 1
					? action.payload
					: state.tasks.concat(action.payload);
			return {
				...state,
				tasks: taskList,
				taskInitialLoader: false,
				taskListLoader: false,
				taskSearchLoader: false,
			};
		case actions.FETCH_JOURNEY_TEMPLATE_TASKS_FAILURE:
			return {
				...state,
				taskInitialLoader: false,
				taskListLoader: false,
				taskSearchLoader: false,
			};
		case actions.UPDATE_TASK_TEMPLATE_DUES:
			return {
				...state,
				taskDues: action.payload,
			};
		case actions.SET_REMINDER_TEMPLATE_OPTIONS: {
			return {
				...state,
				beforeDueDates: action.beforeDueDates,
				afterDueDates: action.afterDueDates,
			};
		}
		case actions.SET_REMINDER_TEMPLATE_DEFAULTS: {
			return {
				...state,
				reminders: action.payload,
			};
		}
		case actions.UPDATE_MEETINGS_TEMPLATE_REMINDER:
			return {
				...state,
				meetingReminders: action.payload,
			};
		case actions.ADD_JOURNEY_MEETING_TEMPLATE:
			return {
				...state,
				addModuleLoader: true,
			};
		case actions.ADD_JOURNEY_MEETING_TEMPLATE_FAILURE:
			return {
				...state,
				addModuleLoader: false,
			};
		case actions.UPDATE_JOURNEY_MEETING_TEMPLATE:
			return {
				...state,
				addModuleLoader: true,
			};
		case actions.UPDATE_JOURNEY_MEETING_TEMPLATE_FAILURE:
			return {
				...state,
				addModuleLoader: false,
			};
		case actions.SET_EDIT_JOURNEY_MODAL:
			return {
				...state,
				editJourneyVisible: action.payload,
			};
		case actions.UPDATE_JOURNEY_TEMPLATE:
			return {
				...state,
				isUpdatingJourney: true,
			};
		case actions.UPDATE_JOURNEY_TEMPLATE_SUCCESS:
			return {
				...state,
				isUpdatingJourney: false,
				journeyBuilderData: action.payload,
			};
		case actions.UPDATE_JOURNEY_TEMPLATE_FAILURE:
			return {
				...state,
				isUpdatingJourney: false,
			};
		case actions.SET_ADMIN_JOURNEY_APPROVE_UNAPPROVE_KEY: {
			return {
				...state,
				journeyApproveKey: action.payload,
			};
		}
		case actions.SET_APPROVAL_MODAL_VISIBLE:
			return {
				...state,
				journeyApprovalModalVisible: action.payload,
			};
		default:
			return state;
	}
}
