import React from "react";
import { formProps } from "containers/OnBoarding/constant";
import { Button, Form, Input } from "antd";
import InjectMessage from "components/utility/intlMessages";
import { getLocaleMessages } from "redux/helper";
import { useDispatch } from "react-redux";
import actions from "redux/auth/actions";

const ChangePasswordForm = (props) => {
	const dispatch = useDispatch();

	const { loader, onClose } = props;

	const onFinish = (values) => {
		dispatch({
			type: actions.EMPLOYEE_CHANGE_PASSWORD,
			payload: values,
		});
	};

	const validateConfirmPassword = ({ getFieldValue }) => ({
		validator(rule, value) {
			if (!value) {
				return Promise.reject(
					<>
						<InjectMessage id={"label.confirmPassword"} />{" "}
						<InjectMessage id={"message.cantEmpty"} />
					</>,
				);
			} else if (getFieldValue("password") !== value) {
				return Promise.reject(<InjectMessage id={"message.passwordNotSame"} />);
			}
			return Promise.resolve();
		},
	});

	return (
		<Form
			name="change_password"
			onFinish={onFinish}
			{...formProps}
			className={"change-password-form"}
		>
			<div className={"form-group"}>
				<Form.Item
					label={<InjectMessage id={"label.oldPassword"} />}
					name="old_password"
					rules={[
						{
							required: true,
							message: (
								<>
									<InjectMessage id={"label.oldPassword"} />{" "}
									<InjectMessage id={"message.cantEmpty"} />
								</>
							),
						},
						{
							min: 8,
							message: (
								<>
									<InjectMessage id={"label.oldPassword"} />{" "}
									<InjectMessage id={"message.passwordMinimumCharacter"} />
								</>
							),
						},
					]}
				>
					<Input.Password
						placeholder={getLocaleMessages("placeholder.oldPassword")}
					/>
				</Form.Item>
				<Form.Item
					label={<InjectMessage id={"label.newPassword"} />}
					name="password"
					rules={[
						{
							required: true,
							message: (
								<>
									<InjectMessage id={"label.newPassword"} />{" "}
									<InjectMessage id={"message.cantEmpty"} />
								</>
							),
						},
						{
							min: 8,
							message: (
								<>
									<InjectMessage id={"label.newPassword"} />{" "}
									<InjectMessage id={"message.passwordMinimumCharacter"} />
								</>
							),
						},
					]}
				>
					<Input.Password
						placeholder={getLocaleMessages("placeholder.newPassword")}
					/>
				</Form.Item>
				<Form.Item
					label={<InjectMessage id={"label.confirmPassword"} />}
					name="confirm_password"
					dependencies={["password"]}
					rules={[validateConfirmPassword]}
				>
					<Input.Password
						placeholder={getLocaleMessages("placeholder.confirmPassword")}
					/>
				</Form.Item>
			</div>
			<div className={"footer-with-button"}>
				<Form.Item>
					<Button
						type={"primary"}
						loading={loader}
						disabled={loader}
						htmlType="submit"
						className={"m-r-10"}
					>
						<InjectMessage id={"profile.changePassword"} />
					</Button>
					<Button onClick={onClose}>
						<InjectMessage id={"label.cancel"} />
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};

export default ChangePasswordForm;
