import { all } from "redux-saga/effects";
import auth from "redux/auth/sagas";
import employees from "redux/employees/saga";
import assets from "redux/assets/sagas";
import journeys from "redux/journeys/saga";
import dashboard from "redux/dashboard/saga";
import users from "redux/settings/users/saga";
import templates from "redux/adminUser/templates/sagas";
import portal from "redux/portal/sagas";
import employee from "redux/employee/saga";
import meetings from "redux/meetings/sagas";
import adminTask from "redux/adminUser/tasks/sagas";
import adminMeetings from "redux/adminUser/meetings/sagas";
import adminJourney from "redux/adminUser/journeys/saga";
import Roles from "redux/settings/rolesAndPermissions/saga";
import task from "redux/Task/saga";
import reports from "redux/reports/sagas";
import moments from "redux/moments/sagas";

export default function* rootSaga(getState) {
	yield all([
		auth(),
		employees(),
		assets(),
		journeys(),
		dashboard(),
		users(),
		templates(),
		portal(),
		employee(),
		meetings(),
		adminTask(),
		adminMeetings(),
		adminJourney(),
		Roles(),
		task(),
		reports(),
		moments(),
	]);
}
