import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { fetchCompanyId } from "redux/helper";
import { useSelector } from "react-redux";
import { routerRoles } from "components/constants";

const AppRouter = (props) => {
	const { url } = props;
	const { role } = useSelector((state) => state.Auth);
	return (
		role !== null && (
			<Switch>
				<Redirect
					exact
					from={url}
					to={{
						pathname: `${url}/${fetchCompanyId()}/${
							routerRoles[`${role}`].url
						}`,
						search: `${routerRoles[`${role}`].search}`,
					}}
				/>
				{routerRoles[`${role}`].router.map((singleRoute) => (
					<Route
						exact
						key={singleRoute.path}
						path={`${url}/:company_id/${singleRoute.path}`}
						component={singleRoute.component}
					/>
				))}
			</Switch>
		)
	);
};

export default AppRouter;
