import asyncComponent from "helpers/asyncComponent";

const templateRoutes = [
	{
		path: "templates",
		component: asyncComponent(() => import("containers/AdminUser/Templates")),
	},
	{
		path: "templates/:templateID/:templateType",
		component: asyncComponent(() =>
			import("containers/AdminUser/TemplateBuilder"),
		),
	},
	{
		path: "meetings",
		component: asyncComponent(() => import("containers/AdminUser/Meetings")),
	},
	{
		path: "tasks",
		component: asyncComponent(() => import("containers/AdminUser/Task")),
	},
	{
		path: "tasks/:taskId",
		component: asyncComponent(() => import("containers/AdminUser/TaskBuilder")),
	},
	{
		path: "journeys",
		component: asyncComponent(() =>
			import("containers/AdminUser/Journeys/Journeys"),
		),
	},
	{
		path: "journeys/:id",
		component: asyncComponent(() =>
			import("containers/AdminUser/Journeys/JourneyBuilder"),
		),
	},
	{
		path: "*",
		component: asyncComponent(() => import("containers/Pages/NotFound")),
	},
];

export default templateRoutes;
