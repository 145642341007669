import React, { useEffect, useState } from "react";
import ExperienceCard from "./ExperienceCard";

const MomentExperienceCards = ({ contributions }) => {
	const [row1Data, setRow1Data] = useState([]);
	const [row2Data, setRow2Data] = useState([]);

	useEffect(() => {
		setRow1Data(
			contributions.contributions.filter((element, index) => index % 2 === 0),
		);
		setRow2Data(
			contributions.contributions.filter((element, index) => index % 2 !== 0),
		);
	}, [contributions.contributions]);

	useEffect(() => {
		if (row1Data.length && row2Data.length) {
			if (contributions.contributions.length > 3) {
				const root = document.documentElement;

				const marqueeElementsDisplayed =
					document.getElementsByClassName("marquee-content")[0].children.length;

				const marqueeContent = document.querySelector("ul.marquee-content");

				root.style.setProperty(
					"--marquee-elements",
					marqueeContent.children.length,
				);

				for (let i = 0; i < marqueeElementsDisplayed; i++) {
					marqueeContent.appendChild(
						marqueeContent.children[i].cloneNode(true),
					);
				}
			}
			if (contributions.contributions.length > 3) {
				const root = document.documentElement;

				const marqueeElementsDisplayedtwo =
					document.getElementsByClassName("marquee-content2")[0].children
						.length;

				const marqueeContent = document.querySelector("ul.marquee-content2");

				root.style.setProperty(
					"--marquee-elementss",
					marqueeContent.children.length,
				);

				for (let i = 0; i < marqueeElementsDisplayedtwo; i++) {
					marqueeContent.appendChild(
						marqueeContent.children[i].cloneNode(true),
					);
				}
			}
		}
	}, [row1Data]);

	return (
		<>
			<div
				className={`css-1k2aww2 cardWrap desktop-slider ${
					contributions.contributions.length === 1
						? "singleCard"
						: contributions.contributions.length > 1 &&
						  contributions.contributions.length < 4
						? "threeCards"
						: "animationCards"
				}`}
				id={"cardWrap"}
			>
				<div className="main-wrap">
					<div className="marquee">
						<ul className="marquee-content">
							{row1Data.length > 0 ? (
								<>
									{row1Data.map((item, index) => (
										<li>
											<ExperienceCard item={item} index={index} />
										</li>
									))}
								</>
							) : null}
						</ul>
					</div>
				</div>
				{row2Data.length > 0 ? (
					<div className="main-wrap">
						<div className="marquee">
							<ul className="marquee-content2">
								{row2Data.length > 0 ? (
									<>
										{row2Data.map((item, index) => (
											<li>
												<ExperienceCard item={item} index={index} />
											</li>
										))}
									</>
								) : null}
							</ul>
						</div>
					</div>
				) : null}
			</div>

			<div
				className={`css-1k2aww2 cardWrap moblie-slider ${
					contributions.contributions.length === 1
						? "singleCard"
						: contributions.contributions.length > 1 &&
						  contributions.contributions.length < 4
						? "threeCards"
						: "animationCards"
				}`}
				id={"cardWrap"}
			>
				<div className="main-wrap">
					<div className="marquee">
						<ul className="marquee-content">
							{contributions.contributions.map((item, index) => (
								<li>
									<ExperienceCard item={item} index={index} />
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default MomentExperienceCards;
