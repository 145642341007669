import React from "react";
import { Layout } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { employeeRoutes } from "routes/privateRoutes";
import { EmployeeStyles } from "styles/Employee.style";
import { getObjectLength } from "components/utility/helper";
import ChatWidget from "components/Employee/ChatWidget";
import { InitialLoader } from "containers/Pages/InitialLoader";

const { Content } = Layout;

function EmployeePage(props) {
	const { match } = props,
		{ url } = match,
		{
			employeeId,
			company,
			isSendingMessage,
			defaultChatMessage,
			chatMessages,
		} = useSelector((state) => ({
			...state.Auth,
			...state.Employee,
		}));

	if (getObjectLength(company) > 0) {
		return (
			<Layout>
				<Content>
					<EmployeeStyles bgImage={company.background_image_url}>
						<Switch>
							<Redirect
								exact
								from={url}
								to={`${url}/${employeeId}/dashboard?type=home`}
							/>
							{employeeRoutes.map((route, index) => (
								<Route
									exact
									key={route.path}
									path={`${url}/${route.path}`}
									component={route.component}
								/>
							))}
						</Switch>
						{getObjectLength(company) > 0 && (
							<ChatWidget
								company={company}
								isSendingMessage={isSendingMessage}
								defaultChatMessage={defaultChatMessage}
								bottom={"20px"}
								left={
									company.chat_widget_position === "left" ? "55px" : "unset"
								}
								right={
									company.chat_widget_position === "right" ? "55px" : "unset"
								}
								chatMessages={chatMessages}
							/>
						)}
					</EmployeeStyles>
				</Content>
			</Layout>
		);
	}
	return <InitialLoader />;
}

export default React.memo(EmployeePage);
