import actions from "redux/settings/rolesAndPermissions/actions";
import { defaultPage, defaultPageSize } from "components/constants";

const initState = {
	isRolesModalVisible: false,
	userRoles: [],
	isFetchingRoles: false,
	selectedRolesKeys: [],
	rolesPerPage: defaultPageSize,
	rolesCurrentPage: defaultPage,
	totalRoles: null,
	rolesInitialFetch: false,
	permissions: [],
	createRoleLoader: false,
	showDeleteModal: false,
	deleteRoleLoader: false,
	roleActionType: null,
	editRoleData: {},
	isFetchingRole: false,
	currentRole: {},
	rolePermissions: [],
	roleSortKey: "created_at",
	roleSortOrder: "desc",
};
export default function rolesReducer(state = initState, action) {
	switch (action.type) {
		case actions.CHANGE_ROLES_MODAL_VISIBLE:
			return {
				...state,
				isRolesModalVisible: action.payload,
				roleActionType: action.actionType,
				editRoleData: action.payload ? state.editRoleData : {},
			};
		case actions.FETCH_USER_ROLES:
			return {
				...state,
				isFetchingRoles: true,
				rolesInitialFetch: action.initialFetch,
			};
		case actions.FETCH_USER_ROLES_SUCCESS:
			return {
				...state,
				userRoles: action.payload.roles,
				isFetchingRoles: false,
				rolesInitialFetch: false,
				totalRoles: action.payload.total_records,
			};
		case actions.FETCH_USER_ROLES_FAILURE:
			return {
				...state,
				isFetchingRoles: false,
				rolesInitialFetch: false,
			};
		case actions.UPDATE_SELECTED_ROLES:
			return {
				...state,
				selectedRolesKeys: action.payload,
			};
		case actions.SET_ROLES_PAGE_SIZE:
			return {
				...state,
				rolesPerPage: action.payload,
			};
		case actions.SET_ROLES_PAGINATION:
			return {
				...state,
				rolesCurrentPage: action.payload,
			};
		case actions.FETCH_PERMISSIONS_SUCCESS:
			return {
				...state,
				permissions: action.payload.permissions,
			};
		case actions.CREATE_ROLE:
			return {
				...state,
				createRoleLoader: true,
			};
		case actions.CREATE_ROLE_SUCCESS:
			return {
				...state,
				createRoleLoader: false,
			};
		case actions.CREATE_ROLE_FAILURE:
			return {
				...state,
				createRoleLoader: false,
			};
		case actions.UPDATE_ROLE:
			return {
				...state,
				createRoleLoader: true,
			};
		case actions.UPDATE_ROLE_SUCCESS:
			return {
				...state,
				createRoleLoader: false,
			};
		case actions.UPDATE_ROLE_FAILURE:
			return {
				...state,
				createRoleLoader: false,
			};
		case actions.SHOW_DELETE_CONFIRM_MODAL:
			return {
				...state,
				showDeleteModal: action.payload,
			};
		case actions.DELETE_ROLE:
			return {
				...state,
				deleteRoleLoader: true,
			};
		case actions.DELETE_ROLE_SUCCESS:
			return {
				...state,
				deleteRoleLoader: false,
				selectedRolesKeys: [],
			};
		case actions.DELETE_ROLE_FAILURE:
			return {
				...state,
				deleteRoleLoader: false,
			};
		case actions.UPDATE_ROLE_DATA:
			return {
				...state,
				editRoleData: action.payload,
			};
		case actions.GET_ROLE_DATA:
			return {
				...state,
				isFetchingRole: true,
			};
		case actions.GET_ROLE_DATA_SUCCESS:
			return {
				...state,
				isFetchingRole: false,
				currentRole: action.payload,
				rolePermissions: action.payload.permissions.map(
					(permission) => permission.id,
				),
			};
		case actions.GET_ROLE_DATA_FAILURE:
			return {
				...state,
				isFetchingRole: false,
			};
		case actions.SET_ROLE_PERMISSIONS:
			return {
				...state,
				rolePermissions: action.payload,
			};
		case actions.SET_ROLE_SORT_ORDER:
			return {
				...state,
				roleSortKey: action.sortKey,
				roleSortOrder: action.sortOrder,
			};
		case actions.SET_ROLE_LOADER:
			return {
				...state,
				isFetchingRole: action.payload,
			};
		default:
			return state;
	}
}
