const actions = {
	FETCH_COMPANY_PROFILE_DETAILS: "FETCH_COMPANY_PROFILE_DETAILS",
	FETCH_COMPANY_PROFILE_DETAILS_SUCCESS:
		"FETCH_COMPANY_PROFILE_DETAILS_SUCCESS",
	FETCH_COMPANY_PROFILE_DETAILS_FAILURE:
		"FETCH_COMPANY_PROFILE_DETAILS_FAILURE",
	UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
	UPLOAD_PROFILE_IMAGE_SUCCESS: "UPLOAD_PROFILE_IMAGE_SUCCESS",
	UPLOAD_PROFILE_IMAGE_TO_S3: "UPLOAD_PROFILE_IMAGE_TO_S3",
	SET_PROFILE_IMAGE_LOADER: "SET_PROFILE_IMAGE_LOADER",
	SEND_PROFILE_IMAGE_KEY: "SEND_PROFILE_IMAGE_KEY",
	SEND_PROFILE_IMAGE_KEY_FAILURE: "SEND_PROFILE_IMAGE_KEY_FAILURE",
	SEND_MESSAGE: "SEND_MESSAGE",
	SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
	SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",
	FETCH_EMPLOYEE_DETAILS: "FETCH_EMPLOYEE_DETAILS",
	FETCH_EMPLOYEE_DETAILS_SUCCESS: "FETCH_EMPLOYEE_DETAILS_SUCCESS",
	FETCH_TASK_CONTENT: "FETCH_TASK_CONTENT",
	FETCH_TASK_CONTENT_SUCCESS: "FETCH_TASK_CONTENT_SUCCESS",
	FETCH_TASK_CONTENT_FAILURE: "FETCH_TASK_CONTENT_FAILURE",
	SET_ACTIVE_EMPLOYEE_TAB: "SET_ACTIVE_EMPLOYEE_TAB",
	UPDATE_EMPLOYEE_TASK: "UPDATE_EMPLOYEE_TASK",
	FETCH_EMPLOYEE_TASKS: "FETCH_EMPLOYEE_TASKS",
	FETCH_EMPLOYEE_TASKS_SUCCESS: "FETCH_EMPLOYEE_TASKS_SUCCESS",
	FETCH_EMPLOYEE_TASKS_FAILURE: "FETCH_EMPLOYEE_TASKS_FAILURE",
	SET_EMPLOYEE_TASKS_PAGE: "SET_EMPLOYEE_TASKS_PAGE",
	SET_EMPLOYEE_TASKS_PAGE_SIZE: "SET_EMPLOYEE_TASKS_PAGE_SIZE",
	FETCH_EMPLOYEE_DETAILS_FAILURE: "FETCH_EMPLOYEE_DETAILS_FAILURE",
	UPDATE_EMPLOYEE_TASK_SUCCESS: "UPDATE_EMPLOYEE_TASK_SUCCESS",
	UPDATE_EMPLOYEE_TASK_FAILURE: "UPDATE_EMPLOYEE_TASK_FAILURE",
	SET_FORM_UPDATE_LOADER: "SET_FORM_UPDATE_LOADER",
	REMOVE_UPLOADED_FILES: "REMOVE_UPLOADED_FILES",
	REMOVE_UPLOADED_FILES_SUCCESS: "REMOVE_UPLOADED_FILES_SUCCESS",
	REMOVE_UPLOADED_FILES_FAILURE: "REMOVE_UPLOADED_FILES_FAILURE",
	SET_SHOW_SELECTION_MODAL: "SET_SHOW_SELECTION_MODAL",
	SET_IMAGE_CAPTURE_MODAL: "SET_IMAGE_CAPTURE_MODAL",
	SET_CONFETTI_VISIBLE: "SET_CONFETTI_VISIBLE",
	SET_TASK_BUTTON_COLOR: "SET_TASK_BUTTON_COLOR",
	SET_EMPLOYEE_TASKS: "SET_EMPLOYEE_TASKS",
	SET_SUB_DOMAIN: "SET_SUB_DOMAIN",
	SET_EMPLOYEE_TASK_DETAILS: "SET_EMPLOYEE_TASK_DETAILS",
	SET_TASK_SUBMIT_STATUS: "SET_TASK_SUBMIT_STATUS",
	ADD_CUSTOM_MESSAGE: "ADD_CUSTOM_MESSAGE",
	CREATE_PAGE_VIEWS: "CREATE_PAGE_VIEWS",
	UPDATE_PAGE_VIEWS: "UPDATE_PAGE_VIEWS",
	UPDATE_PAGE_VIEWS_SUCCESS: "UPDATE_PAGE_VIEWS_SUCCESS",
	SET_TASK_PAGE: "SET_TASK_PAGE",
	CREATE_PAGE_VIEWS_SUCCESS: "CREATE_PAGE_VIEWS_SUCCESS",
};
export default actions;
