import actions from "redux/settings/users/actions";
import { defaultPage, defaultPageSize } from "components/constants";

const initState = {
	isInviteModalVisible: false,
	inviteLoader: false,
	userInitialLoader: false,
	usersLoader: false,
	users: [],
	totalUsers: 0,
	usersCurrentPage: defaultPage,
	usersPerPage: defaultPageSize,
	selectedUsersKeys: [],
	isUserDeleteModalVisible: false,
	isResendModalVisible: false,
	deleteUserLoader: false,
	resendInviteLoader: false,
	settingActiveList: "user",
	isFetchingRoles: false,
	companyRoles: [],
	assignTaskDrawer: false,
	mergeLinkToken: "",
	isFetchingMergeLinkToken: true,
	mergeAccountToken: "",
	userMerges: [],
	selectedMergeInt: [],
	integrations: [],
	slackIntegration: null,
	mergeLoader: false,
	atsCandidates: [],
	selectedAtsCandidates: [],
	totalAtsCandidates: [],
	customRelationsFields: [],
	interviewStages: [],
	currentHiredStage: null,
	isSettingTwilioConfig: false,
	slackIntegrationsCount: 0,
};
export default function usersReducer(state = initState, action) {
	switch (action.type) {
		case actions.CHANGE_INVITE_MODAL_STATUS:
			return {
				...state,
				isInviteModalVisible: action.payload,
			};
		case actions.INVITE_USER:
			return {
				...state,
				inviteLoader: true,
			};
		case actions.INVITE_USER_SUCCESS:
			return {
				...state,
				inviteLoader: false,
				isInviteModalVisible: false,
			};
		case actions.INVITE_USER_FAILURE:
			return {
				...state,
				inviteLoader: false,
			};
		case actions.FETCH_USERS:
			return {
				...state,
				userInitialLoader: action.initialFetch,
				usersLoader: true,
			};
		case actions.FETCH_USERS_SUCCESS:
			return {
				...state,
				userInitialLoader: false,
				usersLoader: false,
				users: action.payload.users,
				totalUsers: action.payload.total_records,
			};
		case actions.FETCH_USERS_FAILURE:
			return {
				...state,
				userInitialLoader: false,
				usersLoader: false,
			};
		case actions.SET_USERS_PAGE_SIZE:
			return {
				...state,
				usersPerPage: action.payload,
			};
		case actions.SET_USERS_PAGINATION:
			return {
				...state,
				usersCurrentPage: action.payload,
			};
		case actions.UPDATE_SELECTED_USERS:
			return {
				...state,
				selectedUsersKeys: action.payload,
			};
		case actions.USER_DELETE_CONFIRM_MODAL_VISIBLE:
			return {
				...state,
				isUserDeleteModalVisible: action.payload,
			};
		case actions.RESEND_CONFIRM_MODAL_VISIBLE:
			return {
				...state,
				isResendModalVisible: action.payload,
			};
		case actions.DELETE_USERS:
			return {
				...state,
				deleteUserLoader: true,
			};
		case actions.DELETE_USERS_SUCCESS:
			return {
				...state,
				deleteUserLoader: false,
				isUserDeleteModalVisible: false,
				selectedUsersKeys: [],
			};
		case actions.DELETE_USERS_FAILURE:
			return {
				...state,
				deleteUserLoader: false,
			};
		case actions.RESEND_INVITE:
			return {
				...state,
				resendInviteLoader: true,
			};
		case actions.RESEND_INVITE_SUCCESS:
			return {
				...state,
				resendInviteLoader: false,
				isResendModalVisible: false,
				selectedUsersKeys: [],
			};
		case actions.RESEND_INVITE_FAILURE:
			return {
				...state,
				resendInviteLoader: false,
			};
		case actions.SET_SETTINGS_ACTIVE_LIST: {
			return {
				...state,
				settingActiveList: action.payload,
			};
		}
		case actions.FETCH_COMPANY_ROLES:
			return {
				...state,
				isFetchingRoles: true,
			};
		case actions.FETCH_COMPANY_ROLES_SUCCESS:
			return {
				...state,
				isFetchingRoles: false,
				companyRoles: action.payload,
			};
		case actions.FETCH_COMPANY_ROLES_FAILURE:
			return {
				...state,
				isFetchingRoles: false,
			};
		case actions.ASSIGN_TASK_DRAWER_VISIBLE:
			return {
				...state,
				assignTaskDrawer: action.payload,
			};
		case actions.USER_MERGES: {
			return {
				...state,
				userMerges: action.payload,
				selectedMergeInt: [],
				mergeLoader: false,
			};
		}
		case actions.MERGE_TOKEN: {
			const payload = action.payload;
			if (payload.link_token) {
				return {
					...state,
					mergeLinkToken: payload.link_token,
				};
			} else if (payload.account_token) {
				return {
					...state,
					mergeAccountToken: payload.account_token,
				};
			} else {
				return {
					...state,
				};
			}
		}
		case actions.MERGE_INT_SELECTED_LIST:
			return {
				...state,
				selectedMergeInt: action.payload,
			};
		case actions.SET_EMAIL_INTEGRATIONS:
			return {
				...state,
				integrations: action.payload,
			};
		case actions.CREATE_MERGE_TOKEN:
			return {
				...state,
				mergeLoader: action.payload.mergeLoader,
			};
		case actions.FETCH_MERGES:
			return {
				...state,
				mergeLoader:
					action.payload === undefined ? false : action.payload.mergeLoader,
			};
		case actions.UNLINK_INT_MERGES:
			return {
				...state,
				mergeLoader: true,
			};
		case actions.SET_ATS_CANDIDATES:
			return {
				...state,
				atsCandidates: action.payload.ats_candidates,
				totalAtsCandidates: action.payload.total_records,
			};
		case actions.UPDATE_SELECTED_ATS_CANDIDATES:
			return {
				...state,
				selectedAtsCandidates: action.payload,
			};
		case actions.SET_CUSTOM_FIELDS: {
			return {
				...state,
				customRelationsFields: action.payload,
			};
		}
		case actions.SET_SLACK_INTEGRATION: {
			return {
				...state,
				slackIntegration: action.payload,
			};
		}
		case actions.SET_INTERVIEW_STAGES: {
			return {
				...state,
				interviewStages: action.payload.interview_stages,
			};
		}
		case actions.SET_CURRENT_HIRED_STAGE: {
			return {
				...state,
				currentHiredStage: action.payload.stage,
			};
		}
		case actions.SET_TWILIO_LOADING: {
			return {
				...state,
				isSettingTwilioConfig: action.payload,
			};
		}
		case actions.SET_FETCHING_LINK_TOKEN: {
			return {
				...state,
				isFetchingMergeLinkToken: action.payload,
			};
		}
		case actions.SET_SLACK_INTEGRATION_COUNT: {
			return {
				...state,
				slackIntegrationsCount: action.payload,
			};
		}

		default:
			return state;
	}
}
