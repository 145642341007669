import React from "react";
import { Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/auth/actions";
import InjectMessage from "components/utility/intlMessages";
import variables from "styles/variables";
import ChangePasswordForm from "components/EmployeeTask/ChangePasswordForm";
import { ModalGlobalStyles } from "styles/Modal.style";

const { Title } = Typography;

const ChangePasswordModal = () => {
	const dispatch = useDispatch();
	const { changePasswordDrawerVisible, loader } = useSelector(
		(state) => state.Auth,
	);

	const handleCancel = () => {
		if (!loader) {
			dispatch({
				type: actions.CHANGE_PASSWORD_MODAL_VISIBLE,
				payload: false,
			});
		}
	};

	return (
		<>
			<ModalGlobalStyles />
			<Modal
				visible={changePasswordDrawerVisible}
				title={
					<Title level={4}>
						<InjectMessage id={"profile.changePassword"} />
					</Title>
				}
				onCancel={handleCancel}
				width={variables.MODAL_WIDTH}
				className={"change-password"}
				destroyOnClose
				footer={null}
			>
				<ChangePasswordForm loader={loader} onClose={handleCancel} />
			</Modal>
		</>
	);
};

export default ChangePasswordModal;
