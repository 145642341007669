import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/auth/actions";
import {
	deleteRequest,
	getRequest,
	postRequest,
	patchRequest,
} from "helpers/axiosClient";
import { history } from "redux/store";
import {
	handleException,
	getLocaleMessages,
	getCompanyUrl,
	setShowWelcomePage,
} from "redux/helper";
import {
	setDisplayWelcomePage,
	showNotification,
} from "components/utility/helper";
import { store } from "redux/store";

export function* userAuth(params) {
	try {
		const response = yield call(() =>
			postRequest("login", { ...params.payload }),
		);
		localStorage.setItem("login_type", "non-employee");
		yield put({
			type: actions.AUTHENTICATE_USER_SUCCESS,
			payload: response.data.data,
		});
		if (response.data.data.user.role) {
			yield put({
				type: actions.GET_USER_PERMISSIONS,
				roleId: response.data.data.user.role.id,
			});
		}
		setShowWelcomePage(response.data.data.user.name);
	} catch (error) {
		handleException(error);
		setDisplayWelcomePage(false);
		yield put({ type: actions.AUTHENTICATE_USER_FAILURE });
	}
}

export function* logout() {
	try {
		yield call(() => deleteRequest("logout"));
		window.localStorage.clear();
		window.location.href = "/login";
	} catch (error) {
		handleException(error);
		yield put({ type: actions.LOGOUT_USER_FAILURE });
	}
}

export function* validateAuthToken() {
	try {
		const response = yield call(() => getRequest("profile"));
		yield put({
			type: actions.AUTHENTICATE_USER_SUCCESS,
			payload: response.data.data,
		});
		if (response.data.data.user.role) {
			yield put({
				type: actions.GET_USER_PERMISSIONS,
				roleId: response.data.data.user.role.id,
			});
		}
	} catch (error) {
		if (!error.response?.status) {
			// serverDownError();
		}
		yield put({ type: actions.AUTHENTICATE_USER_FAILURE });
	}
}

export function* sendPasswordResetLink(params) {
	try {
		const response = yield call(() =>
			patchRequest("password/forgot", params.payload),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		history.push("/login");
		yield put({ type: actions.SEND_PASSWORD_RESET_LINK_SUCCESS });
	} catch (error) {
		handleException(error);
		yield put({ type: actions.SEND_PASSWORD_RESET_LINK_FAILURE });
	}
}

export function* resetPassword(params) {
	try {
		const response = yield call(() =>
			postRequest("password/reset", params.payload),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({ type: actions.RESET_PASSWORD_SUCCESS });
		history.push("/login");
	} catch (error) {
		handleException(error);
		yield put({ type: actions.RESET_PASSWORD_FAILURE });
	}
}

export function* updateProfileImage(params) {
	try {
		const { userId } = store.getState().Auth;
		const { data } = yield call(() =>
			patchRequest(`${getCompanyUrl()}/users/${userId}`, params.formData),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: data.data.message,
		});
		yield put({
			type: actions.UPDATE_PROFILE_IMAGE_SUCCESS,
			imageUrl: params.url,
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.UPDATE_PROFILE_IMAGE_FAILURE });
	}
}

export function* userAuthEmployee(params) {
	try {
		const { company } = store.getState().Employee;
		const response = yield call(() =>
			postRequest("employees/login", {
				...params.payload,
				company_id: company.id,
			}),
		);
		localStorage.setItem("login_type", "employee");
		yield put({
			type: actions.AUTHENTICATE_EMPLOYEE_SUCCESS,
			payload: response.data.data,
		});
		setShowWelcomePage(
			`${response.data.data.employee.first_name} ${response.data.data.employee.last_name}`,
		);
	} catch (error) {
		handleException(error);
		setDisplayWelcomePage(false);
		yield put({ type: actions.AUTHENTICATE_EMPLOYEE_FAILURE });
	}
}

export function* logoutEmployee() {
	try {
		yield call(() => deleteRequest("employees/logout"));
		window.localStorage.clear();
		window.location.href = "/login";
	} catch (error) {
		handleException(error);
		yield put({ type: actions.LOGOUT_EMPLOYEE_FAILURE });
	}
}

export function* validateAuthEmployeeToken() {
	try {
		const response = yield call(() => getRequest("employees/profile"));
		yield put({
			type: actions.AUTHENTICATE_EMPLOYEE_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		if (!error.response?.status) {
			// serverDownError();
		}
		yield put({ type: actions.AUTHENTICATE_EMPLOYEE_FAILURE });
	}
}

export function* employeeSendPasswordResetLink(params) {
	try {
		const response = yield call(() =>
			patchRequest("employees/passwords/forgot", params.payload),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		history.push("/login");
		yield put({ type: actions.EMPLOYEE_SEND_PASSWORD_RESET_LINK_SUCCESS });
	} catch (error) {
		handleException(error);
		yield put({ type: actions.EMPLOYEE_SEND_PASSWORD_RESET_LINK_FAILURE });
	}
}

export function* employeeResetPassword(params) {
	try {
		const response = yield call(() =>
			postRequest("employees/passwords/reset", params.payload),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({ type: actions.EMPLOYEE_RESET_PASSWORD_SUCCESS });
		history.push("/login");
	} catch (error) {
		handleException(error);
		yield put({ type: actions.EMPLOYEE_RESET_PASSWORD_FAILURE });
	}
}

export function* employeeChangePassword(params) {
	try {
		const { employeeId } = store.getState().Auth;
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${employeeId}/update_password`,
				params.payload,
			),
		);
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({ type: actions.EMPLOYEE_CHANGE_PASSWORD_SUCCESS });
	} catch (error) {
		handleException(error);
		yield put({ type: actions.EMPLOYEE_CHANGE_PASSWORD_FAILURE });
	}
}

export function* getUserPermissions(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/roles/${params.roleId}`),
		);
		yield put({
			type: actions.GET_USER_PERMISSIONS_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.GET_USER_PERMISSIONS_FAILURE });
	}
}

export function* otpVerification(params) {
	try {
		const response = yield call(() =>
			postRequest("employees/otp_login", params.payload),
		);
		localStorage.setItem("login_type", "employee");
		yield put({
			type: actions.AUTHENTICATE_EMPLOYEE_SUCCESS,
			payload: response.data.data,
		});
		yield put({ type: actions.OTP_AUTHENTICATION_SUCCESS });
		setShowWelcomePage(
			`${response.data.data.employee.first_name} ${response.data.data.employee.last_name}`,
		);
		history.push("/login");
	} catch (error) {
		handleException(error);
		yield put({ type: actions.OTP_AUTHENTICATION_FAILURE });
	}
}

export function* loginWithOtp(params) {
	try {
		const { company } = store.getState().Employee;
		const response = yield call(() =>
			postRequest("employees/send_otp", {
				...params.payload,
				company_id: company.id,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({ type: actions.LOGIN_WITH_OTP_SUCCESS });
		history.push("/login");
	} catch (error) {
		handleException(error);
		yield put({ type: actions.LOGIN_WITH_OTP_FAILURE });
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.AUTHENTICATE_USER, userAuth),
		takeEvery(actions.LOGOUT_USER, logout),
		takeEvery(actions.VALIDATE_AUTH_TOKEN, validateAuthToken),
		takeEvery(actions.SEND_PASSWORD_RESET_LINK, sendPasswordResetLink),
		takeEvery(actions.RESET_PASSWORD, resetPassword),
		takeEvery(actions.AUTHENTICATE_EMPLOYEE, userAuthEmployee),
		takeEvery(actions.LOGOUT_EMPLOYEE, logoutEmployee),
		takeEvery(actions.VALIDATE_EMPLOYEE_AUTH_TOKEN, validateAuthEmployeeToken),
		takeEvery(
			actions.EMPLOYEE_SEND_PASSWORD_RESET_LINK,
			employeeSendPasswordResetLink,
		),
		takeEvery(actions.EMPLOYEE_RESET_PASSWORD, employeeResetPassword),
		takeEvery(actions.EMPLOYEE_CHANGE_PASSWORD, employeeChangePassword),
		takeEvery(actions.UPDATE_PROFILE_IMAGE, updateProfileImage),
		takeEvery(actions.GET_USER_PERMISSIONS, getUserPermissions),
		takeEvery(actions.LOGIN_WITH_OTP, loginWithOtp),
		takeEvery(actions.OTP_AUTHENTICATION, otpVerification),
	]);
}
