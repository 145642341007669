import React from "react";
import { StyleSheetManager } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";
import { getDirection } from "./helpers";

const withDirection = (Component) => (props) => {
	if (getDirection() === "rtl") {
		return (
			<StyleSheetManager stylisPlugins={[rtlPlugin]}>
				<Component {...props} />
			</StyleSheetManager>
		);
	} else {
		return <Component {...props} />;
	}
};

export default withDirection;
