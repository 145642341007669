import React, { useEffect } from "react";
import { Layout } from "antd";
import AppRouter from "routes/AppRouter";
import Sidebar from "containers/Sidebar/Sidebar";
import { history, store } from "redux/store";
import actions from "redux/app/actions";
import { getCurrentPath } from "redux/helper";
import { useSelector } from "react-redux";
import settings from "config/settings";
import userActions from "redux/settings/users/actions";

const mixpanel = require("mixpanel-browser");

const { Content } = Layout;

function App(props) {
  const { match } = props,
    { url } = match,
    { userId } = useSelector((state) => state.Auth);
  useEffect(() => {
    history.listen(() => {
      if (process.env.REACT_APP_ENV === "production") {
        mixpanel.track("Page View");
      }
      store.dispatch({
        type: actions.CHANGE_CURRENT_MENU,
        payload: getCurrentPath(),
      });
    });
  }, []);

  useEffect(() => {
    // mixpanel config
    if (process.env.REACT_APP_ENV === "production") {
      mixpanel.init(settings.MIX_PANEL_ID);
      mixpanel.identify(userId);
    }
  }, [userId]);

  useEffect(() => {
    store.dispatch({
      type: userActions.FETCH_MERGES
    })
    store.dispatch({
      type: userActions.FETCH_USER_INTEGRATION,
      payload: { type: ["gmail", "outlook"] },
    })
    store.dispatch({
      type: userActions.FETCH_SLACK
    })
  }, [])
  return (
    <Layout>
      <Sidebar />
      <Content>
        <div className={"layout-content"}>
          <AppRouter url={url} />
        </div>
      </Content>
    </Layout>
  );
}

export default React.memo(App);
