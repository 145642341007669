import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";

import enMessages from "./messages/en";

export const allMessages = {
	en: enMessages,
};

export default function I18nProvider({ children }) {
	const locale = useSelector(({ Auth }) => Auth.lang);
	const messages = allMessages[locale];

	return (
		<IntlProvider locale={locale} messages={messages}>
			{children}
		</IntlProvider>
	);
}
