const actions = {
	CHANGE_IMPORT_MODAL_STATUS: "CHANGE_IMPORT_MODAL_STATUS",
	UPDATE_SELECTED_LIST: "UPDATE_SELECTED_LIST",
	SET_LIST_SORT_VALUES: "SET_LIST_SORT_VALUES",
	SET_LIST_PAGINATION: "SET_LIST_PAGINATION",
	UPDATE_SELECTED_EMPLOYEES: "UPDATE_SELECTED_EMPLOYEES",
	SET_EMPLOYEE_SORT_VALUES: "SET_EMPLOYEE_SORT_VALUES",
	SET_EMPLOYEE_PAGINATION: "SET_EMPLOYEE_PAGINATION",
	ON_SEARCH_LIST: "ON_SEARCH_LIST",
	ON_SEARCH_EMPLOYEES: "ON_SEARCH_EMPLOYEES",
	FETCH_IMPORTED_LIST: "FETCH_IMPORTED_LIST",
	FETCH_IMPORTED_LIST_SUCCESS: "FETCH_IMPORTED_LIST_SUCCESS",
	FETCH_IMPORTED_LIST_FAILURE: "FETCH_IMPORTED_LIST_FAILURE",
	FETCH_EMPLOYEES_LIST: "FETCH_EMPLOYEES_LIST",
	FETCH_EMPLOYEES_LIST_SUCCESS: "FETCH_EMPLOYEES_LIST_SUCCESS",
	FETCH_EMPLOYEES_LIST_FAILURE: "FETCH_EMPLOYEES_LIST_FAILURE",
	SET_LIST_PAGE_SIZE: "SET_LIST_PAGE_SIZE",
	SET_EMPLOYEE_PAGE_SIZE: "SET_EMPLOYEE_PAGE_SIZE",
	DELETE_IMPORTS: "DELETE_IMPORTS",
	DELETE_EMPLOYEES: "DELETE_EMPLOYEES",
	UPDATE_IMPORTS_CONFIRMATION_MODAL_VISIBLE:
		"UPDATE_IMPORTS_CONFIRMATION_MODAL_VISIBLE",
	SET_IMPORTS_DELETE_LOADER: "SET_IMPORTS_DELETE_LOADER",
	SET_EMPLOYEES_DELETE_LOADER: "SET_EMPLOYEES_DELETE_LOADER",
	UPDATE_EMPLOYEES_CONFIRMATION_MODAL_VISIBLE:
		"UPDATE_EMPLOYEES_CONFIRMATION_MODAL_VISIBLE",
	GET_PRE_SIGNED_URL: "GET_PRE_SIGNED_URL",
	GET_PRE_SIGNED_URL_SUCCESS: "GET_PRE_SIGNED_URL_SUCCESS",
	GET_PRE_SIGNED_URL_FAILURE: "GET_PRE_SIGNED_URL_FAILURE",
	UPLOAD_TO_S3: "UPLOAD_TO_S3",
	UPLOAD_TO_S3_SUCCESS: "UPLOAD_TO_S3_SUCCESS",
	UPLOAD_TO_S3_FAILURE: "UPLOAD_TO_S3_FAILURE",
	UPDATE_UPLOAD_STATUS: "UPDATE_UPLOAD_STATUS",
	UPDATE_UPLOAD_STATUS_SUCCESS: "UPDATE_UPLOAD_STATUS_SUCCESS",
	UPDATE_UPLOAD_STATUS_FAILURE: "UPDATE_UPLOAD_STATUS_FAILURE",
	UPDATE_SELECTED_LIST_ID: "UPDATE_SELECTED_LIST_ID",
	SET_FILE_UPLOADING_STATUS: "SET_FILE_UPLOADING_STATUS",
	GET_EMPLOYEE_EXTRA_COLUMNS: "GET_EMPLOYEE_EXTRA_COLUMNS",
	GET_EMPLOYEE_EXTRA_COLUMNS_SUCCESS: "GET_EMPLOYEE_EXTRA_COLUMNS_SUCCESS",
	GET_EMPLOYEE_EXTRA_COLUMNS_FAILURE: "GET_EMPLOYEE_EXTRA_COLUMNS_FAILURE",
	SET_EMPLOYEE_SELECTED_COLUMNS: "SET_EMPLOYEE_SELECTED_COLUMNS",
	ASSIGN_JOURNEY_CONFIRM_MODAL_VISIBLE: "ASSIGN_JOURNEY_CONFIRM_MODAL_VISIBLE",
	ASSIGN_JOURNEY: "ASSIGN_JOURNEY",
	ASSIGN_JOURNEY_SUCCESS: "ASSIGN_JOURNEY_SUCCESS",
	ASSIGN_JOURNEY_FAILURE: "ASSIGN_JOURNEY_FAILURE",
	FETCH_JOURNEY: "FETCH_JOURNEY",
	FETCH_JOURNEY_SUCCESS: "FETCH_JOURNEY_SUCCESS",
	FETCH_JOURNEY_FAILURE: "FETCH_JOURNEY_FAILURE",
	PAUSE_EMPLOYEES_FROM_JOURNEY: "PAUSE_EMPLOYEES_FROM_JOURNEY",
	PAUSE_EMPLOYEES_FROM_JOURNEY_SUCCESS: "PAUSE_EMPLOYEES_FROM_JOURNEY_SUCCESS",
	PAUSE_EMPLOYEES_FROM_JOURNEY_FAILURE: "PAUSE_EMPLOYEES_FROM_JOURNEY_FAILURE",
	RESUME_EMPLOYEES_FROM_JOURNEY: "RESUME_EMPLOYEES_FROM_JOURNEY",
	RESUME_EMPLOYEES_FROM_JOURNEY_SUCCESS:
		"RESUME_EMPLOYEES_FROM_JOURNEY_SUCCESS",
	RESUME_EMPLOYEES_FROM_JOURNEY_FAILURE:
		"RESUME_EMPLOYEES_FROM_JOURNEY_FAILURE",
	RESUME_EMPLOYEES_FROM_JOURNEY_CONFIRMATION_MODAL_VISIBLE:
		"RESUME_EMPLOYEES_FROM_JOURNEY_CONFIRMATION_MODAL_VISIBLE",
	PAUSE_EMPLOYEES_FROM_JOURNEY_CONFIRMATION_MODAL_VISIBLE:
		"PAUSE_EMPLOYEES_FROM_JOURNEY_CONFIRMATION_MODAL_VISIBLE",
	SET_JOURNEY_PAGE: "SET_JOURNEY_PAGE",
	SET_JOURNEY_SEARCH: "SET_JOURNEY_SEARCH",
	FETCH_EMPLOYEE_DETAIL: "FETCH_EMPLOYEE_DETAIL",
	UPDATE_CURRENT_EMPLOYEE_ID: "UPDATE_CURRENT_EMPLOYEE_ID",
	FETCH_EMPLOYEE_DETAIL_SUCCESS: "FETCH_EMPLOYEE_DETAIL_SUCCESS",
	UPDATE_EMPLOYEE_DETAILS: "UPDATE_EMPLOYEE_DETAILS",
	FETCH_EMPLOYEE_DETAIL_FAILURE: "FETCH_EMPLOYEE_DETAIL_FAILURE",
	UPDATE_CURRENT_EMPLOYEE: "UPDATE_CURRENT_EMPLOYEE",
	UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
	SET_EMPLOYEE_ACTIVE_LIST: "SET_EMPLOYEE_ACTIVE_LIST",
	GET_EMPLOYEE_TASK_LIST: "GET_EMPLOYEE_TASK_LIST",
	GET_EMPLOYEE_TASK_LIST_SUCCESS: "GET_EMPLOYEE_TASK_LIST_SUCCESS",
	GET_EMPLOYEE_TASK_LIST_FAILURE: "GET_EMPLOYEE_TASK_LIST_FAILURE",
	SET_EMPLOYEE_TASK_LIST_SORT_VALUES: "SET_EMPLOYEE_TASK_LIST_SORT_VALUES",
	SET_EMPLOYEE_TASK_LIST_PAGINATION: "SET_EMPLOYEE_TASK_LIST_PAGINATION",
	SET_EMPLOYEE_TASK_LIST_PAGE_SIZE: "SET_EMPLOYEE_TASK_LIST_PAGE_SIZE",
	GET_EMPLOYEE_TASK_DETAILS: "GET_EMPLOYEE_TASK_DETAILS",
	GET_EMPLOYEE_TASK_DETAILS_SUCCESS: "GET_EMPLOYEE_TASK_DETAILS_SUCCESS",
	GET_EMPLOYEE_TASK_DETAILS_FAILURE: "GET_EMPLOYEE_TASK_DETAILS_FAILURE",
	RESET_EMPLOYEE_TASK_FORM_DATA: "RESET_EMPLOYEE_TASK_FORM_DATA",
	REOPEN_TASK_MODAL_VISIBLE: "REOPEN_MODAL_VISIBLE",
	EMPLOYEE_TASK_DELETE_MODAL_VISIBLE: "EMPLOYEE_TASK_DELETE_MODAL_VISIBLE",
	REOPEN_EMPLOYEE_TASK: "REOPEN_EMPLOYEE_TASK",
	REOPEN_EMPLOYEE_TASK_SUCCESS: "REOPEN_EMPLOYEE_TASK_SUCCESS",
	REOPEN_EMPLOYEE_TASK_FAILURE: "REOPEN_EMPLOYEE_TASK_FAILURE",
	DELETE_EMPLOYEE_TASK: "DELETE_EMPLOYEE_TASK",
	DELETE_EMPLOYEE_TASK_SUCCESS: "DELETE_EMPLOYEE_TASK_SUCCESS",
	DELETE_EMPLOYEE_TASK_FAILURE: "DELETE_EMPLOYEE_TASK_FAILURE",
	ASSIGN_MEETING_CONFIRM_MODAL_VISIBLE: "ASSIGN_MEETING_CONFIRM_MODAL_VISIBLE",
	ASSIGN_MEETING: "ASSIGN_MEETING",
	ASSIGN_MEETING_SUCCESS: "ASSIGN_MEETING_SUCCESS",
	ASSIGN_MEETING_FAILURE: "ASSIGN_MEETING_FAILURE",
	FETCH_MEETING: "FETCH_MEETING",
	FETCH_MEETING_SUCCESS: "FETCH_MEETING_SUCCESS",
	FETCH_MEETING_FAILURE: "FETCH_MEETING_FAILURE",
	SET_MEETINGS_PAGE: "SET_MEETINGS_PAGE",
	SET_MEETINGS_SEARCH: "SET_MEETINGS_SEARCH",
	RESET_EMPLOYEE_DETAILS: "RESET_EMPLOYEE_DETAILS",
	REMOVE_EMPLOYEE_CONFIRM_MODAL_VISIBLE:
		"REMOVE_EMPLOYEE_CONFIRM_MODAL_VISIBLE",
	REMOVE_EMPLOYEE_FROM_JOURNEY: "REMOVE_EMPLOYEE_FROM_JOURNEY",
	REMOVE_EMPLOYEE_FROM_JOURNEY_FAILURE: "REMOVE_EMPLOYEE_FROM_JOURNEY_FAILURE",
	REMOVE_EMPLOYEE_FROM_JOURNEY_SUCCESS: "REMOVE_EMPLOYEE_FROM_JOURNEY_SUCCESS",
	SET_EMPLOYEE_ACTIVE_TAB: "SET_EMPLOYEE_ACTIVE_TAB",
	FETCH_INPROGRESS_EMPLOYEE_LIST: "FETCH_INPROGRESS_EMPLOYEE_LIST",
	FETCH_INPROGRESS_EMPLOYEE_LIST_FAILURE:
		"FETCH_INPROGRESS_EMPLOYEE_LIST_FAILURE",
	FETCH_INPROGRESS_EMPLOYEE_LIST_SUCCESS:
		"FETCH_INPROGRESS_EMPLOYEE_LIST_SUCCESS",
	SET_INPROGRESS_EMPLOYEE_PAGINATION: "SET_INPROGRESS_EMPLOYEE_PAGINATION",
	SET_INPROGRESS_EMPLOYEE_SORT_VALUES: "SET_INPROGRESS_EMPLOYEE_SORT_VALUES",
	ON_SEARCH_INPROGRESS_EMPLOYEES: "ON_SEARCH_INPROGRESS_EMPLOYEES",
	SET_INPROGRESS_EMPLOYEE_PAGE_SIZE: "SET_INPROGRESS_EMPLOYEE_PAGE_SIZE",
	SET_INPROGRESS_EMPLOYEE_LIST_PAGINATION:
		"SET_INPROGRESS_EMPLOYEE_LIST_PAGINATION",
	UPDATE_INPROGRESS_EMPLOYEE_SELECTED_LIST:
		"UPDATE_INPROGRESS_EMPLOYEE_SELECTED_LIST",
	DELETE_INPROGRESS_EMPLOYEE: "DELETE_INPROGRESS_EMPLOYEE",
	PAUSE_INPROGRESS_EMPLOYEES_FROM_JOURNEY:
		"PAUSE_INPROGRESS_EMPLOYEES_FROM_JOURNEY",
	RESUME_INPROGRESS_EMPLOYEES_FROM_JOURNEY:
		"RESUME_INPROGRESS_EMPLOYEES_FROM_JOURNEY",
	SET_ASSIGN_TASK_MODAL_VISIBLE: "SET_ASSIGN_TASK_MODAL_VISIBLE",
	FETCH_ALL_TASKS: "FETCH_ALL_TASKS",
	FETCH_ALL_TASKS_SUCCESS: "FETCH_ALL_TASKS_SUCCESS",
	FETCH_ALL_TASKS_FAILURE: "FETCH_ALL_TASKS_FAILURE",
	EMPLOYEES_SET_TASK_PAGE: "EMPLOYEES_SET_TASK_PAGE",
	ASSIGN_TASK: "ASSIGN_TASK",
	ASSIGN_TASK_SUCCESS: "ASSIGN_TASK_SUCCESS",
	ASSIGN_TASK_FAILURE: "ASSIGN_TASK_FAILURE",
	EMPLOYEES_SET_TASK_SEARCH: "EMPLOYEES_SET_TASK_SEARCH",
	EMPLOYEES_SET_SELECTED_TASK: "EMPLOYEES_SET_SELECTED_TASK",
	FETCH_IMPORT_DETAILS: "FETCH_IMPORT_DETAILS",
	FETCH_IMPORT_DETAILS_SUCCESS: "FETCH_IMPORT_DETAILS_SUCCESS",
	FETCH_IMPORT_DETAILS_FAILURE: "FETCH_IMPORT_DETAILS_FAILURE",
	STORE_SELECTED_TASK_DETAILS: "STORE_SELECTED_TASK_DETAILS",
	SHOW_EMPLOYEE_MODAL: "SHOW_EMPLOYEE_MODAL",
	ADD_MANUAL_EMPLOYEE: "ADD_MANUAL_EMPLOYEE",
	ADD_MANUAL_EMPLOYEE_SUCCESS: "ADD_MANUAL_EMPLOYEE_SUCCESS",
	ADD_MANUAL_EMPLOYEE_FAILURE: "ADD_MANUAL_EMPLOYEE_FAILURE",
	REMOVE_EMPLOYEE_FOR_SELECTED_JOURNEY: "REMOVE_EMPLOYEE_FOR_SELECTED_JOURNEY",
	GET_EMPLOYEE_ACTIVITY_LOG: "GET_EMPLOYEE_ACTIVITY_LOG",
	GET_EMPLOYEE_ACTIVITY_LOG_SUCCESS: "GET_EMPLOYEE_ACTIVITY_LOG_SUCCESS",
	GET_EMPLOYEE_ACTIVITY_LOG_FAILURE: "GET_EMPLOYEE_ACTIVITY_LOG_FAILURE",
	SET_ACTIVITY_LOG_PAGE: "SET_ACTIVITY_LOG_PAGE",
	UPDATE_ACTIVITY_DATE_RANGE: "UPDATE_ACTIVITY_DATE_RANGE",
	SET_CURRENT_LAST_LOG_ID: "SET_CURRENT_LAST_LOG_ID",
	GET_ACTIVITY_MODULE_DETAILS: "GET_ACTIVITY_MODULE_DETAILS",
	GET_MEETING_DETAILS: "GET_MEETING_DETAILS",
	RESEND_EMPLOYEE_INVITE: "RESEND_EMPLOYEE_INVITE",
	RESEND_EMPLOYEE_INVITE_SUCCESS: "RESEND_EMPLOYEE_INVITE_SUCCESS",
	FETCH_TIMEZONE: "FETCH_TIMEZONE",
	FETCH_TIMEZONE_SUCCESS: "FETCH_TIMEZONE_SUCCESS",
	FETCH_TIMEZONE_FAILURE: "FETCH_TIMEZONE_FAILURE",
	FETCH_SLACK_USERS: "FETCH_SLACK_USERS",
	SET_SLACK_USERS: "SET_SLACK_USERS",
	SET_ADD_EMPLOYEES: "SET_ADD_EMPLOYEES",
	SET_FETCHING_FILTERED_EMPLOYEES: "SET_FETCHING_FILTERED_EMPLOYEES",
	FETCH_FILTERED_EMPLOYEES: "FETCH_FILTERED_EMPLOYEES",
	SET_FILTERED_EMPLOYEES: "SET_FILTERED_EMPLOYEES",
	SET_FILTER_EMPLOYEES_DATA: "SET_FILTER_EMPLOYEES_DATA",
	SET_LOADING_FILTERED_EMPLOYEES: "SET_LOADING_FILTERED_EMPLOYEES",
	SET_DEFAULT_FILTERED_EMPLOYEES: "SET_DEFAULT_FILTERED_EMPLOYEES",
	SET_SCROLL_LOADER: "SET_SCROLL_LOADER",
	SET_ASSIGNED_EMPLOYEES_FILTER_JOURNEY_ID:
		"SET_ASSIGNED_EMPLOYEES_FILTER_JOURNEY_ID",
};
export default actions;
