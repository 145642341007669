const actions = {
	GET_EMAIL_TEMPLATE_LIST: "GET_EMAIL_TEMPLATE_LIST",
	GET_EMAIL_TEMPLATE_LIST_SUCCESS: "GET_EMAIL_TEMPLATE_LIST_SUCCESS",
	GET_EMAIL_TEMPLATE_LIST_FAILURE: "GET_EMAIL_TEMPLATE_LIST_FAILURE",
	SET_EMAIL_TEMPLATE_DRAWER_VISIBLE: "SET_EMAIL_TEMPLATE_DRAWER_VISIBLE",
	EMAIL_TEMPLATE_SELECTED_LIST: "EMAIL_TEMPLATE_SELECTED_LIST",
	DELETE_EMAIL_TEMPLATE_LIST: "DELETE_EMAIL_TEMPLATE_LIST",
	DELETE_EMAIL_TEMPLATE_LIST_SUCCESS: "DELETE_EMAIL_TEMPLATE_LIST_SUCCESS",
	DELETE_EMAIL_TEMPLATE_LIST_FAILURE: "DELETE_EMAIL_TEMPLATE_LIST_FAILURE",
	SET_EMAIL_TEMPLATE_TABLE_SIZE: "SET_EMAIL_TEMPLATE_TABLE_SIZE",
	SET_EMAIL_TEMPLATE_TABLE_CURRENT_PAGE:
		"SET_EMAIL_TEMPLATE_TABLE_CURRENT_PAGE",
	SET_EMAIL_TEMPLATE_TABLE_SORT_ORDER: "SET_EMAIL_TEMPLATE_TABLE_SORT_ORDER",
	CREATE_NEW_EMAIL_TEMPLATE: "CREATE_NEW_EMAIL_TEMPLATE",
	CREATE_NEW_EMAIL_TEMPLATE_SUCCESS: "CREATE_NEW_EMAIL_TEMPLATE_SUCCESS",
	CREATE_NEW_EMAIL_TEMPLATE_FAILURE: "CREATE_NEW_EMAIL_TEMPLATE_FAILURE",
	GET_EMAIL_TEMPLATE_DATA: "GET_EMAIL_TEMPLATE_DATA",
	GET_EMAIL_TEMPLATE_DATA_SUCCESS: "GET_EMAIL_TEMPLATE_DATA_SUCCESS",
	GET_EMAIL_TEMPLATE_DATA_FAILURE: "GET_EMAIL_TEMPLATE_DATA_FAILURE",
	UPDATE_EMAIL_TEMPLATE_CONTENT: "UPDATE_EMAIL_TEMPLATE_CONTENT",
	UPDATE_EMAIL_TEMPLATE_CONTENT_SUCCESS:
		"UPDATE_EMAIL_TEMPLATE_CONTENT_SUCCESS",
	UPDATE_EMAIL_TEMPLATE_CONTENT_FAILURE:
		"UPDATE_EMAIL_TEMPLATE_CONTENT_FAILURE",
	RESET_TEMPLATE_DATA: "RESET_TEMPLATE_DATA",
	GET_PAGES_TEMPLATE_LIST: "GET_PAGES_TEMPLATE_LIST",
	GET_PAGES_TEMPLATE_LIST_SUCCESS: "GET_PAGES_TEMPLATE_LIST_SUCCESS",
	GET_PAGES_TEMPLATE_LIST_FAILURE: "GET_PAGES_TEMPLATE_LIST_FAILURE",
	SET_PAGES_TEMPLATE_DRAWER_VISIBLE: "SET_PAGES_TEMPLATE_DRAWER_VISIBLE",
	PAGES_TEMPLATE_SELECTED_LIST: "PAGES_TEMPLATE_SELECTED_LIST",
	DELETE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE:
		"DELETE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE",
	DELETE_PAGES_TEMPLATE_LIST: "DELETE_PAGES_TEMPLATE_LIST",
	DELETE_PAGES_TEMPLATE_LIST_SUCCESS: "DELETE_PAGES_TEMPLATE_LIST_SUCCESS",
	DELETE_PAGES_TEMPLATE_LIST_FAILURE: "DELETE_PAGES_TEMPLATE_LIST_FAILURE",
	SET_PAGES_TEMPLATE_TABLE_SIZE: "SET_PAGES_TEMPLATE_TABLE_SIZE",
	SET_PAGES_TEMPLATE_TABLE_CURRENT_PAGE:
		"SET_PAGES_TEMPLATE_TABLE_CURRENT_PAGE",
	SET_PAGES_TEMPLATE_TABLE_SORT_ORDER: "SET_PAGES_TEMPLATE_TABLE_SORT_ORDER",
	CREATE_NEW_PAGE_TEMPLATE: "CREATE_NEW_PAGE_TEMPLATE",
	CREATE_NEW_PAGE_TEMPLATE_SUCCESS: "CREATE_NEW_PAGE_TEMPLATE_SUCCESS",
	CREATE_NEW_PAGE_TEMPLATE_FAILURE: "CREATE_NEW_PAGE_TEMPLATE_FAILURE",
	GET_PAGE_TEMPLATE_DATA: "GET_PAGE_TEMPLATE_DATA",
	GET_PAGE_TEMPLATE_DATA_SUCCESS: "GET_PAGE_TEMPLATE_DATA_SUCCESS",
	GET_PAGE_TEMPLATE_DATA_FAILURE: "GET_PAGE_TEMPLATE_DATA_FAILURE",
	UPDATE_PAGE_TEMPLATE_CONTENT: "UPDATE_PAGE_TEMPLATE_CONTENT",
	UPDATE_PAGE_TEMPLATE_CONTENT_SUCCESS: "UPDATE_PAGE_TEMPLATE_CONTENT_SUCCESS",
	UPDATE_PAGE_TEMPLATE_CONTENT_FAILURE: "UPDATE_PAGE_TEMPLATE_CONTENT_FAILURE",
	APPROVE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE:
		"APPROVE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE",
	UNAPPROVE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE:
		"UNAPPROVE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE",
	APPROVE_EMAIL_TEMPLATE: "APPROVE_EMAIL_TEMPLATE",
	APPROVE_EMAIL_TEMPLATE_SUCCESS: "APPROVE_EMAIL_TEMPLATE_SUCCESS",
	APPROVE_EMAIL_TEMPLATE_FAILURE: "APPROVE_EMAIL_TEMPLATE_FAILURE",
	APPROVE_PAGES_TEMPLATE: "APPROVE_PAGES_TEMPLATE",
	APPROVE_PAGES_TEMPLATE_SUCCESS: "APPROVE_PAGES_TEMPLATE_SUCCESS",
	APPROVE_PAGES_TEMPLATE_FAILURE: "APPROVE_PAGES_TEMPLATE_FAILURE",
	UNAPPROVE_EMAIL_TEMPLATE: "UNAPPROVE_EMAIL_TEMPLATE",
	UNAPPROVE_EMAIL_TEMPLATE_SUCCESS: "UNAPPROVE_EMAIL_TEMPLATE_SUCCESS",
	UNAPPROVE_EMAIL_TEMPLATE_FAILURE: "UNAPPROVE_EMAIL_TEMPLATE_FAILURE",
	UNAPPROVE_PAGES_TEMPLATE: "UNAPPROVE_PAGES_TEMPLATE",
	UNAPPROVE_PAGES_TEMPLATE_SUCCESS: "UNAPPROVE_PAGES_TEMPLATE_SUCCESS",
	UNAPPROVE_PAGES_TEMPLATE_FAILURE: "UNAPPROVE_PAGES_TEMPLATE_FAILURE",
	SET_TEMPLATE_ACTIVE_LIST: "SET_TEMPLATE_ACTIVE_LIST",
};

export default actions;
