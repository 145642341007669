import React from "react";
import { Result } from "antd";

const Error500 = () => (
	<Result
		status="500"
		title="Something went wrong"
		subTitle="Sorry, We will fix this as soon as possible."
	/>
);

export default Error500;
