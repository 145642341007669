import React from "react";
import { store, history } from "redux/store";
import { Provider } from "react-redux";
import GlobalStyles from "styles/GlobalStyle";
import I18nProvider from "i18n/I18nProvider";
import { ConfigProvider } from "antd";
import { getDirection } from "styles/helpers";
import Boot from "boot";
import Routes from "routes/Routes";

const pathName = history.location.pathname.split("/");

function App() {
	return (
		<Provider store={store}>
			<I18nProvider>
				<GlobalStyles />
				<ConfigProvider direction={getDirection()}>
					<Routes />
				</ConfigProvider>
			</I18nProvider>
		</Provider>
	);
}

(pathName.length === 1 || (pathName.length > 1 && pathName[1] !== "pages")) &&
	Boot()
		.then(() => App())
		.catch((error) => console.error(error));

export default App;
