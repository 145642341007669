import actions from "redux/adminUser/templates/actions";

const initState = {
	emailTemplateCurrentPage: 1,
	emailTemplateList: [],
	emailTemplateData: 0,
	emailTemplatePageSize: 10,
	headerLoader: false,
	emailTemplateTableLoader: false,
	emailTemplateDrawerVisible: false,
	selectedEmailTemplateList: [],
	templateModalVisible: false,
	templateModalLoader: false,
	emailTemplateSortKey: "created_at",
	emailTemplateSortOrder: "desc",
	formLoader: false,
	templateID: null,
	templateJson: null,
	contentName: "",
	templateModifiedAt: null,
	templateHeaderLoader: false,
	templateContentHtml: null,
	templateFormType: null,
	approveTemplateVisible: false,
	approveTemplateLoader: false,
	unApproveTemplateVisible: false,
	unApproveTemplateLoader: false,
	loadDesign: false,
	pagesTemplateCurrentPage: 1,
	pagesTemplateList: [],
	pagesTemplateData: 0,
	pagesTemplatePageSize: 10,
	pagesTemplateTableLoader: false,
	pagesTemplateDrawerVisible: false,
	selectedPagesTemplateList: [],
	pagesTemplateSortKey: "created_at",
	pagesTemplateSortOrder: "desc",
	templateActiveList: "",
	contentSubject: "",
};

export default function templatesReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_EMAIL_TEMPLATE_LIST: {
			return {
				...state,
				emailTemplateTableLoader: true,
				headerLoader: true,
			};
		}
		case actions.GET_EMAIL_TEMPLATE_LIST_SUCCESS: {
			return {
				...state,
				emailTemplateTableLoader: false,
				emailTemplateList: action.payload.assets,
				emailTemplateData: action.payload.total_records,
				headerLoader: false,
			};
		}
		case actions.GET_EMAIL_TEMPLATE_LIST_FAILURE: {
			return {
				...state,
				emailTemplateTableLoader: false,
				headerLoader: false,
			};
		}
		case actions.SET_EMAIL_TEMPLATE_DRAWER_VISIBLE: {
			return {
				...state,
				emailTemplateDrawerVisible: action.payload.visible,
				templateFormType: action.payload.visible
					? action.payload.formType
					: null,
			};
		}
		case actions.EMAIL_TEMPLATE_SELECTED_LIST: {
			return {
				...state,
				selectedEmailTemplateList: action.payload,
			};
		}
		case actions.DELETE_EMAIL_TEMPLATE_LIST: {
			return {
				...state,
				templateModalLoader: true,
			};
		}
		case actions.DELETE_EMAIL_TEMPLATE_LIST_SUCCESS: {
			return {
				...state,
				templateModalLoader: false,
				templateModalVisible: false,
				selectedEmailTemplateList: [],
			};
		}
		case actions.DELETE_EMAIL_TEMPLATE_LIST_FAILURE: {
			return {
				...state,
				templateModalLoader: false,
			};
		}
		case actions.SET_EMAIL_TEMPLATE_TABLE_SIZE: {
			return {
				...state,
				emailTemplatePageSize: action.payload,
			};
		}
		case actions.SET_EMAIL_TEMPLATE_TABLE_CURRENT_PAGE: {
			return {
				...state,
				emailTemplateCurrentPage: action.payload,
			};
		}
		case actions.SET_EMAIL_TEMPLATE_TABLE_SORT_ORDER: {
			return {
				...state,
				emailTemplateSortKey: action.payload.sortKey,
				emailTemplateSortOrder: action.payload.sortOrder,
			};
		}
		case actions.CREATE_NEW_EMAIL_TEMPLATE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_EMAIL_TEMPLATE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				emailTemplateDrawerVisible: false,
				templateFormType: null,
			};
		}
		case actions.CREATE_NEW_EMAIL_TEMPLATE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.GET_EMAIL_TEMPLATE_DATA: {
			return {
				...state,
				contentName: "",
				templateJson: null,
				templateModifiedAt: null,
				templateHeaderLoader: true,
				loadDesign: false,
			};
		}
		case actions.GET_EMAIL_TEMPLATE_DATA_SUCCESS: {
			return {
				...state,
				templateJson: action.payload.asset.raw_json
					? [JSON.parse(action.payload.asset.raw_json)]
					: [],
				templateID: action.payload.asset.id,
				contentName: action.payload.asset.name,
				templateModifiedAt: action.payload.asset.modified_at,
				templateContentHtml: action.payload.asset.content,
				templateHeaderLoader: false,
				loadDesign: true,
				contentSubject: action.payload.asset.subject,
			};
		}
		case actions.GET_EMAIL_TEMPLATE_DATA_FAILURE: {
			return {
				...state,
				templateHeaderLoader: false,
			};
		}
		case actions.UPDATE_EMAIL_TEMPLATE_CONTENT: {
			return {
				...state,
				formLoader: state.emailTemplateDrawerVisible ? true : state.formLoader,
				loadDesign: false,
			};
		}
		case actions.UPDATE_EMAIL_TEMPLATE_CONTENT_SUCCESS: {
			return {
				...state,
				contentName: action.payload.asset.name,
				templateModifiedAt: action.payload.asset.modified_at,
				templateJson: action.payload.asset.raw_json
					? [JSON.parse(action.payload.asset.raw_json)]
					: [],
				templateContentHtml: action.payload.asset.content,
				formLoader: false,
				emailTemplateDrawerVisible: false,
				templateFormType: null,
				contentSubject: action.payload.asset.subject,
			};
		}
		case actions.UPDATE_EMAIL_TEMPLATE_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
				emailTemplateDrawerVisible: false,
			};
		}
		case actions.RESET_TEMPLATE_DATA: {
			return {
				...state,
				templateID: null,
				templateJson: null,
				contentName: "",
				templateModifiedAt: "",
				templateContentHtml: "",
				loadDesign: false,
				contentSubject: "",
			};
		}
		case actions.APPROVE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				approveTemplateVisible: action.payload,
			};
		}
		case actions.UNAPPROVE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				unApproveTemplateVisible: action.payload,
			};
		}
		case actions.APPROVE_EMAIL_TEMPLATE: {
			return {
				...state,
				approveTemplateLoader: true,
			};
		}
		case actions.APPROVE_EMAIL_TEMPLATE_SUCCESS: {
			return {
				...state,
				approveTemplateLoader: false,
				approveTemplateVisible: false,
			};
		}
		case actions.APPROVE_EMAIL_TEMPLATE_FAILURE: {
			return {
				...state,
				approveTemplateLoader: false,
			};
		}
		case actions.UNAPPROVE_EMAIL_TEMPLATE: {
			return {
				...state,
				unApproveTemplateLoader: true,
			};
		}
		case actions.UNAPPROVE_EMAIL_TEMPLATE_SUCCESS: {
			return {
				...state,
				unApproveTemplateLoader: false,
				unApproveTemplateVisible: false,
			};
		}
		case actions.UNAPPROVE_EMAIL_TEMPLATE_FAILURE: {
			return {
				...state,
				unApproveTemplateLoader: false,
			};
		}
		case actions.GET_PAGES_TEMPLATE_LIST: {
			return {
				...state,
				pagesTemplateTableLoader: true,
				headerLoader: true,
			};
		}
		case actions.GET_PAGES_TEMPLATE_LIST_SUCCESS: {
			return {
				...state,
				pagesTemplateTableLoader: false,
				pagesTemplateList: action.payload.pages,
				pagesTemplateData: action.payload.total_records,
				headerLoader: false,
			};
		}
		case actions.GET_PAGES_TEMPLATE_LIST_FAILURE: {
			return {
				...state,
				pagesTemplateTableLoader: false,
				headerLoader: false,
			};
		}
		case actions.SET_PAGES_TEMPLATE_DRAWER_VISIBLE: {
			return {
				...state,
				pagesTemplateDrawerVisible: action.payload.visible,
				templateFormType: action.payload.visible
					? action.payload.formType
					: null,
			};
		}
		case actions.PAGES_TEMPLATE_SELECTED_LIST: {
			return {
				...state,
				selectedPagesTemplateList: action.payload,
			};
		}
		case actions.DELETE_TEMPLATE_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				templateModalVisible: action.payload,
			};
		}
		case actions.DELETE_PAGES_TEMPLATE_LIST: {
			return {
				...state,
				templateModalLoader: true,
			};
		}
		case actions.DELETE_PAGES_TEMPLATE_LIST_SUCCESS: {
			return {
				...state,
				templateModalLoader: false,
				templateModalVisible: false,
				selectedPagesTemplateList: [],
			};
		}
		case actions.DELETE_PAGES_TEMPLATE_LIST_FAILURE: {
			return {
				...state,
				templateModalLoader: false,
			};
		}
		case actions.SET_PAGES_TEMPLATE_TABLE_SIZE: {
			return {
				...state,
				pagesTemplatePageSize: action.payload,
			};
		}
		case actions.SET_PAGES_TEMPLATE_TABLE_CURRENT_PAGE: {
			return {
				...state,
				pagesTemplateCurrentPage: action.payload,
			};
		}
		case actions.SET_PAGES_TEMPLATE_TABLE_SORT_ORDER: {
			return {
				...state,
				pagesTemplateSortKey: action.payload.sortKey,
				pagesTemplateSortOrder: action.payload.sortOrder,
			};
		}
		case actions.CREATE_NEW_PAGE_TEMPLATE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_PAGE_TEMPLATE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				pagesTemplateDrawerVisible: false,
				templateFormType: null,
			};
		}
		case actions.CREATE_NEW_PAGE_TEMPLATE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.GET_PAGE_TEMPLATE_DATA: {
			return {
				...state,
				contentName: "",
				templateJson: null,
				templateModifiedAt: null,
				templateHeaderLoader: true,
				loadDesign: false,
			};
		}
		case actions.GET_PAGE_TEMPLATE_DATA_SUCCESS: {
			return {
				...state,
				templateJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				templateID: action.payload.page.id,
				contentName: action.payload.page.name,
				templateModifiedAt: action.payload.page.modified_at,
				templateContentHtml: action.payload.page.content,
				templateHeaderLoader: false,
				loadDesign: true,
			};
		}
		case actions.GET_PAGE_TEMPLATE_DATA_FAILURE: {
			return {
				...state,
				templateHeaderLoader: false,
			};
		}
		case actions.UPDATE_PAGE_TEMPLATE_CONTENT: {
			return {
				...state,
				formLoader: state.pagesTemplateDrawerVisible,
				loadDesign: false,
			};
		}
		case actions.UPDATE_PAGE_TEMPLATE_CONTENT_SUCCESS: {
			return {
				...state,
				contentName: action.payload.page.name,
				templateModifiedAt: action.payload.page.modified_at,
				templateJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				templateContentHtml: action.payload.page.content,
				formLoader: false,
				pagesTemplateDrawerVisible: false,
				templateFormType: null,
			};
		}
		case actions.UPDATE_PAGE_TEMPLATE_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
				pagesTemplateDrawerVisible: false,
			};
		}
		case actions.APPROVE_PAGES_TEMPLATE: {
			return {
				...state,
				approveTemplateLoader: true,
			};
		}
		case actions.APPROVE_PAGES_TEMPLATE_SUCCESS: {
			return {
				...state,
				approveTemplateLoader: false,
				approveTemplateVisible: false,
			};
		}
		case actions.APPROVE_PAGES_TEMPLATE_FAILURE: {
			return {
				...state,
				approveTemplateLoader: false,
			};
		}
		case actions.UNAPPROVE_PAGES_TEMPLATE: {
			return {
				...state,
				unApproveTemplateLoader: true,
			};
		}
		case actions.UNAPPROVE_PAGES_TEMPLATE_SUCCESS: {
			return {
				...state,
				unApproveTemplateLoader: false,
				unApproveTemplateVisible: false,
			};
		}
		case actions.UNAPPROVE_PAGES_TEMPLATE_FAILURE: {
			return {
				...state,
				unApproveTemplateLoader: false,
			};
		}
		case actions.SET_TEMPLATE_ACTIVE_LIST: {
			return {
				...state,
				templateActiveList: action.payload,
			};
		}
		default:
			return state;
	}
}
