const actions = {
	GET_HOME_PAGE_LIST: "GET_HOME_PAGE_LIST",
	GET_HOME_PAGE_LIST_SUCCESS: "GET_HOME_PAGE_LIST_SUCCESS",
	GET_HOME_PAGE_LIST_FAILURE: "GET_HOME_PAGE_LIST_FAILURE",
	SET_HOME_PAGE_DRAWER_VISIBLE: "SET_HOME_PAGE_DRAWER_VISIBLE",
	HOME_PAGE_SELECTED_LIST: "HOME_PAGE_SELECTED_LIST",
	DELETE_HOME_PAGE_CONFIRMATION_MODAL_VISIBLE:
		"DELETE_HOME_PAGE_CONFIRMATION_MODAL_VISIBLE",
	DELETE_HOME_PAGE_LIST: "DELETE_HOME_PAGE_LIST",
	DELETE_HOME_PAGE_LIST_SUCCESS: "DELETE_HOME_PAGE_LIST_SUCCESS",
	DELETE_HOME_PAGE_LIST_FAILURE: "DELETE_HOME_PAGE_LIST_FAILURE",
	SET_HOME_PAGE_TABLE_SIZE: "SET_HOME_PAGE_TABLE_SIZE",
	SET_HOME_PAGE_TABLE_CURRENT_PAGE: "SET_HOME_PAGE_TABLE_CURRENT_PAGE",
	SET_HOME_PAGE_TABLE_SORT_ORDER: "SET_HOME_PAGE_TABLE_SORT_ORDER",
	SET_PORTAL_ACTIVE_LIST: "SET_PORTAL_ACTIVE_LIST",
	CREATE_NEW_HOME_PAGE: "CREATE_NEW_HOME_PAGE",
	CREATE_NEW_HOME_PAGE_SUCCESS: "CREATE_NEW_HOME_PAGE_SUCCESS",
	CREATE_NEW_HOME_PAGE_FAILURE: "CREATE_NEW_HOME_PAGE_FAILURE",
	GET_HOME_PAGE_DATA: "GET_HOME_PAGE_DATA",
	GET_HOME_PAGE_DATA_SUCCESS: "GET_HOME_PAGE_DATA_SUCCESS",
	GET_HOME_PAGE_DATA_FAILURE: "GET_HOME_PAGE_DATA_FAILURE",
	UPDATE_HOME_PAGE_CONTENT: "UPDATE_HOME_PAGE_CONTENT",
	UPDATE_HOME_PAGE_CONTENT_SUCCESS: "UPDATE_HOME_PAGE_CONTENT_SUCCESS",
	UPDATE_HOME_PAGE_CONTENT_FAILURE: "UPDATE_HOME_PAGE_CONTENT_FAILURE",
	GET_HOME_PAGE_TOKEN: "GET_HOME_PAGE_TOKEN",
	GET_HOME_PAGE_TOKEN_SUCCESS: "GET_HOME_PAGE_TOKEN_SUCCESS",
	GET_HOME_PAGE_TOKEN_FAILURE: "GET_HOME_PAGE_TOKEN_FAILURE",
	SET_HOME_PAGE_DATA: "SET_HOME_PAGE_DATA",
	RESET_HOME_PAGE_DATA: "RESET_HOME_PAGE_DATA",
	CREATE_NEW_LANDING_PAGE: "CREATE_NEW_LANDING_PAGE",
	CREATE_NEW_LANDING_PAGE_SUCCESS: "CREATE_NEW_LANDING_PAGE_SUCCESS",
	CREATE_NEW_LANDING_PAGE_FAILURE: "CREATE_NEW_LANDING_PAGE_FAILURE",
	GET_LANDING_PAGE_DATA: "GET_LANDING_PAGE_DATA",
	GET_LANDING_PAGE_DATA_SUCCESS: "GET_LANDING_PAGE_DATA_SUCCESS",
	GET_LANDING_PAGE_DATA_FAILURE: "GET_LANDING_PAGE_DATA_FAILURE",
	UPDATE_LANDING_PAGE_CONTENT: "UPDATE_LANDING_PAGE_CONTENT",
	UPDATE_LANDING_PAGE_CONTENT_SUCCESS: "UPDATE_LANDING_PAGE_CONTENT_SUCCESS",
	UPDATE_LANDING_PAGE_CONTENT_FAILURE: "UPDATE_LANDING_PAGE_CONTENT_FAILURE",
	SET_LANDING_PAGE_DRAWER_VISIBLE: "SET_LANDING_PAGE_DRAWER_VISIBLE",
	SET_HOME_PAGE_PUBLISH_MODAL_VISIBLE: "SET_HOME_PAGE_PUBLISH_MODAL_VISIBLE",
	PUBLISH_HOME_PAGE: "PUBLISH_HOME_PAGE",
	PUBLISH_HOME_PAGE_SUCCESS: "PUBLISH_HOME_PAGE_SUCCESS",
	PUBLISH_HOME_PAGE_FAILURE: "PUBLISH_HOME_PAGE_FAILURE",
	SET_PUBLISH_DATA: "SET_PUBLISH_DATA",
	FETCH_COMPANY_DETAILS: "FETCH_COMPANY_DETAILS",
	FETCH_COMPANY_DETAILS_SUCCESS: "FETCH_COMPANY_DETAILS_SUCCESS",
	FETCH_COMPANY_DETAILS_FAILURE: "FETCH_COMPANY_DETAILS_FAILURE",
	UPDATE_COMPANY_DETAILS: "UPDATE_COMPANY_DETAILS",
	UPDATE_COMPANY_DETAILS_SUCCESS: "UPDATE_COMPANY_DETAILS_SUCCESS",
	UPDATE_COMPANY_DETAILS_FAILURE: "UPDATE_COMPANY_DETAILS_FAILURE",
	UPLOAD_IMAGE_FILE: "UPLOAD_IMAGE_FILE",
	UPLOAD_IMAGE_FILE_SUCCESS: "UPLOAD_IMAGE_FILE_SUCCESS",
	UPLOAD_IMAGE_FILE_FAILURE: "UPLOAD_IMAGE_FILE_FAILURE",
	UPLOAD_IMAGE_TO_S3: "UPLOAD_IMAGE_TO_S3",
	SET_PREVIEW_MODAL_VISIBLE: "SET_PREVIEW_MODAL_VISIBLE",
	SET_PREVIEW_DATA: "SET_PREVIEW_DATA",
	UPDATE_PORTAL_IMAGES: "UPDATE_PORTAL_IMAGES",
	EMAIL_SETTINGS_ALERT_MODAL_VISIBLE: "EMAIL_SETTINGS_ALERT_MODAL_VISIBLE",
	CREATE_COMPANY_DOMAIN: "CREATE_COMPANY_DOMAIN",
	CREATE_COMPANY_DOMAIN_SUCCESS: "CREATE_COMPANY_DOMAIN_SUCCESS",
	CREATE_COMPANY_DOMAIN_FAILURE: "CREATE_COMPANY_DOMAIN_FAILURE",
	UPDATE_COMPANY_DOMAIN: "UPDATE_COMPANY_DOMAIN",
	UPDATE_COMPANY_DOMAIN_SUCCESS: "CREATE_COMPANY_DOMAIN_SUCCESS",
	UPDATE_COMPANY_DOMAIN_FAILURE: "CREATE_COMPANY_DOMAIN_FAILURE",
	VERIFY_COMPANY_DOMAIN: "VERIFY_COMPANY_DOMAIN",
	VERIFY_COMPANY_DOMAIN_SUCCESS: "VERIFY_COMPANY_DOMAIN_SUCCESS",
	VERIFY_COMPANY_DOMAIN_FAILURE: "VERIFY_COMPANY_DOMAIN_FAILURE",
	DELETE_COMPANY_DOMAIN: "DELETE_COMPANY_DOMAIN",
	DELETE_COMPANY_DOMAIN_SUCCESS: "DELETE_COMPANY_DOMAIN_SUCCESS",
	SET_DELETE_MODAL_VISIBLE: "SET_DELETE_MODAL_VISIBLE",
	VALIDATE_REMOVE_PREFIX: "VALIDATE_REMOVE_PREFIX",
	REMOVE_TEMP_PREFIX: "REMOVE_TEMP_PREFIX",
	CREATE_CUSTOM_RELATIONS: "CREATE_CUSTOM_RELATIONS",
};

export default actions;
