import styled from "styled-components";
import variables from "styles/variables";
import { Modal, Pagination, Progress, Skeleton } from "antd";

export const EmployeeStyles = styled.div`
	color: inherit;
	padding: 5px;
	@media (max-width: 520px) {
		padding: 0;
	}
	border-radius: ${variables.BORDER_RADIUS_BASE} ${variables.BORDER_RADIUS_BASE}
		0 0;
	.ant-card-body {
		box-shadow: 0 0 5px 0 ${variables.SHADOW_MEDIUM};
	}
	background: ${(props) =>
		props.bgImage ? "transparent" : variables.WHITE_COLOR};
	height: calc(100% + 20px);
`;
export const EmployeeProfileStyles = styled.div`
	text-align: center;
	.ant-card {
		&:not(:first-child) {
			margin-top: 30px;
		}
		margin-bottom: 30px;
		color: inherit;
		.ant-card-body {
			text-align: center;
			padding: 40px;
			.anticon-environment,
			.anticon-calendar {
				color: ${variables.ICON_COLOR};
			}
			.welcome-text {
				font-size: 16px;
				font-weight: 600;
				line-height: 1.22;
				opacity: 0.8;
				margin: 15px 0;
				word-break: break-word;
			}
			.greeting-text {
				line-height: 1.57;
				.ant-typography {
					color: inherit;
					opacity: 0.7;
					word-break: break-word;
				}
			}
			.start-day {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				div {
					h1,
					h2 {
						margin-bottom: 7px;
						color: inherit;
					}
					span {
						opacity: 0.8;
					}
				}
			}
			.company-info {
				display: flex;
				justify-content: space-around;
				margin-top: 20px;
				align-items: center;
				.anticon {
					margin-right: 5px;
					font-size: 16px;
				}
				a {
					vertical-align: top;
				}
			}
			.warning {
				color: ${variables.DANGER_COLOR};
			}
			.logo-container {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				margin-bottom: 15px;
				img {
					max-width: ${(props) => (props.companyName ? 50 : 150)}px;
					flex: 1;
					margin-right: ${(props) => (props.companyName ? 10 : 0)}px;
				}
				div {
					font-weight: 600;
					font-size: 18px;
					@media (max-width: 421px) {
						margin-top: 10px;
					}
				}
			}
			h4 {
				color: inherit;
				font-weight: 600;
			}
		}
	}
`;
export const ChatBoxStyles = styled.div`
	& {
		box-shadow: 0 0 6px 0 ${variables.SHADOW_SECONDARY};
		border-radius: 4px;
		min-width: 380px;
		text-align: center;
		padding: 0 0 10px;
		margin-bottom: 10px;
		background: ${variables.WHITE_COLOR};
		color: ${(props) => props.textColor};
		.ant-tabs-tab {
			color: ${(props) => props.textColor};
		}
		.send-button {
			text-align: right;
			.anticon {
				color: ${(props) =>
					props.isFilter ? variables.WHITE_COLOR : variables.TEXT_COLOR};
			}
		}
		.ant-btn {
			padding: 5px 10px;
			height: unset;
			margin-top: 10px;
		}
		.composer {
			display: flex;
			align-items: center;
			border-top: 1px solid ${variables.BORDER_COLOR};
			padding: 10px;
			textarea {
				resize: none;
				padding: 0 10px;
				&,
				&:hover,
				&:active,
				&:focus {
					outline: none;
					box-shadow: none;
					border: 0;
				}
			}
		}
		.header {
			display: flex;
			padding: 20px;
			background: ${(props) => props.widgetColor};
			border-radius: ${variables.BORDER_RADIUS_BASE}
				${variables.BORDER_RADIUS_BASE} 0 0;
			align-items: center;
			.name {
				margin-left: 20px;
				font-weight: 600;
				font-size: 18px;
				color: ${(props) =>
					props.isFilter ? variables.WHITE_COLOR : variables.TEXT_COLOR};
			}
			svg {
				height: 30px;
				width: 30px;
				filter: ${(props) => (props.isFilter ? "invert(1)" : "")};
			}
		}
		.message-container {
			max-height: 440px;
			overflow-y: auto;
			min-height: 440px;
			.custom-message {
				max-width: 380px;
				padding: 45px 50px;
				div {
					margin-top: 20px;
					line-height: 1.4;
					text-align: left;
					color: ${variables.TEXT_COLOR_LIGHT};
					word-break: break-word;
				}
			}
		}
	}
`;
export const EmployeeHomeStyles = styled.div`
	height: 100%;
	.ant-card {
		height: 100%;
		color: inherit;
		.ant-card-body {
			height: 100%;
			@media (max-width: 520px) {
				padding: 15px;
			}
			.ant-tabs {
				height: 100%;
				.ant-tabs-content {
					margin-top: 25px;
				}
				.ant-tabs-nav-container {
					text-align: center;
				}
			}
			.heading {
				color: inherit;
				font-weight: 600;
				text-align: center;
				margin: 10px 0 30px;
			}
		}
	}
`;
export const TaskStyles = styled.div`
	& {
		padding: 15px 30px;
		@media (max-width: 520px) {
			padding: 10px 15px;
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: ${(props) =>
			props.status === "completed"
				? variables.BG_COLOR_SECONDARY
				: variables.WHITE_COLOR};
		&:not(:last-child) {
			border-bottom: 1px solid ${variables.BORDER_COLOR};
		}
		.wrapper {
			display: flex;
			align-items: center;
			a {
				margin: 0 10px;
				font-weight: ${(props) => (props.status === "completed" ? 600 : 500)};
			}
			.reopen-tag {
				max-height: 22px;
				align-self: center;
			}
		}
		.anticon-check-circle {
			color: ${variables.SUCCESS_COLOR};
			font-size: 20px;
		}
		.due {
			font-size: 13px;
			min-width: 100px;
			word-break: break-word;
			@media (max-width: 520px) {
				min-width: 50px;
			}
		}
	}
`;
export const TaskProgressStyles = styled(Progress)`
	&.ant-progress {
		color: inherit;
		display: block;
		margin: 15px 0;
		.ant-progress-text {
			color: inherit;
			.info {
				color: inherit;
				opacity: 0.8;
				.count {
					font-size: 16px;
					font-weight: 600;
				}
				.text {
					font-size: 12px;
				}
			}
		}
	}
`;
export const ProfileWrapper = styled.div`
	display: inline-block;
	position: relative;
	opacity: ${(props) => (props.profileLoader ? 0.5 : 1)};
	.ant-avatar {
		cursor: pointer;
		border: 1px solid ${variables.BORDER_COLOR};
		box-sizing: content-box;
		box-shadow: 0 0 5px 0 ${variables.SHADOW_MEDIUM};
	}
	.ant-spin {
		position: absolute;
		left: 20px;
		bottom: 17px;
	}
`;
export const DotStyles = styled.span`
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
	background: ${(props) => props.color};
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	flex-shrink: 0;
`;
export const EmployeeAppHeaderStyles = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	@media (max-width: 991px) {
		flex-direction: column;
	}
	.title {
		font-weight: 600;
		font-size: 18px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: calc(100vw - 215px);
		line-height: 2.01;
		display: inline-block;
		@media (max-width: 991px) {
			line-height: 1.4;
			white-space: normal;
			max-width: calc(100vw - 50px);
		}
	}
	.header {
		flex: 1;
		text-align: center;
		img {
			max-width: ${(props) => (props.companyName ? 50 : 150)}px;
			flex: 1;
			margin-right: ${(props) => (props.companyName ? 10 : 0)}px;
		}
	}
	.ant-skeleton {
		li {
			height: 40px;
		}
	}
	* {
		z-index: 4;
	}
	.content {
		margin-top: ${variables.SQ_SPACING_SMALL};
		.anticon-check-circle {
			margin-right: 10px;
			font-size: 17px;
			color: ${variables.SUCCESS_COLOR};
		}
		span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: calc(100vw - 215px);
			display: inline-block;
		}
	}
`;
export const PagesStyles = styled.div`
	width: auto;
	margin: ${(props) => props.margin};
	max-height: ${(props) =>
		props.frameId === "home-page-frame" ? "900px" : "1440px"};
	overflow-y: auto;
	pointer-events: all !important;
	iframe {
		border-radius: ${variables.BORDER_RADIUS_BASE};
		box-sizing: content-box;
		border: 0;
		min-height: 100%;
		pointer-events: ${(props) => props.mouseEvents};
	}
`;

export const TaskListStyles = styled.div`
	position: relative;
	opacity: ${(props) => (props.isFetchingEmployeeTasks ? 0.5 : 1)};
	pointer-events: ${(props) => (props.disableTask ? "none" : "all")};
	.task-list-wrapper {
		height: calc(100vh - 299px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.ant-spin {
		position: absolute;
		top: calc(50% - 10px);
		left: calc(50% - 10px);
	}
`;
export const PaginationStyles = styled(Pagination)`
	&.ant-pagination {
		text-align: center;
		a:not(.ant-pagination-item-link) {
			display: inline-block;
		}
		.ant-pagination-next,
		.ant-pagination-prev {
			.ant-pagination-item-link {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.ant-pagination-jump-next,
		.ant-pagination-jump-prev {
			height: auto;
			.ant-pagination-item-ellipsis {
				top: 0;
			}
		}
		.ant-select-selector {
			min-width: 110px;
		}
		.ant-pagination-item-active {
			background: ${variables.PRIMARY_COLOR};
			border: 0;
			a {
				color: ${variables.WHITE_COLOR};
			}
		}
		.ant-pagination-item-ellipsis {
			top: 8px;
		}
	}
`;
export const TaskPageRenderStyles = styled.div`
	opacity: ${(props) => (props.isUpdatingTask ? 0.95 : 1)};
	pointer-events: ${(props) => (props.isUpdatingTask ? "none" : "all")};
	position: ${(props) => (props.completed ? "absolute" : "relative")};
	display: ${(props) => props.completed && "flex"};
	justify-content: ${(props) => props.completed && "center"};
	width: ${(props) => props.completed && "100%"};
	.ant-spin {
		position: absolute;
		top: calc(50% - 10px);
		left: calc(50% - 10px);
		filter: brightness(0.5);
	}
`;

export const NoTaskStyles = styled.div`
	font-size: 13px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 160px);
	opacity: 0.7;
	flex-direction: column;
	img {
		margin-bottom: 40px;
	}
`;

export const EmployeeDashboardStyles = styled.div`
	height: 100%;
	.employee-dashboard {
		&,
		.task {
			height: 100%;
		}
	}
`;

export const SelectionModalStyles = styled(Modal)`
	.ant-modal-body {
		padding: 30px;
		text-align: center;
		.camera {
			width: 100%;
			height: 100%;
			position: relative;
			text-align: center;
			#photo {
				width: 100%;
			}
			#video {
				width: 100%;
				height: 100%;
			}
			#startButton {
				position: absolute;
				bottom: 15px;
				right: calc(50% - 42px);
			}
			#canvas {
				display: none;
			}
		}
	}
	.ant-modal-close {
		.ant-modal-close-x {
			height: 32px;
			width: 32px;
			line-height: 32px;
			.anticon {
				font-size: 14px;
				font-weight: 600;
				&: hover {
					color: ${variables.DANGER_COLOR};
				}
			}
		}
	}
`;

export const CaptureSkeleton = styled(Skeleton)`
	position: absolute;
	z-index: 9;
	height: calc(100% - 60px);
	width: calc(100% - 60px);
	* {
		height: 100% !important;
	}
`;

export const TaskPageStyles = styled.div`
	height: 100%;
	color: inherit;
	.ant-card {
		height: 100%;
		color: inherit;
		.ant-card-body {
			height: 100%;
			overflow: auto;
			display: flex;
			flex-direction: column;
			.task-page {
				max-height: 720px;
				overflow: auto;
				scrollbar-width: none;
				&::-webkit-scrollbar {
					display: none;
				}
			}
			.attachments {
				width: 480px;
				.ant-collapse-content-box {
					max-height: 150px;
					overflow: auto;
				}
			}
		}
	}
	.mark-complete {
		background: ${(props) =>
			props.markAsCompleteBgColor
				? props.markAsCompleteBgColor
				: variables.PRIMARY_COLOR};
		color: ${(props) =>
			props.markAsCompleteTextColor
				? props.markAsCompleteTextColor
				: variables.WHITE_COLOR};
		border: 0;
	}
`;
export const TimeGroupStyles = styled.div`
	font-size: 13px;
	color: ${variables.COLOR_TRANSPARENT};
	margin-top: 5px;
	span {
		${(props) =>
			props.isCustomMessage ? "margin-left: 40px;" : "margin-right: 40px;"}
	}
`;
export const TaskSuccessStyles = styled.div`
	height: calc(
		100vh -
			${(props) =>
				props.taskSuccess ? props.heightDiff + 300 : props.heightDiff}px
	);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	b {
		margin-top: 25px;
	}
`;

export const ChatMessage = styled.div`
	& {
		max-width: 400px;
		text-align: ${(props) => (props.isCustomMessage ? "left" : "right")};
		word-break: break-word;
		margin: 10px;
		.group {
			.ant-avatar {
				${(props) =>
					props.isCustomMessage
						? `
        margin-right: 10px;`
						: "margin-left: 10px;"}
			}
			.bubble {
				display: inline-block;
				padding: 15px 20px;
				background: ${(props) => props.chatColor};
				color: ${(props) =>
					props.isFilter ? variables.WHITE_COLOR : variables.TEXT_COLOR};
				border-radius: ${variables.SPACE_15}px ${variables.SPACE_15}px
					${(props) =>
						props.isCustomMessage
							? `${variables.SPACE_15}px`
							: variables.BORDER_RADIUS_BASE}
					${(props) =>
						props.isCustomMessage
							? variables.BORDER_RADIUS_BASE
							: `${variables.SPACE_15}px`};
				text-align: left;
				max-width: 250px;
				line-height: 1.3;
				letter-spacing: 0.6px;
			}
		}
	}
`;
