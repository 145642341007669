const actions = {
	GET_MEETINGS_LIST: "GET_MEETINGS_LIST",
	GET_MEETINGS_LIST_SUCCESS: "GET_MEETINGS_LIST_SUCCESS",
	GET_MEETINGS_LIST_FAILURE: "GET_MEETINGS_LIST_FAILURE",
	SET_MEETINGS_DRAWER_VISIBLE: "SET_MEETINGS_DRAWER_VISIBLE",
	MEETINGS_SELECTED_LIST: "MEETINGS_SELECTED_LIST",
	DELETE_MEETINGS_CONFIRMATION_MODAL_VISIBLE:
		"DELETE_MEETINGS_CONFIRMATION_MODAL_VISIBLE",
	DELETE_MEETINGS_LIST: "DELETE_MEETINGS_LIST",
	DELETE_MEETINGS_LIST_SUCCESS: "DELETE_MEETINGS_LIST_SUCCESS",
	DELETE_MEETINGS_LIST_FAILURE: "DELETE_MEETINGS_LIST_FAILURE",
	SET_MEETINGS_TABLE_SIZE: "SET_MEETINGS_TABLE_SIZE",
	SET_MEETINGS_TABLE_CURRENT_PAGE: "SET_MEETINGS_TABLE_CURRENT_PAGE",
	SET_MEETINGS_TABLE_SORT_ORDER: "SET_MEETINGS_TABLE_SORT_ORDER",
	CREATE_NEW_MEETINGS: "CREATE_NEW_MEETINGS",
	CREATE_NEW_MEETINGS_SUCCESS: "CREATE_NEW_MEETINGS_SUCCESS",
	CREATE_NEW_MEETINGS_FAILURE: "CREATE_NEW_MEETINGS_FAILURE",
	UPDATE_MEETINGS_CONTENT: "UPDATE_MEETINGS_CONTENT",
	UPDATE_MEETINGS_CONTENT_SUCCESS: "UPDATE_MEETINGS_CONTENT_SUCCESS",
	UPDATE_MEETINGS_CONTENT_FAILURE: "UPDATE_MEETINGS_CONTENT_FAILURE",
	RESET_MEETINGS_DATA: "RESET_MEETINGS_DATA",
	GET_MEETINGS_TOKEN: "GET_MEETINGS_TOKEN",
	GET_MEETINGS_TOKEN_SUCCESS: "GET_MEETINGS_TOKEN_SUCCESS",
	GET_MEETINGS_TOKEN_FAILURE: "GET_MEETINGS_TOKEN_FAILURE",
	SET_MEETING_DATA: "SET_MEETING_DATA",
	RESET_MEETING_DATA: "RESET_MEETING_DATA",
	APPROVE_OR_CANCEL_MEETING_LIST: "APPROVE_OR_CANCEL_MEETING_LIST",
	APPROVE_OR_CANCEL_MEETING_LIST_SUCCESS:
		"APPROVE_OR_CANCEL_MEETING_LIST_SUCCESS",
	APPROVE_OR_CANCEL_MEETING_LIST_FAILURE:
		"APPROVE_OR_CANCEL_MEETING_LIST_FAILURE",
	SET_MEETING_ACTIVE_LIST: "SET_MEETING_ACTIVE_LIST",
	GET_MEETINGS_TEMPLATE_LIST: "GET_MEETINGS_TEMPLATE_LIST",
	GET_MEETINGS_TEMPLATE_LIST_SUCCESS: "GET_MEETINGS_TEMPLATE_LIST_SUCCESS",
	GET_MEETINGS_TEMPLATE_LIST_FAILURE: "GET_MEETINGS_TEMPLATE_LIST_FAILURE",
	SET_MEETINGS_TEMPLATE_TABLE_SIZE: "SET_MEETINGS_TEMPLATE_TABLE_SIZE",
	SET_MEETINGS_TEMPLATE_TABLE_CURRENT_PAGE:
		"SET_MEETINGS_TEMPLATE_TABLE_CURRENT_PAGE",
	SET_MEETINGS_TEMPLATE_TABLE_SORT_ORDER:
		"SET_MEETINGS_TEMPLATE_TABLE_SORT_ORDER",
	SET_MEETING_TEMPLATE_ID: "SET_MEETING_TEMPLATE_ID",
	MEETING_DUPLICATE_MODAL_VISIBLE: "MEETING_DUPLICATE_MODAL_VISIBLE",
	DUPLICATE_MEETING_TEMPLATE: "DUPLICATE_MEETING_TEMPLATE",
	DUPLICATE_MEETING_TEMPLATE_SUCCESS: "DUPLICATE_MEETING_TEMPLATE_SUCCESS",
	DUPLICATE_MEETING_TEMPLATE_FAILURE: "DUPLICATE_MEETING_TEMPLATE_FAILURE",
	MEETINGS_GET_EMPLOYEES: "MEETINGS_GET_EMPLOYEES",
	MEETINGS_GET_EMPLOYEES_SUCCESS: "MEETINGS_GET_EMPLOYEES_SUCCESS",
	MEETINGS_GET_EMPLOYEES_FAILURE: "MEETINGS_GET_EMPLOYEES_FAILURE",
	MEETINGS_SET_EMPLOYEE_PAGE: "MEETINGS_SET_EMPLOYEE_PAGE",
	MEETINGS_SET_EMPLOYEES_SEARCH_TEXT: "MEETINGS_SET_EMPLOYEES_SEARCH_TEXT",
	CREATE_MEETING_TIME_DETAILS: "CREATE_MEETING_TIME_DETAILS",
	FETCH_UPCOMING_MEETINGS: "FETCH_UPCOMING_MEETINGS",
	FETCH_UPCOMING_MEETINGS_SUCCESS: "FETCH_UPCOMING_MEETINGS_SUCCESS",
	FETCH_UPCOMING_MEETINGS_FAILURE: "FETCH_UPCOMING_MEETINGS_FAILURE",
	RESET_CREATE_MEETING_TIME_DETAILS: "RESET_CREATE_MEETING_TIME_DETAILS",
};

export default actions;
