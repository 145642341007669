export default {
	HOST_URL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
	BUGS_NAG_KEY: process.env.REACT_APP_BUGSNAG_KEY,
	DEFAULT_TEMPLATE_USER_EMAIL:
		process.env.REACT_APP_DEFAULT_TEMPLATE_USER_EMAIL,
	UNLAYER_PROJECT_KEY: process.env.REACT_APP_UNLAYER_PROJECT_KEY,
	GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY,
	APP_BASE_URL: process.env.REACT_APP_BASE_URL,
	MIX_PANEL_ID: "94f519d1ffadf0487b15f2a600a21cc3",
	GOOGLE_OAUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
	OUTLOOK_CLIENT_ID: process.env.REACT_APP_OUTLOOK_CLIENT_ID,
	OUTLOOK_TENANT_ID: process.env.REACT_APP_OUTLOOK_TENANT_ID,
	OUTLOOK_CLIENT_SECRET: process.env.REACT_APP_OUTLOOK_CLIENT_SECRET,
	SLACK_CLIENT_ID: process.env.REACT_APP_SLACK_CLIENT_ID,
	SLACK_CLIENT_SECRET: process.env.REACT_APP_SLACK_CLIENT_SECRET,
	REACT_APP_ENV_STATUS: process.env.REACT_APP_ENV,
};
