import { all, call, put, takeEvery } from "redux-saga/effects";
import { getRequest, postRequest } from "../../helpers/axiosClient";
import { getCompanyUrl, handleException } from "../helper";
import actions from "redux/moments/actions";
import { store } from "../store";
import {
	constructAssetsToken,
	notifyResponse,
	showNotification,
} from "../../components/utility/helper";

export function* getTemplateList(params) {
	try {
		const response = yield call(() => getRequest(`${getCompanyUrl()}/pages`));
		yield put({
			type: actions.ALL_TEMPLATES,
			payload: response.data.data.pages,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getEmployeesList() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/employees`),
		);
		yield put({
			type: actions.GET_EMPLOYEES_LIST,
			payload: response.data.data.employees,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* createMoment(params) {
	try {
		yield call(() => postRequest(`${getCompanyUrl()}/moments`, params.payload));
		yield put({
			type: actions.CHANGE_MOMENT_MODAL_STATUS,
			payload: false,
		});
		yield put({
			type: actions.GET_MOMENTS,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getMoments(params) {
	try {
		const { listSortKey, listSortValue, currentPage, listPerPage } =
			store.getState().Moments;
		const sortKey = params.sortKey || listSortKey;
		const page = params.page || currentPage;
		const sortOrder = params.sortOrder || listSortValue;
		const perPage = params.perPage || listPerPage;
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/moments?query[sorts][]=${sortKey} ${sortOrder}&query[sorts][]=id ${sortOrder}&page=${page}&per_page=${perPage}`,
			),
		);
		yield put({
			type: actions.MOMENTS,
			payload: response.data,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getMoment(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/moments/${params.payload.id}`),
		);
		yield put({
			type: actions.MOMENT,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getEmpMoment(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/emp_moments/${params.payload.page_token}`),
		);
		yield put({
			type: actions.EMP_RENDERING_DATA,
			payload: response.data.emp_moment,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getRoles(params) {
	try {
		const response = yield call(() => getRequest(`${getCompanyUrl()}/roles`));
		yield put({
			type: actions.COMPANY_ROLES,
			payload: response.data.data.roles,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* validateEmployeeRole(params) {
	try {
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/employees/${
					params.payload.employee_id
				}/validate_roles?role_id=${params.payload.role_id}`,
			),
		);
		yield put({
			type: actions.VALIDATED_EMPLOYEE_MOMENT,
			payload: response.data,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* createContribution(params) {
	try {
		const status = yield call(() =>
			postRequest("contributions", params.formData),
		);
		if (status !== undefined && status !== null) {
			notifyResponse("success", "Contribution created successfully!");
			yield put({
				type: actions.CONTRIBUTION_DRAWER_STATUS,
				payload: false,
			});
			yield put({
				type: actions.GET_CONTRIBUTIONS,
				payload: params.payload,
			});
		}
	} catch (error) {
		handleException(error);
	}
}

export function* getContributions(params) {
	try {
		const response = yield call(() =>
			getRequest(
				`contributions/contribution_index?page_token=${params.payload}`,
			),
		);
		yield put({
			type: actions.CONTRIBUTION_DATA,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getTemplateToken(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/emp_moments/validate_page_token`),
		);
		yield put({
			type: actions.EMP_PAGE_TOKEN,
			payload: response.data.page_token,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* getAssetTokens(params) {
	try {
		const response = yield call(() => getRequest(`${getCompanyUrl()}/tokens`));
		yield put({
			type: actions.ASSET_TOKENS,
			payload: response.data.data.tokens,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* deleteMomentsList(params) {
	try {
		const { selectedMomentList } = store.getState().Moments;
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/moments/destroy_moments`, {
				moments_list: selectedMomentList,
			}),
		);
		showNotification({
			type: response.data.status,
			message: "Destroyed successfully",
			description: response.data.data.message,
		});
		yield put({
			type: actions.GET_MOMENTS,
		});
		yield put({
			type: actions.MOMENT_SELECTED_LIST,
			payload: [],
		});
	} catch (error) {
		handleException(error);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_TEMPLATES_LIST, getTemplateList),
		takeEvery(actions.GET_TEMPLATES_LIST, getEmployeesList),
		takeEvery(actions.CREATE_MOMENT, createMoment),
		takeEvery(actions.GET_MOMENTS, getMoments),
		takeEvery(actions.GET_MOMENT, getMoment),
		takeEvery(actions.GET_EMP_PAGE_RENDERING_DATA, getEmpMoment),
		takeEvery(actions.GET_ROLES, getRoles),
		takeEvery(actions.VALIDATE_EMPLOYEE_ROLE, validateEmployeeRole),
		takeEvery(actions.CREATE_CONTRIBUTION, createContribution),
		takeEvery(actions.GET_CONTRIBUTIONS, getContributions),
		takeEvery(actions.SET_TEMPLATE_URL, getTemplateToken),
		takeEvery(actions.GET_ASSET_TOKENS, getAssetTokens),
		takeEvery(actions.DELETE_MOMENTS_LIST, deleteMomentsList),
	]);
}
