import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/Task/actions";
import { store } from "redux/store";
import { getRequest, patchRequest } from "helpers/axiosClient";
import {
	getCompanyUrl,
	getLocaleMessages,
	getUserId,
	handleException,
} from "redux/helper";
import { showNotification } from "components/utility/helper";
import employeeActions from "redux/employee/actions";

export function* fetchUserTaskList(params) {
	try {
		const { currentPage, taskSortKey, taskSortValue } = store.getState().Task;
		const { data } = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/users/${getUserId()}/tasks?query[sorts][]=${taskSortKey} ${taskSortValue}&query[sorts][]=id ${taskSortValue}&query[employee_events_users_status_gt]=initial&query[employee_events_users_user_id_eq]=${getUserId()}&page=${currentPage}&per_page=10`,
			),
		);
		yield put({
			type: actions.FETCH_USER_TASKS_SUCCESS,
			payload: data.data,
			isInfinite: params.isInfinite,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_USER_TASKS_FAILURE,
		});
	}
}

export function* fetchOverDueTasks(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/overdue_tasks?user_id=${getUserId()}`),
		);
		yield put({
			type: actions.FETCH_OVERDUE_TASKS_SUCCESS,
			payload: data.data,
			isInfinite: params.isInfinite,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_OVERDUE_TASKS_FAILURE,
		});
	}
}

export function* fetchUserTaskDetail(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/users/${getUserId()}/tasks/${params.id}`),
		);
		yield put({
			type: actions.FETCH_USER_TASK_CONTENT_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_USER_TASK_CONTENT_FAILURE,
		});
	}
}

export function* updateUserTask(params) {
	try {
		const { data } = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/users/${getUserId()}/tasks/${params.id}`,
				{ employee_event: params.payload },
			),
		);
		if (!params.payload.user_status) {
			showNotification({
				type: "success",
				message: data.data.message,
				description: getLocaleMessages("label.successDescription"),
			});
		}
		yield put({
			type: actions.UPDATE_USER_TASK_SUCCESS,
			payload: data.data.task,
			params,
		});
		if (params.payload.form_data) {
			store.dispatch({
				type: employeeActions.SET_TASK_SUBMIT_STATUS,
				payload: true,
			});
		}
		// generate files preview for captured image
		if (params.employeeTaskCallback) {
			params.employeeTaskCallback(data.data.task.files);
		}
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_USER_TASK_FAILURE,
		});
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_USER_TASKS, fetchUserTaskList),
		takeEvery(actions.FETCH_USER_TASK_CONTENT, fetchUserTaskDetail),
		takeEvery(actions.UPDATE_USER_TASK, updateUserTask),
		takeEvery(actions.FETCH_OVERDUE_TASKS, fetchOverDueTasks),
	]);
}
