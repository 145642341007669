import actions from "./actions";
import {
	bgImageText,
	defaultLocation,
	defaultPageSize,
	defaultPosition,
	defaultShape,
	emailLogoText,
	logoText,
	portalPage,
} from "components/constants";
import { isValid } from "redux/helper";

const initState = {
	homePageCurrentPage: 1,
	homePageList: [],
	totalHomePageData: 0,
	homePageSize: defaultPageSize,
	pageHeaderLoader: false,
	homePageTableLoader: false,
	homePageDrawerVisible: false,
	selectedHomePageList: [],
	homePageModalVisible: false,
	homePageModalLoader: false,
	homePageSortKey: "created_at",
	homePageSortOrder: "desc",
	portalActiveList: "",
	formLoader: false,
	portalID: null,
	homePageJson: null,
	contentName: "",
	homePageModifiedAt: null,
	portalToken: [],
	homePageContentHtml: null,
	homePageFormType: null,
	landingPageJson: null,
	landingPageContentName: "",
	landingPageModifiedAt: null,
	landingPageContentHtml: null,
	landingPageFormType: null,
	homePagePublishModalVisible: false,
	homePageUnPublishModalVisible: false,
	homePagePublishModalLoader: false,
	homePageUnPublishModalLoader: false,
	setPublishData: {
		id: null,
		published: null,
	},
	loadDesign: false,
	profileLoader: false,
	companyDetails: {},
	bgImageKey: "",
	logoKey: "",
	imageLoader: {
		[logoText]: false,
		[bgImageText]: false,
	},
	isUpdatingCompany: false,
	isShowPreviewModal: false,
	previewCompanyData: {},
	isShowAlertModal: false,
	domainLoader: false,
	deleteModalVisible: false,
	portalEmailPrefix: [],
	email_prefix_status: null,
};

export default function portalReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_HOME_PAGE_LIST: {
			return {
				...state,
				homePageTableLoader: true,
				pageHeaderLoader: true,
			};
		}
		case actions.GET_HOME_PAGE_LIST_SUCCESS: {
			return {
				...state,
				homePageTableLoader: false,
				homePageList: action.payload.pages,
				totalHomePageData: action.payload.total_records,
				pageHeaderLoader: false,
			};
		}
		case actions.GET_HOME_PAGE_LIST_FAILURE: {
			return {
				...state,
				homePageTableLoader: false,
				pageHeaderLoader: false,
			};
		}
		case actions.SET_HOME_PAGE_DRAWER_VISIBLE: {
			return {
				...state,
				homePageDrawerVisible: action.payload.visible,
				homePageFormType: action.payload.visible
					? action.payload.formType
					: null,
				formLoader: action.payload.visible ? state.formLoader : false,
			};
		}
		case actions.HOME_PAGE_SELECTED_LIST: {
			return {
				...state,
				selectedHomePageList: action.payload,
			};
		}
		case actions.DELETE_HOME_PAGE_CONFIRMATION_MODAL_VISIBLE: {
			return {
				...state,
				homePageModalVisible: action.payload,
			};
		}
		case actions.DELETE_HOME_PAGE_LIST: {
			return {
				...state,
				homePageModalLoader: true,
			};
		}
		case actions.DELETE_HOME_PAGE_LIST_SUCCESS: {
			return {
				...state,
				homePageModalLoader: false,
				homePageModalVisible: false,
				selectedHomePageList: [],
			};
		}
		case actions.DELETE_HOME_PAGE_LIST_FAILURE: {
			return {
				...state,
				homePageModalLoader: false,
			};
		}
		case actions.SET_HOME_PAGE_TABLE_SIZE: {
			return {
				...state,
				homePageSize: action.payload,
			};
		}
		case actions.SET_HOME_PAGE_TABLE_CURRENT_PAGE: {
			return {
				...state,
				homePageCurrentPage: action.payload,
			};
		}
		case actions.SET_HOME_PAGE_TABLE_SORT_ORDER: {
			return {
				...state,
				homePageSortKey: action.payload.sortKey,
				homePageSortOrder: action.payload.sortOrder,
			};
		}
		case actions.SET_PORTAL_ACTIVE_LIST: {
			return {
				...state,
				portalActiveList: action.payload,
			};
		}
		case actions.CREATE_NEW_HOME_PAGE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_HOME_PAGE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				homePageDrawerVisible: false,
				homePageFormType: null,
				portalActiveList: portalPage,
			};
		}
		case actions.CREATE_NEW_HOME_PAGE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}
		case actions.GET_HOME_PAGE_DATA: {
			return {
				...state,
				contentName: "",
				homePageJson: null,
				homePageModifiedAt: null,
				pageHeaderLoader: true,
				contentSubject: "",
				loadDesign: false,
			};
		}
		case actions.GET_HOME_PAGE_DATA_SUCCESS: {
			return {
				...state,
				homePageJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				portalID: action.payload.page.id,
				contentName: action.payload.page.name,
				homePageModifiedAt: action.payload.page.modified_at,
				homePageContentHtml: action.payload.page.content,
				pageHeaderLoader: false,
				contentSubject: action.payload.page.subject,
				loadDesign: true,
			};
		}
		case actions.GET_HOME_PAGE_DATA_FAILURE: {
			return {
				...state,
				pageHeaderLoader: false,
			};
		}
		case actions.UPDATE_HOME_PAGE_CONTENT: {
			return {
				...state,
				formLoader: state.homePageDrawerVisible ? true : state.formLoader,
				loadDesign: false,
			};
		}
		case actions.UPDATE_HOME_PAGE_CONTENT_SUCCESS: {
			return {
				...state,
				contentName: action.payload.page.name,
				homePageModifiedAt: action.payload.page.modified_at,
				homePageJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				homePageContentHtml: action.payload.page.content,
				formLoader: false,
				homePageDrawerVisible: false,
				homePageFormType: null,
				loadDesign: false,
			};
		}
		case actions.UPDATE_HOME_PAGE_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
				homePageDrawerVisible: false,
			};
		}
		case actions.RESET_HOME_PAGE_DATA: {
			return {
				...state,
				portalID: null,
				homePageJson: null,
				contentName: "",
				homePageModifiedAt: "",
				homePageContentHtml: "",
				portalToken: [],
				landingPageJson: null,
				landingPageContentName: "",
				landingPageModifiedAt: "",
				landingPageContentHtml: "",
				loadDesign: false,
			};
		}
		case actions.SET_LANDING_PAGE_DRAWER_VISIBLE: {
			return {
				...state,
				landingPageDrawerVisible: action.payload.visible,
				landingPageFormType: action.payload.visible
					? action.payload.formType
					: null,
				formLoader: action.payload.visible ? state.formLoader : false,
			};
		}
		case actions.CREATE_NEW_LANDING_PAGE: {
			return {
				...state,
				formLoader: true,
			};
		}
		case actions.CREATE_NEW_LANDING_PAGE_SUCCESS: {
			return {
				...state,
				formLoader: false,
				landingPageDrawerVisible: false,
				landingPageFormType: null,
				portalActiveList: portalPage,
			};
		}
		case actions.CREATE_NEW_LANDING_PAGE_FAILURE: {
			return {
				...state,
				formLoader: false,
			};
		}

		case actions.GET_LANDING_PAGE_DATA: {
			return {
				...state,
				landingPageContentName: "",
				landingPageJson: null,
				landingPageModifiedAt: null,
				pageHeaderLoader: true,
				loadDesign: false,
			};
		}
		case actions.GET_LANDING_PAGE_DATA_SUCCESS: {
			return {
				...state,
				landingPageJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				portalID: action.payload.page.id,
				landingPageContentName: action.payload.page.name,
				landingPageModifiedAt: action.payload.page.modified_at,
				landingPageContentHtml: action.payload.page.content,
				pageHeaderLoader: false,
				loadDesign: true,
			};
		}
		case actions.GET_LANDING_PAGE_DATA_FAILURE: {
			return {
				...state,
				pageHeaderLoader: false,
			};
		}
		case actions.UPDATE_LANDING_PAGE_CONTENT: {
			return {
				...state,
				formLoader: state.landingPageDrawerVisible ? true : state.formLoader,
				loadDesign: false,
			};
		}
		case actions.UPDATE_LANDING_PAGE_CONTENT_SUCCESS: {
			return {
				...state,
				landingPageContentName: action.payload.page.name,
				landingPageModifiedAt: action.payload.page.modified_at,
				landingPageJson: action.payload.page.raw_json
					? [JSON.parse(action.payload.page.raw_json)]
					: [],
				landingPageContentHtml: action.payload.page.content,
				formLoader: false,
				landingPageFormType: null,
				landingPageDrawerVisible: false,
				loadDesign: false,
			};
		}
		case actions.UPDATE_LANDING_PAGE_CONTENT_FAILURE: {
			return {
				...state,
				formLoader: false,
				landingPageDrawerVisible: false,
			};
		}
		case actions.SET_HOME_PAGE_PUBLISH_MODAL_VISIBLE: {
			const { id, published } = state.setPublishData;
			return {
				...state,
				homePagePublishModalVisible: action.payload,
				setPublishData: {
					id: action.payload ? id : null,
					published: action.payload ? published : null,
				},
			};
		}
		case actions.PUBLISH_HOME_PAGE: {
			return {
				...state,
				homePagePublishModalLoader: true,
			};
		}
		case actions.PUBLISH_HOME_PAGE_SUCCESS: {
			return {
				...state,
				homePagePublishModalLoader: false,
				homePagePublishModalVisible: false,
				setPublishData: {
					id: null,
					published: null,
				},
			};
		}
		case actions.PUBLISH_HOME_PAGE_FAILURE: {
			return {
				...state,
				homePagePublishModalLoader: false,
			};
		}
		case actions.GET_HOME_PAGE_TOKEN_SUCCESS: {
			return {
				...state,
				portalToken: action.payload,
			};
		}
		case actions.SET_PUBLISH_DATA: {
			const { id, published } = action.payload;
			return {
				...state,
				setPublishData: {
					id: id,
					published: published,
				},
			};
		}
		case actions.FETCH_COMPANY_DETAILS:
			return {
				...state,
				profileLoader: true,
			};
		case actions.FETCH_COMPANY_DETAILS_SUCCESS:
			const { chat_widget_position, chat_widget_shape, direction } =
				action.payload;
			return {
				...state,
				profileLoader: false,
				companyDetails: {
					...action.payload,
					direction: isValid(direction) ? direction : defaultLocation,
					chat_widget_shape: isValid(chat_widget_shape)
						? chat_widget_shape
						: defaultShape,
					chat_widget_position: isValid(chat_widget_position)
						? chat_widget_position
						: defaultPosition,
				},
			};
		case actions.UPLOAD_IMAGE_FILE_SUCCESS:
			return {
				...state,
				logoKey:
					action.imageType === logoText || action.imageType === emailLogoText
						? action.signedId
						: state.logoKey,
				bgImageKey:
					action.imageType === bgImageText ? action.signedId : state.bgImageKey,
				imageLoader: false,
			};
		case actions.UPLOAD_IMAGE_FILE:
			return {
				...state,
				imageLoader: {
					...state.imageLoader,
					[action.imageType]: true,
				},
			};
		case actions.UPLOAD_IMAGE_FILE_FAILURE:
			return {
				...state,
				imageLoader: {
					...state.imageLoader,
					[action.imageType]: false,
				},
			};
		case actions.UPDATE_COMPANY_DETAILS:
			return {
				...state,
				isUpdatingCompany: true,
			};
		case actions.UPDATE_COMPANY_DETAILS_SUCCESS:
			return {
				...state,
				companyDetails: action.payload,
				portalEmailPrefix: action.payload.sendgrid_prefixs,
				isUpdatingCompany: false,
				logoKey: "",
				bgImageKey: "",
			};
		case actions.UPDATE_COMPANY_DETAILS_FAILURE:
			return {
				...state,
				isUpdatingCompany: false,
			};
		case actions.SET_PREVIEW_MODAL_VISIBLE:
			return {
				...state,
				isShowPreviewModal: action.payload,
			};
		case actions.SET_PREVIEW_DATA:
			return {
				...state,
				previewCompanyData: action.payload,
			};
		case actions.UPDATE_PORTAL_IMAGES:
			return {
				...state,
				companyDetails: {
					...state.companyDetails,
					...action.payload,
				},
			};
		case actions.EMAIL_SETTINGS_ALERT_MODAL_VISIBLE:
			return {
				...state,
				isShowAlertModal: action.payload,
			};
		case actions.CREATE_COMPANY_DOMAIN:
			return {
				...state,
				domainLoader: true,
			};
		case actions.CREATE_COMPANY_DOMAIN_SUCCESS:
			return {
				...state,
				domainLoader: false,
				domainDetails: action.payload,
			};
		case actions.UPDATE_COMPANY_DOMAIN:
			return {
				...state,
				domainLoader: true,
			};
		case actions.UPDATE_COMPANY_DOMAIN_SUCCESS:
			return {
				...state,
				domainLoader: false,
				domainDetails: action.payload,
			};
		case actions.UPDATE_COMPANY_DOMAIN_FAILURE:
			return {
				...state,
				domainLoader: false,
			};
		case actions.DELETE_COMPANY_DOMAIN:
			return {
				...state,
				deleteLoader: true,
				domainDetails: action.payload,
			};
		case actions.DELETE_COMPANY_DOMAIN_SUCCESS:
			return {
				...state,
				deleteLoader: false,
				deleteModalVisible: false,
			};
		case actions.VERIFY_COMPANY_DOMAIN:
			return {
				...state,
				verifyLoader: true,
			};
		case actions.VERIFY_COMPANY_DOMAIN_SUCCESS:
			return {
				...state,
				verifyLoader: false,
			};
		case actions.VERIFY_COMPANY_DOMAIN_FAILURE:
			return {
				...state,
				verifyLoader: false,
			};
		case actions.SET_DELETE_MODAL_VISIBLE:
			return {
				...state,
				deleteModalVisible: action.payload,
			};
		case actions.REMOVE_TEMP_PREFIX:
			return {
				...state,
				email_prefix_status: action.payload,
			};
		default:
			return state;
	}
}
