import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import asyncComponent from "helpers/asyncComponent";
import App from "./containers/App/App";
import { InitialLoader } from "containers/Pages/InitialLoader";
import { useSelector } from "react-redux";
import { userPublicRoutes, employeePublicRoutes } from "routes/publicRoutes";
import EmployeePage from "containers/App/EmployeePage";
import { onBoardEmployee } from "components/constants";
import { getRoleType, isEmployeeApp } from "components/utility/helper";
import FinalMomentExperience from "containers/MomentsThatMatter/FinalMomentExperience";
import RedirectSlackPage from "./components/Settings/SlackComp/RedirectSlackPage";
import GmailIntegration from "./components/Settings/EmailComps/GmailIntegration";
import OutlookIntegration from "./components/Settings/EmailComps/OutlookIntegration";

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLoggedIn ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);

const PreventedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			let { from } = props.location.state || {
				from: {
					pathname: `/${
						getRoleType() === onBoardEmployee ? "employee" : "company"
					}`,
				},
			};
			return isLoggedIn ? <Redirect to={from} /> : <Component {...props} />;
		}}
	/>
);

const PublicRoutes = ({ history, subDomain }) => {
	const { isLoggedIn, validatingAuthToken, role, isGettingPermissions } =
		useSelector((state) => state.Auth);

	if (validatingAuthToken || isGettingPermissions) {
		return <InitialLoader />;
	}

	return (
		<Router history={history}>
			<Switch>
				<Route path={"/gmail-integration"} component={GmailIntegration} />
				<Route path={"/outlook-integration"} component={OutlookIntegration} />
				{localStorage.getItem("fullUrl").includes("/slack-integration") ? (
					<Route path={"/slack-integration"} component={RedirectSlackPage} />
				) : null}
				{role === onBoardEmployee ? (
					localStorage.getItem("fullUrl").includes("/moment/") &&
					localStorage.getItem("fullUrl").includes("/auth") ? (
						<Route
							path={"/moment/:empMomentToken/auth"}
							component={FinalMomentExperience}
						/>
					) : (
						<PrivateRoute
							path={"/employee"}
							component={EmployeePage}
							isLoggedIn={isLoggedIn}
						/>
					)
				) : (
					<PrivateRoute
						path={"/company"}
						component={App}
						isLoggedIn={isLoggedIn}
					/>
				)}
				{isEmployeeApp(subDomain)
					? employeePublicRoutes.map((routes) => (
							<PreventedRoute
								exact
								key={routes.path}
								path={routes.path}
								component={routes.component}
								isLoggedIn={isLoggedIn}
							/>
					  ))
					: userPublicRoutes.map((routes) => (
							<PreventedRoute
								exact
								key={routes.path}
								path={routes.path}
								component={routes.component}
								isLoggedIn={isLoggedIn}
							/>
					  ))}
				<Route
					component={asyncComponent(() =>
						import("./containers/Pages/NotFound"),
					)}
				/>
			</Switch>
		</Router>
	);
};

export default PublicRoutes;
