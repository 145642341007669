import styled from "styled-components";
import variables from "styles/variables";
import { bgImageText } from "components/constants";

export const CustomPickerStyles = styled.div`
	position: relative;
	.picker {
		background: ${(props) =>
			props.bgColor ? props.bgColor : variables.WHITE_COLOR};
		border: 1px solid ${variables.BORDER_COLOR};
		position: relative;
		width: ${(props) => props.size}px;
		height: ${(props) => props.size}px;
		margin-right: 10px;
		border-radius: 50%;
		cursor: pointer;
		border: 1px solid ${variables.BORDER_COLOR};
		svg {
			width: 100%;
			height: 55%;
			top: 7px;
			position: relative;
			transform: rotate(45deg);
		}
	}
	.chrome-picker {
		z-index: 9;
		${(props) => props.position}: 50px;
		position: absolute;
		padding: 8px;
	}
`;
export const MessengerIconStyles = styled.div`
	& {
		position: ${(props) => props.position};
		bottom: ${(props) => props.bottom};
		right: ${(props) => props.right};
		left: ${(props) => props.left};
		z-index: 9;
		.messenger-icon {
			width: 60px;
			height: 60px;
			border-radius: ${(props) =>
				props.shape === "circle" ? "50%" : variables.BORDER_RADIUS_BASE};
			opacity: 0.5;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0 0 8px 6px ${variables.BOX_SHADOW_COLOR};
			&.selected {
				opacity: 1;
			}
			svg {
				width: 30px;
				height: 30px;
				opacity: 1;
				filter: ${(props) => (props.isFilter ? "invert(1)" : "")};
			}
		}
	}
`;
export const ColorGroupStyles = styled.div`
	& {
		pointer-events: ${(props) => (props.disabled ? "none" : "all")};
		cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
		.color-or {
			font-weight: 600;
		}
		.ant-input {
			width: 112px;
			padding: 6px 11px;
		}
		.ant-row {
			margin-left: 0 !important;
			margin-right: 0 !important;
			.default-colors {
				position: relative;
				.color-picker {
					width: ${(props) => props.size}px;
					height: ${(props) => props.size}px;
					border-radius: 50%;
					cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
					position: relative;
					border: 1px solid ${variables.BORDER_COLOR};
				}
				.checked {
					position: absolute;
					top: calc(50% - 10px);
					left: calc(50% - 10px);
					color: white;
					font-size: 20px;
				}
			}
		}
	}
`;
export const UploadStyles = styled.div`
	.ant-upload {
		height: ${(props) =>
			props.imageLoader ? "165px" : props.emailSettings && "150px"};
		width: ${(props) => props.emailSettings && "150px"};
		& p:not(:last-child):not(.ant-upload-drag-icon) {
			margin-bottom: ${(props) =>
				props.emailSettings ? "6px" : variables.SQ_SPACING_SMALL};
		}
		& p:last-child:not(.ant-upload-drag-icon) {
			color: ${variables.PRIMARY_COLOR};
		}
		.ant-upload-drag-container {
			.ant-upload-drag-icon {
				margin-bottom: ${(props) => props.emailSettings && "10px"};
			}
		}
		&.ant-upload-drag {
			margin-top: ${(props) => props.emailSettings && "12px"};
			.ant-upload {
				padding: ${(props) => (props.type === bgImageText ? 0 : "16px 0")};
			}
		}
		.remove {
			position: absolute;
			top: -22px;
			right: -10px;
			svg {
				font-size: 20px;
			}
			&:hover {
				.anticon-close-circle {
					color: ${variables.DANGER_COLOR};
				}
			}
		}
		.ant-spin {
			position: absolute;
			top: 45%;
			filter: brightness(0.5);
		}
	}
	.note {
		margin-top: 10px;
	}
`;
export const ProfileStyles = styled.div`
	margin-top: 20px;
	padding: 10px;
	.ant-skeleton {
		margin-top: 20px;
		li:not(:first-child) {
			margin-top: 30px;
		}
	}
	.launcher-header {
		font-weight: 600;
		margin: 10px 0px;
	}
`;
