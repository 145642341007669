import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import SidebarMenu from "components/Sidebar/SidebarMenu";
import SidebarContainer, {
	ProfileWrapper,
	SidebarGlobalStyles,
} from "containers/Sidebar/Sidebar.styles";
import { ProfileGlobalStyles } from "styles/Shared.style";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/app/actions";
import Profile from "components/Shared/Profile";
import { Link } from "react-router-dom";
import logo from "assets/images/logo.svg";
import { store } from "redux/store";
import { getCurrentPath } from "redux/helper";
import { onBoardEmployee, routerRoles } from "components/constants";
import { getBase64, getMenus } from "components/utility/helper";
import authActions from "redux/auth/actions";

const { Sider } = Layout;

function Sidebar() {
	const { currentKey, isAdmin, role, profileImageUrl, profileLoader } =
			useSelector((state) => ({
				...state.App,
				...state.Auth,
			})),
		dispatch = useDispatch();

	useEffect(() => {
		store.dispatch({
			type: actions.CHANGE_CURRENT_MENU,
			payload: getCurrentPath(),
		});
	}, [role]);

	function handleClick(changedKey) {
		dispatch({ type: actions.CHANGE_CURRENT_MENU, payload: [changedKey.key] });
	}

	function onCustomRequest(info) {
		getBase64(info.file, (profileImageUrl) => {
			const formData = new FormData();
			formData.append("user[avatar]", info.file);
			dispatch({
				type: authActions.UPDATE_PROFILE_IMAGE,
				url: profileImageUrl,
				formData,
			});
		});
	}

	const [collapse, setCollapse] = useState(true);

	return (
		role !== onBoardEmployee && (
			<>
				<SidebarGlobalStyles />
				<ProfileGlobalStyles />
				<SidebarContainer>
					<div className={"logo-container"}>
						<Link to={"/dashboard/employees"}>
							<img
								className={collapse ? "logo-image-collapse" : "logo-image"}
								src={logo}
								alt={"logo"}
							/>
						</Link>
					</div>

					<Sider
						width={265}
						collapsed={collapse}
						onMouseOver={() => setCollapse(false)}
						onMouseLeave={() => setCollapse(true)}
					>
						<Menu
							theme="dark"
							mode="vertical"
							selectedKeys={currentKey}
							onClick={handleClick}
						>
							{role !== null &&
								getMenus(routerRoles[`${role}`].menu, role).map(
									(singleOption) => (
										<SidebarMenu
											key={singleOption.key}
											singleOption={singleOption}
											isAdmin={isAdmin}
										/>
									),
								)}
						</Menu>
						<ProfileWrapper profileLoader={profileLoader}>
							<Profile
								onChangeProfile={onCustomRequest}
								size={50}
								imageUrl={profileImageUrl}
								profileLoader={profileLoader}
								maxSize={2}
							/>
						</ProfileWrapper>
					</Sider>
				</SidebarContainer>
			</>
		)
	);
}

export default React.memo(Sidebar);
