import { store } from "redux/store";
import actions from "../../../redux/settings/users/actions";
import settings from "../../../config/settings";
import { showNotification } from "components/utility/helper";

let apiCount = 0;
let notify = false;
function OutlookIntegration() {
	const location = window.location;
	const searchParams = new URLSearchParams(location.search);
	const code = searchParams.get("code");
	const state = searchParams.get("state");
	const error = searchParams.get("error");
	const authState = localStorage.getItem("outlook-oauth-state");
	localStorage.removeItem("outlook-oauth-state");
	if (error === null && code !== null && apiCount < 1 && state === authState) {
		apiCount = 1;
		const isUpdate = localStorage.getItem("outlook-update");
		const integrationId = localStorage.getItem("outlook-integration-id");
		localStorage.removeItem("outlook-update");
		localStorage.removeItem("outlook-integration-id");
		if (!isUpdate) {
			store.dispatch({
				type: actions.CREATE_INTEGRATION_TOKEN,
				payload: {
					integration_type: "outlook",
					code: code,
				},
			});
		} else {
			store.dispatch({
				type: actions.UPDATE_INTEGRATION_TOKEN,
				payload: {
					integration_type: "outlook",
					code: code,
					id: integrationId,
				},
			});
		}
	}

	if (
		location.href !== `${location.origin}/outlook-integration` &&
		error !== null &&
		notify === false
	) {
		notify = true;
		showNotification({
			type: "error",
			message: error,
			description: "Failed to authorize Outlook!",
		});
		setTimeout(() => {
			window.close();
		}, 3000);
	}

	if (location.href === `${location.origin}/outlook-integration`) {
		const state = Math.random().toString(16).slice(2);
		localStorage.setItem("outlook-oauth-state", state);
		const uri = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
			settings.OUTLOOK_CLIENT_ID
		}&redirect_uri=${
			settings.APP_BASE_URL
		}outlook-integration&response_type=code&scope=offline_access mail.send&state=${localStorage.getItem(
			"outlook-oauth-state",
		)}&prompt=select_account`;
		window.location = uri;
	}
	return null;
}

export default OutlookIntegration;
